import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Form } from 'reactstrap';
import moment from 'moment';
import Cards from 'react-credit-cards';
import SVG from 'react-inlinesvg';
import { Redirect } from 'react-router-dom';

import i18n from '../../translations/i18n';

import Loading from "./UI/Loading";

import SignUpModal from '../../containers/Page/SignUpModal';
import SignUpModalComponent from './SignUpModal';

import Login from '../../containers/Page/Login';
import LoginComponent from './Login';

import { useToasts } from 'react-toast-notifications'

import { time, book, locationSharp } from "ionicons/icons";


import 'react-credit-cards/es/styles-compiled.css';

const CustomOrder = ({ user, order, cards, handleUpdateOrderInput, isOpenSignUp, isOpenLogin, toggleSignUp, toggleLogin, handlePayOrder, isLoading, toastMessage, redirect }) => { 
    
    const { addToast } = useToasts();
    const [focusedField, setFocusedField] = useState('name');

console.log('***** order', order);
    return(
    <>
        { isLoading || !order ? <Loading /> : undefined }
        { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }
        { redirect ? <Redirect to='/confirmation' /> : undefined }
        { !user.token ? 
            <div id="accountPage" className="paddingContainer">
                <h1 style={{ textAlign: "center"}}>{i18n.t('global.notLoggedTitle')}</h1>
                <div class="not_logged" style={{ textAlign: "center"}}>
                    {i18n.t('global.notLoggedSubtitleShort')}<div class="login_link" onClick={toggleLogin}>{i18n.t('global.notLoggedSubtitleEnd')}</div>
                </div>
            </div>
            
            :

            !order ? 

            <div id="accountPage" className="paddingContainer">
                <h1 style={{ textAlign: "center"}}>{i18n.t('payment.orderNotFound')}</h1>
                <p style={{ textAlign: "center"}}>{i18n.t('payment.orderNotFoundDesc')}</p>
            </div>

            :

            <div id="pickupSection" className="paddingContainer">
                <h2>{i18n.t('pickup.summaryOrder')}</h2>

                <Form className="pickupForm">
                    <div className="deliveryResume">
                        <div className="deliveryTitle">
                            <div className="titleResume">
                                <SVG src={locationSharp} alt="icon location" className="iconResume"/>
                                {i18n.t('pickup.startAddress')}
                            </div>
                        </div>

                        <div className="deliveryText">
                            <div className="deliveryAddress">{ order.contactAddress.full }</div>
                        </div>
                    </div>

                    { order && order.isADelivery ? 
                    <div className="deliveryResume">
                        <div className="deliveryTitle">
                            <div className="titleResume">
                                <SVG src={locationSharp} alt="icon location" className="iconResume"/>
                                {i18n.t('pickup.deliveryAddress')}
                            </div>
                        </div>

                        <div className="deliveryText">
                            <div className="deliveryAddress">{ order.deliveryAddress.full }</div>
                        </div>
                    </div> : undefined }

                    <div className="deliveryResume">
                        <div className="deliveryTitle">
                            <div className="titleResume">
                                <SVG src={time} alt="icon time" className="iconResume"/>
                                {i18n.t('pickup.datePreference')}
                            </div>
                        </div>

                        <div className="deliveryText">
                    <div className="deliveryAddress">{i18n.t('global.pickup')}: { moment.unix(order.deliveryDate / 1000).format("DD/MM/YYYY HH:mm:ss") } </div>
                        </div>
                    </div>

                    <div className="deliveryResume">
                        <div className="deliveryTitle">
                            <div className="titleResume">
                                <SVG src={book} alt="icon location" className="iconResume"/>
                                {i18n.t('payment.orderInfo')}
                            </div>
                        </div>

                        <div className="deliveryText">
                            <div className="deliveryAddress">{ order.details.split('\n').map((i, index) => {
                                                                return <p key={index} style={{ margin: "0px 10px"}}>{i}</p>
                                                            })}
                            </div>
                        </div>
                    </div>

                    <FormGroup className="pickupOrder">
                        <div className="caegoryTitle">
                            {i18n.t('pickup.customOrderFee')}
                        </div>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.totalOrder')}</div>
                            <div className="price" style={  order.voucherCode?.isOnProduct ? {textDecoration: 'line-through'} : undefined }>{ order.price.toFixed(2)}$</div>
                        </div>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.deliveryFee')}</div>
                            <div className="price" style={ order.voucherCode?.isOnDeliveryOnly ? {textDecoration: 'line-through'} : undefined }>{ order.deliveryPrice.toFixed(2)}$</div>
                        </div>
                        { order.voucherCode ?
                            <>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.reductionCode')}</div>
                                <div className="price">{ order?.voucherCode?.name} ({ order?.voucherCode?.percentage}%)</div>
                            </div>

                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                                <div className="price">{ (order?.deliveryPrice + order.price - order?.discount).toFixed(2)}$</div>
                            </div>
                            </>
                            : undefined
                        }
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.fees')}</div>
                            <div className="price">{ order.taxes.toFixed(2)}$</div>
                        </div>

                        <hr/>

                        <div className="commandTotal">
                            <div className="totalTitle">{i18n.t('payment.total')}</div>
                            <div className="total">{ order.totalPrice.toFixed(2)}$</div>
                        </div>
                    </FormGroup>

                    { cards && cards.map( (card, index) => (
                        <Label className="form-check" key={index}>
                            <Input type="radio" checked={order.paymentCard === card.id} name="paymentCard" onChange={ e => handleUpdateOrderInput({ target: { name:'paymentCard', value: card.id }}) }/>{card.brand} - •••• •••• •••• {card.last4}
                        </Label>
                    ))}

                    <Label className="form-check">
                        <Input type="radio" name="paymentCard" checked={order.paymentCard === 'other'} value="other" onChange={ e => handleUpdateOrderInput({ target: { name:'paymentCard', value: 'other'} }) }/>{i18n.t('global.other')}
                    </Label>

                    { order.paymentCard === 'other' ? 
                        <div className="clientInfo">
                            <Label>
                                {i18n.t('payment.creditCard')}
                            </Label>
                            <Row>
                                <Col>
                                    <Cards
                                        cvc={order.otherPaymentCardCVC ? order.otherPaymentCardCVC : ''}
                                        expiry={order.otherPaymentCardExpiry ? order.otherPaymentCardExpiry : ''}
                                        name={order.otherPaymentCardName ? order.otherPaymentCardName : ''}
                                        number={order.otherPaymentCardNumber ? order.otherPaymentCardNumber : ''}
                                        focused={focusedField}
                                        locale={{valid: ""}}
                                        placeholders={{name: "Martin Jean"}}
                                    />
                                </Col>
                                <Col>
                            
                                    <Row className="reservationRow">
                                        <Col>
                                            <Input type="text" placeholder={i18n.t('payment.name')} onFocus={e => {setFocusedField('name')}} name='otherPaymentCardName' value={order.otherPaymentCardName ? order.otherPaymentCardName : ''} onChange={ e => handleUpdateOrderInput(e) }></Input>
                                        </Col>
                                    </Row>
                                    
                                    <Row className="reservationRow">
                                        <Col>
                                            <Input type="text" placeholder={i18n.t('payment.number')} onFocus={e => {setFocusedField('number')}} name='otherPaymentCardNumber' value={order.otherPaymentCardNumber ? order.otherPaymentCardNumber : ''} onChange={ e => handleUpdateOrderInput(e) } maxLength="19"></Input>
                                        </Col>
                                    </Row>

                                    <Row className="reservationRow">
                                        <Col>
                                            <Input type="text" placeholder={i18n.t('payment.mmyy')} onFocus={e => {setFocusedField('expiry')}} name='otherPaymentCardExpiry' value={order.otherPaymentCardExpiry ? order.otherPaymentCardExpiry : ''} onChange={ e => handleUpdateOrderInput(e) }></Input>
                                        </Col>

                                        <Col>
                                            <Input type="text" placeholder={i18n.t('payment.cvc')} onFocus={e => {setFocusedField('cvc')}} name='otherPaymentCardCVC' value={order.otherPaymentCardCVC ? order.otherPaymentCardCVC : ''} onChange={ e => handleUpdateOrderInput(e) }></Input>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        :
                        undefined
                    }

                        
                    <FormGroup className="pickupButton">
                        <Button
                            onClick={ e => handlePayOrder(e) } 
                            className="nextButton">
                                {i18n.t('payment.payTheOrder')}
                        </Button>
                    </FormGroup>
                </Form>
            </div>
        }

        <SignUpModal isOpen={isOpenSignUp} toggle={toggleSignUp} toggleLogin={toggleLogin} Layout={SignUpModalComponent} />
        <Login isOpen={isOpenLogin} toggle={toggleLogin} toggleSignUp={toggleSignUp} Layout={LoginComponent} />
    </>
    )
}

export default CustomOrder;