import React, { useState } from 'react';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { resizeImage } from '../../../config/utils';
import StoreProductSingleContainer from '../../../containers/Store/StoreProductSingle';
import StoreProductSingle from "./StoreProductSingle";
import Cart from '../../../containers/Page/Cart';
import CartComponent from '../Cart';
import Loading from '../UI/Loading';
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import Skeleton from 'react-loading-skeleton';
import Sticky from 'react-sticky-el';

import { SkeletonProduct } from '../UI/Skeleton';

import i18n from '../../../translations/i18n';
import { numberOfCommentsRange } from '../../../utils/utils'

const fuseSearch = (options) => {
    const fuse = new Fuse(options, {
        keys: ['name', 'name_fr', 'name_en'],
        threshold: 0.3,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }
        return fuse.search(value).map(item => item);
    };
}


const StoreSingleComponent = ({ store, products, cart, isCartSticky, handleAddProductToCart, handleRemoveProductToCart, chooseService, chooseProduct, isLoading, isFetching, categoryRef }) => {
    const { search } = useLocation();
    
    const formattedPhone = store.phone && store.phone.length === 10 ? store.phone.substring(0,3) + '-' + store.phone.substring(3,6) + '-' + store.phone.substring(6, 10) : '';

    const lProducts = products.filter(p => !p.isService)
    const lServices = products.filter(p => p.isService)

    let displayedCat = 0;
    let otherCats = [];


    let catRefs = [];
    let prodRefs = [];
    const [selectedProduct, setSelectedProduct] = useState();

    const scrollToCategory = (cat) => {
        setSelectedCat(cat);
        catRefs[cat].scrollIntoView(true);
        window.scrollBy(0, -170);
    }

    const handleSearchClick = (value) => {
        /**
         * Uncomment following code if open product modal is desired
         */
         setSelectedProduct(value);

        /**
         * Uncomment following code if scroll to product is desired
         */
        // prodRefs[`product_#${value}`].scrollIntoView(true);
        // window.scrollBy(0, -170);
    }

    const [dropdownOpen, setOpen] = useState(false);
    const [selectedCat, setSelectedCat] = useState('');


    const toggle = () => setOpen(!dropdownOpen);

    let currentCat = '';

    if(isLoading) return <Loading emptyPage={true} />

    return (
        <>
        { isFetching ? <Skeleton height={350}/> : 
        
        <div id="storeBanner" style={!store.available || store.currentlyUnavailable ? {
            backgroundImage: "url('" + resizeImage(store.mainPhoto, '_2000x.') +"')",
            filter: "grayscale(100%)"

        } : {
            backgroundImage: "url('" + resizeImage(store.mainPhoto, '_2000x.') + "')"
        }}>

            {store.currentlyUnavailable ? <p className="currentlyClose">{i18n.t("homepage.currentlyUnavailable")}</p> : !store.available ? <p className="currentlyClose">{i18n.t("homepage.currentlyClosed")}</p> : undefined}

        </div> }

        <div id="storeSection" className="paddingContainer">
            <Row className="storeRow">
                <Col xs="8" className="storeItem singleStore">
                    <Sticky topOffset={15} stickyStyle={{ backgroundColor: 'white', top: -15, zIndex: 3}}>
                        <div className="singleStoreHeader">
                            <span className="storeTag">{store.categoryName}</span>

                            <div id="breadcrumb">
                                <p><Link to="/">{i18n.t('footer.home')}</Link> &gt; <Link to={"/stores/" + store.parentCategoryName?.toLowerCase() }>{store.parentCategoryName}</Link> &gt; {store.name}</p>
                            </div>

                            <h3 className="storeName" style={{ marginBottom: 0, display: "inline"}}>{store.name}</h3>
                            <p className="storeAddress" style={{ marginLeft: 15, fontSize: 16, display: "inline-block" }}>{store.rating?.toFixed(1)} <img className="estimationImg" src="/images/menu/icon-star.svg" alt="icon star"/>({numberOfCommentsRange(store.commentNumber)})</p>
                            { store.description && <p className="contact" >{store.description}</p> } 
                            <div className="contact">
                                { store && !store.hideAddress ? <><img src="/images/menu/icon-location.svg" alt="icon location"/> {store.address.full}</>: undefined }
                                { store && !store.hidePhone ? <><img src="/images/menu/icon-phone.svg" alt="icon location"/> {formattedPhone}</>: undefined }
                            </div>
                            <div className="scroll-horizontal">
                                { isFetching ? undefined : 
                                    
                                    <>
                                    { [...lServices, ...lProducts].map((product, index) => {
                                        let newCat = false;
                                        let currentKey = (product.isService ? 'service_' : 'product_') + (product.category ? product.category.name : i18n.t('global.other'));
                                        if(product.category && currentCat != product.category.name){
                                            currentCat = product.category.name;
                                            newCat = true;
                                            displayedCat += 1;
                                            if(displayedCat >= 6) otherCats.push(product);
                                        }
                                        else if(!product.category){
                                            currentCat = '';
                                            newCat = true;
                                            displayedCat += 1;
                                            if(displayedCat >= 6) otherCats.push(product);
                                        }

                                        return(
                                        <>
                                            { newCat && displayedCat < 6 &&

                                            <div
                                                className={  selectedCat === currentKey ? "scroll-horizontal-active" : "scroll-horizontal-category"}
                                                onClick={e => scrollToCategory(currentKey)}>
                                                {product.category ? product.category.name : i18n.t('global.other')}
                                            </div>

                                            // <div style={{ cursor: "pointer", width: 180, marginLeft: 10, marginRight: 10, display: "inline-block"}} onClick={e => scrollToCategory((product.isService ? 'service_' : 'product_') + (product.category ? product.category.name : i18n.t('global.other')))}>
                                            //     <div className="storeImage" style={{
                                            //         backgroundImage: "linear-gradient(#342535, #342535), url('" + product.category.image + "')",
                                            //         backgroundBlendMode: "hard-light",
                                            //         height: 100,
                                            //     }}>
                                            //         <p style={{ color: "white", textAlign: "center", width: "100%", lineHeight: "100px", fontSize: "1.3em", marginBottom: 0 }}>{product.category ? product.category.name : i18n.t('global.other')}</p>
                                            //     </div>
                                            // </div>

                                            }
                                        </>                                 
                                    )}) }

                                    {displayedCat >= 6 ?
                                        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                                            <DropdownToggle caret>
                                                {i18n.t('global.other')}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                    { otherCats.map((product, index) => {
                                                        return(
                                                        <>
                                                            <DropdownItem>
                                                                <div
                                                                    className={"dropdown-horizontal"}
                                                                    onClick={e => scrollToCategory((product.isService ? 'service_' : 'product_') + (product.category ? product.category.name : i18n.t('global.other')))}>
                                                                    {product.category ? product.category.name : i18n.t('global.other')}
                                                                </div>
                                                            </DropdownItem>
                                                        </>                         
                                                    )}) }
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                        : undefined
                                    }
                                    </>
                                }
                            </div>
                        </div>
                    </Sticky>

                    <div id="storeSearch">
                        <div className="productDropdown">
                            <span className="dropdownTitle">
                                <h4>{i18n.t("search.search")}</h4>
                            </span>
                        </div>
                        <SelectSearch
                            options={products.map(product => ({
                                value: product.id,
                                name: product[`name_${i18n.locale == 'fr' ? 'FR' : 'EN'}`],
                                name_fr: product.name_FR,
                                name_en: product.name_EN,
                            }))}
                            search
                            filterOptions={fuseSearch}
                            emptyMessage={i18n.t("search.noResult")}
                            placeholder={i18n.t("search.placeholder_product")}
                            onChange={handleSearchClick}
                        />
                        <p>{i18n.t("search.noFind_web")}</p>
                    </div>

                    <div id="storeProduct">
                        { lServices.length > 0 ? <>
                            <div className="productDropdown">
                                <span className="dropdownTitle">
                                    <h4>Services</h4>
                                </span>
                            </div>

                            <div className="productList">
                                <Row>
                                { isFetching ? undefined : 
                                    lServices && lServices.map((service, index) => {
                                        let newCat = false;
                                        if(service.category && currentCat != service.category.name){
                                            currentCat = service.category.name;
                                            newCat = true;
                                        }
                                        else if(!service.category){
                                            currentCat = '';
                                            newCat = true;
                                        }

                                        return(
                                        <>

                                        { newCat ?
                                        <div ref={el => (catRefs['service_' + (service.category ? service.category.name : i18n.t('global.other'))] = el)} className="serviceDropdown" style={{ display: 'block', width: '100%', marginLeft: 25}}>
                                            <span className="dropdownTitle">
                                                    <h4>{service.category ? service.category.name : i18n.t('global.other')}</h4>
                                                    <hr/>
                                            </span>
                                        </div> : undefined
                                        }

                                        <StoreProductSingleContainer
                                            Layout={StoreProductSingle}
                                            product={service}
                                            readOnly={false}
                                            key={index}
                                            handleAddProductToCart={handleAddProductToCart}
                                            handleRemoveProductToCart={handleRemoveProductToCart}
                                            chooseService={chooseService}
                                            chooseProduct={chooseProduct}
                                            alreadyInCart={ cart && cart.products.find(p => p.id === service.id ) }
                                            prodRefs={prodRefs}
                                            selectedProduct={selectedProduct}
                                        />   

                                        </>                                 
                                    )})
                                
                                }
                                </Row>
                            </div> </> : undefined
                        }
                        
                        { lProducts.length > 0 ? <>
                            <div className="productDropdown">
                                <span className="dropdownTitle">
                                        <h4>{i18n.t('footer.products')}</h4>
                                </span>
                            </div>

                            <div className="productList">
                                <Row>

                                { isFetching ? <SkeletonProduct /> :
                                    lProducts && lProducts.map((product, index) => {
                                        let newCat = false;
                                        if(product.category && currentCat != product.category.name){
                                            currentCat = product.category.name;
                                            newCat = true;
                                        }
                                        else if(!product.category){
                                            currentCat = '';
                                            newCat = true;
                                        }

                                        return(
                                        <>

                                        { newCat ?
                                        <div ref={el => (catRefs['product_' + (product.category ? product.category.name : i18n.t('global.other'))] = el)} className="serviceDropdown" style={{ display: 'block', width: '100%', marginLeft: 25}}>
                                            <span className="dropdownTitle">
                                                    <h4>{product.category ? product.category.name : i18n.t('global.other')}</h4>
                                                    <hr/>

                                            </span>
                                        </div>: undefined
                                        }

                                        <StoreProductSingleContainer
                                            Layout={StoreProductSingle}
                                            product={product}
                                            readOnly={false}
                                            key={index}
                                            handleAddProductToCart={handleAddProductToCart}
                                            handleRemoveProductToCart={handleRemoveProductToCart}
                                            chooseService={chooseService}
                                            chooseProduct={chooseProduct}
                                            alreadyInCart={ cart && cart.products.find(p => p.id === product.id ) }
                                            prodRefs={prodRefs}
                                            selectedProduct={selectedProduct}
                                            search={search}
                                        />
                                        </>                         
                                    )})
                                }

                                </Row>
                            </div> </> : undefined
                        }

                    </div>

                </Col>
                <Col xs="4">
                    {/* <Sticky topOffset={-25} stickyStyle={{ top: 25}}> */}
                        <Cart store={store} isSticky={isCartSticky} Layout={CartComponent} showAll={false} ordering={true} />
                    {/* </Sticky> */}
                </Col>
            </Row>
        </div>
        </>
    )
};

export default StoreSingleComponent;