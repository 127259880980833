import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getChildCategories } from "../../actions/storeAction";

class StoreCategories extends Component {

    constructor(props){
        super(props);

        this.state = {
            categories: []
        }
    }

    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+this.props.title;

        let categoryId = this.props.match ? this.props.match.params.categoryId : this.props.route.params.categoryId;
        
        this.props.getChildCategories(categoryId).then(response => {
            this.setState({ categories: response });
        }).catch( e => {
            console.log("e",e);
        })
        
    }

    render() {
        const { Layout, navigation, isService, isFetching } = this.props;

        return (
            <Layout 
                isFetching={isFetching}
                categories={this.state.categories}
                navigation={navigation}
                isService={isService}
            />
        );
    }
    
}

const mapStateToProps = (state) => ({
    isFetching: state.stores.isFetching || false,
});

const mapDispatchToProps = {
    getChildCategories,
};


export default connect(mapStateToProps, mapDispatchToProps)(StoreCategories);
