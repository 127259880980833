import React, { Component } from 'react';
import { connect } from 'react-redux';


class AddressBlock extends Component {
    constructor(props){
        super(props);

        this.state = {
            address: this.props.address
        }
    }

    handleChangeInput = e => {
        this.props.handleUpdateAddress({ ...this.state.address, [e.target.name] : e.target.value }, this.props.index);
    }

    handleRemoveAddress = () => {
        this.props.handleRemoveAddress(this.props.address.id, this.props.index);
    }

    render() {
        const { Layout } = this.props;

        return (

            <Layout 
                address={this.state.address}
                handleChangeInput={this.handleChangeInput.bind(this)}
                handleRemoveAddress={this.handleRemoveAddress.bind(this)}
            />
        );
    }
    
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
};


export default connect(mapStateToProps, mapDispatchToProps)(AddressBlock);
