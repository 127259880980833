import React, { Component } from 'react';
import config from '../../config/config';

import { sendEmail } from "../../actions/userAction";

import i18n from "../../translations/i18n";

class Recruit extends Component {

    constructor(props){
        super(props);
        this.state = {
            recruit: {}
        }
    }

    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+this.props.title;
    }

    handleUpdateRecruitInput = (e) => {
        this.setState({ recruit: { ...this.state.recruit, [e.target.name] : e.target.value }});
    }

    handleSendEmail = (e) => {
        let recruit = {
            template: 'recrutement',
            data: { ...this.state.recruit }
        }

        this.setState({isLoading: true})

        sendEmail(recruit).then( response => {
            this.setState({ toastMessage: { type: 'success', msg: i18n.t('recruit.success')} }, () => {
                this.setState({ toastMessage: undefined, recruit: {}, isLoading: false });
            });

        }).catch(err => {
            this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.unknown_error')} }, () => {
                this.setState({ toastMessage: undefined, isLoading: false });
            });
            console.log('***** RecruitPage - handleSendEmail - sendEmail ', err);
        })
    }

    render() {
        const { Layout, navigation } = this.props;      

        return (
            <Layout
                handleUpdateRecruitInput={this.handleUpdateRecruitInput.bind(this)}
                handleSendEmail={this.handleSendEmail.bind(this)}
                navigation={navigation}
                recruitForm={this.state.recruit}
                isLoading={this.state.isLoading}
                toastMessage={this.state.toastMessage}
            />

        );
    }
}

export default Recruit;