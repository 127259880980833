import React from 'react';
import { Link } from 'react-router-dom';
import { resizeImage } from '../../../config/utils';
import i18n from '../../../translations/i18n';
import { numberOfCommentsRange } from '../../../utils/utils'

const StoreItemComponent = ({ store, product, category, image, name_EN, name_FR }) => (
    <Link 
        to={"/store/"+ (store.id ? store.id : store._id) + (product ? ('?product=' + product) : '') } 
        key={store.id ? store.id : store._id}
    >
        <div className="storeItem">
            <div className="storeImage" style={
            !store.available || store.currentlyUnavailable? {
                backgroundImage: "linear-gradient(black, black), url('" + resizeImage(image ? image : store.mainPhoto, '_500x.') +"')",
                backgroundBlendMode: 'saturation'
            } : { backgroundImage: "url('" + resizeImage(image ? image : store.mainPhoto, '_500x.') +"')"}}>
                {store.currentlyUnavailable ? <p className="currentlyClose">{i18n.t("homepage.currentlyUnavailable")}</p> : !store.available ? <p className="currentlyClose">{i18n.t("homepage.currentlyClosed")}</p> : undefined}
            </div>
            { store.hasProductDiscount ? <span className="storeDiscount">PROMO</span> : null}
            { category || store.categoryName ? <span className="storeTag">{category ? category : store[`categoryName_${i18n.locale == 'fr' ? 'FR' : 'EN'}`] ? store[`categoryName_${i18n.locale == 'fr' ? 'FR' : 'EN'}`] : store.categoryName }</span> : null}
            <p className="storeTitle">{(name_EN && name_FR) ? (i18n.locale == 'fr' ? name_FR : name_EN) : store.name}</p>
            {/* <p className="storeAddress"><img src="/images/menu/icon-location.svg" alt="icon location"/>{store.address.full}</p> */}
            {store.deliveryEstimated ? <p className="storeAddress"><img className="estimationImg" src="/images/menu/icon-clock.svg" alt="icon location"/>{ i18n.t("global.deliveryEstimated")}: {store.deliveryEstimated} min</p> : undefined }
            {<p className="storeAddress">{store.rating?.toFixed(1)} <img className="estimationImg" src="/images/menu/icon-star.svg" alt="icon star"/>({numberOfCommentsRange(store.commentNumber)})</p> }

        </div>
    </Link>
)

export default StoreItemComponent;