const isLocal =
  window && window.location && window.location.hostname.includes("localhost");

export default {
  appName: "Appli",
  DEV: isLocal,
  baseUrlApi: process.env.REACT_APP_SERVER_URL
    ? process.env.REACT_APP_SERVER_URL + "/api"
    : isLocal
    ? "http://localhost:1337/api"
    : "https://geniemtl-dev.herokuapp.com/api",
  webSocketUrl: process.env.REACT_APP_WEBSOCKET_BASE_URL
    ? process.env.REACT_APP_WEBSOCKET_BASE_URL
    : isLocal
    ? "http://localhost:1337"
    : "https://geniemtl-dev.herokuapp.com",
  chatWebSocketUrl: process.env.REACT_APP_CHAT_SERVER_URL
    ? process.env.REACT_APP_CHAT_SERVER_URL
    : isLocal
    ? "http://localhost:3002"
    : "https://geniemtl-dev-nestjs.herokuapp.com",
  baseUrl: isLocal ? "/" : "/",
  userUriCheckConnected: "user/token",
  userUriLogin: "user/login",
  userUriRegister: "user/register",
  googlePlaceAPIkey: process.env.REACT_APP_GOOGLE_PLACE_API
    ? process.env.REACT_APP_GOOGLE_PLACE_API
    : "AIzaSyAG2gZIR5w0TXZr5rGc_FGoTvX8drAs_iY",
  googlePlaceAPIkeyMobile: process.env.REACT_APP_GOOGLE_PLACE_API_MOBILE
    ? process.env.REACT_APP_GOOGLE_PLACE_API_MOBILE
    : "AIzaSyCZWKY2ir83MDF8VfB-h2OO55F1LHkKwmU",
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY
    : "pk_test_p9hSDApxpPmOkORPRSBop7Qh",
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID
    ? process.env.REACT_APP_FACEBOOK_APP_ID
    : "1144922010021075",
  facebookDisplayName: "Genie Mtl",
  googleAnalyticsMeasurementId: "G-LHFMKYJEFP",
};
