import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAllStores } from "../../actions/storeAction";

class StorePage extends Component {

    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+this.props.title;

        this.props.getAllStores().then(response => {

        }).catch( e => {
            console.log("e",e);
        })
    }

    render() {
        const { Layout, navigation, stores, isFetching } = this.props;

        return (
            <Layout 
                isFetching={isFetching}
                stores={stores}
                navigation={navigation}
            />
        );
    }
    
}

const mapStateToProps = (state) => ({
    isFetching: state.stores.isFetching || false,
    stores: state.stores.stores
});

const mapDispatchToProps = {
    getAllStores,
};


export default connect(mapStateToProps, mapDispatchToProps)(StorePage);
