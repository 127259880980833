import React from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import i18n from "../../../translations/i18n";

import LocationSearchInput from '../../../containers/Utils/LocationSearchInput';
import PhoneInput from "../UI/PhoneInput";

import enCA from 'date-fns/locale/en-CA';
import frCA from 'date-fns/locale/fr-CA';
registerLocale('fr-CA', frCA);
registerLocale('en-CA', enCA);

const PickupStep1Component = ({ user, pickup, currentStep, nextStep, handleUpdatePickupInput, handleCheckedAddress, requiredFields, openingHours, monthClosedDays, handleChangeMonth }) => {
    const language = useSelector(state => state.user.lang);
    const currentDate = moment().format("YYYY-MM-DD");
    const currentHours = moment().format("HH");
    
    let isInTimeSlot = false;
    for (let i = 0; i < openingHours.length; i++) {
        const hour = parseInt(openingHours[i].value.substring(0, 2));
        if (parseInt(currentHours) + 1 === hour) {
            isInTimeSlot = true;
            break;
        }
    }

    let isWeekendStart = moment(pickup.startDate).isoWeekday() > 5;

    return(
    <>
        <FormGroup>
            <Label>
                <h5 className="contact">
                    <img src="/images/menu/icon-location.svg" alt="icon location" /> {i18n.t('pickup.startAddress')}
                </h5>
            </Label>
            <Row className="pickupRow">
                <Col xs="6"><Input type="text" value={pickup.startName} placeholder={i18n.t('global.contactName')} name="startName" onChange={e => handleUpdatePickupInput(e)}></Input>{requiredFields && requiredFields.startName ? <span className="errorMsg">{requiredFields.startName}</span> : undefined } <span className="red_ast">*</span></Col>
                <Col xs="6"><PhoneInput phone={pickup.startPhone} onChange={phone => handleUpdatePickupInput({ target: { name: "startPhone", value: phone } })} />{requiredFields && requiredFields.startPhone ? <span className="errorMsg">{requiredFields.startPhone}</span> : undefined } <span className="red_ast">*</span></Col>
            </Row>

            <div className="clientAddress">
                <Row className="street pickupRow">
                    <FormGroup className="form-check" style={{ width: '100%' }}>
                    <span className="red_ast">*</span>
                        {user?.addresses?.map((a, index) => (
                            <Label className="form-check" key={index}>
                                <Input checked={pickup.startAddress && pickup.startAddress.full === a.full} type="radio" name="address" onChange={e => handleUpdatePickupInput({ target: { name: 'startAddress', value: { ...a, apartment: a.apartment } } })} />{a.full}
                            </Label>
                        ))}

                        <Label className="form-check">
                            <Input value type="radio" name="address" checked={pickup.displayStartOtherAddress} onChange={e => handleUpdatePickupInput({ target: { name: 'displayStartOtherAddress', value: true } })} />{i18n.t('global.other')}
                        </Label>

                        {requiredFields && requiredFields.startAddress ? <span className="errorMsg">{requiredFields.startAddress}</span> : undefined }

                    </FormGroup>

                    {pickup && pickup.displayStartOtherAddress ?
                        <>
                            <Col xs="8"><LocationSearchInput value={pickup.otherStartAddress && pickup.otherStartAddress.formatted_address} handleCheckedAddress={isChecked => handleCheckedAddress(isChecked)} onSelect={(a, latLng) => handleUpdatePickupInput({ target: { name: "otherStartAddress", value: { ...a, ...latLng } } })} /></Col>
                            <Col xs="4"><Input type="text" placeholder={i18n.t('address.app')} value={pickup.otherStartAddress && pickup.otherStartAddress.apartment} onChange={e => handleUpdatePickupInput(e)} name="apartment"></Input></Col>
                        </>
                        : undefined
                    }
                </Row>
            </div>

            <Row className="pickupRow">
                <Col xs="12" ><Input type="textarea" value={pickup.specialInstructionFrom} placeholder={i18n.t('global.instruction')} name="specialInstructionFrom" rows="5" onChange={e => handleUpdatePickupInput(e)}></Input></Col>
            </Row>

        </FormGroup>
        <FormGroup>
            <Label>
                <h5 className="contact">
                    <img src="/images/menu/icon-location.svg" alt="icon location" /> {i18n.t('pickup.pickupDate')}
                </h5>
            </Label>
            <Row className="pickupRow">
                <Col>
                    <Row>
                        <Col>
                            <Row className="pickupRow">
                                <Col>
                                    <DatePicker 
                                        className='form-control' 
                                        locale={language === 'fr' ? 'fr-CA' : 'en-CA'} 
                                        type="date" 
                                        placeholderText="Aujourd'hui" 
                                        selected={moment(pickup.startDate).toDate()} 
                                        onChange={ e => handleUpdatePickupInput({ target: { name: 'startDate', value: moment(e).format('YYYY-MM-DD')} }) } 
                                        minDate={moment(currentDate).toDate()} 
                                        excludeDates={monthClosedDays || []}
                                        popperPlacement='top-start'
                                        popperModifiers={[
                                            {
                                                name: 'flip',
                                                enabled: false
                                            }
                                        ]}
                                        onMonthChange={(date) => handleChangeMonth(date)}
                                    />
                                    <span className="red_ast">*</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                        <Input type="select" disabled={!pickup.startDate} defaultValue="" id="selectTime" value={pickup.deliveryType} name="deliveryType" onChange={e => handleUpdatePickupInput(e)}>
                                <option value="" disabled hidden>{i18n.t('pickup.chooseMode')}</option>
                                { moment(pickup.startDate).isSame( moment(), 'day') && isInTimeSlot ? <option value="asap">{i18n.t('pickup.asap')}</option> : undefined }
                                {/* { !isWeekendStart && ( (moment(pickup.startDate).isSame( moment(), 'day') && currentHours >= 9 && currentHours <= 12) || !moment(pickup.startDate).isSame( moment(), 'day')) ? <option value="business">{i18n.t('pickup.businessHours')}</option> : undefined } */}
                                <option value="custom">{i18n.t('pickup.customHours')}</option>

                            </Input>
                            <br/>
                            { pickup.deliveryType == 'custom' ?
                            <>
                            <p>{i18n.t('pickup.scheduleRange')}</p>
                            <Input type="select" defaultValue="" id="selectTime" value={pickup.startTimeMin} name="startTimeMin" onChange={e => handleUpdatePickupInput(e)} style={{ width: '50%', display: 'inline'}}>
                                <option value="" selected={pickup.startTimeMin == ''} disabled hidden>{i18n.t('pickup.chooseHours')}</option>
                                { openingHours.map( (h, index) => {
                                    if (index !== openingHours.length - 1) return (<option value={h.value} selected={pickup.startTimeMin == h.value}>{h.label}</option>)
                                })}
                                
                            </Input>
                            <Input type="select" defaultValue="" id="selectTime" value={pickup.startTimeMax} name="startTimeMax" onChange={e => handleUpdatePickupInput(e)} style={{ width: '50%', display: 'inline'}}>
                            <option value="" selected={pickup.startTimeMax == ''} hidden>{i18n.t('pickup.chooseHours')}</option>
                            { openingHours.map( (h, index ) => {
                                if(h.value > pickup.startTimeMin) return (<option selected={pickup.startTimeMax == h.value} value={h.value}>{h.label}</option>)
                            })}
                            
                            </Input> </>: undefined }
                            {requiredFields && ( requiredFields.startDate || requiredFields.startTime ) ? <span className="errorMsg">{requiredFields.startDate || requiredFields.startTime}</span> : undefined } 
                            <span className="red_ast">*</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </FormGroup>
        <FormGroup className="pickupButton">
            <Button
                //isabled={!pickup.startName || (!pickup.startPhone || pickup.startPhone.length !== 10) || (pickup.displayStartOtherAddress ? !pickup.otherStartAddress || !pickup.isCheckedAddress : !pickup.startAddress)}
                className="nextButton"
                onClick={e => nextStep(false)}>
                {i18n.t('global.continue')}
            </Button>
        </FormGroup>

    </>
)}

export default PickupStep1Component;