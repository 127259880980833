import { applyMiddleware, compose, createStore } from 'redux'
import { persistCombineReducers, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import reducers from '../reducers/indexReducer'

/*let storage = undefined;
try {
    const m = require('react-native');
    storage = require('redux-persist-expo-fs-storage')
    storage = storage.default();
    // If we're in native mode
} catch (e) {
    // If we're on web
    storage = require('redux-persist/es/storage').default;
}*/

const createRootReducer = (storage) => {
    const config = {
        key: 'root',
        storage,
        blacklist: ['status'],
    }
    
    const reducer = persistCombineReducers(config, reducers)
    
    return (state, action) => {
        // if (action.type === 'LOG_OUT') {
        //     //storage.removeItem('persist:root');
        //     state = undefined;
        // }
    
        return reducer(state, action)
    }
}

const middleware = [thunk]

const indexStore = (storage) => {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    const store = createStore(
        createRootReducer(storage),
        composeEnhancer(applyMiddleware(...middleware)),
    )

    const persistor = persistStore(
        store,
        null,
        () => {
            store.getState()
        },
    )

    return { persistor, store }
}

export default indexStore
