import {
    REQUEST_PICKUP_DATA,
    RECEIVE_PICKUP_DATA,
    RECEIVE_MULTIPLE_PICKUP_DATA,
    RECEIVE_DATA,
    SET_FETCHING
} from '../actions/pickupAction';

import Pickup from '../store/pickupStore';

export const initialState = Pickup;

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_PICKUP_DATA:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_DATA:
            return {
                ...state,
                isFetching: false,
            };
        case RECEIVE_PICKUP_DATA:
            return {
                ...state,
                isFetching: false,
                pickup: action.payload,
            };
        case RECEIVE_MULTIPLE_PICKUP_DATA:
            return {
                ...state,
                isFetching: false,
                pickups: action.payload,
            };
        case SET_FETCHING:
            return {
                ...state,
                isFetching: action.payload,
            };
        default:
            return state;
    }
}
