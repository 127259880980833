import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import ReactLoading from 'react-loading';
import OtpInput from 'react-otp-input';

import { useToasts } from 'react-toast-notifications'

import i18n from '../../translations/i18n';

const AccountActivationComponent = ({ toastMessage, isResending, checkCode, resendCode, isOpen, toggle }) => {
  const [code, setCode] = useState('');

  const { addToast } = useToasts();

  const onChangeCode = e => {
    setCode(e);
    if(e.length === 4){
        checkCode(e);
    }
  }

  return (
    <>
    { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }

    <Modal isOpen={isOpen} toggle={toggle} id="signUpModal">
      <button onClick={toggle} className="btnClose">
        <img src="/images/menu/icon-close.svg" alt="icon close" />
      </button>
      <div id='activateContainer'>
        <h4>{i18n.t('login.activation.title')}</h4>

        <p id='activateTitle'>{i18n.t('login.activation.description')} </p>

        <OtpInput 
          value={code}
          onChange={onChangeCode}
          renderInput={(props) => <input {...props} />}
          inputStyle={{ width: 50, height: 50, borderRadius: 5, marginRight: 5, marginLeft: 5 }}
          shouldAutoFocus
          
        />

        <button id='activateResendButton' onClick={resendCode}>
            { isResending ? <ReactLoading height={45} color='white' /> : <p>{i18n.t('login.activation.resend_button')}</p> }
        </button>

        <p id='activateResendText'>{i18n.t('login.activation.resend_description')}</p>

      </div>
    </Modal>
  </>)
};

export default AccountActivationComponent;