import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Sentry from "@sentry/react";

import config from '../../config/config';

import i18n from '../../translations/i18n';

import axios from 'axios';
import { loginJWT, updateConfirmationCode } from '../../actions/userAction';


class TrackMyOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            toastMessage: undefined,
            isLoading: false,
            resendLoading: false,
        }

        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+props.title;

    }

    checkCode = e => {
        this.setState({ isLoading: true });
        
        axios.post( config.baseUrlApi + "/user/validEmail", { code: e })
        .then(response => {
            this.setState({ toastMessage: { type: 'success', msg: i18n.t('login.activation.activated')} }, async () => {
                this.setState({ toastMessage: undefined });

                await this.props.loginJWT(response.data.token, response.data.user);

                if(this.props.navigation){
                    if(this.props.cart?.products?.length > 0){
                            if(this.props.cart?.products[0].isService){
                                this.props.navigation.navigate('Main', {
                                    screen: 'ServiceStack', params: {
                                        screen: 'OrderService'
                                    }
                                })
                            }else{
                                this.props.navigation.navigate('Main', {
                                    screen: 'StoreStack', params: {
                                        screen: 'Order'
                                    }
                                })
                            }
                    } else {
                        this.props.navigation.navigate('Home')
                    }
                } else{
                    if(this.props.toggle){
                        this.props.toggle();
                    }
                }
            });
        }).catch( err => {
            if(err.response?.data === "User.invalid.token") {
                this.setState({ toastMessage: { type: 'error', msg: i18n.t('login.activation.invalid_token')} }, () => {
                    this.setState({ toastMessage: undefined });
                });
            }
            console.log('ERROR - AccountActivation - checkCode', err.response.data);
        }).finally(_ => {
            this.setState({ isLoading: false });
        })
    }

    resendCode = () => {
        this.setState({ resendLoading: true });
        let user = this.props.user;
        user.confirmationCode = (1000 + Math.floor(Math.random() * 9000));

        this.props.updateConfirmationCode(user).then(res => {
            this.setState({ resendLoading: false });
        }).catch( err => {
            console.log('ERROR - AccountActivation - resendCode');
            Sentry.captureException(new Error(err));
            this.setState({ resendLoading: false });
        });
    }

    render() {
        const { Layout, isLoading, navigation, isOpen, toggle } = this.props;
        return (
            <Layout
                isLoading={isLoading || this.state.isLoading }
                isResending={this.state.resendLoading}
                navigation={navigation}
                toastMessage={this.state.toastMessage}
                checkCode={this.checkCode.bind(this)}
                resendCode={this.resendCode.bind(this)}
                isOpen={isOpen}
                toggle={toggle}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    cart: state.carts?.cart,
    user: state.user.user,
});

const mapDispatchToProps = {
    loginJWT,
    updateConfirmationCode
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackMyOrder);
