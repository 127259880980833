import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import { resizeImage } from '../../config/utils';
import { useToasts } from 'react-toast-notifications'
import Rating from 'react-rating';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import i18n from '../../translations/i18n';
import moment from 'moment';

const RatingOrder = ({ isOpen, orderToReview, toastMessage, handleRefuseReview, handleSendComment, handleUpdateNote, handleChangeComment, note, openModal }) => {

  const { addToast } = useToasts();

  if(!isOpen){
    confirmAlert({
      title: i18n.t('rating.title'),
      message: i18n.t('rating.text'),
      buttons: [
        {
          label: i18n.t('rating.yes'),
          onClick: () => openModal()
        },
        {
          label: i18n.t('rating.no'),
          onClick: () => handleRefuseReview()
        }
      ],
      closeOnClickOutside: false,
      closeOnEscape: false
    });
  }

  return (
    <>
    { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }

    <Modal isOpen={isOpen}>
      <h2 className="center" style={{ marginTop: 15 }}>{ i18n.t("rating.title")}</h2>

      <img src={resizeImage(orderToReview.store.mainPhoto, '_500x.')} className="roundedImage" style={{ margin: '20px auto' }} />

      <h4 className="center">{i18n.t("rating.yourOrderOf")} {moment(orderToReview.deliveryDate).format('DD/MM/YYYY')}</h4>
      <h4 className="center">{i18n.t("rating.from")} {orderToReview.store.name}</h4>
      
      <Rating className="center" style={{ marginTop: 25, fontSize: "1.2em" }}
        emptySymbol="fa fa-star-o fa-2x emptySymbol"
        fullSymbol="fa fa-star fa-2x fullSymbol"
        initialRating={note}
        onChange={handleUpdateNote}
        
      />

      <input type="text" placeholder={i18n.t('rating.placeholder_comment')} className="center" style={{ width: "80%", margin: "25px auto" }} onBlur={handleChangeComment} />
      <button className='primary' style={{ width: "80%", margin: "25px auto" }} onClick={handleSendComment}>{i18n.t('rating.send')}</button>

    </Modal>
  </>)
};

export default RatingOrder;