import {
    REQUEST_STORE_DATA,
    RECEIVE_STORE_DATA,
    RECEIVE_MULTIPLE_STORE_DATA,
    RECEIVE_CAROUSEL_DATA,
    STOP_FETCHING
} from '../actions/storeAction';

import {
    RECEIVE_SEARCH_DATA,
    REQUEST_SEARCH_DATA
} from '../actions/searchAction';

import Store from '../store/storeStore';

export const initialState = Store;

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_STORE_DATA:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_STORE_DATA:
            return {
                ...state,
                isFetching: false,
                store: action.payload,
            };
        case RECEIVE_MULTIPLE_STORE_DATA:
            return {
                ...state,
                isFetching: false,
                stores: action.payload,
            };
        case RECEIVE_CAROUSEL_DATA:
            return {
                ...state,
                isFetching: false,
                carouselImages: action.payload,
            };
        case REQUEST_SEARCH_DATA:
            return {
                ...state,
                isFetching: true,
            };
        case STOP_FETCHING:
            return {
                ...state,
                isFetching: false,
            };
        case RECEIVE_SEARCH_DATA:
            return {
                ...state,
                isFetching: false,
                searchedStores: action.payload.stores,
                searchedServices: action.payload.services,                
            };
        default:
            return state;
    }
}
