import React from 'react';
import i18n from '../../translations/i18n';

const ThankYou = () => {
    return(
       <div className="paddingContainerMobile">
            <div id="purchasePage">
                <section id="purchase-content">
                    <h1 className="title-content">{i18n.t('thankYou.content')}</h1>
                </section>
            </div>
       </div>
    );
}

export default ThankYou;