import { card as cardIcon, pricetag, home, create } from "ionicons/icons";
import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import LocationSearchInput from "../../../../containers/Utils/LocationSearchInput";
import TipBlock from '../../UI/TipBlock';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import i18n from "../../../../translations/i18n";
import moment from 'moment';


const ReservationStep2Component = ({order, store, user, cart, cards, handleSendOrder, prevStep, handleUpdateOrderInput, handleCheckVoucher, handleToggleModal, isModalOpen, isService, isServiceRequiringDelivery, isServiceWithDestination, cardErrors}) => {
    const [focusedField, setFocusedField] = useState('name');
    const shippingAddress = order.destinationAddress && Object.keys(order.destinationAddress).includes('formatted_address') ? order.destinationAddress.formatted_address : order.address?.full ? order.address.full : order.otherAddress?.formatted_address;

    return (
        <>
        {isService ? ((isServiceRequiringDelivery || isServiceWithDestination) ?  <h3>{i18n.t('payment.revisedService')}</h3> : undefined) : <h3>{i18n.t('pickup.summaryOrder')}</h3>}

        { isServiceRequiringDelivery ?
        <div className="deliveryResume">
            <div className="deliveryTitle">
                <div className="titleResume">
                    <SVG src="/images/menu/icon-location.svg" alt="icon location" className="iconResume"/>
                    {i18n.t('address.pickupAddress')}
                </div>
                <div className="editResume">
                    <SVG className="iconEdit" src={create} alt="button edit" onClick={prevStep}></SVG>
                </div>
            </div>

            <div className="deliveryText">
                <div className="deliveryAddress">{order.address?.full ? order.address.full : order.otherAddress?.formatted_address}</div>
                <div className="deliveryTime">{ moment(order.sendingDate + ' ' + order.sendingHours).format('DD/MM/YYYY HH:mm') }</div>
            </div> 
        </div> : undefined }

        { !isService || isServiceWithDestination ?
        <div className="deliveryResume">
            <div className="deliveryTitle">
                <div className="titleResume">
                    <SVG src="/images/menu/icon-location.svg" alt="icon location" className="iconResume"/>
                    {isServiceWithDestination ? i18n.t('global.destinationAddress') : i18n.t('address.deliveryAddress')}
                </div>
                <div className="editResume">
                    <SVG className="iconEdit" src={create} alt="button edit" onClick={prevStep}></SVG>
                </div>
            </div>

            <div className="deliveryText">
                <div className="deliveryAddress">{shippingAddress}</div>
                { !isService && <div className="deliveryTime">{ moment(order.sendingDate + ' ' + order.sendingHours).format('DD/MM/YYYY HH:mm') }</div> }
            </div> 
        </div> : undefined }

        <h3>{i18n.t('pickup.payment')}</h3>

        <FormGroup>
            <div className="categoryTitle">
                <SVG className="iconCategory" src={pricetag} alt="icon coursier"/> {i18n.t('payment.promoCode')}
            </div>

            <Row className="reservationRow">
                <Col xs="8">
                    <Input type="text" placeholder={i18n.t('payment.addPromoCode')} value={order.promoCode} name="promoCode" onChange={ e => handleUpdateOrderInput(e) }></Input>
                </Col>

                <Col xs="4">
                    <Button className="reservationButton" onClick={e => handleCheckVoucher(isServiceRequiringDelivery || !isService || isServiceWithDestination || false) }>{i18n.t('payment.applyPromoCode')}</Button>
                </Col>
            </Row>
        </FormGroup>

        <FormGroup>
            <div className="categoryTitle">
                <SVG className="iconCategory" src={cardIcon} alt="icon card"/> {i18n.t('payment.info')}<span style={{ color: 'red'}}>*</span>
            </div>

            { cards && cards.map( (card, index) => (
                <Label className="form-check" key={index}>
                    <Input type="radio" checked={order.paymentCard === card.id} name="paymentCard" onChange={ e => handleUpdateOrderInput({ target: { name:'paymentCard', value: card.id }}) }/>{card.brand} - •••• •••• •••• {card.last4}
                </Label>
            ))}

            <Label className="form-check">
                <Input type="radio" name="paymentCard" checked={order.displayOtherCard} value="other" onChange={ e => handleUpdateOrderInput({ target: { name:'displayOtherCard', value: true} }) }/>{i18n.t('global.other')}
            </Label>

            { order.displayOtherCard ? 
                <div className="clientInfo">
                    <Label>
                        {i18n.t('payment.creditCard')}
                    </Label>
                    <Row>
                        <Col>
                            <Cards
                                cvc={order.otherPaymentCardCVC ? order.otherPaymentCardCVC : ''}
                                expiry={order.otherPaymentCardExpiry ? order.otherPaymentCardExpiry : ''}
                                name={order.otherPaymentCardName ? order.otherPaymentCardName : ''}
                                number={order.otherPaymentCardNumber ? order.otherPaymentCardNumber : ''}
                                focused={focusedField}
                                locale={{valid: ""}}
                                placeholders={{name: "Martin Jean"}}
                            />
                        </Col>
                        <Col>
                    
                            <Row className="reservationRow">
                                <Col>
                                    <Input type="text" placeholder={i18n.t('payment.name')} onFocus={e => {setFocusedField('name')}} value={order.otherPaymentCardName} name='otherPaymentCardName' onChange={ e => handleUpdateOrderInput(e) }></Input>
                                </Col>
                            </Row>
                            
                            <Row className="reservationRow">
                                <Col>
                                    <Input invalid={cardErrors.number ? true : false} type="text" placeholder={i18n.t('payment.number')} onFocus={e => {setFocusedField('number')}} value={order.otherPaymentCardNumber} name='otherPaymentCardNumber' onChange={ e => handleUpdateOrderInput(e) } maxLength="19"></Input>
                                </Col>
                            </Row>

                            <Row className="reservationRow">
                                <Col>
                                    <Input invalid={cardErrors.expiration ? true : false} type="text" placeholder={i18n.t('payment.mmyy')} onFocus={e => {setFocusedField('expiry')}} value={order.otherPaymentCardExpiry} name='otherPaymentCardExpiry' onChange={ e => handleUpdateOrderInput(e) }></Input>
                                </Col>

                                <Col>
                                    <Input invalid={cardErrors.cvc ? true : false} type="text" placeholder={i18n.t('payment.cvc')} onFocus={e => {setFocusedField('cvc')}} value={order.otherPaymentCardCVC} name='otherPaymentCardCVC' onChange={ e => handleUpdateOrderInput(e) }></Input>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                :
                undefined
            }
            
            { !isService && store.hasTips ?
                <TipBlock handleToggleModal={handleToggleModal} cart={cart} isModalOpen={isModalOpen} order={order} handleUpdateOrderInput={handleUpdateOrderInput} /> : undefined
            }
            <hr/>

            <FormGroup tag="fieldset">
                <div className="categoryTitle">
                    <SVG className="iconCategory" src={home} alt="icon card"/> {i18n.t('address.facturationAddress')}<span style={{ color: 'red'}}>*</span>
                </div>

                { !isService || isServiceRequiringDelivery ?
                <FormGroup check>
                    <Label check>
                        <Input 
                            type="radio" 
                            name="facturationAddress" 
                            checked={order.facturationAddress} 
                            onChange={ e => handleUpdateOrderInput({ 
                                target: { 
                                    name:'facturationAddress', 
                                    value: order.address && order.address !== 'other' ? order.address : order.otherAddress 
                                }
                            })} 
                        /> 
                        {isServiceWithDestination ? i18n.t('address.useSameFacturationAddress') : i18n.t('address.useSameFacturationAddressOrder')}
                    </Label>
                </FormGroup> : (
                    user?.addresses?.map((a,i) => (
                        <FormGroup key={'fac-'+i} check>
                            <Label check>
                                <Input type="radio" name="facturationAddress" checked={order.facturationAddress == a} onChange={ e => handleUpdateOrderInput({ target: { name:'facturationAddress', value: a }})} /> { a.full }
                            </Label>
                        </FormGroup>
                    ))
                )}

                <FormGroup check>
                    <Label check>
                        <Input type="radio" name="facturationAddress" checked={order.displayOtherFacturationAddress} onChange={ e => handleUpdateOrderInput({ target: { name:'displayOtherFacturationAddress', value: true }})} /> {i18n.t('address.useOtherFacturationAddress')}
                    </Label>
                </FormGroup>

                <Row>
                    { order && order.displayOtherFacturationAddress ?
                        <>
                            <Col xs="8"><LocationSearchInput value={order.otherFacturationAddress && order.otherFacturationAddress.formatted_address} displayLabel={false} onSelect={ e => handleUpdateOrderInput({ target: { name: "otherFacturationAddress", value: e}})}/></Col>
                            <Col xs="4"><Input type="text" placeholder={i18n.t('address.app')} value={order.facturationApartment} onChange={ e => handleUpdateOrderInput(e) } name="otherFacturationApartment"></Input></Col>
                        </>
                        : undefined
                    }
                </Row>

            </FormGroup>
        </FormGroup>

        <FormGroup className="buttonForm">
            <Row>
                <div className="stepBack">
                    <Button className="stepBackButton" onClick={prevStep}>{i18n.t('pickup.returnToDelivery')}</Button>
                </div>
                <div className="confirmation">
                    <Button
                        disabled={ (order.displayOtherCard ? (!order.otherPaymentCardCVC || !order.otherPaymentCardCVC.match( /([0-9]{3})/ )) || (!order.otherPaymentCardExpiry || !order.otherPaymentCardExpiry.match( /(0[1-9]|10|11|12)\/[0-9]{2}/ )) || (!order.otherPaymentCardNumber || !order.otherPaymentCardNumber.replaceAll(' ','').match( /([0-9]{16})/ )) || (!order.otherPaymentCardName || !order.otherPaymentCardName.length > 3) : !order.paymentCard) || (order.displayOtherFacturationAddress ? !order.otherFacturationAddress : !order.facturationAddress) }
                        className="confirmationButton" 
                        onClick={ e => handleSendOrder(e, isServiceRequiringDelivery, isService, isServiceWithDestination)}
                    >
                        {(order.displayOtherCard && (order.otherPaymentCardCVC && order.otherPaymentCardExpiry && order.otherPaymentCardName && order.otherPaymentCardNumber)) ? i18n.t('payment.saveCardAndOrder') : i18n.t('payment.ordering')}
                    </Button>
                </div>
            </Row>
        </FormGroup>
        </>
    )

};

export default ReservationStep2Component;