import React, { useState, useRef } from 'react';
import { Nav, Navbar, Form, FormControl, Dropdown } from 'react-bootstrap'
import { Link } from "react-router-dom";
import SVG from 'react-inlinesvg';
import { ReactComponent as WhiteLogo} from '../../../assets/images/genie_blanc.svg';
import i18n from '../../../translations/i18n';

import RoundedMenu from '../../../containers/Layout/RoundedMenu';

import SignUpModal from '../../../containers/Page/SignUpModal';
import SignUpModalComponent from '../SignUpModal';

import Login from '../../../containers/Page/Login';
import LoginComponent from '../Login';

import RatingOrder from '../../../containers/Page/RatingOrder';
import RatingOrderComponent from '../RatingOrder';

import AccountActivation from '../../../containers/Page/AccountActivation';
import AccountActivationComponent from '../AccountActivation';

import { personCircle, menuSharp, searchSharp } from "ionicons/icons";
import AsyncSelect from 'react-select/async';
// import { dropdownSearch } from '../../../actions/searchAction';
import axios from 'axios';
import AppConfig from '../../../config/config';

const TopBarComponent = ({ isHomePage, user, search, isOpenSignUp, toggleSignUp, isOpenLogin, orderToReview, clearOrderToReview, toggleLogin, isOpenAccountActivation, toggleAccountActivation, handleChangeInput, handleSelectProduct, handleSubmitSearch, handleChangeInputDropdown, handleToggleLang, handleLogout }) => {

    let menu = useRef();

    const customStyles = {
        container: (provided, { selectProps }) => ({
            ...provided,
            display: 'flex',
            width: 'calc(100% - 40px)',
        }),
        control: (provided, { isFocused }) => ({
            ...provided,
            display: 'flex',
            width: '100%',
            borderStyle: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            "&:hover": {
                boxShadow: 'none'
            },
            cursor: 'pointer'
        }),
        placeholder: (styles) => ({
            ...styles,
            color: 'white'
        }),
        input: (styles) => ({
            ...styles,
            color: 'white',
        }),
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        singleValue: (styles) => ({
            ...styles,
            color: 'white',
        }),
    }

    const loadOptions = (inputValue) => {
        return new Promise((resolve) => {
            if (inputValue.length > 2) {
                axios.get(AppConfig.baseUrlApi + '/search/dropdown?term=' + inputValue.toLowerCase())
                .then(response => {
                    resolve(response.data.products);
                });
            } else resolve([]);
        });
    };

    return( 
    <>
        <div ref={ menu } className={isHomePage ? 'topBarBackground mobileMainContainer' : 'topBarBackground'} style={{ 
            height: isHomePage ? '100vh': '300px',
            minHeight: isHomePage ? '800px' : '300px',
            backgroundPosition: isHomePage ? 'center' : 'top' 
        }}>
            <Navbar bg="transparent" id="topBar">
                <Navbar.Brand href='/' id='logoCentral' className='logoCentralBlackNavbar'>
                    <WhiteLogo />
                </Navbar.Brand>

                <Nav className="mr-auto">
                    <Form inline id="searchBar" onSubmit={e => handleSubmitSearch(e)} autoComplete="off" >
                        <SVG src={searchSharp} alt="icon search" className='searchBarIcon fillWhite' style={{ cursor: "pointer" }} onClick={e => { handleSubmitSearch(e) }} />
                        <AsyncSelect
                            value={search || ''}
                            inputValue={search || ''}
                            onInputChange={(value, action) => handleChangeInputDropdown(value, action)}
                            onChange={product => handleSubmitSearch()}
                            onKeyDown={e => {
                                var key = e.key || e.keyCode;
                                if (key === 'Enter' || key === 13) {
                                    handleSubmitSearch(e)
                                }
                            }}
                            loadOptions={loadOptions}
                            loadingMessage={() => i18n.t('search.searching') + '...'}
                            getOptionLabel={product => `${product[`name_${i18n.currentLocale() === 'fr' ? 'FR' : 'EN'}`]} (${product.myStore.name})`}
                            getOptionValue={product => product.myStore._id}
                            menuIsOpen={search ? true : false}
                            closeMenuOnSelect
                            placeholder={i18n.t('global.howCanIHelpYou')}
                            noOptionsMessage={() => null}
                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                            styles={customStyles}
                        />
                    </Form>
                </Nav>

                <Nav>
                    {!user.token ?
                        <>
                            <button onClick={e => handleToggleLang()} className="" style={{ padding: 0, backgroundColor: 'transparent', color: i18n.locale === 'en' ? "lightgrey" : "white", margin: "0 5px", width: 30}}>FR</button>
                            <button onClick={e => handleToggleLang()} className="" style={{ padding: 0, backgroundColor: 'transparent', color: i18n.locale === 'fr' ? "lightgrey" : "white", margin: "0 5px", width: 30}}>EN</button>
                            <button onClick={toggleSignUp} style={{ backgroundColor: 'transparent', color: 'white', textTransform: 'uppercase' }}>{i18n.t('global.signin')}</button>
                            <button style={{ backgroundColor: 'transparent', textTransform: 'uppercase' }}><a href='/dashboard' style={{ color: 'white !important' }}>{i18n.t('global.partnerAccess')}</a></button>
                            <button onClick={toggleLogin} className="whiteBtn" style={{ textTransform: 'uppercase'}}>{i18n.t('global.login')}</button>
                        </>
                        :
                        <>
                            <button onClick={e => handleToggleLang()} className="" style={{ padding: 0, backgroundColor: 'transparent', color: i18n.locale === 'en' ? "lightgrey" : "white", margin: "0 5px", width: 30}}>FR</button>
                            <button onClick={e => handleToggleLang()} className="" style={{ padding: 0, backgroundColor: 'transparent', color: i18n.locale === 'fr' ? "lightgrey" : "white", margin: "0 5px", width: 30}}>EN</button>

                            <Dropdown className="dropdownUser">
                                <Dropdown.Toggle as={CustomToggleButton} id="dropdown-custom-components">
                                    <SVG src={personCircle} alt="button account" className='btnAccount fillWhite' />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as={CustomToggleItem}>{user.firstName} {user.lastName}</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item as={CustomToggleItem}>
                                        <Link to="/account">{i18n.t('global.myAccount')}</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item as={CustomToggleItem}>
                                        <Link to="/historic">{i18n.t('global.historic')}</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item as={CustomToggleItem}>
                                        <Link to="logout" onClick={(e) => handleLogout(e)}>{i18n.t('global.logout')}</Link>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <div className="dropdown-menu m-2 dropdownUserMobile">
                                <Dropdown.Item as={CustomToggleItem}>{user.firstName} {user.lastName}</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item as={CustomToggleItem}>
                                    <Link to="/account">{i18n.t('global.myAccount')}</Link>
                                </Dropdown.Item>
                                <Dropdown.Item as={CustomToggleItem}>
                                    <Link to="/historic">{i18n.t('global.historic')}</Link>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item as={CustomToggleItem}>
                                    <Link to="logout" onClick={(e) => handleLogout(e)}>{i18n.t('global.logout')}</Link>
                                </Dropdown.Item>
                            </div>
                        </>
                    }
                </Nav>
                {/* </Navbar.Collapse> */}
            </Navbar>
            <section id="storeMenu">
                <RoundedMenu withChat={true} isHomePage={isHomePage}/>
            </section>

            { isHomePage && 
            <section id='heroTitle'>
                <p className='heroTitleText'>{i18n.t('homepage.title_1')}</p>
                <p className='heroTitleText' style={{ marginBottom: '20px' }}>{i18n.t('homepage.title_2')}</p>
                <p className='heroDescriptionText'>{i18n.t('homepage.heroDescription_1')}</p>
                <p className='heroDescriptionText'>{i18n.t('homepage.heroDescription_2')}</p>
            </section>
            }
        </div>
            
        <SignUpModal isOpen={isOpenSignUp} toggle={toggleSignUp} toggleLogin={toggleLogin} Layout={SignUpModalComponent} />
        <Login isOpen={isOpenLogin} toggle={toggleLogin} toggleSignUp={toggleSignUp} toggleAccountActivation={toggleAccountActivation} Layout={LoginComponent} />
        { isOpenAccountActivation ?
        <AccountActivation isOpen={isOpenAccountActivation} toggle={toggleAccountActivation} Layout={AccountActivationComponent} />
        : null }

        { orderToReview ? <RatingOrder orderToReview={orderToReview} clearOrderToReview={_ => clearOrderToReview() } Layout={RatingOrderComponent} /> : undefined }
    </>
)};

const CustomToggleButton = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </span>
 ));
 
 const CustomToggleItem = React.forwardRef(({ children, onClick }, ref) => (
    <li ref={ref} className="dropdown-item">
        {children}
    </li>
 ));

export default TopBarComponent;