import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

import moment from "moment";

import { useToasts } from 'react-toast-notifications'

import i18n from "../../translations/i18n";
import Loading from "./UI/Loading";

import SignUpModal from '../../containers/Page/SignUpModal';
import SignUpModalComponent from './SignUpModal';

import Login from '../../containers/Page/Login';
import LoginComponent from './Login';

import RatingOrder from '../../containers/Page/RatingOrder';
import RatingOrderComponent from './RatingOrder';

const Historic = ({ user, orders, customOrders, pickups, view, setView, pages, activePage, setActivePage, passSameOrder, cancelOrder, isLoading, isOpenLogin, isOpenSignUp, toggleSignUp, toggleLogin, history, toastMessage, downloadInvoice, downloadInvoiceGenie }) => {
    
    const { addToast } = useToasts();
    const [orderToReview, setOrderToReview] = useState(undefined);
    
    return(
    <>
        { !user.token ? 
            <div id="historicPage" className="paddingContainer">
                <h1 style={{ textAlign: "center"}}>{i18n.t('global.notLoggedTitle')}</h1>
                <div class="not_logged" style={{ textAlign: "center"}}>
                    {i18n.t('global.notLoggedSubtitleShort')}<div class="login_link" onClick={toggleLogin}>{i18n.t('global.notLoggedSubtitleEnd')}</div>
                </div>
            </div>
            
            :
        <>
            { isLoading ? <Loading /> : undefined }
            { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }

            <div id="historicPage" className="paddingContainer">
                <h2 className="historicTitle">{i18n.t('historic.historicOrder')}</h2>

                {(orders.length === 0 && pickups.length === 0 && customOrders.length === 0) ?
                    <div className="emptyHistoric">
                        {i18n.t('historic.emptyHistoric')}
                    </div>
                :

                <div className="historic">
                    <div className="historicToggle">
                        <button type='button' className={view === 'order' ? 'active' : null} onClick={(e) => setView('order')}>
                            {i18n.t('historic.order')}
                        </button>
                        <button type='button' className={view === 'customOrder' ? 'active' : null} onClick={(e) => setView('customOrder')}>
                            {i18n.t('historic.customOrder')}
                        </button>
                        <button type='button' className={view === 'pickup' ? 'active' : null} onClick={(e) => setView('pickup')}>
                            {i18n.t('historic.pickup')}
                        </button>
                    </div>

                    <div className="historicPagination">
                        <button type='button' className={''} disabled={activePage === 1} onClick={(e) => setActivePage(activePage - 1)}>
                            {'<'}
                        </button>
                        { Array.from({length: pages}, (x, i) => i + 1).map(page => (
                        <button type='button' className={activePage === page ? 'active' : null} onClick={(e) => setActivePage(page)}>
                            {page}
                        </button>
                        )) }
                        <button type='button' className={''} disabled={activePage === pages} onClick={(e) => setActivePage(activePage + 1)}>
                            {'>'}
                        </button>
                    </div>

                    { view === 'order' && <div>
                    {/* { orders.length > 0 ? <h3 className="orderTitle">{i18n.t('footer.order')}</h3> : undefined } */}
                    { orders && orders.map((o, index) => {
                        if(!o.store) return undefined
                        return(
                        <div style={{ marginBottom: '15px'}} key={index}>
                            <div className="orderHistoric">
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.orderDate')}: {moment.unix(o.date/1000).format("DD/MM/YYYY")}</Col>
                                </Row>
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.orderRef')}: {o.id}</Col>
                                    {o.deliveryDate ? <Col> {i18n.t('historic.deliveryDate')}: {moment.unix(o.deliveryDate/1000).format("DD/MM/YYYY")}</Col> : undefined }
                                </Row>
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.store')}: {o.store.name} </Col>
                                    <Col> {i18n.t('historic.status')}: {o.status}</Col>
                                </Row>
                                <Row className="categoryOrder">
                                    <Col>
                                        { o.items.map((i, index) => {
                                            return (
                                                <>
                                                <Row key={'pro'+index} style={{ marginLeft: 25}}> {i.quantity} x {i.product.name}</Row>

                                                {
                                                    i.subProducts != null && i?.subProducts?.length > 0 ?
                                                        i.subProducts.map((sp, index) => {
                                                            if(sp.name !== 'standard') return(
                                                            <Row key={'sb'+index} style={{ marginLeft: 35, color: "grey"}}> {sp.name}</Row>
                                                        )})
                                                    
                                                    :
                                                    undefined
                                                }
                                                </>
                                            )
                                        })}
                                    </Col>
                                </Row>
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.totalCost')}: {o.totalPrice.toFixed(2)} $</Col>
                                </Row>
                                <Row className="categoryOrder">
                                    { (o.status != i18n.t('status.completed') && o.status != i18n.t('status.cancelled') && o.status != i18n.t('status.rejected')) && <Button variant="success" className="primary" onClick={e => history.push('/track/'+o.id) }>{i18n.t('trackMyOrder.title')}</Button> }
                                    <Button variant="success" className="primary" onClick={e => passSameOrder(o).then( res => history.push('/store/'+o.store.id)) }>{i18n.t('historic.sameOrder')}</Button>
                                    <Button variant="success" className="primary" onClick={e => downloadInvoiceGenie('order', o.id)}>{i18n.t('historic.emailInvoices')}</Button>
                                    <Button variant="success" className="primary" onClick={e => downloadInvoice('order', o.id, true)}>{i18n.t('historic.downloadInvoiceOrder')}</Button>
                                    <Button variant="success" className="primary" onClick={e => downloadInvoiceGenie('order', o.id, true)}>{i18n.t('historic.downloadInvoiceGenie')}</Button>
                                    { o.status == 'En attente de paiement' ||  o.status == 'En attente de validation' || o.status == 'Waiting for validation' || o.status == 'Waiting for payement' ? <Button onClick={e => cancelOrder(o.id)} variant="danger" className="danger">{i18n.t('historic.cancel')}</Button> : undefined }
                                </Row>
                                <Row className="categoryOrder">
                                    { o.commentStatus == 'toSuggest' || o.commentStatus == 'refused' ? <Button variant="success" className="primary" onClick={e => setOrderToReview(o) }>{i18n.t('rating.text')}</Button> : undefined }
                                </Row>
                            </div>
                        </div>
                    )}) }
                    </div> }

                    { view === 'customOrder' && <div>
                    { customOrders && customOrders.map((o, index) => (
                        <div style={{ marginBottom: '15px'}} key={'co'+index}>
                            <div className="orderHistoric">
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.orderDate')}: {moment.unix(o.date/1000).format("DD/MM/YYYY")}</Col>
                                </Row>
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.orderRef')}: {o.id}</Col>
                                    <Col> {i18n.t('historic.deliveryDate')}: {moment.unix(o.deliveryDate/1000).format("DD/MM/YYYY")}</Col>
                                </Row>
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.status')}: {o.status}</Col>
                                </Row>
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.client')}: {o.contactName} </Col>
                                    <Col> {i18n.t('historic.totalCost')}: {o.totalPrice.toFixed(2)} $</Col>
                                </Row>
                                { o.payed && 
                                <Row className="categoryOrder">
                                    <Button variant="success" className="primary" onClick={e => downloadInvoice('customorder', o.id)}>{i18n.t('historic.emailInvoice')}</Button>
                                    <Button variant="success" className="primary" onClick={e => downloadInvoice('customorder', o.id, true)}>{i18n.t('historic.downloadInvoice')}</Button>
                                </Row> }
                            </div>
                        </div>
                    )) }
                    </div> }

                    { view === 'pickup' && <div>
                    { pickups && pickups.map((p, index) => (
                        <div style={{ marginBottom: '15px'}} key={'p'+index}>
                            <div className="orderHistoric">
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.orderDate')}: {moment.unix(p.date/1000).format("DD/MM/YYYY")}</Col>
                                </Row>
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.orderRef')}: {p.id}</Col>
                                    <Col> {i18n.t('historic.deliveryDate')}: {moment.unix((p.deliveryDate ? p.deliveryDate : p.deliveryDateEnd)/1000).format("DD/MM/YYYY")}</Col>
                                </Row>
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.departure')}: {p.fromAddress.full} </Col>
                                    <Col> {i18n.t('historic.arrival')}: {p.toAddress.full}</Col>
                                </Row>
                                <Row className="categoryOrder">
                                    <Col> {i18n.t('historic.status')}: {p.status} </Col>
                                    <Col> {i18n.t('historic.totalCost')}: {p.totalPrice.toFixed(2)}$</Col>
                                </Row>

                                { p.payed && 
                                <Row className="categoryOrder">
                                    { (p.status != i18n.t('status.completed') && p.status != i18n.t('status.cancelled')) ?
                                        <Link to={"/track-pickup/" + p.id} >
                                            <Button className="primary" style={{ marginLeft: '5px' }}>{i18n.t('trackMyOrder.pickupTitle')}</Button>
                                        </Link>
                                    : null}
                                    <Button variant="success" className="primary" onClick={e => downloadInvoice('pickup', p.id)}>{i18n.t('historic.emailInvoice')}</Button>
                                    <Button variant="success" className="primary" onClick={e => downloadInvoice('pickup', p.id, true)}>{i18n.t('historic.downloadInvoice')}</Button>
                                </Row> }
                            </div>
                        </div>
                    )) }
                    </div> }

                    <div className="historicPagination">
                        <button type='button' className={''} disabled={activePage === 1} onClick={(e) => setActivePage(activePage - 1)}>
                            {'<'}
                        </button>
                        { Array.from({length: pages}, (x, i) => i + 1).map(page => (
                        <button type='button' className={activePage === page ? 'active' : null} onClick={(e) => setActivePage(page)}>
                            {page}
                        </button>
                        )) }
                        <button type='button' className={''} disabled={activePage === pages} onClick={(e) => setActivePage(activePage + 1)}>
                            {'>'}
                        </button>
                    </div>
                </div>
                }
            </div>
        </>
        }

        <Login isOpen={isOpenLogin} toggle={toggleLogin} toggleSignUp={toggleSignUp} Layout={LoginComponent} />
        <SignUpModal isOpen={isOpenSignUp} toggle={toggleSignUp} toggleLogin={toggleLogin} Layout={SignUpModalComponent} />
        { orderToReview ? <RatingOrder isOpen={true} orderToReview={orderToReview} clearOrderToReview={_ => setOrderToReview(undefined)} Layout={RatingOrderComponent} /> : undefined }

    </>
)}

export default withRouter(Historic);