import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../../config/config';

import i18n from '../../translations/i18n';

import { ratingMyOrder } from '../../actions/orderAction';

class RatingOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            note: 5,
            comment: '',
            toastMessage: undefined,
            isOpen: false
        }
    }

    handleUpdateNote = e => {
        this.setState({ note: e });
    }

    handleChangeComment = e => {
        this.setState({ comment: e.nativeEvent?.text || e.target?.value })
    }

    handleSendComment = e => {
        this.props.ratingMyOrder(
                this.props.route?.params?.orderToReview.store.id || this.props.orderToReview?.store?.id,
                this.props.route?.params?.orderToReview.id || this.props.orderToReview?.id,
                this.state.comment,
                this.state.note
        )
        .then( response => {
            this.setState({ toastMessage: { type: 'success', msg: i18n.t('rating.thank_you')} }, () => {
                this.props.clearOrderToReview ? this.props.clearOrderToReview() : undefined;
                this.setState({ toastMessage: undefined });

                if(window?.document){}
                else this.props.navigation.goBack();
            });
        }).catch(err => {
            console.log('Error - RatingOrder - handleSendComment', err)
        })
    }

    handleRefuseReview = async () => {
        try{
            await axios.patch(config.baseUrlApi + '/order/' + (this.props.route?.params?.orderToReview.id || this.props.orderToReview?.id), { commentStatus: 'refused' });
            this.props.clearOrderToReview ? this.props.clearOrderToReview() : undefined;
        }catch(err){
            console.log('ERROR - RatingOrder - handleRefuseReview', err);
        }
    }

    openModal = () => {
        this.setState({ isOpen: true })
    }

    render() {
        const { Layout, isLoading, navigation } = this.props;
        
        return (
            <Layout
                isOpen={this.props.isOpen || this.state.isOpen}
                order={this.state.order}
                isLoading={isLoading}
                navigation={navigation}
                orderToReview={this.props.route?.params?.orderToReview || this.props.orderToReview }
                note={this.state.note}
                comment={this.state.comment}
                handleUpdateNote={this.handleUpdateNote}
                handleChangeComment={this.handleChangeComment}
                handleSendComment={this.handleSendComment}
                toastMessage={this.state.toastMessage}
                handleRefuseReview={this.handleRefuseReview}
                openModal={this.openModal}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.user.isFetching
});

const mapDispatchToProps = {
    ratingMyOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(RatingOrder);
