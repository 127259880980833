import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addProductCart, chooseService } from "../../actions/cartAction";
import { getOneProduct } from "../../actions/productAction";

import i18n from '../../translations/i18n';

class StoreProductSingle extends Component {

    constructor(props){
        super(props);

        this.state = {
            isModalOpen: false,
            isMobileLoading: true,
            product: undefined,
            toastMessage: undefined,
            items: {}
        }
    }

    UNSAFE_componentWillMount() {
        let product = this.props.product ? this.props.product : this.props.route.params.product;
        this.setState({ product: product })
    }

    componentDidMount() {
        if (this.props.search && this.props.search.includes('product')) {
            const productId = this.props.search.replace('?product=', '');
            if (productId == this.props.product.id) {
                const element = document.getElementById('product_' + productId);
                element.scrollIntoView(true);
                window.scrollBy(0, -200);
            }
        }
    }

    handleToggleModal = async (targetLabel) => {
        if (!targetLabel || (targetLabel && (typeof targetLabel === 'string' || targetLabel instanceof String) && targetLabel.includes('options'))) {
            return;
        }

        if(!this.state.isModalOpen){
            try{
                let p = await this.props.getOneProduct( this.state.product.id );

                await this.fillSubproduct(p);
                await Promise.all(p.subProducts?.filter(p => p.isMultiOptions || p.optionProduct).map(p => this.fillSubproduct(p)));

                
                this.setState({ product: p, isModalOpen: !this.state.isModalOpen, items: { standard: [ p.subProducts.find( sp => sp.name == 'standard' ) ], optionCategories: {} }});
            }catch(err){
                console.log('***** StoreProductSingle - handleToggleModal - getOneProduct', err);
            }
        }
        else{
            this.setState({ isModalOpen: !this.state.isModalOpen});
        }
    }

    fillSubproduct = async (p) => {
        return new Promise( async (resolve, reject) => {
            this.setState({ isMobileLoading: true });

            let subproducts = p.optionProductObj ? p.optionProductObj.subProducts : p.subProducts;

            if(subproducts) subproducts = await Promise.all( subproducts.filter( sp => sp.optionProduct != null ).map( async sp => { sp.optionProductObj = await this.props.getOneProduct( sp.optionProduct ); return sp }) );
            else{
                p.optionProductObj = await this.props.getOneProduct( p.id, true ); 

                // TO REMOVE
                let lCats = p.optionProductObj.optionCategories.map(oc => oc.id);
                p.optionProductObj.subProducts = this.state.product.subProducts.filter( sp => lCats.includes(sp.category));
            }
            
            this.setState({ product: this.state.product, isMobileLoading: false }, _ => resolve());
        });
    }

    checkSelectedQteFromSubcategory = (subcategory, optionCategory) => {
        let op = {};
        if (optionCategory) {
            op = optionCategory;
        } else op = this.state.product.optionCategories?.filter(op => op.id === subcategory.id)[0];

        if (op) {
            if (op.selectedQty) return op.selectedQty < op.subProductOptionMaximum;
            else return true;
        } else return true;
    }

    clearMyProductSubProducts = () => {
        let myProduct = this.state.product;
        myProduct.selectedSubproducts = [];
        this.setState({ product: myProduct })
    }

    checkValidationButton = (override_product) => {
        let requiredCat = (override_product ? override_product.optionProductObj?.optionCategories : this.state.product?.optionCategories)?.filter( op => op.subProductOptionMinimum > 0 );
        
        let requiredChildCat = (override_product ? override_product.subProducts : this.state.product.subProducts)?.filter( sp => sp.qty && sp.qty > 0).map( sp => { return(( sp.optionProductObj ? sp.optionProductObj : sp)?.optionCategories?.filter( op => op.subProductOptionMinimum > 0 ))});

        if(requiredChildCat?.length > 0 && requiredChildCat[0]) requiredCat = requiredCat.concat(...requiredChildCat);

        if(requiredCat && requiredCat.length > 0){
            for( let cat of requiredCat ){
                if( cat && ( !cat.selectedQty || cat.selectedQty < cat.subProductOptionMinimum )) return false;
            }

            return true;

        }
        else return true;

    }

    formatOrderItem = (item, filled) => {      
        if(!filled) {
            var myProduct = { name_FR: item.name_FR, name_EN: item.name_EN, product: item.id, quantity: item.quantity, unitPrice: item.unitPrice, store: item.store, isTaxable: item.isTaxable, preparationTime: item.preparationTime };
            if (item.isService) {
                myProduct.isService = true;
                myProduct.isRequiringDelivery = item.isRequiringDelivery
                myProduct.isWithoutBooking = item.isWithoutBooking
                myProduct.isRequiringDestination = item.isRequiringDestination
                myProduct.isProductWithHourlyRate = item.isProductWithHourlyRate
                myProduct.preparationTime = item.preparationTime
            }
        }

        let sp = item.optionProductObj ? item.optionProductObj.subProducts : item.subProducts;

        if(sp){
            sp = sp.filter( aSp => aSp.qty && aSp.qty > 0 );

            if(sp.length > 0){
                let subProducts = sp.map( aSp => this.formatOrderItem(aSp, true) );
                (filled ? item : myProduct).subProducts = subProducts;
            }

        }
        if(!filled) return myProduct;
        else{
            let myItem = item.optionProductObj ? item.optionProductObj : item;
            console.log('***** myItem', myItem);
            return { id: item.id, quantity: item.qty, subProducts: item.subProducts,  name_FR: myItem.name_FR, name_EN: myItem.name_EN, additionalPrice: item.additionalPrice, preparationTime: item.preparationTime };
        }

    }

    handleChooseSubProduct = (e, subproduct) => {
        let product = this.state.product;
        if(subproduct){
            product.selectedSubproducts = [{ id: subproduct.id, name: subproduct.name, additionalPrice: subproduct.additionalPrice }];
            if(!product.isService){
                this.chooseProduct(product);
            }else{
                this.chooseService(product);
            }
        }
        else{
            if(product.maximumNumberOption > 1){
                if(e.target.checked){
                    if(product.selectedSubproducts) product.selectedSubproducts.push({ id: e.target.value, name: e.target.dataset.name, additionalPrice: e.target.dataset.price });
                    else product.selectedSubproducts = [{ id: e.target.value, name: e.target.dataset.name, additionalPrice: e.target.dataset.price }];
                }
                else product.selectedSubproducts = product.selectedSubproducts.filter(sp => sp.id !== e.target.value );
            }
            else{
                product.selectedSubproducts = [{ id: e.target.value, name: e.target.dataset.name, additionalPrice: e.target.dataset.price }];
            }
        }

        this.setState({ product: product })
    }

    chooseService = (service) => {
        let serviceToAdd = this.formatOrderItem(service);

        this.props.chooseService(serviceToAdd).then(response => {
            this.setState({ redirect: true}, () => {
                this.setState({ redirect: false })
            })
        }).catch(err => {
            console.log('***** ERROR - StorePage - chooseService', err);
        })
    }

    chooseProduct = (product) => {

        let productToAdd = this.formatOrderItem(this.state.product);

        this.props.addProductCart(productToAdd).then(response => {
            this.setState({ toastMessage: { type: 'success', msg: i18n.t('payment.productAdded')} }, () => {
                this.setState({ toastMessage: undefined });
            });
        }).catch(err => {
            console.log('***** ERROR - StorePage - chooseProduct', err);
        })
    }

    handleUpdateSubproduct = (sp, isAdding, clearSelection, op, sps) => {
        let items = sp;
        //let cat = this.state.product?.optionCategories?.find(op => op.id == sp.category);
        if(isAdding){
            if(sps){
                sps.filter( sp => sp.id != items.id && sp.qty > 0).forEach( sp => { sp.qty -= 1; op.selectedQty -= 1; op.additionalPrice -= sp.additionalPrice;});
            }

            if(!items?.qty) items.qty = 1
            else sp.qty += 1;

            if(!op.selectedQty) op.selectedQty = 1;
            else op.selectedQty += 1;
           
            if(!op.additionalPrice) op.additionalPrice = sp.additionalPrice;
            else op.additionalPrice += sp.additionalPrice;

        }else{

            sp.qty -= 1;

            op.selectedQty -= 1;
            op.additionalPrice -= sp.additionalPrice;
        }

        this.setState({ product: this.state.product });
    }

    render() {
        const { Layout, navigation } = this.props;

        return (
            <Layout
                navigation={navigation}
                product={this.state.product}
                readOnly={this.props.readOnly}
                redirect={this.state.redirect}
                handleAddProductToCart={this.props.handleAddProductToCart}
                handleRemoveProductToCart={this.props.handleRemoveProductToCart}
                chooseService={this.chooseService.bind(this)}
                chooseProduct={this.chooseProduct.bind(this)}
                isModalOpen={this.state.isModalOpen}
                handleToggleModal={this.handleToggleModal.bind(this)}
                handleChooseSubProduct={this.handleChooseSubProduct.bind(this)}
                clearMyProductSubProducts={this.clearMyProductSubProducts.bind(this)}
                handleUpdateSubproduct={this.handleUpdateSubproduct.bind(this)}
                checkSelectedQteFromSubcategory={this.checkSelectedQteFromSubcategory.bind(this)}
                toastMessage={this.state.toastMessage}
                items={this.state.items}
                checkValidationButton={this.checkValidationButton.bind(this)}
                fillSubproduct={this.fillSubproduct.bind(this)}
                isMobileLoading={this.state.isMobileLoading}
                prodRefs={this.props.prodRefs}
                selectedProduct={this.props.selectedProduct}
                flatlistRef={this.props.flatlistRef}
                mobileProductId={this.props.mobileProductId}
                flatlistIndex={this.props.flatlistIndex}
            />
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    addProductCart,
    chooseService,
    getOneProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreProductSingle);
