import React, { Component } from 'react';
import config from '../../config/config';

import { sendEmail } from "../../actions/userAction";

import i18n from "../../translations/i18n";

class Partner extends Component {

    constructor(props){
        super(props);
        this.state = {
            partner: {}
        }
    }

    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+this.props.title;
    }
    handleUpdateRecruitInput = (e) => {
        this.setState({ partner: { ...this.state.partner, [e.target.name] : e.target.value }});
    }

    handleSendEmail = (e) => {
        let partner = {
            template: 'partenaire',
            data: { ...this.state.partner }
        }

        sendEmail(partner).then( response => {
            this.setState({ toastMessage: { type: 'success', msg: i18n.t('recruit.success')} }, () => {
                this.setState({ toastMessage: undefined, partner: {}, isLoading: false });
            });

        }).catch(err => {
            this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.unknown_error')} }, () => {
                this.setState({ toastMessage: undefined, isLoading: false });
            });
            console.log('***** PartnerPage - handleSendEmail - sendEmail ', err);
        })
    }


    render() {
        const { Layout, navigation } = this.props;

        return (
            <Layout
                handleUpdateRecruitInput={this.handleUpdateRecruitInput.bind(this)}
                handleSendEmail={this.handleSendEmail.bind(this)}
                navigation={navigation}
                partnerForm={this.state.partner}
                isLoading={this.state.isLoading}
                toastMessage={this.state.toastMessage}
            />

        );
    }
}

export default Partner;