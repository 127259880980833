import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import SVG from 'react-inlinesvg';
import { pricetag, card, home, create, time, basket } from "ionicons/icons";
import LocationSearchInput from '../../../containers/Utils/LocationSearchInput';
import moment from 'moment';
import i18n from "../../../translations/i18n";

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

const PickupStep5Component = ({ changeStep, cards, pickup, handleUpdatePickupInput, handleCheckVoucher, prevStep, handleCheckedAddress, cardErrors, handleSendPickup }) => {
    const [focusedField, setFocusedField] = useState('name');

    return (
        <>
            <h3>{i18n.t('pickup.summaryOrder')}</h3>

            <div className="deliveryResume">
                <div className="deliveryTitle">
                    <div className="titleResume">
                        <SVG src="/images/menu/icon-location.svg" alt="icon location" className="iconResume"/>
                        {i18n.t('pickup.startAddress')}
                    </div>
                    <div className="editResume" onClick={e => changeStep(1)}>
                        <SVG className="iconEdit" src={create} alt="button edit"></SVG>
                    </div>
                </div>

                <div className="deliveryText">
                    <div className="deliveryAddress">{ pickup.displayOtherAddress || pickup.displayStartOtherAddress ? pickup.otherStartAddress.formatted_address : pickup.startAddress.full }</div>
                </div>
            </div>

            <div className="deliveryResume">
                <div className="deliveryTitle">
                    <div className="titleResume">
                        <SVG src="/images/menu/icon-location.svg" alt="icon location" className="iconResume"/>
                        {i18n.t('pickup.deliveryAddress')}
                    </div>
                    <div className="editResume" onClick={e => changeStep(2)}>
                        <SVG className="iconEdit" src={create} alt="button edit"></SVG>
                    </div>
                </div>

                <div className="deliveryText">
                    <div className="deliveryAddress">{ pickup.endAddress.full ? pickup.endAddress.full : pickup.endAddress.formatted_address }</div>
                </div>
            </div>

            <div className="deliveryResume">
                <div className="deliveryTitle">
                    <div className="titleResume">
                        <SVG src={time} alt="icon time" className="iconResume"/>
                        {i18n.t('pickup.pickupDate')}
                    </div>
                    <div className="editResume" onClick={e => changeStep(1)}>
                        <SVG className="iconEdit" src={create} alt="button edit"></SVG>
                    </div>
                </div>

                <div className="deliveryText">
                    <div className="deliveryAddress">{i18n.t('global.pickup')}: { moment(pickup.startDate).isSame(Date(), 'day') ? "Aujourd'hui" : pickup.startDate}, { pickup.deliveryType === 'asap' ? i18n.t('pickup.asap') : pickup.startTimeMin + ' - ' + pickup.startTimeMax } </div>
                    <div className="deliveryTime">{i18n.t('pickup.delivery')}: { moment(pickup.endDate).isSame(Date(), 'day') ? "Aujourd'hui" : pickup.endDate}, { pickup.deliveryType === 'asap' ? i18n.t('pickup.asap') : pickup.endTimeMin + ' - ' + pickup.endTimeMax } </div>
                </div>
            </div>

            <div className="deliveryResume">
                <div className="deliveryTitle">
                    <div className="titleResume">
                        <SVG src={basket} alt="icon location" className="iconResume"/>
                        {i18n.t('pickup.packageSize')}
                    </div>
                    <div className="editResume" onClick={e => changeStep(3)}>
                        <SVG className="iconEdit" src={create} alt="button edit"></SVG>
                    </div>
                </div>

                { pickup.packFormat === 'package' ? 
                    <div className="deliveryText">
                        <div className="deliveryAddress">{i18n.t('pickup.format')} : {i18n.t('pickup.package')}</div>
                        <div className="deliveryAddress">{i18n.t('pickup.weight')}: {pickup.packWeight}kg</div>
                        <div className="deliveryTime">{i18n.t('pickup.dimensions')}: { pickup.packLength } x { pickup.packWidth } x { pickup.packHeight } cm</div>
                    </div>
                    :
                    <div className="deliveryText">
                        <div className="deliveryAddress">{i18n.t('pickup.format')} : {i18n.t('pickup.letter')}</div>
                    </div>
                }
            </div>

            {/* <FormGroup>
                <Label>
                    <h5 className="contact">
                        <img src="/images/menu/icon-location.svg" alt="icon location" /> {i18n.t('payment.info')}
                    </h5>
                </Label>

            </FormGroup> */}
            <h3 style={{ marginTop: '10px' }}>{i18n.t('payment.info')}</h3>

            <FormGroup>
                <div className="categoryTitle">
                    <SVG className="iconCategory" src={pricetag} alt="icon coursier" /> {i18n.t('payment.promoCode')}
                </div>

                <Row className="pickupRow">
                    <Col xs="8">
                        <Input type="text" name="promoCode" placeholder={i18n.t('payment.addPromoCode')} disabled={pickup.voucherCode} onChange={e => handleUpdatePickupInput(e)}></Input>
                    </Col>

                    <Col xs="4">
                        <Button className="codeButton" onClick={e => handleCheckVoucher(e)} disabled={pickup.voucherCode}>{i18n.t('payment.applyPromoCode')}</Button>
                    </Col>
                </Row>
            </FormGroup>

            <FormGroup className="pickupOrder">
                <div className="categoryTitle">
                    {i18n.t('pickup.pickupFee')}
                </div>
                <div className="commandInfo">
                    <div className="priceTitle">{i18n.t('payment.deliveryFee')}</div>
                    <div className="price" style={pickup.voucherCode ? { textDecoration: 'line-through' } : undefined}>{pickup.price.basePrice.toFixed(2)}$</div>
                </div>
                { pickup.price.extraAsap ? 
                <div className="commandInfo">
                    <div className="priceTitle">{i18n.t('payment.extraAsap')}</div>
                    <div className="price" style={pickup.voucherCode ? { textDecoration: 'line-through' } : undefined}>{pickup.price.extraAsap.toFixed(2)}$</div>
                </div> : undefined }

                { pickup.price.extraVolume || pickup.price.extraWeigth ? 
                <div className="commandInfo">
                    <div className="priceTitle">{i18n.t('payment.extraFormat')}</div>
                    <div className="price" style={pickup.voucherCode ? { textDecoration: 'line-through' } : undefined}>{( pickup.price.extraVolume + pickup.price.extraWeigth ).toFixed(2)}$</div>
                </div> : undefined }

                {pickup.voucherCode ?
                    <>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.reductionCode')}</div>
                            <div className="price">{pickup.voucher.name} ({pickup.voucher.percentage}%)</div>
                        </div>

                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                            <div className="price">{pickup.price.discountedPrice.toFixed(2)}$</div>
                        </div>
                    </>
                    : undefined
                }
                <div className="commandInfo">
                    <div className="priceTitle">{i18n.t('payment.fees')}</div>
                    <div className="price">{pickup.price.taxes.toFixed(2)}$</div>
                </div>

                <hr />

                <div className="commandTotal">
                    <div className="totalTitle">{i18n.t('payment.total')}</div>
                    <div className="total">{pickup.price.totalWithTaxes.toFixed(2)}$</div>
                </div>
            </FormGroup>

            <FormGroup>
                <div className="categoryTitle">
                    <SVG className="iconCategory" src={card} alt="icon card" /> {i18n.t('payment.info')}<span style={{ color: 'red'}}>*</span>
                </div>

                {cards && cards.map((card, index) => (
                    <Label className="form-check" key={index}>
                        <Input type="radio" checked={pickup.paymentCard === card.id} name="paymentCard" onChange={e => handleUpdatePickupInput({ target: { name: 'paymentCard', value: card.id } })} />{card.brand} - •••• •••• •••• {card.last4}
                    </Label>
                ))}

                <Label className="form-check">
                    <Input type="radio" name="paymentCard" checked={pickup.otherPaymentCardName} value="other" onChange={e => handleUpdatePickupInput({ target: { name: 'displayOtherCard', value: true } })} />{i18n.t('global.other')}
                </Label>

                {pickup.displayOtherCard ?
                    <div className="clientInfo">
                        <Label>
                            {i18n.t('payment.creditCard')}
                        </Label>

                        <Row>
                            <Col>
                                <Cards
                                    cvc={pickup.otherPaymentCardCVC ? pickup.otherPaymentCardCVC : ''}
                                    expiry={pickup.otherPaymentCardExpiry ? pickup.otherPaymentCardExpiry : ''}
                                    name={pickup.otherPaymentCardName ? pickup.otherPaymentCardName : ''}
                                    number={pickup.otherPaymentCardNumber ? pickup.otherPaymentCardNumber : ''}
                                    focused={focusedField}
                                    locale={{ valid: "" }}
                                    placeholders={{ name: "Martin Jean" }}
                                />
                            </Col>
                            <Col>
                                <Row className="pickupRow">
                                    <Col>
                                        <Input type="text" placeholder={i18n.t('payment.name')} onFocus={e => { setFocusedField('name') }} value={pickup.otherPaymentCardName} name='otherPaymentCardName' onChange={e => handleUpdatePickupInput(e)}></Input>
                                    </Col>
                                </Row>

                                <Row className="pickupRow">
                                    <Col>
                                        <Input invalid={cardErrors.number ? true : false} type="text" placeholder={i18n.t('payment.number')} onFocus={e => { setFocusedField('number') }} value={pickup.otherPaymentCardNumber} name='otherPaymentCardNumber' onChange={e => handleUpdatePickupInput(e)} maxLength="19"></Input>
                                    </Col>
                                </Row>

                                <Row className="reservationRow">
                                    <Col>
                                        <Input invalid={cardErrors.expiration ? true : false} type="text" placeholder={i18n.t('payment.mmyy')} onFocus={e => { setFocusedField('expiry') }} value={pickup.otherPaymentCardExpiry} name='otherPaymentCardExpiry' onChange={e => handleUpdatePickupInput(e)}></Input>
                                    </Col>

                                    <Col>
                                        <Input invalid={cardErrors.cvc ? true : false} type="text" placeholder={i18n.t('payment.cvc')} onFocus={e => { setFocusedField('cvc') }} value={pickup.otherPaymentCardCVC} name='otherPaymentCardCVC' onChange={e => handleUpdatePickupInput(e)}></Input>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    :
                    undefined
                }
            </FormGroup>

            < hr />

            <FormGroup tag="fieldset">
                <div className="categoryTitle">
                    <SVG className="iconCategory" src={home} alt="icon card" /> {i18n.t('address.facturationAddress')}<span style={{ color: 'red'}}>*</span>
                </div>
                <FormGroup check>
                    <Label check>
                        <Input type="radio" name="facturationAddress" checked={pickup.facturationAddress} onChange={e => handleUpdatePickupInput({ target: { name: 'facturationAddress', value: pickup.startAddress && pickup.startAddress !== 'other' ? pickup.startAddress : pickup.otherStartAddress } })} /> {i18n.t('address.useSameFacturationAddress')}
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="radio" name="facturationAddress" checked={pickup.displayOtherFacturationAddress} onChange={e => handleUpdatePickupInput({ target: { name: 'displayOtherFacturationAddress', value: true } })} /> {i18n.t('address.useOtherFacturationAddress')}
                    </Label>
                </FormGroup>

                <Row>
                    {pickup && pickup.displayOtherFacturationAddress ?
                        <>
                            <Col xs="8"><LocationSearchInput value={pickup.otherFacturationAddress && pickup.otherFacturationAddress.formatted_address} handleCheckedAddress={isChecked => handleCheckedAddress(isChecked)} displayLabel={false} onSelect={e => handleUpdatePickupInput({ target: { name: "otherFacturationAddress", value: e } })} /><span className="red_ast">*</span></Col>
                            <Col xs="4"><Input type="text" placeholder={i18n.t('address.app')} value={pickup.otherFacturationApartment ? pickup.otherFacturationApartment : ''} onChange={e => handleUpdatePickupInput(e)} name="otherFacturationApartment"></Input></Col>
                        </>
                        : undefined
                    }
                </Row>

            </FormGroup>

            <FormGroup className="pickupButton">
                <Button
                    className="prevButton"
                    onClick={prevStep}>
                    {i18n.t('pickup.returnToPackage')}
                </Button>

                <Button
                    disabled={(pickup.displayOtherFacturationAddress ? !pickup.otherFacturationAddress || !pickup.isCheckedAddress : !pickup.facturationAddress) || (pickup.displayOtherCard ? (!pickup.otherPaymentCardCVC || !pickup.otherPaymentCardCVC.match(/([0-9]{3})/)) || (!pickup.otherPaymentCardExpiry || !pickup.otherPaymentCardExpiry.match(/(0[1-9]|10|11|12)\/[0-9]{2}/)) || (!pickup.otherPaymentCardNumber || !pickup.otherPaymentCardNumber.replaceAll(' ', '').match(/([0-9]{16})/)) || (!pickup.otherPaymentCardName || !pickup.otherPaymentCardName.match(/([a-z]{2,}) ([a-z]{2,})/i)) : !pickup.paymentCard)}
                    className="nextButton"
                    onClick={e => handleSendPickup(e)}>
                    {i18n.t('payment.ordering')}
                </Button>
            </FormGroup>

        </>
    )

};

export default PickupStep5Component;