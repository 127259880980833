import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import config from "../../config/config";

import i18n from "../../translations/i18n";

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
  
import { GoogleApiWrapper } from 'google-maps-react';

class LocationSearchInput extends Component {

    constructor(props){
        super(props);
        this.state = {
            address: undefined
        }
    }

    handleChange = address => {
        this.setState({ address: address });
        if(this.props.handleCheckedAddress) this.props.handleCheckedAddress(false);
    }

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => {
                if(!this.props.resetInput) this.setState({ address: results[0].formatted_address });
                getLatLng(results[0]).then(latLng => {
                    if(this.props.handleCheckedAddress) this.props.handleCheckedAddress(true);
                    this.props.onSelect(results[0], latLng);
                })
            })
            .catch(error => console.error('Error', error));
    }

    clearValue = () => {
        this.setState({ address: ''});
    }

    render() {
        return (
            <PlacesAutocomplete
                searchOptions={{componentRestrictions: { country: ['ca'] }}}
                value={this.state.address}
                onChange={ e => this.handleChange(e) }
                onSelect={ e => this.handleSelect(e) }
            >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <FormGroup >
                    <Row>
                        <Col>
                            { this.props.displayLabel ? <Label className="accountLabel" htmlFor="lastName">{i18n.t('global.address')}</Label> : undefined }
                            <Input
                                {...getInputProps({
                                placeholder: i18n.t('global.typeAddress'),
                                className: 'location-search-input',
                            })}
                            autoComplete='none'
                            value={this.state.address || this.state.address === '' ? this.state.address : this.props.value}
                            />
                        <div className="autocomplete-dropdown-container" style={{position: "absolute", backgroundColor: "white", zIndex:"10", width:"100%"}}>
                        {loading && <div>{i18n.t('global.loading')}...</div>}
                        {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                            <div
                                {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                                })}
                                key={index}
                            >
                                <span>{suggestion.description}</span>
                            </div>
                            );
                        })}
                        </div>
                        </Col>
                    </Row>
                </FormGroup>
                )}
            </PlacesAutocomplete>

        )
    }
}

export default GoogleApiWrapper({
    apiKey: config.googlePlaceAPIkey
})( (props) => <LocationSearchInput {...props} ref={props.myRef}/>);