import React, { Component } from 'react';
import { connect } from 'react-redux';

import { cleanMyLastOrder } from '../../actions/orderAction';

class ConfirmOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: undefined
        }

        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+ props.title;
    }

    UNSAFE_componentWillMount() {
        this.setState({ order: this.props.lastOrder}, () => {
            this.props.cleanMyLastOrder();
        });
    } 


    render() {
        const { Layout, isLoading, lastOrder, navigation } = this.props;

        return (
            <Layout
                order={this.state.order}
                isLoading={isLoading}
                navigation={navigation}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.user.isFetching,
    lastOrder: state.orders.lastOrder
});

const mapDispatchToProps = {
    cleanMyLastOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOrder);
