import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form } from 'reactstrap';

import i18n from "../../../translations/i18n";

import Step1 from './PickupStep1'
import Step2 from './PickupStep2'
import Step3 from './PickupStep3'
import Step4 from './PickupStep4'
import Step5 from './PickupStep5'
import Step6 from './PickupStep6'

import ProgressBar from '../UI/ProgressBar';

import SignUpModal from '../../../containers/Page/SignUpModal';
import SignUpModalComponent from '../SignUpModal';

import Login from '../../../containers/Page/Login';
import LoginComponent from '../Login';

import Loading from '../UI/Loading'

import { useToasts } from 'react-toast-notifications'

import { Redirect } from 'react-router-dom';

import { setChatOpen, setToastMessage } from '../../../actions/chatAction';

const PickupPageComponent = ({ cards, user, pickup, currentStep, nextStep, prevStep, handleUpdatePickupInput, handleCheckVoucher, handleSendPickup, handleChangeMonth, monthClosedDays, isOpenLogin, isOpenSignUp, toggleSignUp, toggleLogin, changeStep, isLoading, redirect, toastMessage, handleCheckedAddress, openingHours, requiredFields, cardErrors, ...props }) => {

    const { addToast } = useToasts();

    const openChat = () => {
        if (window.navigator.onLine) {
            props.setChatOpen(true);
        } else {
            props.setToastMessage(i18n.t('error_messages.internet_connection'));
        }
    };

    return( 
        <>
        { isLoading ? <Loading /> : undefined }
        { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }
        { redirect ? <Redirect to='/confirmation' /> : undefined }

        { !user || !user.token ? 
            <div id="historicPage" className="paddingContainer">
                <h1 style={{ textAlign: "center"}}>{i18n.t('global.notLoggedTitle')}</h1>
                <div class="not_logged" style={{ textAlign: "center"}}>
                    {i18n.t('global.notLoggedSubtitleShort')}<div class="login_link" onClick={toggleLogin}>{i18n.t('global.notLoggedSubtitleEnd')}</div>
                </div>
            </div>
            
            :
            <div className="paddingContainer">
                <section id="pickupSection">
                    <div id="breadcrumb">
                        <p><Link to="/">{i18n.t('footer.home')}</Link> &gt; {i18n.t('global.pickup')}</p>
                    </div>
                    <h2>{i18n.t('pickup.pickupPackage')}</h2>
        
                    <Form className="pickupForm">
                        <ProgressBar type="pickup" currentStep={currentStep} changeStep={changeStep} />
                        
                        { currentStep === 1 ?
                        <Step1
                        user={user}
                        pickup={pickup} 
                        handleUpdatePickupInput={handleUpdatePickupInput}
                        handleCheckedAddress={handleCheckedAddress}
                        handleChangeMonth={handleChangeMonth}
                        currentStep={currentStep}
                        nextStep={nextStep}
                        requiredFields={requiredFields}
                        openingHours={openingHours}
                        monthClosedDays={monthClosedDays}
                        /> : undefined }
                        
                        { currentStep === 2 ?
                        <Step2
                        user={user}
                        pickup={pickup} 
                        handleUpdatePickupInput={handleUpdatePickupInput}
                        handleCheckedAddress={handleCheckedAddress}
                        handleChangeMonth={handleChangeMonth}
                        currentStep={currentStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        requiredFields={requiredFields}
                        openingHours={openingHours}
                        monthClosedDays={monthClosedDays}
                        /> : undefined }
                        
                        {/* { currentStep === 3 ?
                        <Step3
                        pickup={pickup} 
                        handleUpdatePickupInput={handleUpdatePickupInput}
                        currentStep={currentStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        requiredFields={requiredFields}
                        /> : undefined } */}
                        
                        { currentStep === 3 ?
                        <Step4
                        pickup={pickup} 
                        handleUpdatePickupInput={handleUpdatePickupInput}
                        currentStep={currentStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        requiredFields={requiredFields}
                        /> : undefined }
                        
                        { currentStep === 4 ?
                        <Step5
                        cards={cards}
                        pickup={pickup}
                        card={user.card}
                        handleUpdatePickupInput={handleUpdatePickupInput}
                        handleCheckedAddress={handleCheckedAddress}
                        handleCheckVoucher={handleCheckVoucher}
                        changeStep={changeStep}
                        prevStep={prevStep}
                        requiredFields={requiredFields}
                        cardErrors={cardErrors}
                        handleSendPickup={handleSendPickup}
                        /> : undefined }  
                        
                        {/* { currentStep === 5 ?
                        <Step6
                        pickup={pickup}
                        handleUpdatePickupInput={handleUpdatePickupInput}
                        currentStep={currentStep}
                        prevStep={prevStep}
                        changeStep={changeStep}
                        cards={cards}
                        handleSendPickup={handleSendPickup}
                        /> : undefined }    */}

                    </Form>

                        <p style={{ textAlign: 'center' }}>{i18n.t("pickup.complex")} <span style={{ cursor: 'pointer', textDecoration: 'underline'}} onClick={openChat}>Chat</span></p>
                </section>
            </div>
        }
        <Login isOpen={isOpenLogin} toggle={toggleLogin} toggleSignUp={toggleSignUp} Layout={LoginComponent} />
        <SignUpModal isOpen={isOpenSignUp} toggle={toggleSignUp} toggleLogin={toggleLogin} Layout={SignUpModalComponent} />    
    </>
    )
    
};

const mapStateToProps = state => ({
    isOpen: state.chat.isOpen,
});

const mapDispatchToProps = {
    setChatOpen,
    setToastMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupPageComponent);