import axios from 'axios';
import AppConfig from '../config/config';

export const REQUEST_PRODUCT_DATA = 'REQUEST_PRODUCT_DATA';
export const RECEIVE_PRODUCT_DATA = 'RECEIVE_PRODUCT_DATA';
export const RECEIVE_SEARCHED_PRODUCTS_DATA = 'RECEIVE_SEARCHED_PRODUCTS_DATA';
export const SET_FETCHING = 'SET_FETCHING';

export const requestProductData = () => ({
    type: REQUEST_PRODUCT_DATA,
});

export const setFetching = (data) => ({
    type: SET_FETCHING,
    payload: data,
});

export const receiveProductData = (data) => ({
    type: RECEIVE_PRODUCT_DATA,
    payload: data,
});

export const receiveSearchedProductsData = (data) => ({
    type: RECEIVE_SEARCHED_PRODUCTS_DATA,
    payload: data,
});

export const getProductsByStore = (store) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        const lang = getState().user.lang;

        _dispatch(requestProductData());

        axios.get(AppConfig.baseUrlApi + '/product?where={"store":"'+store+'", "isVisible":"true"}')
            .then(response => {
                return resolve(_dispatch(receiveProductData(response.data.data.map(p => sanitizeProductLang(p, lang)))));
            })
            .catch(error => {
                _dispatch(receiveProductData([]));
                reject(error);
            });
    })
);

export const getOneProduct = (id, isSubproduct = false) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        const lang = getState().user.lang;


        axios.get(AppConfig.baseUrlApi + '/' + (isSubproduct ? 'subproduct' : 'product') + '/' + id)
            .then(response => {
                return resolve(sanitizeProductLang(response.data, lang));
            })
            .catch(error => {
                _dispatch(receiveProductData([]));
                reject(error);
            });
    })
);

export const getProductsByKeyword = (keyword) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        const lang = getState().user.lang;

        _dispatch(requestProductData());
        axios.get(AppConfig.baseUrlApi + '/product/search?term='+keyword)
            .then(response => {
                return resolve(_dispatch(receiveSearchedProductsData(response.data.map(p => sanitizeProductLang(p, lang)))));
            })
            .catch(error => {
                _dispatch(receiveSearchedProductsData([]));
                reject(error);
            });
    })
);

export const getAvailableHoursService = (serviceId, date) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {  

        _dispatch(setFetching(true));

        axios.post(AppConfig.baseUrlApi + '/product/availableHours', 
        { serviceId: serviceId, date: date})
            .then(response => {
                resolve(response.data)
                _dispatch(setFetching(false));

            })
            .catch(error => {
                console.log('***** productAction - getAvailableHoursService - axios', error);
                reject(error);
                _dispatch(setFetching(false));

            });
    })
);

export const sanitizeProductLang = (product, lang) => {
    if(lang === 'fr'){
        product.description = product.description_FR;
        product.name = product.name_FR;
        product.keywords = product.keywords_FR;

        if(product.subProducts){
            product.subProducts = product.subProducts.map( sp => ({ ...sp, name: sp.name_FR }))
        }

        if(product.category){
            product.category.name = product.category.name_FR
        }
    }
    else{
        product.description = product.description_EN;
        product.name = product.name_EN;
        product.keywords = product.keywords_EN;

        if(product.subProducts){
            product.subProducts = product.subProducts.map( sp => ({ ...sp, name: sp.name_EN }))
        }

        if(product.category){
            product.category.name = product.category.name_EN
        }
    }

    return product;
}