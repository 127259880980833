import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { checkmarkCircleOutline } from 'ionicons/icons'

import moment from "moment";
import 'moment/locale/fr';

import i18n from "../../translations/i18n";
import Loading from "./UI/Loading";

const ConfirmOrder = ({ order, isLoading }) => {

    moment.locale(i18n.currentLocale());
    let isServiceOrder = order?.items?.some(anItem => anItem.product.isService);
    console.log('***** order', order);

    if(isLoading) return <Loading />
    if(!order) return(
        <div className="paddingContainer forgotPage" id="signUpModal">
            <h2 style={{ textAlign: 'center' }}> {i18n.t('confirmOrder.noOrder')} </h2>
        </div>
    )

    return(
    <>
    <div className="paddingContainer forgotPage" id="signUpModal">
        <SVG src={checkmarkCircleOutline} className="invert" style={{ width: 35, verticalAlign: 'sub'}} />
        <h2 style={{ display: 'inline' }}> {i18n.t('confirmOrder.orderConfirmed')}</h2>
        <hr />

        <h3>{i18n.t('confirmOrder.transactionDetail')}</h3>
        <p style={{ textAlign: 'left', margin: 0}}>{i18n.t('confirmOrder.orderNumber')} : <strong>{ order.orderNumber || order.pickupNumber }</strong></p>
        { order.store ? <p style={{ textAlign: 'left', margin: 0}}>{i18n.t('historic.store')} : <strong>{ order.store.name }</strong></p> : null }
        <p style={{ textAlign: 'left', margin: 0}}>{i18n.t('pickup.date')} : <strong>{ moment(order.createdAt).format('DD/MM/YYYY') }</strong></p>

        { order.deliveryAddress ? <p style={{ textAlign: 'left', margin: 0}}>{i18n.t('global.address')} : <strong>{ order.deliveryAddress.full }</strong></p>
        : order.toAddress ?<p style={{ textAlign: 'left', margin: 0}}>{i18n.t('address.deliveryAddress')} : <strong>{ order.toAddress.full }</strong></p> : null}

        { order.destinationAddress ? <p style={{ textAlign: 'left', margin: 0}}>{i18n.t('global.destinationAddress')} : <strong>{ order.destinationAddress.full }</strong></p> : null}

        <p style={{ textAlign: 'left', margin: 0}}>{i18n.t('payment.totalOrder')} : { order?.items && order.items[0]?.product?.isProductWithHourlyRate ? <strong>{i18n.t("payment.realAmount")}</strong> : <strong>{ order.totalPrice.toFixed(2) }$</strong>}</p>


        {/* PICKUP FIELDS */}
        { order.pickupNumber ? 
        <>
            <hr />
            <p style={{ textAlign: 'left', marginTop: 30, marginBottom: 0}}><strong>{i18n.t('confirmOrder.pickupInformation')}</strong></p>
            <p style={{ textAlign: 'left', margin: 0}}>{order.senderName}</p>
            <p style={{ textAlign: 'left', margin: 0}}>{order.senderPhone}</p>
            <p style={{ textAlign: 'left', margin: 0}}>{order.fromAddress?.full}</p>
            { order.fromAddress.apartment ? <p style={{ textAlign: 'left', margin: 0}}>{i18n.t('address.app')} {order.fromAddress.apartment}</p> : null}
            <p style={{ textAlign: 'left', margin: 0}}>{moment(order.departureDateStart).format('DD/MM/YYYY hh:mm a')} - {moment(order.departureDateEnd).format('DD/MM/YYYY hh:mm a')}</p>
            <hr />
            <p style={{ textAlign: 'left', marginTop: 10, marginBottom: 0}}><strong>{i18n.t('confirmOrder.deliveryInformation')}</strong></p>
            <p style={{ textAlign: 'left', margin: 0}}>{order.receiverName}</p>
            <p style={{ textAlign: 'left', margin: 0}}>{order.receiverPhone}</p>
            <p style={{ textAlign: 'left', margin: 0}}>{order.toAddress?.full}</p>
            { order.toAddress.apartment ? <p style={{ textAlign: 'left', margin: 0}}>{i18n.t('address.app')} {order.toAddress.apartment}</p> : null}
            <p style={{ textAlign: 'left', margin: 0}}>{moment(order.deliveryDateStart).format('DD/MM/YYYY hh:mm a')} - {moment(order.deliveryDateEnd).format('DD/MM/YYYY hh:mm a')}</p>

        </>
            : undefined}

        {/* ORDER FIELDS */}
        { order.orderNumber && order.orderNumber.startsWith('O-') ?
        <div id="yourOrder" style={{ marginTop: 100}}>
            <h3>{i18n.t('confirmOrder.orderDetail')}</h3>
            { order.items?.map( (p, index) => (
                <Row key={index}>
                        <Col xs="6" className="itemTitle">{p.product['name_' + i18n.locale.toUpperCase()]} 
                        { p.subProducts?.map((sp, i) => {
                                if(sp['name_' + i18n.locale.toUpperCase()] !== 'standard') return(<div key={'sp-'+i} className="legendItem">{sp['name_' + i18n.locale.toUpperCase()]}</div>)
                        }) } </Col>
                    <Col xs="6" className="itemPrice">{ p.totalPrice.toFixed(2) }$ {p.product.isProductWithHourlyRate ? '/ h' : undefined }</Col>
                </Row>
                ))
            }

            { isServiceOrder ? 
                <Row>
                    <Col>
                        <p style={{ textAlign: 'left', margin: 0}}>
                            {`${i18n.t('confirmOrder.serviceDateTime')} ${order.items[0][`name_${i18n.locale == 'fr' ? 'FR' : 'EN'}`]}`}
                            {i18n.locale == 'fr' ? ' sera le ' : ' will be on '}
                            {moment(order.deliveryDate).format(`${i18n.locale == 'fr' ? 'dddd, Do MMMM YYYY [entre]' : 'dddd, MMMM Do YYYY [between]'} hh:mm a`)}
                            {i18n.locale == 'fr' ? ' et ' : ' and '}{moment(order.deliveryDate).add(order?.store?.deliveryRange || 60, 'minutes').format('hh:mm a')}{`.`}
                        </p>
                    </Col>
                </Row> 
            : 
                <Row>
                    <Col>
                        <p style={{ textAlign: 'left', margin: 0}}>
                            {i18n.t('confirmOrder.orderDateTime')} {moment(order.deliveryDate).format(`dddd, MMMM Do YYYY ${i18n.locale == 'fr' ? '[entre]' : '[between]'} hh:mm a`)}
                            {` `}{i18n.locale == 'fr' ? 'et' : 'and'}{` `}{moment(order.deliveryDate).add(order.store.deliveryEstimated, 'minutes').format('hh:mm a')}
                        </p>
                    </Col>
                </Row> 
            }

            <hr />

            { order.items[0].product.isProductWithHourlyRate ? 
                <>
                    <Row>
                        <Col xs="9" className="itemTitle">{i18n.t('payment.subtotal')} </Col>
                        <Col xs="3" className="itemPrice" style={ order.voucherCode?.isOnProduct ? {textDecoration: 'line-through'} : undefined } >{ order.totalProductsPrice?.toFixed(2)}$ / h</Col>
                    </Row>
                    
                    { order.voucherCode?.isOnProduct ?
                    <>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                            <div className="price">{order.voucherCode?.name} ({order.voucherCode?.percentage}%)</div>
                        </div>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                            <div className="price">{(order.totalProductsPrice - parseFloat((order.totalProductsPrice * (order.voucherCode?.percentage / 100))).toFixed(2)).toFixed(2)}$ / h</div>
                        </div>
                    </> : undefined }
                    
                    { order.deliveryBasePrice != null && order.deliveryBasePrice > 0 &&
                        <Row>
                            <Col xs="6" className="itemTitle">{i18n.t('confirmOrder.deliveryFee')}</Col>
                            <Col xs="6" className="itemPrice" style={ order.voucherCode?.isOnDeliveryOnly ? {textDecoration: 'line-through'} : undefined }>{ order.deliveryBasePrice?.toFixed(2) }$</Col>
                        </Row>
                    }

                    { order.deliveryExtraPrice != null && order.deliveryExtraPrice > 0 &&
                        <Row>
                            <Col xs="6" className="itemTitle">{i18n.t('confirmOrder.extraFee')}</Col>
                            <Col xs="6" className="itemPrice" style={ order.voucherCode?.isOnDeliveryOnly ? {textDecoration: 'line-through'} : undefined }>{ order.deliveryExtraPrice?.toFixed(2) }$</Col>
                        </Row>
                    }

                    { order.deliveryPrice != null && order.deliveryPrice > 0 && order.voucherCode?.isOnDeliveryOnly ?
                        <>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                                <div className="price">{order.voucherCode?.name} ({order.voucherCode?.percentage}%)</div>
                            </div>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                                <div className="price">{(order.deliveryPrice - parseFloat((order.deliveryPrice * (order.voucherCode?.percentage / 100))).toFixed(2)).toFixed(2)}$</div>
                            </div>
                        </> : undefined }

                    { order.serviceFee > 0 && isServiceOrder ?
                        <Row>
                            <Col xs="9" className="itemTitle">{i18n.t('payment.serviceFee')}</Col>
                            <Col xs="3" className="itemPrice" style={  order.voucherCode?.isOnGenieFee ? {textDecoration: 'line-through'} : undefined }>{order.serviceFee.toFixed(2)}$</Col>
                        </Row> : undefined }
                        
                    { order.serviceFee > 0  && order.voucherCode?.isOnGenieFee && isServiceOrder ?
                    <>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                            <div className="price">{order.voucherCode?.name} ({order.voucherCode?.percentage}%)</div>
                        </div>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                            <div className="price">{(order.serviceFee - parseFloat((order.serviceFee * (order.voucherCode?.percentage / 100))).toFixed(2)).toFixed(2)}$</div>
                        </div>
                    </>: undefined }
                    
                    <hr />

                    <Link to={"/track/"+order.id} ><Button className="primary" style={{ width: 250}}>{i18n.t('trackMyOrder.title')}</Button></Link>

                    {/* <Row>
                        <Col xs="6" className="itemTitle">{i18n.t('payment.preauthorization')}</Col>
                        <Col xs="6" style={{ textAlign: 'right' }} className="itemTitle">{ order.totalPreAuthorization.toFixed(2) }$</Col>
                    </Row>   */}
                </>              
                :
                <>
                    <Row>
                        <Col xs="9" className="itemTitle">{i18n.t('payment.subtotal')} </Col>
                        <Col xs="3" className="itemPrice" style={ order.voucherCode?.isOnProduct ? {textDecoration: 'line-through'} : undefined } >{ order.totalProductsPrice?.toFixed(2)}$</Col>
                    </Row>
                    { order.voucherCode?.isOnProduct ?
                    <>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                            <div className="price">{order.voucherCode?.name} ({order.voucherCode?.percentage}%)</div>
                        </div>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                            <div className="price">{(order.totalProductsPrice - parseFloat((order.totalProductsPrice * (order.voucherCode?.percentage / 100))).toFixed(2)).toFixed(2)}$</div>
                        </div>
                    </> : undefined }

                    { order.deliveryPrice != null && order.deliveryPrice > 0 &&
                        <Row>
                            <Col xs="6" className="itemTitle">{i18n.t('confirmOrder.deliveryFee')}</Col>
                            <Col xs="6" className="itemPrice" style={ order.voucherCode?.isOnDeliveryOnly ? {textDecoration: 'line-through'} : undefined }>{ order.deliveryBasePrice.toFixed(2) }$</Col>
                        </Row>
                    }

                    { order.deliveryExtraPrice != null && order.deliveryExtraPrice > 0 &&
                        <Row>
                            <Col xs="6" className="itemTitle">+ {i18n.t('confirmOrder.extraFee')}</Col>
                            <Col xs="6" className="itemPrice" style={ order.voucherCode?.isOnDeliveryOnly ? {textDecoration: 'line-through'} : undefined }>{ order.deliveryExtraPrice.toFixed(2) }$</Col>
                        </Row>
                    }

                    { order.deliveryPrice != null && order.deliveryPrice > 0 && order.voucherCode?.isOnDeliveryOnly ?
                        <>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                                <div className="price">{order.voucherCode?.name} ({order.voucherCode?.percentage}%)</div>
                            </div>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                                <div className="price">{(order.deliveryPrice - parseFloat((order.deliveryPrice * (order.voucherCode?.percentage / 100))).toFixed(2)).toFixed(2)}$</div>
                            </div>
                        </> : undefined }


                    { order.serviceFee > 0 && isServiceOrder ?
                        <Row>
                            <Col xs="9" className="itemTitle">{i18n.t('payment.serviceFee')}</Col>
                            <Col xs="3" className="itemPrice" style={  order.voucherCode?.isOnGenieFee ? {textDecoration: 'line-through'} : undefined }>{order.serviceFee.toFixed(2)}$</Col>
                        </Row> : undefined }
                        
                    { order.serviceFee > 0  && order.voucherCode?.isOnGenieFee && isServiceOrder ?
                    <>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                            <div className="price">{order.voucherCode?.name} ({order.voucherCode?.percentage}%)</div>
                        </div>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                            <div className="price">{(order.serviceFee - parseFloat((order.serviceFee * (order.voucherCode?.percentage / 100))).toFixed(2)).toFixed(2)}$</div>
                        </div>
                    </>: undefined }

                    
                    <Row>
                        <Col xs="6" className="itemTitle">{i18n.t('payment.fees')}</Col>
                        <Col xs="6" style={{ textAlign: 'right' }} className="itemTitle">{ order.taxes.toFixed(2) }$</Col>
                    </Row>

                    <Row>
                        <Col xs="6" className="itemTitle">{i18n.t('payment.tips')}</Col>
                        <Col xs="6" style={{ textAlign: 'right' }} className="itemTitle">{ order.tips.toFixed(2) }$</Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col xs="6" className="itemTitle">{i18n.t('payment.total')}</Col>
                        <Col xs="6" style={{ textAlign: 'right' }} className="itemTitle">{ order.totalPrice.toFixed(2) }$</Col>
                    </Row>

                    <Link to={"/track/"+order.id} ><Button className="primary" style={{ width: 250}}>{i18n.t('trackMyOrder.title')}</Button></Link>
                </>
            } 
        </div>
        : undefined }

        {/* CUSTOM ORDER FIELDS */}
        { order.orderNumber && order.orderNumber.startsWith('CO-') ? 
                <>
                <hr />
                <p style={{ textAlign: 'left', margin: 0}}><strong>{i18n.t('payment.orderInfo')}</strong></p>
                { order.details.split('\n').map(d => (
                    <p style={{ textAlign: 'left', margin: 0, marginLeft: 10}}>{d}</p>
                ))}
                

                { order.isADelivery ?
                <>
                <hr />
                <p style={{ textAlign: 'left', marginTop: 10, marginBottom: 0}}><strong>{i18n.t('confirmOrder.deliveryInformation')}</strong></p>
                <p style={{ textAlign: 'left', margin: 0}}>{order.deliveryName}</p>
                <p style={{ textAlign: 'left', margin: 0}}>{order.deliveryPhone}</p>
                <p style={{ textAlign: 'left', margin: 0}}>{order.deliveryAddress?.full}</p>
                <p style={{ textAlign: 'left', margin: 0}}>{moment(order.deliveryDate).format('DD/MM/YYYY - hh:mm')}</p>
                </>
                : undefined }
            </>
        : undefined }

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Link to="/" ><Button className="primary" style={{ marginRight: '5px' }}>{i18n.t('confirmOrder.backToHome')}</Button></Link>
            { order.pickupNumber ? <Link to={"/track-pickup/"+order.id} ><Button className="primary" style={{ marginLeft: '5px' }}>{i18n.t('trackMyOrder.pickupTitle')}</Button></Link> : null}
        </div>
    </div>
    </>
)}

export default withRouter(ConfirmOrder);