import i18n from 'i18n-js';
// Set the key-value pairs for the different languages you want to support.
i18n.translations = {
    en: require('./en.json'),
    fr: require('./fr.json')
};


i18n.locale = 'fr';

export default i18n;
