import {
    REQUEST_CART_DATA,
    RECEIVE_CART_DATA,
    ADD_CART_DATA,
    ADD_TIPS_DATA
} from '../actions/cartAction';

import { RECEIVE_LOAD_ORDER_DATA } from "../actions/orderAction";

import Cart from '../store/cartStore';

export const initialState = Cart;

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_CART_DATA:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_CART_DATA:
            return {
                ...state,
                isFetching: false,
                cart: action.payload,
            };
        case ADD_TIPS_DATA:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    tips: action.payload
                }
            };
        case ADD_CART_DATA:            
            return {
                ...state,
                isFetching: false,
                cart: action.payload,
            };
        case RECEIVE_LOAD_ORDER_DATA:
            return {
                ...state,
                isFetching: false,
                cart: {
                    products: action.payload.items,
                    storeId: action.payload.store.id,
                    totalPrice: action.payload.totalProductsPrice
                }
            }
        default:
            return state;
    }
}
