import React from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import i18n from "../../../translations/i18n";

import LocationSearchInput from '../../../containers/Utils/LocationSearchInput';
import PhoneInput from "../UI/PhoneInput";

import enCA from 'date-fns/locale/en-CA';
import frCA from 'date-fns/locale/fr-CA';
registerLocale('fr-CA', frCA);
registerLocale('en-CA', enCA);

const PickupStep2Component = ({ user, pickup, currentStep, nextStep, prevStep, handleUpdatePickupInput, handleCheckedAddress, handleChangeMonth, requiredFields, openingHours, monthClosedDays }) => {
    const language = useSelector(state => state.user.lang);

    const currentDate = moment().format("YYYY-MM-DD");
    const currentHours = moment().format("HH");

    let isWeekendEnd = moment(pickup.endDate).isoWeekday() > 5;
    
    return(
    <>
        <FormGroup>
            <Label>
                <h5 className="contact">
                    <img src="/images/menu/icon-location.svg" alt="icon location" /> {i18n.t('pickup.deliveryAddress')}
                </h5>
            </Label>
            <Row className="pickupRow">
                <Col xs="6"><Input type="text" value={pickup.endName} placeholder={i18n.t('global.contactName')} name="endName" onChange={e => handleUpdatePickupInput(e)}></Input>{requiredFields && requiredFields.endName ? <span className="errorMsg">{requiredFields.endName}</span> : undefined }<span className="red_ast">*</span></Col>
                <Col xs="6"><PhoneInput phone={pickup.endPhone} onChange={phone => handleUpdatePickupInput({ target: { name: "endPhone", value: phone } })} />{requiredFields && requiredFields.endPhone ? <span className="errorMsg">{requiredFields.endPhone}</span> : undefined }<span className="red_ast">*</span></Col>
            </Row>

            <Row className="pickupRow">
                <Col xs="8"><LocationSearchInput value={pickup.endAddress && pickup.endAddress.formatted_address} handleCheckedAddress={isChecked => handleCheckedAddress(isChecked)} onSelect={(a, latLng) => handleUpdatePickupInput({ target: { name: "endAddress", value: { ...a, ...latLng } } })} /><span className="red_ast">*</span></Col>
                <Col xs="4"><Input type="text" placeholder={i18n.t('address.app')} value={pickup.endAddress && pickup.endApartment} onChange={e => handleUpdatePickupInput(e)} name="endApartment"></Input></Col>
                {requiredFields && requiredFields.endAddress ? <span className="errorMsg">{requiredFields.endAddress}</span> : undefined }

            </Row>

            <Row className="pickupRow">
                <Col xs="12"><Input type="textarea" value={pickup.specialInstructionTo} placeholder={i18n.t('global.instruction')} name="specialInstructionTo" rows="5" onChange={e => handleUpdatePickupInput(e)}></Input></Col>
            </Row>
        </FormGroup>

        { pickup.deliveryType == 'custom' ?

        <FormGroup>
            <Label>
                <h5 className="contact">
                    <img src="/images/menu/icon-location.svg" alt="icon location" /> {i18n.t('pickup.deliveryDate')}
                </h5>
            </Label>

            <Row className="pickupRow">
                <Col>
                    <Row>
                        <Col>
                            <Row className="pickupRow">
                                <Col>
                                    <DatePicker 
                                        disabled={!pickup.startTimeMin || !pickup.startTimeMax}
                                        className='form-control' 
                                        locale={language === 'fr' ? 'fr-CA' : 'en-CA'} 
                                        type="date" 
                                        placeholderText="Aujourd'hui" 
                                        selected={moment(pickup.endDate).toDate()} 
                                        onChange={ e => handleUpdatePickupInput({ target: { name: 'endDate', value: moment(e).format('YYYY-MM-DD')} }) } 
                                        minDate={moment(pickup.startDate).toDate()} 
                                        excludeDates={monthClosedDays || []}
                                        popperPlacement='top-start'
                                        popperModifiers={[
                                            {
                                                name: 'flip',
                                                enabled: false
                                            }
                                        ]}
                                        onMonthChange={(date) => handleChangeMonth(date)}
                                    />
                                    <span className="red_ast">*</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col>

                            <p>{i18n.t('pickup.scheduleRange')}</p>
                            <Input type="select" defaultValue="" id="selectTime" value={pickup.endTimeMin} name="endTimeMin" onChange={e => handleUpdatePickupInput(e)} style={{ width: '50%', display: 'inline'}}>
                                <option value="" selected={pickup.endTimeMin == ''} disabled hidden>{i18n.t('pickup.chooseHours')}</option>
                                { openingHours.map( (h, index) => {
                                    if (index !== openingHours.length - 1) {
                                        if(pickup.startDate === pickup.endDate) {
                                            if (h.value >= pickup.startTimeMin) return (<option value={h.value} selected={pickup.endTimeMin == h.value}>{h.label}</option>)
                                        } else return (<option value={h.value} selected={pickup.endTimeMin == h.value}>{h.label}</option>)
                                    }
                                })}
                                
                            </Input>
                            <Input type="select" defaultValue="" id="selectTime" value={pickup.endTimeMax} name="endTimeMax" onChange={e => handleUpdatePickupInput(e)} style={{ width: '50%', display: 'inline'}}>
                            <option value="" selected={pickup.endTimeMax == ''} hidden>{i18n.t('pickup.chooseHours')}</option>
                            { openingHours.map( (h, index ) => {
                                if (pickup.startDate === pickup.endDate) {
                                    if(h.value >= pickup.startTimeMax  && h.value > pickup.endTimeMin) return (<option selected={pickup.endTimeMax == h.value} value={h.value}>{h.label}</option>)
                                } else if (h.value > pickup.endTimeMin) return (<option value={h.value} selected={pickup.endTimeMin == h.value}>{h.label}</option>)
                            })}
                            
                            </Input>
                            {requiredFields && ( requiredFields.endDate || requiredFields.endTime ) ? <span className="errorMsg">{requiredFields.endDate || requiredFields.endTime}</span> : undefined } 

                            <span className="red_ast">*</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </FormGroup> : undefined }
        

        <FormGroup className="pickupButton">
            <Button
                className="prevButton"
                onClick={prevStep}>
                {i18n.t('pickup.returnToHandling')}
            </Button>

            <Button
                className="nextButton"
                //disabled={!pickup.endName || (!pickup.endPhone || pickup.endPhone.length !== 10) || !pickup.endAddress || !pickup.isCheckedAddress}
                onClick={e => nextStep(false)}>
                {i18n.t('global.continue')}
            </Button>
        </FormGroup>

    </>
)}

export default PickupStep2Component;