import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Col, Row, Button, Form, FormText, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import Loading from "./UI/Loading";
import { useToasts } from 'react-toast-notifications'

import i18n from '../../translations/i18n';

const Partner = ({ partnerForm, handleUpdateRecruitInput, handleSendEmail, isLoading, toastMessage }) => {
    
    const { addToast } = useToasts();

    return(
    <div className="paddingContainerMobile">

        { isLoading ? <Loading /> : undefined }
        { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }

        <div id="partnerPage">

            <Container id="title-section">

                <Row sm={2} xs={1}>
                    <Col sm={6}>
                        <img className="title-img" src="/images/partner/partner_header.jpg"
                        alt="Montreal Skyline"/>
                    </Col>

                    <Col sm={6}>
                        <div className="title-text-wrapper">
                            <div className="title-text">
                                <h1 className="bigTitle">{i18n.t("partner.discover_genie")}</h1>
                                <h3 className="subtitle-text-wrapper">{i18n.t("partner.subtitle_genie")}</h3>
                                <Button className='primary marginTop15 padding15 uppercase'><Link to="/qcommerce">{i18n.t("partner.discover_qcommerce")}</Link></Button>
                            </div>
                        </div>
                    </Col>
                </Row>

                <hr/>

                <h3 className="bigTitle" style={{textAlign: 'center'}}>{i18n.t("partner.easy_step")}:</h3>

                <Row sm={2} xs={1} id="colDesc" style={{ alignItems: 'start'}}>
                    <Col sm={4} className="gutter">
                        <img src="/images/partner/partner_business.jpg" />
                        <h5>{i18n.t("partner.desc_title_1")}</h5>
                        <p>{i18n.t("partner.desc_1")}</p>
                        <Link to="/choisissez-votre-plan"><Button className="primary marginTop15 padding15 uppercase">{i18n.t("partner.desc_btn_1")}</Button></Link>
                    </Col>
                    <Col sm={4} className="gutter">
                        <img src="/images/partner/partner_map.jpg" />
                        <h5>{i18n.t("partner.desc_title_2")}</h5>
                        <p>{i18n.t("partner.desc_2")}</p>
                        <Link to="/delivery-zone"><Button className="primary marginTop15 padding15 uppercase">{i18n.t("partner.desc_btn_2")}</Button></Link>
                    </Col>
                    <Col sm={4} className="gutter">
                        <img src="/images/partner/partner_app.jpg" />
                        <h5>{i18n.t("partner.desc_title_3")}</h5>
                        <p>{i18n.t("partner.desc_3")}</p>
                        <Link to="/creez-votre-store-en-ligne"><Button className="primary marginTop15 padding15 uppercase">{i18n.t("partner.desc_btn_3")}</Button></Link>
                    </Col>
                </Row>

                <hr/>

                <Row sm={2} xs={1} id="colDesc">
                    <Col sm={6}>
                        <h2 className="bigTitle">{i18n.t("partner.join_marketplace")}</h2>
                        <p>{i18n.t("partner.subtitle_marketplace")}</p>

                        <h3 className="titleQuote">{i18n.t("partner.whatisit")}</h3>
                        <p className="quote">{i18n.t("partner.whatisit_desc")}</p>
                    
                        <h3 className="titleQuote">{i18n.t("partner.whatweoffer")}</h3>
                        <p className="quote">{i18n.t("partner.whatweoffer_desc")}</p>

                    </Col>
                    <Col sm={6}>
                        <img src="/images/partner/iphone_preview.png" />
                    </Col>
                </Row>

                <hr />

                <Row sm={2} xs={1} id="colDesc">
                    <Col sm={6}>
                        <h2>{i18n.t("partner.delivery_everywhere")}</h2>
                        <p>{i18n.t("partner.delivery_everywhere_desc")}</p>

                        <Link to="/delivery-zone">
                            <Button className="primary marginTop15 padding15 uppercase">{i18n.t("partner.desc_btn_2")}</Button>
                        </Link>

                    </Col>
                    <Col sm={6}>
                        <img src="/images/partner/delivery_map.png" />
                    </Col>
                </Row>

                <hr />

                <img style={{ width: '100%'}} src={"/images/partner/avis_genie_" + (i18n.locale === 'en' ?  'en.png' : 'fr.jpg')} />


            </Container>

            <Form className="accountForm" style={{padding: "5% 15%"}}>
                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="lastName">{i18n.t('global.lastName')}</Label>
                            <Input type="text" name="lastName" value={partnerForm.lastName || ''} onChange={e => handleUpdateRecruitInput(e)}></Input>
                        </Col>
                        <Col>
                            <Label className="accountLabel" htmlFor="firstName">{i18n.t('global.firstName')}</Label>
                            <Input type="text" name="firstName" value={partnerForm.firstName || ''} onChange={e => handleUpdateRecruitInput(e)}></Input>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="email">{i18n.t('global.email')}</Label>
                            <Input type="email" name="email" value={partnerForm.email || ''} onChange={e => handleUpdateRecruitInput(e)}></Input>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="tel">{i18n.t('global.phoneNumber')}</Label>
                            <Input type="text" name="tel"onChange={e => handleUpdateRecruitInput(e)}></Input>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="website">{i18n.t('partner.website')}</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>http://</InputGroupText>
                                </InputGroupAddon>
                                <Input type="url" name="website" onChange={e => handleUpdateRecruitInput(e)}></Input>
                            </InputGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup tag="fieldset">
                    <Label className="accountLabel" htmlFor="radio1">{i18n.t('partner.establishment')}</Label>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="domaine" value={i18n.t('partner.restaurant')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.restaurant')}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="domaine" value={i18n.t('partner.store')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.store')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="domaine" value={i18n.t('partner.bigStore')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.bigStore')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="domaine" value={i18n.t('partner.service')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.service')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="domaine" value={i18n.t('partner.other')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.other')}                                
                        </Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup tag="fieldset">
                    <Label className="accountLabel" htmlFor="radio1">{i18n.t('partner.delivery_title')}</Label>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="delivery" value={i18n.t('partner.delivery_less1h')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.delivery_less1h')}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="delivery" value={i18n.t('partner.delivery_less4h')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.delivery_less4h')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="delivery" value={i18n.t('partner.delivery_sameDay')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.delivery_sameDay')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="delivery" value={i18n.t('partner.other')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.other')}                                
                        </Label>
                    </FormGroup>
                </FormGroup>

                <FormGroup tag="fieldset">
                    <Label className="accountLabel" htmlFor="radio1">{i18n.t('partner.territory_title')}</Label>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="territory" value={i18n.t('partner.territory_street')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.territory_street')}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="territory" value={i18n.t('partner.territory_city')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.territory_city')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="territory" value={i18n.t('partner.territory_state')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.territory_state')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="territory" value={i18n.t('partner.other')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.other')}                                
                        </Label>
                    </FormGroup>
                </FormGroup>

                <FormGroup tag="fieldset">
                    <Label className="accountLabel" htmlFor="radio1">{i18n.t('partner.about_title')}</Label>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="about" value={i18n.t('partner.about_socialNetwork')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.about_socialNetwork')}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="about" value={i18n.t('partner.about_ads')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.about_ads')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="about" value={i18n.t('partner.about_media')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.about_media')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="about" value={i18n.t('partner.about_near')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.about_near')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="about" value={i18n.t('partner.about_web')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.about_web')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="about" value={i18n.t('partner.about_who')} onChange={e => handleUpdateRecruitInput(e)}/>{' '}
                            {i18n.t('partner.about_who')}                                
                        </Label>
                    </FormGroup>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="comment">{i18n.t('partner.comment')}</Label>
                            <Input type="text" name="comment" value={partnerForm.comment || ''} onChange={e => handleUpdateRecruitInput(e)}></Input>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Label className="accountLabel" htmlFor="conditions">{i18n.t('partner.conditions')}</Label>
                    <FormGroup check>
                        <Input type="checkbox" id="conditions" name="conditions" onChange={e => handleUpdateRecruitInput({ target: { name: 'conditions', value: e.target.checked}})}>{i18n.t('partner.accept_cond')}</Input>
                        <Label className="accountLabel form-text text-muted small" for="conditions">{i18n.t('partner.accept_cond')}</Label>

                    </FormGroup>
                </FormGroup>

                <Button className='primary' disabled={ !partnerForm || !partnerForm.conditions || !partnerForm.domaine || !partnerForm.territory || !partnerForm.delivery || !partnerForm.about} onClick={e => handleSendEmail(e)}>{i18n.t('recruit.submit')}</Button>
            </Form>


        </div>
    </div>
)}

export default Partner;