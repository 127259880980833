import React, { Component } from 'react';

class DeliveryTerritoryPage extends Component {

    constructor(props){
        super(props);
    }

    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+this.props.title;
    }

    render() {
        const { Layout, navigation } = this.props;

        return ( <Layout /> );
    }
}

export default DeliveryTerritoryPage;