import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { checkVoucherCode } from "../../actions/orderAction";
import {
  createPickup,
  calculatePrice,
  loadMyPickup,
  getAvailableHours,
  getMonthClosedDays,
} from "../../actions/pickupAction";
import { getTokenCard, createUserCard } from "../../actions/userAction";

import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
  checkRequiredFields,
} from "../../utils/utils";

import i18n from "../../translations/i18n";

class PickupPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentStep: 1,
      user: this.props.user.user,
      requiredFields: undefined,
      openingHours: [],
      monthClosedDays: [],
      isOpenLogin: false,
      isOpenSignUp: false,
      pickup: {
        startName:
          this.props.user.user.firstName + " " + this.props.user.user.lastName,
        startPhone: this.props.user.user.phone,
        toastMessage: undefined,
        startDate: moment().format("YYYY-MM-DD"),
        startTimeMin: undefined,
        startTimeMax: undefined,
        endTimeMin: undefined,
        endTimeMax: undefined,
        endDate: moment().format("YYYY-MM-DD"),
        startAddress:
          this.props.user.user.addresses &&
          this.props.user.user.addresses.length > 0
            ? this.props.user.user.addresses[0]
            : "other",
        displayOtherAddress:
          this.props.user.user.addresses &&
          this.props.user.user.addresses.length > 0
            ? false
            : true,
        paymentCard:
          this.props.user.cards && this.props.user.cards.length > 0
            ? this.props.user.cards[0].id
            : "other",
        displayOtherCard:
          this.props.user.cards && this.props.user.cards.length > 0
            ? false
            : true,
      },
      card_errors: {
        number: false,
        expiration: false,
        cvc: false,
      },
    };

    if (window && window.document && window.document.title)
      window.document.title = "Genie Montréal | " + props.title;
  }

  UNSAFE_componentWillMount() {
    const pickupId =
      this.props.match && this.props.match.params
        ? this.props.match.params.id
        : this.props.route &&
          this.props.route.params &&
          this.props.route.params.id
        ? this.props.route.params.id
        : undefined;

    if (pickupId) {
      this.props
        .loadMyPickup(pickupId)
        .then((response) => {
          this.setState({
            pickup: {
              ...this.state.pickup,
              startName: response.senderName,
              startPhone: response.senderPhone,
              endName: response.receiverName,
              endPhone: response.receiverPhone,
              specialInstructionFrom: response.specialInstructionFrom,
              specialInstructionTo: response.specialInstructionTo,
              packFormat: response.packageFormat,
              packWeight: response.weight,
              packLength: response.length,
              packWidth: response.width,
              packHeight: response.height,
            },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    this.props
      .getAvailableHours(moment().format("YYYY-MM-DD"))
      .then((hours) => {
        let currentHours = moment().format("HH:mm");
        let openingHours = hours.filter((h) => h.value >= currentHours);

        this.setState({ openingHours: openingHours });
      });

      this.props
        .getMonthClosedDays(moment().format("YYYY-MM-DD"))
        .then((monthClosedDaysStr) => {
          const monthClosedDays = monthClosedDaysStr?.map(day => moment(day).toDate()) || [];;
          this.setState({ monthClosedDays });
        });



    this.setState({
      pickup: {
        ...this.state.pickup,
        displayStartOtherAddress:
          this.state.user.addresses && this.state.user.addresses.length === 0
            ? true
            : false,
        displayOtherCard:
          this.props.user.cards && this.props.user.cards.length === 0
            ? true
            : false,
      },
    });
  }

  handleChangeMonth = (date) => {
    this.props
      .getMonthClosedDays(moment(date).format("YYYY-MM-DD"))
      .then((monthClosedDaysStr) => {
        const monthClosedDays = monthClosedDaysStr?.map(day => moment(day).toDate()) || [];
        this.setState({ monthClosedDays });
      });
  }

  handleUpdatePickupInput = (e) => {
    let checkField = {};

    if (
      e.target.name.startsWith("startAddress") &&
      this.state.pickup.displayStartOtherAddress
    )
      checkField = {
        displayStartOtherAddress: false,
        otherStartAddress: undefined,
        startApartment: null,
      };
    if (e.target.name === "displayStartOtherAddress")
      checkField = { startAddress: null, startApartment: null };

    if (
      e.target.name.startsWith("facturation") &&
      this.state.pickup.displayOtherFacturationAddress
    )
      checkField = {
        displayOtherFacturationAddress: false,
        otherFacturationAddress: undefined,
        facturationApartment: null,
      };
    if (e.target.name === "displayOtherFacturationAddress")
      checkField = {
        facturationAddress: null,
        facturationApartment: null,
      };

    if (
      e.target.name.startsWith("paymentCard") &&
      this.state.pickup.displayOtherCard
    )
      checkField = {
        displayOtherCard: false,
        otherPaymentCardName: null,
        otherPaymentCardNumber: null,
        otherPaymentCardExpiry: null,
        otherPaymentCardCVC: null,
      };
    if (e.target.name === "displayOtherCard")
      checkField = { paymentCard: null };

    if (e.target.name === "otherPaymentCardNumber")
      e.target.value = formatCreditCardNumber(e.target.value);
    if (e.target.name === "otherPaymentCardExpiry")
      e.target.value = formatExpirationDate(e.target.value);
    if (e.target.name === "otherPaymentCardCVC")
      e.target.value = formatCVC(e.target.value);

    if (
      e.target.name === "startDate" &&
      this.state.pickup.endDate < e.target.value
    )
      checkField = { endDate: e.target.value };

    if (
      (e.target.name === "startPhone" || e.target.name === "endPhone") &&
      e.target.value.length > 10
    )
      return;

    if (e.target.name === "startDate" || e.target.name === "endDate") {
      let selectedDate = e.target.value;
      let type = e.target.name === "startDate" ? "startDate" : "endDate";
      let startTime = this.state.pickup.startTime;

      this.props
        .getAvailableHours(moment(selectedDate).format("YYYY-MM-DD"))
        .then((hours) => {
          let currentHours = moment().format("HH:mm");
          let openingHours = hours;

          if (selectedDate === moment().format("YYYY-MM-DD"))
            openingHours = openingHours.filter((h) => h.value >= currentHours);

          this.setState({ openingHours: openingHours, pickup: {...this.state.pickup, [type === 'startDate' ? 'startTimeMin' : 'endTimeMin'] : openingHours[0].value } });
        });
    }

    if (e.target.name === "startTimeMin") checkField.startTimeMax = undefined;
    if (e.target.name === "endTimeMin") checkField.endTimeMax = undefined;

    if (e.target.name === "deliveryType") {
      switch (e.target.value) {
        case "custom":
          checkField.startTimeMin = undefined;
          checkField.startTimeMax = undefined;
          checkField.endDate = this.state.pickup.startDate;
          checkField.endTimeMin = undefined;
          checkField.endTimeMax = undefined;
          break;
        case "asap":
          checkField.startTimeMin = moment().add(15, "minutes").format("HH:mm");
          checkField.startTimeMax = moment().add(15, "minutes").format("HH:mm");
          checkField.endDate = this.state.pickup.startDate;
          checkField.endTimeMin = moment().add(15, "minutes").format("HH:mm");
          checkField.endTimeMax = moment().add(15, "minutes").format("HH:mm");
          break;
        case "business":
          checkField.startTimeMin = "09:00";
          checkField.startTimeMax = "16:00";
          checkField.endDate = this.state.pickup.startDate;
          checkField.endTimeMin = "09:00";
          checkField.endTimeMax = "16:00";
          break;
      }
    }

    this.setState({
      pickup: {
        ...this.state.pickup,
        [e.target.name]: e.target.value,
        ...checkField,
      },
    });
  };

  requiredFields = (mobile, currentStep) => {
    return new Promise((resolve, reject) => {
      let checkedRequired = undefined;
      let currentCheck;

      if (mobile) {
        switch (currentStep) {
          case 1:
            currentCheck = checkRequiredFields(
              this.state.pickup.startName,
              "name"
            );
            if (currentCheck)
              checkedRequired = { ...checkedRequired, startName: currentCheck };

            currentCheck = checkRequiredFields(
              this.state.pickup.startPhone,
              "phone"
            );
            if (currentCheck)
              checkedRequired = {
                ...checkedRequired,
                startPhone: currentCheck,
              };

            if (
              this.state.pickup.displayStartOtherAddress ||
              this.state.pickup.startAddress === "other"
            ) {
              if (!this.state.pickup.isCheckedAddress)
                checkedRequired = {
                  ...checkedRequired,
                  startAddress: i18n.t("error_messages.invalid_address"),
                };
              else {
                currentCheck = checkRequiredFields(
                  this.state.pickup.otherStartAddress,
                  "address"
                );
                if (currentCheck)
                  checkedRequired = {
                    ...checkedRequired,
                    startAddress: currentCheck,
                  };
              }
            } else {
              currentCheck = checkRequiredFields(
                this.state.pickup.startAddress,
                "address"
              );
              if (currentCheck)
                checkedRequired = {
                  ...checkedRequired,
                  startAddress: currentCheck,
                };
            }

            if (
              !this.state.pickup.deliveryType ||
              (this.state.pickup.deliveryType == "custom" &&
                (!this.state.pickup.startTimeMin ||
                  !this.state.pickup.startTimeMax))
            )
              checkedRequired = {
                ...checkedRequired,
                startTime: i18n.t("error_messages.required_field"),
              };
            break;
          case 2:
            currentCheck = checkRequiredFields(
              this.state.pickup.endName,
              "name"
            );
            if (currentCheck)
              checkedRequired = { ...checkedRequired, endName: currentCheck };

            currentCheck = checkRequiredFields(
              this.state.pickup.endPhone,
              "phone"
            );
            if (currentCheck)
              checkedRequired = { ...checkedRequired, endPhone: currentCheck };

            if (!this.state.pickup.isCheckedAddress)
              checkedRequired = {
                ...checkedRequired,
                endAddress: i18n.t("error_messages.invalid_address"),
              };
            else {
              currentCheck = checkRequiredFields(
                this.state.pickup.endAddress,
                "address"
              );
              if (currentCheck)
                checkedRequired = {
                  ...checkedRequired,
                  endAddress: currentCheck,
                };
            }
            if (
              !this.state.pickup.deliveryType ||
              (this.state.pickup.deliveryType == "custom" &&
                (!this.state.pickup.endTimeMin ||
                  !this.state.pickup.endTimeMax))
            )
              checkedRequired = {
                ...checkedRequired,
                endTime: i18n.t("error_messages.required_field"),
              };
            break;
          default:
        }
      } else {
        switch (currentStep) {
          case 1:
            currentCheck = checkRequiredFields(
              this.state.pickup.startName,
              "name"
            );
            if (currentCheck)
              checkedRequired = { ...checkedRequired, startName: currentCheck };

            currentCheck = checkRequiredFields(
              this.state.pickup.startPhone,
              "phone"
            );
            if (currentCheck)
              checkedRequired = {
                ...checkedRequired,
                startPhone: currentCheck,
              };

            if (
              this.state.pickup.displayStartOtherAddress ||
              this.state.pickup.startAddress === "other"
            ) {
              if (!this.state.pickup.isCheckedAddress)
                checkedRequired = {
                  ...checkedRequired,
                  startAddress: i18n.t("error_messages.invalid_address"),
                };
              else {
                currentCheck = checkRequiredFields(
                  this.state.pickup.otherStartAddress,
                  "address"
                );
                if (currentCheck)
                  checkedRequired = {
                    ...checkedRequired,
                    startAddress: currentCheck,
                  };
              }
            } else {
              currentCheck = checkRequiredFields(
                this.state.pickup.startAddress,
                "address"
              );
              if (currentCheck)
                checkedRequired = {
                  ...checkedRequired,
                  startAddress: currentCheck,
                };
            }
            if (!this.state.pickup.deliveryType)
              checkedRequired = {
                ...checkedRequired,
                startTime: i18n.t("error_messages.required_field"),
              };
            if (!this.state.pickup.startDate)
              checkedRequired = {
                ...checkedRequired,
                startDate: i18n.t("error_messages.required_field"),
              };

            if (this.state.pickup.deliveryType == "custom") {
              if (
                !this.state.pickup.startTimeMin ||
                !this.state.pickup.startTimeMax
              )
                checkedRequired = {
                  ...checkedRequired,
                  startTime: i18n.t("error_messages.required_field"),
                };
            }

            break;
          case 2:
            currentCheck = checkRequiredFields(
              this.state.pickup.endName,
              "name"
            );
            if (currentCheck)
              checkedRequired = { ...checkedRequired, endName: currentCheck };

            currentCheck = checkRequiredFields(
              this.state.pickup.endPhone,
              "phone"
            );
            if (currentCheck)
              checkedRequired = { ...checkedRequired, endPhone: currentCheck };

            if (!this.state.pickup.isCheckedAddress)
              checkedRequired = {
                ...checkedRequired,
                endAddress: i18n.t("error_messages.invalid_address"),
              };
            else {
              currentCheck = checkRequiredFields(
                this.state.pickup.endAddress,
                "address"
              );
              if (currentCheck)
                checkedRequired = {
                  ...checkedRequired,
                  endAddress: currentCheck,
                };
            }
            if (!this.state.pickup.endDate)
              checkedRequired = {
                ...checkedRequired,
                endDate: i18n.t("error_messages.required_field"),
              };

            if (this.state.pickup.deliveryType == "custom") {
              if (
                !this.state.pickup.endTimeMin ||
                !this.state.pickup.endTimeMax
              )
                checkedRequired = {
                  ...checkedRequired,
                  endTime: i18n.t("error_messages.required_field"),
                };
            }

          default:
        }
      }

      this.setState({ requiredFields: checkedRequired });

      if (checkedRequired) return resolve(false);
      else return resolve(true);
    });
  };

  nextStep = async (mobile) => {
    if (window && window.document)
      window.scrollTo({ top: 0, behavior: "smooth" });

    let currentStep = this.state.currentStep;

    let allFieldsRequired = await this.requiredFields(mobile, currentStep);

    if (!allFieldsRequired) return;

    if (currentStep === 1) {
      let that = this;

      this.props
        .getAvailableHours(
          moment(this.state.pickup.endDate).format("YYYY-MM-DD")
        )
        .then((hours) => {
          let currentHours = moment().format("HH:mm");
          let openingHours = hours;

          if (this.state.pickup.endDate === moment().format("YYYY-MM-DD"))
            openingHours = openingHours.filter((h) => h.value >= currentHours);

          let endTimeMin = this.state.pickup.startTimeMin ? this.state.pickup.startTimeMin : openingHours[0].value

          this.setState({ openingHours: openingHours, pickup: {...this.state.pickup, endTimeMin } });
        });

      // Sets facturationAddress as startAddress by default
      this.setState({
        pickup: {
          ...this.state.pickup,
          facturationAddress:
            this.state.pickup.startAddress &&
            this.state.pickup.startAddress !== "other"
              ? this.state.pickup.startAddress
              : this.state.pickup.otherStartAddress,
        },
      });
    }

    if (currentStep === 3) {
      if (this.state.pickup.packFormat === "package") {
        const { packHeight, packLength, packWeight, packWidth } =
          this.state.pickup;
        if (
          isNaN(packHeight) ||
          isNaN(packLength) ||
          isNaN(packWeight) ||
          isNaN(packWidth)
        )
          return this.setState(
            {
              toastMessage: {
                msg: i18n.t("error_messages.incorrect_dimensions"),
                type: "error",
              },
            },
            () => {
              this.setState({ toastMessage: undefined });
            }
          );
      }

      this.calculatePrice()
        .then((price) => {
          this.setState({
            currentStep: currentStep + 1,
            pickup: { ...this.state.pickup, price: price },
          });
        })
        .catch((err) => {
          console.log("***** err", err);
          if (err.data.message) {
            this.setState(
              {
                toastMessage: {
                  msg: i18n.t(err.data.message),
                  type: "error",
                },
              },
              () => {
                this.setState({ toastMessage: undefined });
              }
            );
          }
          console.log("***** PickupPage - nextStep - calculatePrice", err);
        });
    } else if (currentStep < 3) {
      currentStep = currentStep + 1;
      this.setState({
        currentStep: currentStep,
        card_errors: {
          number: false,
          expiration: false,
          cvc: false,
        },
      });
    }
  };

  prevStep = () => {
    if (window && window.document)
      window.scrollTo({ top: 0, behavior: "smooth" });

    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });

    if (currentStep === 1) {
      let that = this;

      this.props
        .getAvailableHours(
          moment(this.state.pickup.startDate).format("YYYY-MM-DD")
        )
        .then((hours) => {
          let currentHours = moment().format("HH:mm");
          let openingHours = hours;

          if (this.state.pickup.startDate === moment().format("YYYY-MM-DD"))
            openingHours = openingHours.filter((h) => h.value >= currentHours);

          this.setState({ openingHours: openingHours });
        });
    }
  };

  changeStep = (step) => {
    if (window && window.document)
      window.scrollTo({ top: 0, behavior: "smooth" });

    this.setState({ currentStep: step });
  };

  handleSendPickup = async (e) => {
    this.setState({ isLoading: true });
    if (e) e.preventDefault();

    // Create clean pickup object
    let pickup = {};

    pickup.customer = this.props.user.user.id;

    pickup.deliveryType = this.state.pickup.deliveryType;

    switch (this.state.pickup.deliveryType) {
      case "custom":
        pickup.departureDateStart = moment(
          this.state.pickup.startDate + " " + this.state.pickup.startTimeMin
        ).valueOf();
        pickup.departureDateEnd = moment(
          this.state.pickup.startDate + " " + this.state.pickup.startTimeMax
        ).valueOf();
        pickup.deliveryDateStart = moment(
          this.state.pickup.endDate + " " + this.state.pickup.endTimeMin
        ).valueOf();
        pickup.deliveryDateEnd = moment(
          this.state.pickup.endDate + " " + this.state.pickup.endTimeMax
        ).valueOf();
        break;
      case "asap":
        pickup.departureDateStart = moment().add(15, "minutes").valueOf();
        pickup.departureDateEnd = moment().add(15, "minutes").valueOf();
        pickup.deliveryDateStart = moment()
          .add(15 + this.state.pickup.price.duration, "minutes")
          .valueOf();
        pickup.deliveryDateEnd = moment()
          .add(15 + this.state.pickup.price.duration, "minutes")
          .valueOf();
        break;
      case "business":
        pickup.departureDateStart = moment(
          this.state.pickup.startDate +
            " " +
            moment().add(5, "minutes").format("HH:mm")
        ).valueOf();
        pickup.departureDateEnd = moment(
          this.state.pickup.startDate + " 16:00"
        ).valueOf();
        pickup.deliveryDateStart = moment(
          this.state.pickup.endDate +
            " " +
            moment().add(5, "minutes").format("HH:mm")
        ).valueOf();
        pickup.deliveryDateEnd = moment(
          this.state.pickup.endDate + " 16:00"
        ).valueOf();
        break;
    }

    if (this.state.pickup.voucherCode)
      pickup.voucherCode = { id: this.state.pickup.voucherCode };

    //TODO Price
    pickup.price = 0;

    pickup.packageFormat = this.state.pickup.packFormat;

    if (this.state.pickup.packFormat === "package") {
      pickup.packageFormat = "package";
      pickup.weight = this.state.pickup.packWeight;
      pickup.length = this.state.pickup.packLength;
      pickup.width = this.state.pickup.packWidth;
      pickup.height = this.state.pickup.packHeight;
    } else {
      pickup.packageFormat = "standard";
      pickup.weight = 0;
      pickup.length = 0;
      pickup.width = 0;
      pickup.height = 0;
    }

    if (
      this.state.pickup.startAddress &&
      this.state.pickup.startAddress !== null &&
      this.state.pickup.startAddress !== "other"
    )
      pickup.fromAddress = { id: this.state.pickup.startAddress.id };
    else
      pickup.fromAddress = {
        apartment: this.state.pickup.otherStartApartment,
        ...this.sanitizeAddress(this.state.pickup.otherStartAddress),
      };

    pickup.toAddress = {
      apartment: this.state.pickup.endApartment,
      ...this.sanitizeAddress(this.state.pickup.endAddress),
    };

    if (
      this.state.pickup.facturationAddress &&
      this.state.pickup.facturationAddress !== null &&
      this.state.pickup.facturationAddress !== "other"
    )
      pickup.billingAddress = this.state.pickup.facturationAddress.id
        ? { id: this.state.pickup.facturationAddress.id }
        : pickup.fromAddress;
    else
      pickup.billingAddress = {
        apartment: this.state.pickup.otherFacturationApartment,
        ...this.sanitizeAddress(this.state.pickup.otherFacturationAddress),
      };

    if (this.state.pickup.specialInstructionFrom)
      pickup.specialInstructionFrom = this.state.pickup.specialInstructionFrom;
    if (this.state.pickup.specialInstructionTo)
      pickup.specialInstructionTo = this.state.pickup.specialInstructionTo;

    pickup.isBillingAddressDifferent =
      this.state.pickup.displayOtherFacturationAddress ||
      this.state.pickup.facturationAddress === "other";

    pickup.senderName = this.state.pickup.startName;
    pickup.receiverName = this.state.pickup.endName;

    pickup.senderPhone = this.state.pickup.startPhone;
    pickup.receiverPhone = this.state.pickup.endPhone;

    // Payment
    if (
      this.state.pickup.paymentCard &&
      this.state.pickup.paymentCard !== "other"
    )
      pickup.stripeToken = this.state.pickup.paymentCard;
    else {
      try {
        let card = await this.props.getTokenCard({
          name: this.state.pickup.otherPaymentCardName,
          number: this.state.pickup.otherPaymentCardNumber,
          expiry: this.state.pickup.otherPaymentCardExpiry,
          cvc: this.state.pickup.otherPaymentCardCVC,
        });
        pickup.stripeToken = card.data.id;

        await this.props.createUserCard({
          name: this.state.pickup.otherPaymentCardName,
          number: this.state.pickup.otherPaymentCardNumber,
          expiry: this.state.pickup.otherPaymentCardExpiry,
          cvc: this.state.pickup.otherPaymentCardCVC,
        });

      } catch (err) {
        console.log("getTokenCard err", err);
        if (err.response.status === 402) {
          this.setState(
            {
              toastMessage: {
                type: "error",
                msg: i18n.t("error_messages.invalid_credit_card"),
              },
              card_errors: { number: true },
              isLoading: false,
            },
            () => {
              this.setState({ toastMessage: undefined });
              this.prevStep();
            }
          );
        } else {
          this.setState(
            {
              toastMessage: {
                type: "error",
                msg: i18n.t("error_messages.payment_error"),
              },
              isLoading: false,
            },
            () => {
              this.setState({ toastMessage: undefined });
            }
          );
        }

        return;
      }
    }
    this.props
      .createPickup(pickup)
      .then((response) => {
        this.setState(
          {
            toastMessage: {
              msg: i18n.t("payment.payment_success"),
              type: "success",
            },
            redirect: true,
          },
          () => {
            this.setState({ toastMessage: undefined, redirect: false });
            if (!e) this.props.navigation.navigate("ConfirmOrder");
          }
        );
      })
      .catch((err) => {
        let errMsg;
        let cvcErr = false;
        let expErr = false;

        console.log("***** err.message", err.data?.message);

        switch (err.data.message) {
          case "Error.Pickup.receiverPhone.unvalid":
            errMsg = "error_messages.unvalid_receiver_phone";
            break;
          case "Error.Pickup.senderPhone.unvalid":
            errMsg = "error_messages.unvalid_sender_phone";
            break;
          case "Error.Pickup.deliveryDate.unvalid":
            errMsg = "error_messages.unvalid_delivery_date";
            break;
        }

        if (err.data.message.decline_code) {
          switch (err.data.message.decline_code) {
            case "insufficient_funds":
              errMsg = "error_messages.insufficient_funds";
              break;
            case "expired_card":
              errMsg = "error_messages.expired_card";
              expErr = true;
              break;
            case "incorrect_cvc":
              errMsg = "error_messages.incorrect_cvc";
              cvcErr = true;
              break;
            default:
              errMsg = "error_messages.invalid_credit_card";
              break;
          }
        } else if (err.data.message.code) {
          switch (err.data.message.code) {
            case "insufficient_funds":
              errMsg = "error_messages.insufficient_funds";
              break;
            case "expired_card":
              errMsg = "error_messages.expired_card";
              expErr = true;
              break;
            case "incorrect_cvc":
              errMsg = "error_messages.incorrect_cvc";
              cvcErr = true;
              break;
            default:
              errMsg = "error_messages.invalid_credit_card";
              break;
          }
        }

        if (!e && !errMsg) this.props.navigation.navigate("Home");

        this.setState(
          {
            toastMessage: {
              msg: i18n.t(errMsg || "error_messages.payment_error"),
              type: "error",
            },
            redirect: errMsg ? false : true,
            card_errors: { cvc: cvcErr, expiration: expErr },
            isLoading: false,
          },
          () => {
            this.setState({ toastMessage: undefined, redirect: false });
            this.prevStep();
          }
        );

        console.log(
          "***** ERROR : PickupPage - handleSendPickup - createPickup",
          err
        );
      });
  };

  handleCheckVoucher = (e) => {
    if (e) e.preventDefault();

    if (this.state.pickup.promoCode) {
      this.props
        .checkVoucherCode(this.state.pickup.promoCode, "pickup")
        .then((response) => {
          if (response.isActive)
            this.setState(
              {
                pickup: {
                  ...this.state.pickup,
                  voucherCode: response.id,
                  voucher: response,
                },
              },
              () => {
                this.calculatePrice()
                  .then((price) => {
                    this.setState({
                      pickup: { ...this.state.pickup, price: price },
                    });
                  })
                  .catch((err) => {
                    this.setState(
                      {
                        toastMessage: {
                          msg: i18n.t("error_messages.incorrect_data"),
                          type: "error",
                        },
                      },
                      () => {
                        this.setState({ toastMessage: undefined });
                      }
                    );
                    console.log(
                      "***** PickupPage - handleCheckVoucher  - calculatePrice",
                      err
                    );
                  });
              }
            );
        })
        .catch((err) => {
          this.setState(
            {
              toastMessage: {
                type: "error",
                msg: i18n.t("error_messages.promo_not_available"),
              },
            },
            () => {
              this.setState({ toastMessage: undefined });
            }
          );
        });
    } else {
      // TODO : NO PROMO CODE
    }
  };

  calculatePrice = () => {
    return new Promise((resolve, reject) => {
      this.props
        .calculatePrice({
          weight:
            this.state.pickup.packFormat === "package"
              ? this.state.pickup.packWeight
              : 0,
          height:
            this.state.pickup.packFormat === "package"
              ? this.state.pickup.packHeight
              : 0,
          length:
            this.state.pickup.packFormat === "package"
              ? this.state.pickup.packLength
              : 0,
          width:
            this.state.pickup.packFormat === "package"
              ? this.state.pickup.packWidth
              : 0,
          voucherCodeId: this.state.pickup.voucherCode
            ? this.state.pickup.voucherCode
            : undefined,
          originTime:
            this.state.pickup.deliveryType === "custom"
              ? moment().valueOf()
              : moment(
                  this.state.pickup.startDate +
                    " " +
                    this.state.pickup.startTimeMin
                ).valueOf(),
          destinationTime:
            this.state.pickup.deliveryType === "custom"
              ? moment().valueOf()
              : moment(
                  this.state.pickup.endDate + " " + this.state.pickup.endTimeMin
                ).valueOf(),
          origin:
            this.state.pickup.startAddress &&
            this.state.pickup.startAddress !== "other"
              ? this.state.pickup.startAddress.latitude +
                "," +
                this.state.pickup.startAddress.longitude
              : this.state.pickup.otherStartAddress.lat +
                "," +
                this.state.pickup.otherStartAddress.lng,
          destination:
            this.state.pickup.endAddress.lat +
            "," +
            this.state.pickup.endAddress.lng,
          deliveryType: this.state.pickup.deliveryType,
        })
        .then((price) => {
          return resolve(price);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  sanitizeAddress = (address) => {
    let addressObj = {};

    address.address_components.forEach((aPart) => {
      if (aPart.types.includes("street_number")) {
        addressObj.street = aPart.long_name;
      }
      if (aPart.types.includes("route")) {
        addressObj.street = addressObj.street + " " + aPart.long_name;
      }
      if (aPart.types.includes("locality")) {
        addressObj.city = aPart.long_name;
      }
      if (aPart.types.includes("administrative_area_level_1")) {
        addressObj.region = aPart.long_name;
        addressObj.regionShort = aPart.short_name;
      }
      if (aPart.types.includes("country")) {
        addressObj.country = aPart.long_name;
      }
      if (aPart.types.includes("postal_code")) {
        addressObj.postalCode = aPart.long_name;
      }

      addressObj.full =
        addressObj.street +
        ", " +
        addressObj.city +
        ", " +
        addressObj.regionShort +
        ", " +
        addressObj.country;
      addressObj.latitude = address.lat;
      addressObj.longitude = address.lng;
    });

    return addressObj;
  };

  handleUpdateCurrentCardNative = (form) => {
    let card = {
      otherPaymentCardName: form.values.name,
      otherPaymentCardNumber: form.values.number,
      otherPaymentCardExpiry: form.values.expiry,
      otherPaymentCardCVC: form.values.cvc,
    };

    this.setState({ pickup: { ...this.state.pickup, ...card } });
  };

  toggleSignUp = () => {
    this.setState({
      isOpenSignUp: !this.state.isOpenSignUp,
    });
  };

  toggleLogin = () => {
    this.setState({
      isOpenLogin: !this.state.isOpenLogin,
    });
  };

  handleCheckedAddress = (isChecked) => {
    this.setState({
      pickup: { ...this.state.pickup, isCheckedAddress: isChecked },
    });
  };

  render() {
    const { Layout, navigation, user, isLoading } = this.props;

    return (
      <Layout
        isLoading={isLoading || this.state.isLoading}
        cards={user.cards ? user.cards : []}
        user={user.user}
        pickup={this.state.pickup}
        currentStep={this.state.currentStep}
        nextStep={this.nextStep}
        prevStep={this.prevStep}
        handleUpdatePickupInput={this.handleUpdatePickupInput.bind(this)}
        handleCheckVoucher={this.handleCheckVoucher.bind(this)}
        handleSendPickup={this.handleSendPickup.bind(this)}
        handleUpdateCurrentCardNative={this.handleUpdateCurrentCardNative.bind(
          this
        )}
        handleCheckedAddress={this.handleCheckedAddress.bind(this)}
        handleChangeMonth={this.handleChangeMonth.bind(this)}
        changeStep={this.changeStep.bind(this)}
        navigation={navigation}
        toastMessage={this.state.toastMessage}
        redirect={this.state.redirect}
        isOpenSignUp={this.state.isOpenSignUp}
        isOpenLogin={this.state.isOpenLogin}
        toggleSignUp={this.toggleSignUp}
        toggleLogin={this.toggleLogin}
        requiredFields={this.state.requiredFields}
        openingHours={this.state.openingHours}
        monthClosedDays={this.state.monthClosedDays}
        cardErrors={this.state.card_errors}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  isLoading:
    state.user.isFetching || state.carts.isFetching || state.pickups.isFetching,
});

const mapDispatchToProps = {
  checkVoucherCode,
  createPickup,
  getTokenCard,
  calculatePrice,
  loadMyPickup,
  getAvailableHours,
  getMonthClosedDays,
  createUserCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupPage);
