import React from 'react';
import { Modal } from 'reactstrap';
import SVG from 'react-inlinesvg';
import ReactLoading from 'react-loading';

import AppConfig from "../../config/config";

import { useToasts } from 'react-toast-notifications'

import { person, mail, call, lockClosed } from "ionicons/icons";
import i18n from '../../translations/i18n';
import PhoneInput from "./UI/PhoneInput";


const SignUpModalComponent = ({ isOpen, toggle, toggleLogin, user, handleChangeInputUser, handleCreateUser, handleOnBlur, isLoading, formErrors, toastMessage }) =>
    {
        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        const { addToast } = useToasts();

        return(
            <>
                { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }
            <Modal isOpen={isOpen} toggle={toggle} id="signUpModal">
                <button onClick={toggle} className="btnClose">
                    <img src="/images/menu/icon-close.svg" alt="icon close" />
                </button>

                <h4>{i18n.t('signup.signup')}</h4>

                <a href={AppConfig.baseUrlApi + '/auth/facebook'}>
                    <button id="signupFacebook" className="primary">
                        {i18n.t('signup.signup_with')}
                        <img src="/images/social/social-facebook-filled.svg" alt="icon facebook" />
                    </button>
                </a>
                { false && !isChrome ?
                <button id="signupApple" className="primary">
                    {i18n.t('signup.signup_with')}
                    <img src="/images/social/social-apple.svg" alt="icon apple" />
                </button> : undefined }

                <p>{i18n.t('signup.or')}</p>

                <div className="inputContainer">
                    <SVG src={person} width='20' />
                    <input type="text" name="firstName" value={user ? user.firstName : ''} placeholder={i18n.t('signup.field.first_name')} onChange={e => handleChangeInputUser(e)} />
                </div>

                <div className="inputContainer">
                    <SVG src={person} width='20' />
                    <input type="text" name="lastName" value={user ? user.lastName : ''} placeholder={i18n.t('signup.field.last_name')} onChange={e => handleChangeInputUser(e)} />
                </div>

                <div className="inputContainer">
                    <SVG src={mail} width='20' />
                    <input type="text" name="email" value={user ? user.email : ''} placeholder={i18n.t('signup.field.email')} onChange={e => handleChangeInputUser(e)} />
                </div>

                <div className="inputContainer">
                    <SVG src={call} width='20' style={{ marginRight: 5}} />
                    <PhoneInput phone={user ? user.phone : ''} onChange={ phone => handleChangeInputUser({ target: { name: "phone", value: phone }})}/>
                </div>

                <div className="inputContainer">
                    <SVG src={lockClosed} width='20' />
                    <input type="password" name="password" onBlur={e => handleOnBlur(e)} value={user ? user.password : ''} placeholder={i18n.t('signup.field.password')} onChange={e => handleChangeInputUser(e)} />
                </div>

                <div className="inputContainer">
                    <SVG src={lockClosed} width='20' />
                    <input type="password" name="confirmPassword" onBlur={e => handleOnBlur(e)} value={user ? user.confirmPassword : ''} placeholder={i18n.t('signup.field.confirm_password')} onChange={e => handleChangeInputUser(e)} />
                </div>

                { formErrors && formErrors.register ? Object.keys(formErrors.register).map(t => formErrors.register[t] ? <p className="errorMessage" key={`error${t}`}>{formErrors.register[t]}</p> : undefined ) : undefined }


                {
                    isLoading ? (
                        <div className="alignCenterContent loading">
                            <ReactLoading type={'bars'} color={'#342535'} height={30} width={30} />
                        </div>
                    ) : (
                            <div>
                                <button
                                    onClick={e => handleCreateUser(e)}
                                    className="primary"
                                    disabled={!user || !user.lastName || !user.firstName || !user.email || !user.email.match(/^\S+@\S+\.\S+$/) || !user.phone || !user.password || !user.confirmPassword || user.password !== user.confirmPassword || user.password.length < 8}
                                >
                                    {i18n.t('signup.signup_with_email')}
                                </button>

                                <p className="already" onClick={_ => {
                                    toggle();
                                    toggleLogin();
                                }}>{i18n.t('signup.already_signup')}</p>

                                <p className="cgu">{i18n.t('signup.cgu')}</p>
                            </div>
                        )

                }



            </Modal>
        </>
    )};

export default SignUpModalComponent;