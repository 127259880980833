import React, { Component } from 'react';

import { sendEmail } from "../../actions/userAction";

import i18n from '../../translations/i18n';

class ChooseYourPlan extends Component {

    constructor(props){
        super(props);
        this.state = {
            form: {}
        }
    }
    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+this.props.title;
    }

    handleUpdateFormInput = (e) => {
        this.setState({ form: { ...this.state.form, [e.target.name] : e.target.value }});
    }

    handleSendEmail = (e) => {
        let form = {
            template: 'plan',
            data: { ...this.state.form }
        }

        sendEmail(form).then( response => {
            this.setState({ toastMessage: { type: 'success', msg: i18n.t('recruit.success')} }, () => {
                this.setState({ toastMessage: undefined, form: {}, isLoading: false });
            });

        }).catch(err => {
            this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.unknown_error')} }, () => {
                this.setState({ toastMessage: undefined, isLoading: false });
            });
            console.log('***** ChooseYourPlan - handleSendEmail - sendEmail ', err);
        })
    }


    render() {
        const { Layout, navigation } = this.props;

        return ( <Layout
            handleUpdateFormInput={this.handleUpdateFormInput.bind(this)}
            handleSendEmail={this.handleSendEmail.bind(this)}
            navigation={navigation}
            form={this.state.form}
            isLoading={this.state.isLoading}
            toastMessage={this.state.toastMessage}
        /> );
    }
}

export default ChooseYourPlan;