import axios from 'axios';
import AppConfig from '../config/config';

export const REQUEST_STORE_DATA = 'REQUEST_STORE_DATA';
export const RECEIVE_STORE_DATA = 'RECEIVE_STORE_DATA';
export const RECEIVE_MULTIPLE_STORE_DATA = 'RECEIVE_MULTIPLE_STORE_DATA';
export const RECEIVE_CAROUSEL_DATA = 'RECEIVE_CAROUSEL_DATA';
export const STOP_FETCHING = 'STOP_FETCHING';

export const requestStoreData = () => ({
    type: REQUEST_STORE_DATA,
});

export const receiveStoreData = (data) => ({
    type: RECEIVE_STORE_DATA,
    payload: data,
});

export const receiveMultipleStoreData = (data) => ({
    type: RECEIVE_MULTIPLE_STORE_DATA,
    payload: data,
});

export const receiveCarouselData = (data) => ({
    type: RECEIVE_CAROUSEL_DATA,
    payload: data,
});

export const stopFetching = () => ({
    type: STOP_FETCHING
});

export const getStoreHomepage = () => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestStoreData());
        
        const lang = getState().user.lang;

        axios.get(AppConfig.baseUrlApi + '/store?where={"homepage": true, "isVisible": true}')
            .then(response => {
                response.data.data.map(s => {
                    return sanitizeStoreLang(s);
                })

                return resolve(_dispatch(receiveMultipleStoreData(response.data.data.map(s => sanitizeStoreLang(s, lang)))));
            })
            .catch(error => {
                _dispatch(receiveMultipleStoreData([]));
                reject(error);
            });
    })
);

export const getAllStores = () => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestStoreData());
        
        const lang = getState().user.lang;

        axios.get(AppConfig.baseUrlApi + '/store?where={"isVisible": true}')
            .then(response => {
                response.data.data.map(s => {
                    return sanitizeStoreLang(s);
                })

                return resolve(_dispatch(receiveMultipleStoreData(response.data.data.map(s => sanitizeStoreLang(s, lang)))));
            })
            .catch(error => {
                _dispatch(receiveMultipleStoreData([]));
                reject(error);
            });
    })
);

export const getStoresByCategory = (id) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestStoreData());
        
        const lang = getState().user.lang;

        axios.get(AppConfig.baseUrlApi + '/store?categories=' + JSON.stringify(id) + '&isVisible=true')
            .then(response => {
                _dispatch(stopFetching());
                return resolve(_dispatch(receiveMultipleStoreData(response.data.data.map(s => sanitizeStoreLang(s, lang)))));
            })
            .catch(error => {
                _dispatch(receiveMultipleStoreData([]));
                reject(error);
            });
    })
);

export const getChildCategories = (parentId) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestStoreData());
        
        const lang = getState().user.lang;

        axios.get(AppConfig.baseUrlApi + '/category?sort=order&where={"parent":' + JSON.stringify(parentId) + '}')
            .then(response => {
                _dispatch(stopFetching());
                return resolve(response.data.data.map(c => sanitizeCategoryLang(c, lang)));
            })
            .catch(error => {
                _dispatch(receiveMultipleStoreData([]));
                reject(error);
            });
    })
);

export const getIdByCategoryName = (category) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestStoreData());
        
        const lang = getState().user.lang;
        category = category.charAt(0).toUpperCase() + category.slice(1);

        const searchString = category == "All" ? 'where={"and":[{"name_FR":["Restaurant","Commerce"]},{"parent":null}]}' : 'name_FR=' + category;
        axios.get(AppConfig.baseUrlApi + '/category?' + searchString)
            .then(response => {
                _dispatch(stopFetching());
                if(response.data.data.length == 0) return resolve('nothing');
                if(response.data.data.length == 1) return resolve(response.data.data[0].id);
                if(response.data.data.length > 1) return resolve(response.data.data.map(c => c.id));
            })
            .catch(error => {
                _dispatch(stopFetching());

                reject(error);
            });
    })
);

export const getStore = (id) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestStoreData());
        
        const lang = getState().user.lang;

        axios.get(AppConfig.baseUrlApi + '/store/' +id)
            .then(response => {
                return resolve(_dispatch(receiveStoreData(sanitizeStoreLang(response.data, lang))));
            })
            .catch(error => {
                _dispatch(receiveStoreData([]));
                reject(error);
            });
    })
);

export const getAvailableHours = (storeId, date) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {        
        axios.get(AppConfig.baseUrlApi + '/store/availableHours?storeId=' +storeId+'&date='+date)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                console.log('***** storeAction - getAvailableHours - axios', error);
                reject(error);
            });
    })
);

export const getCarouselImages = () => (
    (_dispatch, getState) => new Promise((resolve, reject) => {        
        axios.get(AppConfig.baseUrlApi + '/carousel')
            .then(response => {
                _dispatch(receiveCarouselData(response.data.data));
                resolve(response.data)
            })
            .catch(error => {
                console.log('***** storeAction - getCarousel - axios', error);
                reject(error);
            });
    })
);

const global = new Set();

export const sanitizeStoreLang = (store, lang) => {
    let masterCategory = store.categories.find( c => c.parent != null );

    if(lang === 'fr'){
        store.categoryName = masterCategory?.name_FR;
        store.parentCategoryName = masterCategory?.parentName_FR;
        store.keywords = store.keywords_FR;
    }
    else{
        store.categoryName = masterCategory?.name_EN;
        store.parentCategoryName = masterCategory?.parentName_EN;
        store.keywords = store.keywords_EN;
    }

    return store;
}

export const sanitizeCategoryLang = (category, lang) => {
    if(lang === 'fr'){
        category.name = category.name_FR;
        category.parentName = category.parentName_FR;
    }
    else{
        category.name = category.name_EN;
        category.parentName = category.parentName_EN;
    }

    return category;
}
