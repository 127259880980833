import React from "react";
import { useDropzone } from "react-dropzone";
import SVG from 'react-inlinesvg';
import { imagesSharp } from 'ionicons/icons';

const ChatDropzone = ({ onDrop, accept, label, multiple }) => {

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept,
        multiple
    });

    const overrideDropzoneStyle = {
        marginTop: "8px",
        marginBottom: "4px",
        backgroundColor: "rgba(0, 0, 0, 0.09)",
        borderBottom: "1px solid #929292",
        borderRadius: "4px 4px 0px 0px",
        padding: "15px",
        color: "rgba(0, 0, 0, 0.55)"
    };

    return (
        <div {...getRootProps()}>
            <SVG src={imagesSharp} alt="close" style={{
                position: 'absolute',
                color: 'white',
                width: 28,
                bottom: 9,
                right: 60,
                cursor: 'pointer',
                fill: '#342535',
            }}/>
            <input className="dropzone-input" {...getInputProps()} />
        </div>
    );
};

export default ChatDropzone;
