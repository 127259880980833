import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getStoresByCategory, getIdByCategoryName, getChildCategories, getCarouselImages } from "../../actions/storeAction";

class StoreCategoryPage extends Component {

    constructor(props){
        super(props);

        this.state = {
            childCategories: []
        }
    }

    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+this.props.title;

        let storeType = this.props.match ? this.props.match.params.category : this.props.route.params.category;

        this.props.getCarouselImages().then(response => {}).catch(e => {
            console.log("getCarouselImages error:",e);
        });

        if(storeType){
            this.props.getIdByCategoryName(storeType).then(response => {
                this.getStoresByCategory(response);
            });
        }
        else{
            let categoryId = this.props.match ? this.props.match.params.categoryId : this.props.route.params.categoryId;
            this.getStoresByCategory(categoryId);
        }
    }

    UNSAFE_componentWillUpdate(prevProps){
        const storeType = this.props.match ? this.props.match.params.category : this.props.route.params.category;
        const prevCategory = prevProps.match ? prevProps.match.params.category : prevProps.route.params.category;

        const categoryId = this.props.match ? this.props.match.params.categoryId : this.props.route.params.categoryId;
        const prevCategoryId = prevProps.match ? prevProps.match.params.categoryId : prevProps.route.params.categoryId

        if (storeType !== prevCategory) {
            this.props.getIdByCategoryName(prevCategory == 'all' ? "Commerce" : prevCategory).then(response => {
                this.getStoresByCategory(response);
            });
        }

        if (categoryId !== prevCategoryId) {
            this.getStoresByCategory(prevCategoryId);
        }
    }

    getStoresByCategory = (id) => {
        this.props.getStoresByCategory(typeof id === 'object' ? id : [id]).then(response => {
            this.props.getChildCategories(typeof id === 'object' ? id : [id]).then(response => {
                this.setState({ childCategories: response });
            }).catch( e => {
                console.log("e",e);
            })
        }).catch( e => {
            console.log("e",e);
        })
    }

    render() {
        const { Layout, stores, carouselImages, navigation, isFetching } = this.props;
        return (

            <Layout 
                isFetching={isFetching}
                stores={stores}
                carouselImages={carouselImages}
                category={this.props.match ? this.props.match.params.category : this.props.route.params.category}
                childCategories={this.state.childCategories}
                navigation={navigation}
                isService={this.props.isService}
                getStoresByCategory={this.getStoresByCategory.bind(this)}
            />
        );
    }
    
}

const mapStateToProps = (state) => ({
    isFetching: state.stores.isFetching || false,
    stores: state.stores.stores,
    carouselImages: state.stores.carouselImages,
});

const mapDispatchToProps = {
    getStoresByCategory,
    getIdByCategoryName,
    getChildCategories,
    getCarouselImages
};


export default connect(mapStateToProps, mapDispatchToProps)(StoreCategoryPage);
