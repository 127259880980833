import React from 'react';

import Loading from "../UI/Loading";

import { useToasts } from 'react-toast-notifications'

import { Container, Col, Row, Button, Form, FormText, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';


import i18n from '../../../translations/i18n';

const ChooseYourPlan = ({ form, handleUpdateFormInput, handleSendEmail, isLoading, toastMessage }) => {
    const { addToast } = useToasts();

    return(
    <div className="paddingContainerMobile">

        { isLoading ? <Loading /> : undefined }
        { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }


        <div id="partnerPage" className="qcommercepage chooseyourplan">

            <Container id="title-section">
                <img src={ "/images/plans/plan_header_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".png" } style={{ width: "100%" }} />

                < hr/>

                <div className="title-text-wrapper">
                    <div className="title-text">
                        <h1 className="bigTitle" style={{ textAlign: 'center', fontSize: "2.2em", marginBottom: "25px"}}>{ i18n.t("plan.info") }</h1>
                    </div>
                </div>

                <img src={ "/images/plans/plan_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".png" } style={{ width: "800px", display: "block", margin: "0 auto" }} />

                <h1 className="bigTitle" style={{ textAlign: 'center', fontSize: "2.2em", marginBottom: "25px"}}>{ i18n.t("plan.more") }</h1>

                <p>{ i18n.t("plan.detail") }</p>

            </Container>

            <Form className="accountForm" style={{padding: "5% 15%"}}>
                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="lastName">{i18n.t('global.lastName')}</Label>
                            <Input type="text" name="lastName" value={form.lastName || ''} onChange={e => handleUpdateFormInput(e)}></Input>
                        </Col>
                        <Col>
                            <Label className="accountLabel" htmlFor="firstName">{i18n.t('global.firstName')}</Label>
                            <Input type="text" name="firstName" value={form.firstName || ''} onChange={e => handleUpdateFormInput(e)}></Input>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="email">{i18n.t('global.email')}</Label>
                            <Input type="email" name="email" value={form.email || ''} onChange={e => handleUpdateFormInput(e)}></Input>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="tel">{i18n.t('global.phoneNumber')}</Label>
                            <Input type="phone" name="tel" value={form.tel || ''} onChange={e => handleUpdateFormInput(e)}></Input>
                        </Col>
                    </Row>
                </FormGroup>

               

                <FormGroup tag="fieldset">
                    <Label className="accountLabel" htmlFor="radio1">{i18n.t('plan.type_title')}</Label>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="domaine" value={i18n.t('plan.type_restaurant')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.type_restaurant')}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="domaine" value={i18n.t('plan.type_traiteur')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.type_traiteur')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="domaine" value={i18n.t('plan.type_proxi')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.type_proxi')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="domaine" value={i18n.t('plan.type_online')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.type_online')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="domaine" value={i18n.t('partner.other')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('partner.other')}                                
                        </Label>
                    </FormGroup>
                </FormGroup>

                <FormGroup tag="fieldset">
                    <Label className="accountLabel" htmlFor="radio1">{i18n.t('plan.delivery_title')}</Label>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="delivery" value={i18n.t('plan.delivery_express')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.delivery_express')}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="delivery" value={i18n.t('plan.delivery_4hours')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.delivery_4hours')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="delivery" value={i18n.t('plan.delivery_sameday')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.delivery_sameday')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="delivery" value={i18n.t('plan.delivery_ponctual')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.delivery_ponctual')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="delivery" value={i18n.t('partner.other')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('partner.other')}                                
                        </Label>
                    </FormGroup>
                </FormGroup>

                <FormGroup tag="fieldset">
                    <Label className="accountLabel" htmlFor="radio1">{i18n.t('plan.weight_title')}</Label>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="weight" value={i18n.t('plan.weight_less1kg')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.weight_less1kg')}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="weight" value={i18n.t('plan.weight_less5kg')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.weight_less5kg')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="weight" value={i18n.t('plan.weight_less15kg')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.weight_less15kg')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="weight" value={i18n.t('plan.weight_more15kg')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('plan.weight_more15kg')}                                
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="weight" value={i18n.t('partner.other')} onChange={e => handleUpdateFormInput(e)}/>{' '}
                            {i18n.t('partner.other')}                                
                        </Label>
                    </FormGroup>
                </FormGroup>

                {/* <FormGroup>
                    <Label className="accountLabel" htmlFor="conditions">{i18n.t('partner.conditions')}</Label>
                    <FormGroup check>
                        <Input type="checkbox" id="conditions" name="conditions" onChange={e => handleUpdateFormInput({ target: { name: 'conditions', value: e.target.checked}})}>{i18n.t('partner.accept_cond')}</Input>
                        <Label className="accountLabel form-text text-muted small" for="conditions">{i18n.t('partner.accept_cond')}</Label>

                    </FormGroup>
                </FormGroup> */}

                <Button className='primary' disabled={ !form || !form.domaine || !form.lastName || !form.firstName || !form.email || !form.tel} onClick={e => handleSendEmail(e)}>{i18n.t('recruit.submit')}</Button>
            </Form>

        </div>
    </div>
)}

export default ChooseYourPlan;