import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getTokenCard } from "../../actions/userAction";
import { loadMyCustomOrder, payCustomOrder } from "../../actions/orderAction";

import { formatCVC, formatCreditCardNumber, formatExpirationDate } from "../../utils/utils";
import i18n from '../../translations/i18n';

class CustomOrder extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            ...props.user.user,
            isOpenLogin : false,
            isOpenSignUp : false,
        }

        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+props.title;
    }

    UNSAFE_componentWillMount() {
        const orderId = this.props.match && this.props.match.params ? this.props.match.params.id : this.props.route && this.props.route.params && this.props.route.params.id ? this.props.route.params.id : undefined;
        if(orderId){
            this.props.loadMyCustomOrder(orderId).then(response => { 
                if(this.props.order.payed) {
                    this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.order_already_payed')}, redirect: true }, () => {
                        this.setState({ toastMessage: undefined, redirect: false });
                    });
                }
                else{
                    this.setState({ order: {...this.props.order, paymentCard: this.props.user.cards && this.props.user.cards.length === 0 ? 'other' : undefined }})
                }
            }).catch( err => {
                console.log('***** err', err);
                this.setState({ toastMessage: { type: 'error', msg: i18n.t('payment.orderNotFoundDesc')} }, () => {
                    this.setState({ toastMessage: undefined });
                });
            })
        }

    }

    handleUpdateOrderInput = (e) => {
        if (e.target.name === "otherPaymentCardNumber") e.target.value = formatCreditCardNumber(e.target.value);
        if (e.target.name === "otherPaymentCardExpiry") e.target.value = formatExpirationDate(e.target.value);
        if (e.target.name === "otherPaymentCardCVC") e.target.value = formatCVC(e.target.value);

        this.setState({ order: { ...this.state.order, [e.target.name] : e.target.value }});
    }

    handlePayOrder = async (e) => {
        if (e) e.preventDefault();

        let order = this.state.order;

        if(this.state.order.paymentCard && this.state.order.paymentCard !== 'other') order.stripeToken = this.state.order.paymentCard;
        else{
            let card = await this.props.getTokenCard({
                name: this.state.order.otherPaymentCardName || this.state.currentCard.name,
                number: this.state.order.otherPaymentCardNumber || this.state.currentCard.number,
                expiry: this.state.order.otherPaymentCardExpiry || this.state.currentCard.expiry,
                cvc: this.state.order.otherPaymentCardCVC || this.state.currentCard.cvc
            });
            order.stripeToken = card.data.id;
        }
        
        this.props.payCustomOrder(order)
        .then(response => {

            if(!e) this.props.navigation.navigate('ConfirmOrder');

            this.setState({ toastMessage: { type: 'success', msg: i18n.t('payment.payment_success')}, redirect: true }, () => {
                this.setState({ toastMessage: undefined, redirect: false });
            });
        })
        .catch(err => {
            if(err.data.message === 'Error.CustomOrder.deliveryDate.unvalid'){
                this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.invalid_delivery_date')} }, () => {
                    this.setState({ toastMessage: undefined });
                });
            }
            else{
                this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.payment_error')} }, () => {
                    this.setState({ toastMessage: undefined });
                });
            }
        })
    }

    handleUpdateCurrentCardNative = form => {
        this.setState({ currentCard: { ...this.state.currentCard, ...form.values }});
    }

    toggleSignUp = () => {
        this.setState({
            isOpenSignUp: !this.state.isOpenSignUp
        })
    }

    toggleLogin = () => {
        this.setState({
            isOpenLogin: !this.state.isOpenLogin
        })
    }

    render() {
        const { navigation, Layout, user, isLoading } = this.props;

        return (
            <Layout
                navigation={navigation}
                user={user.user}
                cards={user.cards}
                order={this.state.order}
                redirect={this.state.redirect}
                toastMessage={this.state.toastMessage}
                isLoading={isLoading}
                handleUpdateOrderInput={this.handleUpdateOrderInput.bind(this)}
                handlePayOrder={this.handlePayOrder.bind(this)}
                handleUpdateCurrentCardNative={this.handleUpdateCurrentCardNative.bind(this)}
                currentCard={this.state.currentCard}
                isOpenSignUp={this.state.isOpenSignUp}
                isOpenLogin={this.state.isOpenLogin}
                toggleSignUp={this.toggleSignUp}
                toggleLogin={this.toggleLogin}
            />

        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.user.isFetching || state.orders.isFetching,
    order: state.orders.order,
    user: state.user
});

const mapDispatchToProps = {
    loadMyCustomOrder,
    getTokenCard,
    payCustomOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomOrder);