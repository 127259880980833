import React from 'react';

import i18n from "../../../translations/i18n";

const ProgressBar = ({type, currentStep, changeStep}) => {
    if( type === "pickup") {
        return(
            <>
                <div className="progressBar">
                    <div className="stepName">
                        <div className="step">
                            {i18n.t('pickup.handling')}
                        </div>
                        <div className="step">
                            {i18n.t('pickup.delivery')}
                        </div>
                        {/* <div className="step">
                            {i18n.t('pickup.schedule')}
                        </div> */}
                        <div className="step">
                            {i18n.t('pickup.package')}
                        </div>
                        <div className="step">
                            {i18n.t('pickup.payment')}
                        </div>
                        {/* <div className="step">
                            {i18n.t('pickup.summary')}
                        </div> */}
                    </div>

                    <div className="circle">
                        <div className="progressAlignement"><div onClick={e => { if(currentStep > 1) changeStep(1) }} className={currentStep >= 1 ? 'activeCircle' : 'nonactiveCircle'}></div></div>
                        <div className="progressAlignement"><div onClick={e => { if(currentStep > 2) changeStep(2) }} className={currentStep >= 2 ? 'activeCircle' : 'nonactiveCircle'}></div></div>
                        <div className="progressAlignement"><div onClick={e => { if(currentStep > 3) changeStep(3) }} className={currentStep >= 3 ? 'activeCircle' : 'nonactiveCircle'}></div></div>
                        <div className="progressAlignement"><div onClick={e => { if(currentStep > 4) changeStep(4) }} className={currentStep >= 4 ? 'activeCircle' : 'nonactiveCircle'}></div></div>
                        {/* <div className="progressAlignement"><div onClick={e => { if(currentStep > 5) changeStep(5) }} className={currentStep >= 5 ? 'activeCircle' : 'nonactiveCircle'}></div></div> */}
                        {/* <div className="progressAlignement"><div onClick={e => { if(currentStep > 6) changeStep(6) }} className={currentStep >= 6 ? 'activeCircle' : 'nonactiveCircle'}></div></div>                        */}
                    </div>

                    <div className="trait">
                        <div className="progressAlignement"><div className={currentStep >= 2 ? 'activeTrait' : 'nonactiveTrait'}></div></div>
                        <div className="progressAlignement"><div className={currentStep >= 3 ? 'activeTrait' : 'nonactiveTrait'}></div></div>
                        <div className="progressAlignement"><div className={currentStep >= 4 ? 'activeTrait' : 'nonactiveTrait'}></div></div>
                        {/* <div className="progressAlignement"><div className={currentStep >= 5 ? 'activeTrait' : 'nonactiveTrait'}></div></div> */}
                        {/* <div className="progressAlignement"><div className={currentStep >= 6 ? 'activeTrait' : 'nonactiveTrait'}></div></div>                       */}
                    </div>
                </div>
            </>
        )
    } 

    if( type === "reservation") {
        return (
            <>
            <div className="progressBar">
                    <div className="stepName">
                        <div className="step">
                            {i18n.t('pickup.delivery')}
                        </div>
                        <div className="step">
                            {i18n.t('pickup.payment')}
                        </div>
                        {/* <div className="step">
                            {i18n.t('pickup.summary')}
                        </div> */}
                    </div>

                    <div className="circle">
                        <div className="progressAlignement"><div onClick={e => { if(currentStep > 1) changeStep(1) }} className={currentStep >= 1 ? 'activeCircle' : 'nonactiveCircle'}></div></div>
                        <div className="progressAlignement"><div onClick={e => { if(currentStep > 2) changeStep(2) }} className={currentStep >= 2 ? 'activeCircle' : 'nonactiveCircle'}></div></div>
                        {/* <div className="progressAlignement"><div onClick={e => { if(currentStep > 3) changeStep(3) }} className={currentStep >= 3 ? 'activeCircle' : 'nonactiveCircle'}></div></div>*/}
                    </div>

                    <div className="trait">
                        <div className="progressAlignement"><div className={currentStep >= 2 ? 'activeTrait' : 'nonactiveTrait'}></div></div>
                        {/* <div className="progressAlignement"><div className={currentStep >= 3 ? 'activeTrait' : 'nonactiveTrait'}></div></div>*/}
                    </div>
                </div>
            </>
        )
    }
};

export default ProgressBar;