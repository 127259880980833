import React from 'react';
import i18n from '../../../translations/i18n';
import { useLocation } from 'react-router-dom';
import { ReactComponent as WhiteLogo} from '../../../assets/images/genie_blanc.svg';

const MobileHome = ({}) => {
    const [mobileEnabled, setEnabled] = React.useState(' mobileEnabled');

    const location = useLocation();

    React.useEffect(() => {
        const enabled = !(
            location.pathname == '/purchase' || 
            location.pathname == '/merci' || 
            location.pathname.slice(0, 7) == '/forgot' || 
            location.pathname == '/recrutement' || 
            location.pathname == '/devenez-partenaire' ||
            location.pathname == '/qcommerce' ||
            location.pathname == '/choisissez-votre-plan' ||
            location.pathname == '/delivery-zone' ||
            location.pathname == '/creez-votre-store-en-ligne' ||
            location.pathname == '/confidentialite'
        );
        setEnabled(enabled ? ' mobileEnabled' : '');
    }, [location]);

    return (
        <div className={'mobileHome' + mobileEnabled}>
            <div className='mobile_header'>
                <WhiteLogo />
                <span style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    padding: '0 5%'
                }}>
                    <div className='mobile_title'>{i18n.t('mobile.heroTitle_1')}</div>
                    <div className='mobile_title'>&nbsp;{i18n.t('mobile.heroTitle_2')}</div>
                </span>
                <div className='mobile_title' style={{ 'marginBottom': '10px' }}>{i18n.t('mobile.heroTitle_3')}</div>
                <div className='mobile_description'>{i18n.t('mobile.heroDescription')}</div>
            </div>

            <div className='mobile_body'>
                <div className='mobile_section'>
                    <div className='mobile_title' style={{ 
                        display: 'inline-flex',
                        maxWidth: i18n.locale === 'en' ? '19ch' : '20ch',
                        marginBottom: '10px' 
                    }}>{i18n.t('mobile.downloadNowTitle')}</div>
                    <div className='mobile_description'>{i18n.t('mobile.downloadNowDescription_1')}</div>
                    <div className='mobile_row'>
                        <a className='mobile_link_container' href='https://apps.apple.com/ca/app/genie-mtl/id1545682977'>
                            <img className='mobile_link' src={"/images/mobile/download_apple_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".svg"} alt='mobile gif' />
                        </a>
                        <a className='mobile_link_container' href='https://play.google.com/store/apps/details?id=com.geniemtl.geniemtl'>
                            <img className='mobile_link' src={"/images/mobile/download_google_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".png"} alt='mobile gif' />
                        </a>
                    </div>
                </div>

                <div className='mobile_section' style={{
                    "color": "white",
                    "background": "rgb(96,70,106)",
                    "background": "linear-gradient(0deg, rgba(96,70,106,1) 0%, rgba(102,99,140,1) 50%, rgba(110,136,184,1) 100%)"
                }}>
                    <div className='mobile_title' style={{ 'marginBottom': '10px' }}>{i18n.t('mobile.sectionTitle_1')}</div>
                    <div className='mobile_description'>{i18n.t('mobile.sectionDescription_1_1')}</div>
                    <div className='mobile_description'>{i18n.t('mobile.sectionDescription_1_2')}</div>
                    <div className='mobile_row'>
                        <img className='mobile_link' src='/images/mobile/phone_full_accueil_fr.png' alt='mobile mockup' />
                        <img className='mobile_link' src='/images/mobile/phone_full_commerce_fr.png' alt='mobile commerce mockup' />
                    </div>
                </div>

                <div className='mobile_section' style={{ paddingBottom: "0px" }}>
                    <div className='mobile_title' style={{
                        display: 'inline-flex',
                        maxWidth: '24ch',
                        marginBottom: '10px' 
                    }}>{i18n.t('mobile.sectionTitle_2')}</div>
                    <div className='mobile_description'>{i18n.t('mobile.sectionDescription_2')}</div>
                    <img className='mobile_mockup' src='/images/mobile/phone_half_chat_fr.png' alt='mobile commerce mockup' />
                </div>

                <div className='mobile_section' style={{
                    "color": "white",
                    "paddingBottom": "0px",
                    "background": "rgb(96,70,106)",
                    "background": "linear-gradient(0deg, rgba(96,70,106,1) 0%, rgba(102,99,140,1) 50%, rgba(110,136,184,1) 100%)"
                }}>
                    <div className='mobile_title' style={{ 'marginBottom': '10px' }}>{i18n.t('mobile.sectionTitle_3')}</div>
                    <div className='mobile_description'>{i18n.t('mobile.sectionDescription_3')}</div>
                    <img className='mobile_mockup' src='/images/mobile/phone_half_services_fr.png' alt='mobile service mockup' />
                </div>

                <div className='mobile_section' style={{
                    "paddingBottom": "0px"
                }}>
                    <div className='mobile_title' style={{ 'marginBottom': '10px' }}>{i18n.t('mobile.sectionTitle_4')}</div>
                    <div className='mobile_description'>{i18n.t('mobile.sectionDescription_4_1')}</div>
                    <div className='mobile_description'>{i18n.t('mobile.sectionDescription_4_2')}</div>
                    <img className='mobile_mockup' src='/images/mobile/phone_half_cueillette_fr.png' alt='mobile pickup mockup' />
                </div>

                <div className='mobile_section' style={{
                    "color": "white",
                    "paddingBottom": "100px",
                    "background": "rgb(96,70,106)",
                    "background": "linear-gradient(0deg, rgba(96,70,106,1) 0%, rgba(102,99,140,1) 50%, rgba(110,136,184,1) 100%)"
                }}>
                    <div className='mobile_title' style={{ 
                        display: 'inline-flex',
                        maxWidth: i18n.locale === 'en' ? '19ch' : '20ch',
                        marginBottom: '10px' 
                    }}>{i18n.t('mobile.downloadNowTitle')}</div>
                    <div className='mobile_description'>{i18n.t('mobile.downloadNowDescription_2')}</div>
                    <div className='mobile_row'>
                        <a className='mobile_link_container' href='https://apps.apple.com/ca/app/genie-mtl/id1545682977'>
                            <img className='mobile_link' src={"/images/mobile/download_apple_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".svg"} alt='mobile gif' />
                        </a>
                        <a className='mobile_link_container' href='https://play.google.com/store/apps/details?id=com.geniemtl.geniemtl'>
                            <img className='mobile_link' src={"/images/mobile/download_google_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".png"} alt='mobile gif' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
)}

export default MobileHome;