import React, { Component } from 'react';
import { connect } from 'react-redux';

class Footer extends Component {

    render() {
        const { Layout, user } = this.props;
        
        return (

            <Layout
                user={user}
            />
        );
    }
}


const mapStateToProps = (state) => ({
    isLoading: state.user.isFetching,
    user: state.user
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);