import React from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import SVG from "react-inlinesvg";

import i18n from "../../../translations/i18n";

import { mail, cube } from "ionicons/icons";

const PickupStep4Component = ({
  pickup,
  currentStep,
  nextStep,
  prevStep,
  handleUpdatePickupInput,
}) => (
  <>
    <FormGroup>
      <Label>
        <h5 className="contact">
          <img src="/images/menu/icon-location.svg" alt="icon location" />{" "}
          {i18n.t("pickup.packageFormat")}
        </h5>
      </Label>

      <div className="formatCheck">
        <Label className="formatTitle">{i18n.t("pickup.chooseFormat")}</Label>
        <div className="format">
          <Label className="form-check">
            <Input
              type="radio"
              name="packFormat"
              style={{ display: "none" }}
              onChange={(e) =>
                handleUpdatePickupInput({
                  target: { name: "packFormat", value: "standard" },
                })
              }
            ></Input>
            <SVG
              src={mail}
              width="50"
              className={pickup.packFormat === "standard" ? "fillPrimary" : ""}
              style={{ display: "block", cursor: "pointer" }}
            />
            {i18n.t("pickup.letter")}
          </Label>
          <Label className="form-check">
            <Input
              type="radio"
              name="packFormat"
              style={{ display: "none" }}
              onChange={(e) =>
                handleUpdatePickupInput({
                  target: { name: "packFormat", value: "package" },
                })
              }
            ></Input>
            <SVG
              src={cube}
              width="50"
              className={pickup.packFormat === "package" ? "fillPrimary" : ""}
              style={{ display: "block", cursor: "pointer" }}
            />
            {i18n.t("pickup.medium")}
          </Label>
        </div>
      </div>
    </FormGroup>

    {pickup.packFormat === "package" ? (
      <FormGroup>
        <Label>
          <h5 className="contact">
            <img src="/images/menu/icon-location.svg" alt="icon location" />{" "}
            {i18n.t("pickup.packageSize")}
          </h5>
        </Label>

        <div>
          <Label>{i18n.t("pickup.weight")}</Label>
          <Row className="pickupRow">
            <Col xs="8">
              <Input
                type="text"
                placeholder={i18n.t("pickup.weight")}
                value={pickup.packWeight}
                name="packWeight"
                onChange={(e) => handleUpdatePickupInput(e)}
              ></Input>
              <span className="red_ast">*</span>
            </Col>
            <Col xs="2">
              {/* <Input type="select" value={pickup.select} name="select-weight" id="selectWeight" onChange={ e => handleUpdatePickupInput(e) }>
                                <option>Kg</option>
                                <option>Lb</option>
                            </Input> */}
            </Col>
          </Row>
          <Label>{i18n.t("pickup.dimensions")}</Label>
          <Row className="pickupRow">
            <Col>
              <Input
                type="text"
                placeholder={i18n.t("pickup.lengthInCm")}
                value={pickup.packLength}
                name="packLength"
                onChange={(e) => handleUpdatePickupInput(e)}
              ></Input>
              <span className="red_ast">*</span>
            </Col>
            <Col>
              <Input
                type="text"
                placeholder={i18n.t("pickup.widthInCm")}
                value={pickup.packWidth}
                name="packWidth"
                onChange={(e) => handleUpdatePickupInput(e)}
              ></Input>
              <span className="red_ast">*</span>
            </Col>
            <Col>
              <Input
                type="text"
                placeholder={i18n.t("pickup.heightInCm")}
                value={pickup.packHeight}
                name="packHeight"
                onChange={(e) => handleUpdatePickupInput(e)}
              ></Input>
              <span className="red_ast">*</span>
            </Col>
          </Row>
        </div>
      </FormGroup>
    ) : undefined}

    <FormGroup className="pickupButton">
      <Button className="prevButton" onClick={prevStep}>
        {i18n.t("pickup.returnToSchedule")}
      </Button>

      <Button
        disabled={
          !pickup.packFormat ||
          (pickup.packFormat === "package"
            ? !pickup.packWeight ||
              !pickup.packLength ||
              !pickup.packWidth ||
              !pickup.packHeight
            : false)
        }
        className="nextButton"
        onClick={(e) => nextStep(false)}
      >
        {i18n.t("global.continue")}
      </Button>
    </FormGroup>
  </>
);

export default PickupStep4Component;
