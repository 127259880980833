import {
    RECEIVE_USER_DATA,
    REQUEST_USER_DATA,
    RECEIVE_USER_CARD_DATA,
    REGISTER_OK,
    RECEIVE_INTERCEPTOR_DATA,
    ONBOARDING_DONE,
    LOG_OUT,
    USER_TOGGLE_LANG
} from '../actions/userAction';

import Store from '../store/userStore';

import i18n from '../translations/i18n';

export const initialState = Store;

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_USER_DATA:
            return {
                ...state,
                isFetching: true,
            };
        case REGISTER_OK:
            return {
                ...state,
                isFetching: false,
            };
        case RECEIVE_USER_DATA:
            return {
                ...state,
                isFetching: false,
                user: action.payload,
            };
        case RECEIVE_USER_CARD_DATA:
            return {
                ...state,
                isFetching: false,
                cards: action.payload,
            };
        case RECEIVE_INTERCEPTOR_DATA:
            return {
                ...state,
                interceptorId: action.payload,
            }
        case LOG_OUT:
            return initialState;
        case USER_TOGGLE_LANG:
            i18n.locale = state.lang === 'fr' ? 'en' : 'fr';
            return {
                ...state,
                isFetching: false,
                lang: state.lang === 'fr' ? 'en' : 'fr'
            }
        default:
            return state;
    }
}
