import axios from "axios";
import qs from "querystring";
import AppConfig from "../config/config";

export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const REQUEST_USER_DATA = "REQUEST_USER_DATA";
export const RECEIVE_USER_DATA = "RECEIVE_USER_DATA";
export const RECEIVE_USER_CARD_DATA = "RECEIVE_USER_CARD_DATA";
export const RECEIVE_INTERCEPTOR_DATA = "RECEIVE_INTERCEPTOR_DATA";
export const REGISTER_OK = "REGISTER_OK";
export const USER_TOGGLE_LANG = "USER_TOGGLE_LANG";

export const requestUserData = () => ({
  type: REQUEST_USER_DATA,
});

export const receiveUserData = (data) => ({
  type: RECEIVE_USER_DATA,
  payload: data,
});

export const receiveInterceptorId = (data) => ({
  type: RECEIVE_INTERCEPTOR_DATA,
  payload: data,
});

export const receiveCardData = (data) => ({
  type: RECEIVE_USER_CARD_DATA,
  payload: data,
});

export const toggleLang = () => ({
  type: USER_TOGGLE_LANG,
});

export const logIn = () => ({
  type: LOG_IN,
});

export const logOut = () => ({
  type: LOG_OUT,
});

export const registered = () => ({
  type: REGISTER_OK,
});

export const isConnected = () => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    const token = getState().user.user.token;
    if (!token) {
      return reject();
    }

    const interceptorId = axios.interceptors.request.use((config) => {
      config.headers.Authorization = `JWT ${token}`;
      return config;
    });

    axios
      .get(AppConfig.baseUrlApi + "/user/token")
      .then((response) => {
        if (!response.error) {
          _dispatch(receiveInterceptorId(interceptorId));
          _dispatch(
            receiveUserData({
              fail: false,
              message: "",
              ...response.data,
              token,
            })
          );
          _dispatch(getUserCards());
          return resolve();
        } else {
          _dispatch(logout());
          return reject();
        }
      })
      .catch((error) => {
        _dispatch(logout());
        reject();
      });
  });

export const login = (email, password) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(requestUserData());

    axios
      .post(AppConfig.baseUrlApi + "/auth/local", {
        email: email.trim(),
        password,
      })
      .then((response) => {
        if (!response.data.error) {
          if (response.data.user.isProfessional || response.data.user.isAdmin) {
            _dispatch(
              receiveUserData({
                fail: true,
                message: {},
              })
            );
            return reject({ message: "ONLY STANDARD USER CAN CONNECT" });
          }
          const interceptorId = axios.interceptors.request.use((config) => {
            config.headers.Authorization = `JWT ${response.data.token}`;
            config.headers.Cookie = undefined;
            return config;
          });

          _dispatch(receiveInterceptorId(interceptorId));
          _dispatch(
            receiveUserData({
              fail: false,
              message: "",
              ...response.data.user,
              token: response.data.token,
            })
          );
          _dispatch(getUserCards());
          return resolve();
        } else {
          _dispatch(
            receiveUserData({
              fail: true,
              message: response.data,
            })
          );
          reject({ message: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        _dispatch(
          receiveUserData({
            fail: true,
            message: error.response ? error.response.data : {},
          })
        );
        reject({ message: error.response ? error.response.data : {} });
      });
  });

export const loginJWT = (jwt, user) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(requestUserData());

    const interceptorId = axios.interceptors.request.use((config) => {
      config.headers.Authorization = `JWT ${jwt}`;
      config.headers.Cookie = undefined;
      return config;
    });

    _dispatch(receiveInterceptorId(interceptorId));
    _dispatch(
      receiveUserData({
        fail: false,
        message: "",
        ...user,
        token: jwt,
      })
    );
    _dispatch(getUserCards());
    return resolve();
  });

export const loginFacebook = (code) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios
      .get(AppConfig.baseUrlApi + "/auth/facebook/callback?code=" + code, {})
      .then((response) => {
        if (!response.data.error) {
          if (response.data.user.isProfessional || response.data.user.isAdmin) {
            _dispatch(
              receiveUserData({
                fail: true,
                message: {},
              })
            );
            return reject({ message: "ONLY STANDARD USER CAN CONNECT" });
          }

          const interceptorId = axios.interceptors.request.use((config) => {
            config.headers.Authorization = `JWT ${response.data.token}`;
            config.headers.Cookie = undefined;
            return config;
          });

          _dispatch(receiveInterceptorId(interceptorId));
          _dispatch(
            receiveUserData({
              fail: false,
              message: "",
              ...response.data.user,
              token: response.data.token,
            })
          );
          _dispatch(getUserCards());
          return resolve();
        } else {
          _dispatch(
            receiveUserData({
              fail: true,
              message: response.data.message,
            })
          );
          reject({ message: response.data.message });
        }
      })
      .catch((error) => {
        _dispatch(
          receiveUserData({
            fail: true,
            message: error.response
              ? error.response.data.message
              : "Une erreur inconnu est survenue",
          })
        );
        reject({
          message: error.response
            ? error.response.data.message
            : "Une erreur inconnu est survenue",
        });
      });
  });

export const loginFacebookToken = (access_token) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        AppConfig.baseUrlApi +
          "/auth/facebook/token?access_token=" +
          access_token
      )
      .then((response) => {
        if (!response.data.error) {
          const interceptorId = axios.interceptors.request.use((config) => {
            config.headers.Authorization = `JWT ${response.data.token}`;
            config.headers.Cookie = undefined;
            return config;
          });

          _dispatch(receiveInterceptorId(interceptorId));
          _dispatch(
            receiveUserData({
              fail: false,
              message: "",
              ...response.data.user,
              token: response.data.token,
            })
          );
          _dispatch(getUserCards());
          return resolve();
        } else {
          _dispatch(
            receiveUserData({
              fail: true,
              message: response.data.message,
            })
          );
          reject({ message: response.data.message });
        }
      })
      .catch((error) => {
        _dispatch(
          receiveUserData({
            fail: true,
            message: error.response
              ? error.response.data.message
              : "Une erreur inconnu est survenue",
          })
        );
        reject({
          message: error.response
            ? error.response.data.message
            : "Une erreur inconnu est survenue",
        });
      });
  });

export const loginFacebookLimited = (profile) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios
      .post(AppConfig.baseUrlApi + "/auth/facebook/limited", profile)
      .then((response) => {
        if (!response.data.error) {
          const interceptorId = axios.interceptors.request.use((config) => {
            config.headers.Authorization = `JWT ${response.data.token}`;
            config.headers.Cookie = undefined;
            return config;
          });

          _dispatch(receiveInterceptorId(interceptorId));
          _dispatch(
            receiveUserData({
              fail: false,
              message: "",
              ...response.data.user,
              token: response.data.token,
            })
          );
          _dispatch(getUserCards());
          return resolve();
        } else {
          _dispatch(
            receiveUserData({
              fail: true,
              message: response.data.message,
            })
          );
          reject({ message: response.data.message });
        }
      })
      .catch((error) => {
        _dispatch(
          receiveUserData({
            fail: true,
            message: error.response
              ? error.response.data.message
              : "Une erreur inconnu est survenue",
          })
        );
        reject({
          message: error.response
            ? error.response.data.message
            : "Une erreur inconnu est survenue",
        });
      });
  });

export const loginUserApple = (token) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(requestUserData());

    axios
      .get(
        AppConfig.baseUrlApi +
          "/auth/apple?access_token=" +
          token.identityToken +
          "&appleId=" +
          token.user +
          ""
      )
      .then((response) => {
        if (!response.data.error) {
          if (response.data.user.isProfessional || response.data.user.isAdmin) {
            _dispatch(
              receiveUserData({
                fail: true,
                message: {},
              })
            );
            return reject({ message: "ONLY STANDARD USER CAN CONNECT" });
          }

          const interceptorId = axios.interceptors.request.use((config) => {
            config.headers.Authorization = `JWT ${response.data.token}`;
            config.headers.Cookie = undefined;
            return config;
          });

          _dispatch(receiveInterceptorId(interceptorId));
          _dispatch(
            receiveUserData({
              fail: false,
              message: "",
              ...response.data.user,
              token: response.data.token,
            })
          );
          _dispatch(getUserCards());
          return resolve();
        } else {
          _dispatch(
            receiveUserData({
              fail: true,
              message: response.data.message,
            })
          );
          reject({ message: response.data.message });
        }
      })
      .catch((error) => {
        _dispatch(
          receiveUserData({
            fail: true,
            message: error.response
              ? error.response.data.message
              : "Une erreur inconnu est survenue",
          })
        );
        reject({
          message: error.response
            ? error.response.data.message
            : "Une erreur inconnu est survenue",
        });
      });
  });

export const forgot = (email) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios
      .post(AppConfig.baseUrlApi + "/user/resetPassword", { email: email })
      .then((response) => {
        if (!response.data.error) {
          return resolve();
        } else {
          reject({ message: response.data.message });
        }
      })
      .catch((error) => {
        reject({
          message: error.response
            ? error.response.data
            : "Une erreur inconnu est survenue",
        });
      });
  });

export const emailValidation = (code) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios
      .post(AppConfig.baseUrlApi + "/user/validEmail", { code: code })
      .then((response) => {
        if (!response.data.error) {
          return resolve();
        } else {
          reject({ message: response.data.message });
        }
      })
      .catch((error) => {
        reject({
          message: error.response
            ? error.response.data
            : "Une erreur inconnu est survenue",
        });
      });
  });

export const resetPassword = (newPassword, token) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios
      .post(AppConfig.baseUrlApi + "/user/validResetPassword", {
        code: token,
        password: newPassword,
      })
      .then((response) => {
        if (!response.data.error) {
          return resolve();
        } else {
          reject({ message: response.data.message });
        }
      })
      .catch((error) => {
        reject({
          message: error.response
            ? error.response.data.message
            : "Une erreur inconnu est survenue",
        });
      });
  });

export const logout = () => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios.interceptors.request.eject(getState().user.interceptorId);
    _dispatch(logOut());
    resolve();
  });

export const updateUser = (user) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(requestUserData());
    let userToUpdate = { ...user };

    //fields to omit
    delete userToUpdate.token;
    delete userToUpdate.fail;
    delete userToUpdate.message;
    delete userToUpdate.cards;
    delete userToUpdate.currentCard;
    delete userToUpdate.isOpenLogin;
    delete userToUpdate.isOpenSignUp;

    //fields to omit for dispatch
    delete user.fail;
    delete user.message;

    axios
      .patch(AppConfig.baseUrlApi + "/user/" + userToUpdate.id, userToUpdate)
      .then((response) => {
        _dispatch(receiveUserData({ token: user.token, ...response.data }));
        return resolve();
      })
      .catch((err) => {
        _dispatch(receiveUserData({}));

        console.log("***** userAction - updateUser", err);
        return reject();
      });
  });

export const updateConfirmationCode = (user) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(requestUserData());

    axios
      .patch(AppConfig.baseUrlApi + "/user/updateConfirmationCode", user)
      .then((response) => {
        _dispatch(receiveUserData(response.data));
        return resolve();
      })
      .catch((err) => {
        _dispatch(receiveUserData({}));

        console.log(
          "***** userAction - updateConfirmationCode",
          err.response.data
        );
        return reject();
      });
  });

export const createUser = (user) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(requestUserData());
    delete user.confirmPassword;

    axios
      .post(AppConfig.baseUrlApi + "/user", user)
      .then((response) => {
        // _dispatch(registered());
        _dispatch(receiveUserData(response.data));
        resolve();
      })
      .catch((err) => {
        _dispatch(logOut());
        console.log("***** userAction - createUser", err);
        return reject(err.response.data);
      });
  });

export const deleteUser = () => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(requestUserData());
    const user = getState().user.user;

    axios
      .delete(AppConfig.baseUrlApi + `/user/delete?id=${user.id}`)
      .then((response) => {
        resolve(response.data.message);
      })
      .catch((err) => {
        console.log("***** userAction - deleteUser", err.response.data);
        return reject(err.response.data);
      });
  });

export const createUserCard = (card) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(requestUserData());
    const instance = axios.create();
    instance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${AppConfig.stripePublicKey}`;
      return config;
    });

    instance
      .post(
        "https://api.stripe.com/v1/tokens",
        qs.stringify({
          "card[name]": card.name,
          "card[number]": card.number,
          "card[exp_month]": card.expiry.split("/")[0],
          "card[exp_year]": card.expiry.split("/")[1],
          "card[cvc]": card.cvc,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.id) {
          axios
            .post(AppConfig.baseUrlApi + "/card", { cardId: response.data.id })
            .then((response) => {
              if (!response.data.error) {
                resolve(_dispatch(getUserCards()));
              } else {
                reject(_dispatch(getUserCards()));
              }
            })
            .catch((err) => {
              reject(err);
              _dispatch(getUserCards());
            });
        }
      })
      .catch((err) => {
        _dispatch(getUserCards());
        reject(err);
      });
  });

export const removeUserCard = (cardId) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(requestUserData());
    axios
      .delete(AppConfig.baseUrlApi + "/card/" + cardId)
      .then((response) => {
        if (!response.data.error) {
          _dispatch(getUserCards());
          resolve();
        } else {
          _dispatch(receiveCardData());
          reject(response.data.error);
        }
      })
      .catch((err) => {
        _dispatch(receiveCardData());
        reject(err);
      });
  });

export const getUserCards = () => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(requestUserData());

    // const interceptorId = axios.interceptors.request.use((config) => {
    //     return config;
    //   });

    axios
      .get(AppConfig.baseUrlApi + "/card")
      .then((response) => {
        if (!response.data.error) {
          _dispatch(receiveCardData(response.data.data));
          resolve();
        }
      })
      .catch((err) => {
        _dispatch(receiveCardData());
        reject(err);
      });
  });

export const getTokenCard = (card) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    const instance = axios.create();
    instance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${AppConfig.stripePublicKey}`;
      return config;
    });
    instance
      .post(
        "https://api.stripe.com/v1/tokens",
        qs.stringify({
          "card[name]": card.name,
          "card[number]": card.number,
          "card[exp_month]": card.expiry.split("/")[0],
          "card[exp_year]": card.expiry.split("/")[1],
          "card[cvc]": card.cvc,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        return reject(err);
      });
  });

export const checkAppleId = (credentials) => async (_dispatch, getState) => {
  try {
    const response = await axios.get(
      AppConfig.baseUrlApi + "/user/appleUser?appleId=" + credentials.user
    );

    if (!response.data.error) {
      if (!response.data.id) {
        // No user with this id
        if (
          !credentials.email ||
          !credentials.fullName ||
          !credentials.fullName.familyName ||
          !credentials.fullName.givenName
        ) {
          return { err: false, status: "ALREADY_USED" };
        } else {
          const emailResponse = await axios.get(
            AppConfig.baseUrlApi +
              `/user/returnEmail?email=${credentials.email}`
          );

          if (emailResponse.data) {
            const responseMessage = await axios.patch(
              AppConfig.baseUrlApi +
                `/user/updateAppleId?email=${credentials.email}&appleId=${credentials.user}`
            );
            _dispatch(loginUserApple(credentials));
            return { err: false, status: "ALREADY_CREATED" };
          } else {
            await _dispatch(
              createUser({
                appleId: credentials.user,
                isActive: true,
                email: credentials.email,
                lastName: credentials.fullName.familyName,
                firstName: credentials.fullName.givenName,
              })
            );
            await _dispatch(loginUserApple(credentials));
            return { err: false, status: "ALREADY_CREATED" };
          }
        }
      } else if (!response.data.isActive) {
        return { err: false, status: "NOT_ACTIVATE" };
      } else {
        _dispatch(loginUserApple(credentials));
        return { err: false, status: "ALREADY_CREATED" };
      }
    }
  } catch (err) {
    console.log("***** err", err);
    throw err;
  }
};

export const sendEmail = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post(AppConfig.baseUrlApi + "/sendgrid/sendMail", body)
      .then((response) => {
        if (!response.data.error) {
          resolve();
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

export const setToggleLang = () => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    const user = getState().user.user;
    if (Object.entries(user).length) {
      _dispatch(requestUserData());

      const previousLang = getState().user.lang;
      const newLang = previousLang === "fr" ? "en" : "fr";
      _dispatch(updateUser({ languagePreference: newLang, ...user }));
    }
    return resolve(_dispatch(toggleLang()));
  });

export const getLastOrderToComment = () => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        AppConfig.baseUrlApi +
          '/order?where={"customer": "' +
          getState().user.user.id +
          '", "commentStatus":"toSuggest"}',
        { headers: { Authorization: "JWT " + getState().user.user.token } }
      )
      .then((response) => {
        if (!response.data.error) {
          return resolve(response.data.data[0]);
        } else {
          return reject(response.data.error);
        }
      })
      .catch((err) => {
        console.log("***** err", err.response);
        reject(err);
      });
  });
