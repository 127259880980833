import i18n from "../translations/i18n";

export const generalMessages = {};

export const successMessages = {};

export const errorMessagesLogin = {
    email: {
        empty: i18n.t('error_messages.missing_email')
    },
    password: {
        empty: i18n.t('error_messages.missing_password'),
    },
    unknownCredentials: i18n.t('error_messages.invalid_credentials')
};
export const updateUserData = {
    email: {}
}
export const errorMessagesRegister = {
    email: {
        empty: i18n.t('error_messages.missing_email'),
        isEmail: i18n.t('error_messages.invalid_email')
    },
    password: {
        empty: i18n.t('error_messages.missing_password'),
        format: i18n.t('error_messages.invalid_password_format'),
    },
    confirmPassword: {
        empty: i18n.t('error_messages.missing_password_confirm'),
        match: i18n.t('error_messages.invalid_password_match'),
    },
    firstName: {
        empty: i18n.t('error_messages.missing_first_name'),
    },
    lastName: {
        empty: i18n.t('error_messages.missing_last_name'),
    },
    phone: {
        empty: i18n.t('error_messages.missing_phone_number'),
        valid: i18n.t('error_messages.invalid_phone_number')
    },
};

export const errorMessagesUpdate = {
    bankDetails: {
        empty: 'Veuillez entrer vos informations bancaires',
    },
    street: {
        empty: 'Veuillez entrer une rue',
    },
    city: {
        empty: 'Veuillez entrer une ville',
    },
    postalCode: {
        empty: 'Veuillez entrer un code postal',
    },
    tel: {
        empty: 'Veuillez entrer un numéro de téléphone',
        numeric: 'Veuillez entrer un numéro de téléphone valide'
    },
    email: {
        empty: 'Veuillez entrer une adresse e-mail',
    }
}

export const errorPasswordUpdate = {
    passwordConfirmation: {
        empty: 'Veuillez confirmer le nouveau mot de passe',
    },
    actualPassword: {
        empty: 'Veuillez entrer votre mot de passe actuel',
        wrong: 'Votre mot de passe actuel est erroné',
    },
    password: {
        empty: 'Veuillez entrer un nouveau mot de passe',
        format: 'Le mot de passe doit contenir entre 8 et 20 caractères, avec une majuscule, une minuscule, un chiffre et un caractère spécial.',
    },
}

export const errorMessagesForgot = {
    unavailableLink: 'Ce lien n\'est plus disponible',
    unknownEmail: 'Cet email n\'est associé à aucun compte',
    email: {
        empty: 'Veuillez entrer une adresse courriel'
    }
}

export const changePhoneNumber = {
    inputPhoneChange: {
        empty: 'Veuillez entrer un numéro téléphone',
        numeric: 'Veuillez entrer un numéro de téléphone valide'
    }
}