import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateQuantityProduct, addProductCart } from "../../actions/cartAction";

class Cart extends Component {

    constructor(props){
        super(props);

        this.state = {
            cart: this.props.cart
        };
    }

    handleUpdateQuantity = (index, quantity) => {
        this.props.updateQuantityProduct(index, quantity).then(response => {
            this.setState({ cart : response.payload })
        })
    }

    render() {
        const { Layout, isSticky, carts, deliveryFee, voucher, showAll, ordering, tips, isServiceWithRate, route, navigation } = this.props;

        return (
            <Layout 
                navigation={navigation}
                tips={tips}
                store={route ? route.params.store : this.props.store}
                cart={carts.cart}
                isSticky={isSticky}
                voucher={voucher}
                deliveryFee={deliveryFee ? deliveryFee : 0}
                showAll={showAll}
                handleUpdateQuantity={this.handleUpdateQuantity.bind(this)}
                ordering={ordering}
                isServiceWithRate={isServiceWithRate}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    carts: state.carts
});

const mapDispatchToProps = {
    updateQuantityProduct,
    addProductCart,

};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
