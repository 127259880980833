import React, { Component } from 'react';

import { connect } from 'react-redux';

import { searchAll } from "../../actions/searchAction";

class SearchPage extends Component {

    constructor(props){
        super(props);

        this.state = {
            searchedProducts: [],
            searchedStores: [],
            searchedServices: []
        }

        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+props.title;

    }

    UNSAFE_componentWillMount() {
        const keyword = decodeURIComponent(this.props.match ? this.props.match.params.keyword : this.props.route.params.keyword);
        this.search(keyword);
    }

    UNSAFE_componentWillUpdate(prevProps){
        const keyword = decodeURIComponent(this.props.match ? this.props.match.params.keyword : this.props.route.params.keyword);
        const prevKeyword = decodeURIComponent(prevProps.match ? prevProps.match.params.keyword : prevProps.route.params.keyword);

        if (keyword !== prevKeyword) {
            this.search(prevKeyword);
        }
    }

    search = keyword => {
        this.props.searchAll(keyword).then( response => {
        }).catch( e => {
            console.error("e",e);
        })
    }
    
    render() {
        const { Layout, searchedProducts, searchedStores, searchedServices, isLoading, navigation} = this.props;
        const keyword = this.props.match ? this.props.match.params.keyword : this.props.route.params.keyword;

        return (

            <Layout 
                keyword={keyword}
                isLoading={isLoading}
                searchedProducts={searchedProducts}
                searchedStores={searchedStores}
                searchedServices={searchedServices}
                navigation={navigation}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    searchedProducts: state.products.searchedProducts,
    searchedStores: state.stores.searchedStores,
    searchedServices: state.stores.searchedServices,
    isLoading: state.stores.isFetching || state.products.isFetching
});

const mapDispatchToProps = {
    searchAll
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
