import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Col, Row, Button, Form, FormText, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';


import i18n from '../../../translations/i18n';

const QCommerce = ({ }) => {
    
    return(
    <div className="paddingContainerMobile">
        <div id="partnerPage" className="qcommercepage">

            <Container id="title-section">

                <div className="title-text-wrapper">
                    <div className="title-text">
                        <h1 className="bigTitle">Genie is revolutionizing the world of delivery with its Quick-Commerce formula (Ultra-Fast Delivery).</h1>
                    </div>
                </div>

                <div id="articleHeader">
                    <p>Article written by:</p>
                    <p>Simo Filali</p>
                    <p>Director of Communications, Genie Mtl.</p>
                </div>

                <p><strong>An innovative concept ...</strong></p>

                <p>We define Quick-Commerce (Q-commerce) as the next generation of online commerce practiced by Genie since 2017. This method has revolutionized traditional electronic commerce (e-commerce), notably by allowing faster delivery times.</p>

                <p>To put you in context, when e-commerce first appeared, standard delivery times (SDT) were around 3 to 5 business days. Thus, when a person placed an order, even from a local store, they had to wait for these delays in order to receive their packages.</p>

                <p>Genie took advantage of this situation, and in 2017, created its order management platform allowing it to serve its customers the same day. Thus, the concept of Q-commerce was born and allowed Genie's customers to benefit from faster and more personalized SDTs.</p>

                <p>Genie delivers housewares, errands, dishes from favorite restaurants, and whatever else customers want and need all day. Thus, this revolutionary method of delivery allows consumers to benefit from a feeling of closeness to their local merchants. On the other hand, merchants can rely on a delivery partner who delivers the ordered products instantly, conveniently and quickly.</p>

                <p>Our CEO and co-founder, Olivier Hudon, mentioned:</p>

                <p className="citation">“Q-Commerce is the future of convenience stores. COVID-19 has unfortunately taught us about the flaws in traditional delivery systems. At Genie we have set up a tool to instantly receive what customers want. We believe that this system will revolutionize the way we consume, since it is an optimization of existing procedures . We are happy to share this new way of doing things with our partners and customers”.  </p>
                <p className="author">Olivier Hudon, CEO and co-founder.</p>

                <p><strong>Q-Commerce in our daily life.</strong></p>
                <p>Today, and after many years in business, Genie continues to partner with hundreds of local businesses to offer its customers a wide range of products that can be delivered the same. Among these partners we find convenience stores, restaurants, grocery stores, online stores, pharmacies, florists, etc.</p>
                <p>Early on, Genie recognized the need to deliver products other than restaurant food. With a large panel of choices, customers at Genie's Market Place can have everything delivered the same day. After building its customer base and social media, Genie has decided to launch its transactional website and apps in 2020. Today, Genie delivers to thousands of users per month!</p>

                <p><strong>How does it work?</strong></p>
                <p>Our reputation has been built on the success of our logistics model. Thanks to an optimal targeting of our partners, a meticulous choice of our personal assistants, and state-of-the-art route optimization technology, we are now able to guarantee unbeatable delivery times in the Montreal region.</p>
                <p>For the customer, our choice of partners allows them to benefit from fresh and local products, at store prices. With a varied offer, ultra-fast delivery times, safe delivery, and reliable technology, we are able to meet the customers’ needs.</p>
                <p>Our teams work together with partners in order to promote exclusive products and sellers, in particular through data and optimized stock management. Our sales teams offer advice and best practices allowing any partner to master the tools that make it successful online.</p>

                <p><strong>Always on the lookout for innovation.</strong></p>
                <p>Genie never stops reinventing itself and revolutionizing its way of doing things. Lifestyles change, new needs are born, and Genie adapts. Today more than ever, businesses have to digitize and face a new type of demand. The process of e-commerce as we knew it before is called into question. The watchwords since the start of the pandemic have been: Speed and Flexibility. Q-Commerce therefore becomes the natural continuity of its predecessor. Genie is there to accompany you there.</p>

                <p>For more information on this subject, we invite you to contact info@geniemtl.com</p>
                <Link to="/devenez-partenaire"><Button className="primary uppercase">Become a partener</Button></Link>
            </Container>

        </div>
    </div>
)}

export default QCommerce;