import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import { logout, getLastOrderToComment } from "../../actions/userAction";
class TopBar extends Component {
    constructor(props){
        super(props);

        this.state = {
            isOpenSignUp : false,
            isOpenLogin : false,
            isOpenAccountActivation: false,
            orderToReview : undefined,
            search: null
        }
    }

    componentDidMount = e => {
        if(this.props.user?.user?.token){
            this.props.getLastOrderToComment().then(response => {
                this.setState({ orderToReview: response })
            }).catch( err => {
                console.log('ERROR - TopBar - componentDidMount - getLastOrderToComment', err);
            })
        }
    }

    handleLogout = e => {
        e.preventDefault();
        
        this.props.logout().then(response => {
        }).catch(error => {
            console.log('***** TopBar - handleLogout', error);
        });
    }

    handleChangeInput = e => {
        this.setState({ [e.target.name] : e.target.value });
    }

    handleChangeInputDropdown = (value, action) => {
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            this.setState({ search : value });
        }
    }

    handleSubmitSearch = e => {
        if (e) {
            e.preventDefault();
        }

        if(!this.state.search || this.state.search.trim() === ''){
            // TODO: Add error message search can't be null
            return;
        }
        
        this.setState({ search: '' })
        this.props.history.push('/search/'+encodeURIComponent(this.state.search));
    }

    handleSelectProduct = store_id => {
        this.setState({ search: '' });
        this.props.history.push('/store/'+ store_id);
    }

    toggleSignUp = () => {
        this.setState({
            isOpenSignUp: !this.state.isOpenSignUp
        })
    }

    toggleLogin = () => {
        this.setState({
            isOpenLogin: !this.state.isOpenLogin
        })
    }

    toggleAccountActivation = () => {
        this.setState({
            isOpenAccountActivation: !this.state.isOpenAccountActivation
        })
    }

    clearOrderToReview = _ => {
        this.setState({ orderToReview: undefined });
    }

    render() {
        const { Layout, user, handleToggleLang, isHomePage } = this.props;

        return (
            <Layout
                isHomePage={isHomePage}
                search={this.state.search}
                user={user.user}
                handleSelectProduct={this.handleSelectProduct}
                isOpenSignUp={this.state.isOpenSignUp}
                toggleSignUp={this.toggleSignUp}
                isOpenLogin={this.state.isOpenLogin}
                toggleLogin={this.toggleLogin}
                isOpenAccountActivation={this.state.isOpenAccountActivation}
                toggleAccountActivation={this.toggleAccountActivation}
                handleChangeInput={this.handleChangeInput}
                handleChangeInputDropdown={this.handleChangeInputDropdown}
                handleSubmitSearch={this.handleSubmitSearch}
                handleLogout={this.handleLogout}
                handleToggleLang={handleToggleLang}
                orderToReview={this.state.orderToReview}
                clearOrderToReview={this.clearOrderToReview}
                
            />
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user

});

const mapDispatchToProps = {
    getLastOrderToComment,
    logout
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBar));