import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Platform } from 'react-native';
import { getStore } from "../../actions/storeAction";
import { getProductsByStore } from "../../actions/productAction";
import { reinitCart, addProductCart, updateQuantityProduct, chooseService } from "../../actions/cartAction";

class StoreSingle extends Component {

    constructor(props){
        super(props);

        this.state = {
            redirect: false,
            categoryRef: [],
            isCartSticky: true,
        }

        const storeId = this.props.match ? this.props.match.params.id : this.props.route.params.id;

        if(this.props.cart.cart && this.props.cart.cart.storeId !== storeId) this.props.reinitCart();

        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+props.title;
        
    }

    UNSAFE_componentWillMount() {
        const storeId = this.props.match ? this.props.match.params.id : this.props.route.params.id;

        this.props.getStore(storeId).then(response => {
            if(this.props.store) this.props.getProductsByStore(this.props.store.id).then(response => {
            }).catch( e => {
                console.log("e",e);
            })
        }).catch( e => {
            console.log("e",e);
        })
    }

    // componentDidMount() {
    //     if (Platform.OS !== 'ios' && Platform.OS !== 'android')
    //         window.document.addEventListener('scroll', this.trackScrolling);
    // }
    
    // componentWillUnmount() {
    //     if (Platform.OS !== 'ios' && Platform.OS !== 'android')
    //         window.document.removeEventListener('scroll', this.trackScrolling);
    // }

    hasReachedFooter = (el) => {
        return el.getBoundingClientRect().top <= window.innerHeight;
    }

    trackScrolling = () => {
        const wrappedElement = window.document.getElementById('genieFooter');
        if (this.hasReachedFooter(wrappedElement)) {
          if (this.state.isCartSticky) {
              this.setState({ isCartSticky: false });
          }
        } else {
            if (!this.state.isCartSticky) {
                this.setState({ isCartSticky: true });
            }
        }
    };

    handleAddProductToCart = product => {
        this.props.addProductCart(product).then(response => {
            this.setState({ cart : response.payload })
        })
    }

    handleRemoveProductToCart = index => {
        this.props.updateQuantityProduct(index, -1).then(response => {
            this.setState({ cart : response.payload })
        })
    }

    handleUpdateQuantity = (index, quantity) => {
        this.props.updateQuantityProduct(index, quantity).then(response => {
            this.setState({ cart : response.payload })
        })
    }

    render() {
        const { Layout, store, cart, products, navigation, isLoading, isFetching } = this.props;

        return (

            <Layout
                isFetching={isFetching}
                navigation={navigation}
                redirect={this.state.redirect}
                categoryRef={this.state.categoryRef}
                isLoading={isLoading}
                store={store}
                products={products}
                cart={cart.cart}
                isCartSticky={this.state.isCartSticky}
                handleAddProductToCart={this.handleAddProductToCart.bind(this)}
                handleRemoveProductToCart={this.handleRemoveProductToCart.bind(this)}
                handleUpdateQuantity={this.handleUpdateQuantity.bind(this)}
                isService={this.props.isService}
                mobileProductId={this.props.route?.params?.productId}
            />
        );
    }
    
}

const mapStateToProps = (state) => ({
    isLoading: state.stores.isLoading || state.products.isLoading || !state.stores.store.id,
    cart: state.carts,
    store: state.stores.store,
    products: state.products.products,
    isFetching: state.stores.isFetching || state.products.isFetching || false
});

const mapDispatchToProps = {
    getStore,
    getProductsByStore,
    addProductCart,
    updateQuantityProduct,
    reinitCart,
    chooseService
};


export default connect(mapStateToProps, mapDispatchToProps)(StoreSingle);
