import React, { Component } from 'react';
//import { isConnected } from '../../actions/userAction';
import { connect } from 'react-redux';

import i18n from '../../translations/i18n';

import { isConnected, setToggleLang } from "../../actions/userAction";

import Header from './Header';
import Footer from './Footer';

class MainContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            enabled:  !(window.location.pathname == '/purchase' || 
            window.location.pathname == '/merci' || 
            window.location.pathname.slice(0, 7) == '/forgot' || 
            window.location.pathname == '/recrutement' || 
            window.location.pathname == '/devenez-partenaire' ||
            window.location.pathname == '/qcommerce' ||
            window.location.pathname == '/choisissez-votre-plan' ||
            window.location.pathname == '/delivery-zone' ||
            window.location.pathname == '/confidentialite' ||
            window.location.pathname == '/creez-votre-store-en-ligne')
            ? 'mobileMainContainer' : '',
        }
    }
    
    UNSAFE_componentWillMount() {
        i18n.locale = this.props.lang;
        
        this.props.isConnected()
        .then(() => console.log('You are connected'))
        .catch(() => console.log('You are not connected'));
    }
    
    componentDidUpdate(_, prevState) {
        const enabled =  !(window.location.pathname == '/purchase' || 
            window.location.pathname == '/merci' || 
            window.location.pathname.slice(0, 7) == '/forgot' || 
            window.location.pathname == '/recrutement' || 
            window.location.pathname == '/devenez-partenaire' ||
            window.location.pathname == '/qcommerce' ||
            window.location.pathname == '/choisissez-votre-plan' ||
            window.location.pathname == '/delivery-zone' ||
            window.location.pathname == '/confidentialite' ||
            window.location.pathname == '/creez-votre-store-en-ligne')
            ? 'mobileMainContainer' : '';
            
        if (prevState.enabled !== enabled)
        this.setState({ enabled });
    }

    handleToggleLang = e => {
        this.setState({ isLoading: true});
        this.props.setToggleLang().then( response => {
            this.setState({ isLoading: false});
            window.location = "/";
        }).catch(err => {
            console.log('***** err', err);
        })
    } 

    render() {
        const { header, footer, topbar, children } = this.props;

        return (
            <>
            <Header Layout={header} LayoutTopBar={topbar} handleToggleLang={this.handleToggleLang}/>
            <div id="mainContainer" className={this.state.enabled}>
                {children}
            </div>
            <Footer Layout={footer} />

            </>
        );
    }
}


const mapStateToProps = (state) => ({
    lang: state.user.lang,
    user: state.user
});

const mapDispatchToProps = {
    isConnected,
    setToggleLang
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
