import React from 'react';

import { Container, Col, Row, Button } from 'reactstrap';

import i18n from '../../translations/i18n';

const Business = () => {

   return(
       <div className="paddingContainer">
            <div id="businessPage">
                <section>
                    <Container fluid id="business-title">
                        <Row md={2} sm={1} xs={1}>
                            <Col>
                                <img className="img-responsive" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1595616992301-X82U5IEJKS4KKDOIR6KT/ke17ZwdGBToddI8pDm48kLkXF2pIyv_F2eUT9F60jBl7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0iyqMbMesKd95J-X4EagrgU9L3Sa3U8cogeb0tjXbfawd0urKshkc5MgdBeJmALQKw/patrick-connor-klopf-pZcCZf9SqkE-unsplash.jpg?format=1000w"
                                alt="Business Ride"/>
                            </Col>

                            <Col style={{textAlign: "center", padding: "5% 0"}}>
                                <h1 className="title-head">{i18n.t('business.business_title')}</h1>
                                <h3 className="title-content">{i18n.t('business.business_content')}</h3>
                                <a href="https://www.facebook.com/GenieMtl/"><Button check>{i18n.t('business.start_here')}</Button></a>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <div className="business-separator">
                     <hr />
                </div>

                <section>
                    <Container fluid id="business-info">
                        <Row lg={4} sm={2} xs={1}>
                            <Col sm={{order: 1}} xs={{order: 1}}>
                                <img className="img-responsive" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1595624407853-W47OBMJUWL7X4ACY02MA/ke17ZwdGBToddI8pDm48kClQOQ9UlrrBfbOXedyZv1oUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcykG_Tcl3vOLFLW8QZ7-mpvgqs4qJqW8Cpmk7u8F5rtoBu9PGFRtcs_R-ojdw0MRd/undraw_schedule_pnbk.png?format=1000w"
                                alt="Scheduler"/>
                            </Col>
                            <Col className="card-text" sm={{order: 2}} xs={{order: 2}}>
                                <h1>{i18n.t('business.effective')}</h1>
                                <p>{i18n.t('business.effective_content')}</p>
                            </Col>
                            <Col className="card-text" sm={{order: 3}} xs={{order: 4}}>
                                <h1>{i18n.t('business.fast')}</h1>
                                <p>{i18n.t('business.fast_content')}</p>
                            </Col>
                            <Col sm={{order: 4}} xs={{order: 3}}>
                                <img className="img-responsive" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1595624814582-O8RKXVWLVSG7TPO1DLS9/ke17ZwdGBToddI8pDm48kKsJVAefNGfvbDvlpegcP6MUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcWDmN1StrXM47kC1SyG-voXkISZctATxz2VPzQz5e74jfa2mKunwrnPCY3nDOO6Pe/undraw_order_delivered_p6ba.png?format=1000w"
                                alt="Boxes"/>
                            </Col>
                        </Row>

                        <Row lg={4} sm={2} xs={1}>
                            <Col sm={{order: 1}} xs={{order: 1}}>
                                <img className="img-responsive" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1595625820251-2XCOJWUCNAEBQXNVD0QK/ke17ZwdGBToddI8pDm48kDthNd0VUJGrSMPCWi4hzhUUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcj0yjGhhs65ipR3LttuZHDxMAfjmms0YIhQWH2LMKDKNr4L5GcuEgGnUlUGABckUo/undraw_takeout_boxes_ap54.png?format=1000w"
                                alt="Handing Boxes"/>
                            </Col>
                            <Col className="card-text" sm={{order: 2}} xs={{order: 2}}>
                                <h1>{i18n.t('business.reliable')}</h1>
                                <p>{i18n.t('business.reliable_content')}</p>
                            </Col>
                            <Col className="card-text" sm={{order: 3}} xs={{order: 4}}>
                                <h1>{i18n.t('business.practical')}</h1>
                                <p>{i18n.t('business.practical_content')}</p>
                            </Col>
                            <Col sm={{order: 4}} xs={{order: 3}}>
                                <img className="img-responsive" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1595626369512-RQRUR8QZ1RHI9LP3QWNA/ke17ZwdGBToddI8pDm48kFq_f9qf1vjseouvJVcpdChZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZamWLI2zvYWH8K3-s_4yszcp2ryTI0HqTOaaUohrI8PI7UbabECdnMlOqSHtQjR2T5M2VBaQv99GxypIQsD1tqQKMshLAGzx4R3EDFOm1kBS/undraw_location_tracking_46qb.jpg?format=1000w"
                                alt="Tracker"/>
                            </Col>
                        </Row>

                        <Row sm={2} xs={1} className="bigger-row">
                            <Col>
                                <img className="big-img-responsive" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1595627744987-A1IOVG14EETKYSYDELIT/ke17ZwdGBToddI8pDm48kEOlkn5MiGlBR9dH0TfvKApZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpyjyzMnZmVFdFCuNryyeci0auuEDJf4nLFgOKvgFFH8OJLRAXct_XT7bvcLyUkokG4/mask-man.png?format=1000w"
                                alt="Masked Man"/>
                            </Col>
                            <Col className="txt-responsive">
                                <h1>{i18n.t('business.no_contact')}</h1>
                                <p>{i18n.t('business.no_contact_content')}</p>
                                <a href="https://www.facebook.com/GenieMtl"><Button>{i18n.t('business.sign_up')}</Button></a>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <div className="business-separator">
                     <hr />
                </div>

                <section>
                    <Container fluid id="business-info2">
                        <Row>
                            <Col>
                                <img className="img-responsive2" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1595628487297-0QT5Q4G1YUPOB62QP0LE/ke17ZwdGBToddI8pDm48kEiGyN46bN-55yC7SqbroqAUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcnVpzUV0b4yGJ6SY54sZMK9WtFPDGQ2QGfIM-ITC23huWKIQUv2UKVi5bYn0SYFEC/undraw_secure_data_0rwp.png?format=1000w"
                                alt="Bills"/>
                                <h1>{i18n.t('business.personalized')}</h1>
                                <p>{i18n.t('business.personalized_content')}</p>
                            </Col>
                            <Col>
                                <img className="img-responsive2" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1595628899857-V6ROI1CH5UO5F9XE2THA/ke17ZwdGBToddI8pDm48kDUQ5mRG8eZIzWNQ4NxcLckUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKc83nk9XLs-k3jdHQnmxYPUhH4LadvUgdj3tk1aTZc9Re4zkQrWliUABK-dpmySYQN/undraw_Savings_dwkw.png?format=1000w"
                                alt="Piggy Bank"/>
                                <h1>{i18n.t('business.competitive')}</h1>
                                <p>{i18n.t('business.competitive_content')}</p>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </div>
       </div>
   );
}

export default Business;