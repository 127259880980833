import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { resizeImage } from '../../../config/utils';
import StoreItemComponent from './StoreItem';
import Skeleton from '../UI/Skeleton';

// Carousel Imports
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import SVG from 'react-inlinesvg';

import i18n from '../../../translations/i18n';

const StoreCategoryPageComponent = ({ stores, category, childCategories, isFetching, carouselImages }) => {

    let categoryList = [];
    let currentCat = '';

    for(let store of stores){

        if(currentCat != store.categories[0]['name_' + i18n.locale.toUpperCase()]){
            currentCat = store.categories[0]['name_' + i18n.locale.toUpperCase()];
            categoryList.push({ name: currentCat, image: store.categories[0].image, id: store.categories[0].id });
        }
    }

    return (
        <div className="paddingContainer">
            <section id="storeSection">

               { category &&  <>
                <div id="breadcrumb">
                    <p><Link style={{ width: '100%', textAlign: 'right' }} to="/">{i18n.t('footer.home')}</Link> &gt; {i18n.t("category."+category).includes("missing") ? category.charAt(0).toUpperCase() + category.slice(1) : i18n.t("category."+category)}</p>
                </div>
                <h4>{i18n.t("category."+category).includes("missing") ? category.charAt(0).toUpperCase() + category.slice(1) : i18n.t("category."+category)}</h4>
               </>}
               { carouselImages?.filter(carousel => carousel.location?.includes(category)).length > 0 && 
                <Row>
                    <Col sm="12" md="12">
                        <CarouselProvider
                            totalSlides={carouselImages.filter(carousel => carousel.location?.includes(category)).length}
                            visibleSlides={2}
                            interval={10000}
                            infinite
                            isIntrinsicHeight={true}
                            isPlaying={true}
                            className="carouselContainer"
                            // hasMasterSpinner
                        >
                            <Slider className="carouselSlider">
                                { carouselImages.filter(carousel => carousel.location?.includes(category)).map((slide, key) =>
                                <Slide className="carouselSlide" style={{ backgroundImage: `url(${resizeImage(slide.background, '_1000x.')})`}} index={key}>
                                    <Link to={slide.url} className="carouselContent">
                                        <h5 className="carouselText" style={{ color: slide.textColor }}>{slide.text}</h5>
                                        <button className="carouselButton">{slide.buttonText}</button>
                                    </Link>
                                </Slide>)}
                            </Slider>
                            {carouselImages.filter(carousel => carousel.location?.includes(category)).length > 2 ?
                            <>
                            <ButtonBack className="carouselNav previous"><SVG src={chevronBackOutline} alt="icon previous" className="carouselIcon"/></ButtonBack>
                            <ButtonNext className="carouselNav next"><SVG src={chevronForwardOutline} alt="icon next" className="carouselIcon"/></ButtonNext>
                            </> : null}
                        </CarouselProvider>
                    </Col>
                </Row>}
                <div className="storeList">
                    { isFetching ? <Skeleton /> :
                    <Row>
                    {   stores && stores.length === 0 ?
                        <h3 style={{width: "100%", textAlign: "center"}}>{i18n.t('search.noResult')}</h3>
                        :
                        <>
                            { childCategories && childCategories.length > 0 && <Link style={{width: "100%", textAlign: "right"}} to={"/categories/" + childCategories[0].parent.id}>{i18n.t("global.allCategories")}</Link> }
                            <Row style={{ width: '100%', marginBottom: 50 }}>
                            { childCategories.length > 0 && childCategories.splice(0,5).map( (cat, index) => (
                                <Col sm="4" md="2" key={'catIndex-' + index}>
                                    <Link to={"/category/" + cat.id} style={{}}>
                                        <div className="storeImage" style={{
                                            backgroundImage: "linear-gradient(#342535, #342535), url('" + resizeImage(cat.image, '_250x.') + "')",
                                            backgroundBlendMode: "hard-light",
                                            height: 100,
                                            borderRadius: 5,
                                            backgroundSize: "cover",
                                            display:"flex",
                                            alignItems: "center"
                                        }}>
                                            <p style={{ color: "white", textAlign: "center", width: "100%", fontSize: "1.3em", marginBottom: 0 }}>{cat.name}</p>
                                        </div>
                                    </Link>    
                                </Col>
                            ))}
                            </Row>

                            { stores && stores.map((store, index) => (
                                <Col sm="4" md="3" key={index}>
                                    <StoreItemComponent store={store} key={index} />
                                </Col>
                            )) }
                        </>
                    }
                    </Row> }
                </div>

            </section>
        </div>
    )
};

export default StoreCategoryPageComponent;