import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if ( !(pathname.includes('store') && search.includes('product')) ) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [pathname]);

  return null;
}