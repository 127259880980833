import React from 'react';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import i18n from '../../../translations/i18n';
import { connect } from 'react-redux';
import { setChatOpen, setToastMessage } from '../../../actions/chatAction';

const RoundedMenuComponent = ({ withChat, isHomePage, ...props }) => {

    const openChat = () => {
        if (window.navigator.onLine) {
            const isOpen = props.isOpen;
            props.setChatOpen(!isOpen);
        } else {
            props.setToastMessage(i18n.t('error_messages.internet_connection'));
        }
    };

    return (
        <div className="roundedMenu" style={{ height: isHomePage ? '425px' : '170px' }}>
            <div className="roundedMenuRow">
                <Link to="/stores/restaurant">
                    <button className="menuBtn">
                        <SVG src="/images/menu/icon-alimentation.svg" className="roundedMenuImg" alt="icon menu" />
                        <div className='menuBtnText'>{i18n.t('rounded_menu.food')}</div>
                    </button>
                </Link>
                <Link to="/stores/commerce">
                    <button className="menuBtn">
                        <SVG src="/images/menu/icon-commerce.svg" className="roundedMenuImg" alt="icon menu" />
                        <div className='menuBtnText'>{i18n.t('rounded_menu.shop')}</div>
                    </button>
                </Link>
                <Link to="/stores/promo">
                    <button className="menuBtn">
                        <SVG src="/images/menu/icon-promo.svg" className="roundedMenuImg" alt="icon menu" />
                        <div className='menuBtnText'>{i18n.t('rounded_menu.promo')}</div>
                    </button>
                </Link>
            </div>

            {/* <button className="menuBtn">
                <img src="/images/menu/icon-coursier.png" className="roundedMenuImg" alt="icon menu"/>
                Coursier
            </button> */}
            { withChat ?
            (<button onClick={openChat} className="menuBtn bigBtn">
                <SVG src="/images/menu/icon-chat.svg" alt="icon menu" />
                <div className='menuBtnText'>{i18n.t('rounded_menu.live_chat')}</div>
            </button>) : undefined}

            <div className="roundedMenuRow">
                <Link to="/stores/service">
                    <button className="menuBtn">
                        <SVG src="/images/menu/icon-service.svg" className="roundedMenuImg" alt="icon menu" />
                        <div className='menuBtnText'>{i18n.t('rounded_menu.services')}</div>
                    </button>
                </Link>
                <Link to="/pickup">
                    <button className="menuBtn">
                        <SVG src="/images/menu/icon-pickup.svg" className="roundedMenuImg" alt="icon menu" />
                        <div className='menuBtnText'>{i18n.t('rounded_menu.pickup')}</div>
                    </button>
                </Link>
                <Link to="/business">
                    <button className="menuBtn">
                        <SVG src="/images/menu/icon-business.svg" className="roundedMenuImg" alt="icon menu" />
                        <div className='menuBtnText'>{i18n.t('rounded_menu.business')}</div>
                    </button>
                </Link>
            </div>
            {/* <button className="menuBtn">
                <img src="/images/menu/icon-commande.png" className="roundedMenuImg" alt="icon menu"/>
                Commande
            </button>
            <button className="menuBtn">
                <img src="/images/menu/icon-reservation.png" className="roundedMenuImg" alt="icon menu"/>
                Réservations
            </button> */}
        </div>
    );

};

const mapStateToProps = state => ({
    isOpen: state.chat.isOpen,
});

const mapDispatchToProps = {
    setChatOpen,
    setToastMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoundedMenuComponent);
