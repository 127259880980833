import React from 'react';
import i18n from '../../translations/i18n';

const CGU = () => {

   return(
       <div className="paddingContainerMobile">
            <div id="confidentialityPage">
                <section id="confidentiality-title">
                    <h1 className="title-head">{i18n.t('confidentiality.title')}</h1>
                    <h4 className="title-content" style={{ padding: 0, fontWeight: 'bold' }}>{i18n.t('confidentiality.title_content')}</h4>
                    <h4 className="title-content">{i18n.t('confidentiality.title_content_2')}</h4>
                    <h4 className="title-content">{i18n.t('confidentiality.title_content_3')}</h4>
                </section>

                <section className='confidentiality-section'>
                    <h2 className="section-title">{i18n.t('confidentiality.collection_title')}</h2>
                    <h4 className="section-content">{i18n.t('confidentiality.collection_content')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.collection_content_2')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.collection_content_3')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.collection_content_4')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.collection_content_5')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.collection_content_6')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.collection_content_7')}:</h4>
                    <h4 className="section-content" style={{ marginLeft: '1em' }}>• {i18n.t('confidentiality.collection_content_7_part_1')}</h4>
                    <h4 className="section-content" style={{ marginLeft: '1em' }}>• {i18n.t('confidentiality.collection_content_7_part_2')}</h4>
                    <h4 className="section-content" style={{ marginLeft: '1em' }}>• {i18n.t('confidentiality.collection_content_7_part_3')}</h4>
                </section>

                <section className='confidentiality-section'>
                    <h2 className="section-title">{i18n.t('confidentiality.security_title')}</h2>
                    <h4 className="section-content">{i18n.t('confidentiality.security_content')}</h4>
                </section>

                <section className='confidentiality-section'>
                    <h2 className="section-title">{i18n.t('confidentiality.sites_title')}</h2>
                    <h4 className="section-content">{i18n.t('confidentiality.sites_content')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.sites_content_2')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.sites_content_3')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.sites_content_4')}</h4>
                    <h4 className="section-content" style={{ display: 'flex', marginLeft: '1em' }}>
                        -
                        <a href="https://stripe.com/ca/privacy" target="_blank"><h4 className="section-content" style={{ marginLeft: '1em', textDecoration: 'underline' }}>https://stripe.com/ca/privacy</h4></a>
                    </h4>
                    <h4 className="section-content" style={{ display: 'flex', marginLeft: '1em' }}>
                        -
                        <a href="https://www.facebook.com/about/privacy/update" target="_blank"><h4 className="section-content" style={{ marginLeft: '1em', textDecoration: 'underline' }}>https://www.facebook.com/about/privacy/update</h4></a>
                    </h4>
                    <h4 className="section-content" style={{ display: 'flex', marginLeft: '1em' }}>
                        -
                        <a href="https://www.paypal.com/ca/webapps/mpp/ua/useragreement-full?locale.x=fr_CA" target="_blank"><h4 className="section-content" style={{ marginLeft: '1em', textDecoration: 'underline' }}>https://www.paypal.com/ca/webapps/mpp/ua/useragreement-full?locale.x=fr_CA</h4></a>
                    </h4>
                    <h4 className="section-content">{i18n.t('confidentiality.sites_content_5')}</h4>
                </section>

                <section className='confidentiality-section'>
                    <h2 className="section-title">{i18n.t('confidentiality.sharing_title')}</h2>
                    <h4 className="section-content">{i18n.t('confidentiality.sharing_content')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.sharing_content_2')}</h4>
                    <h4 className="section-content" style={{ marginLeft: '1em' }}>• {i18n.t('confidentiality.sharing_content_2_part_1')}</h4>
                    <h4 className="section-content" style={{ marginLeft: '1em' }}>• {i18n.t('confidentiality.sharing_content_2_part_2')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.sharing_content_3')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.sharing_content_4')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.sharing_content_5')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.sharing_content_6')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.sharing_content_7')}</h4>
                </section>

                <section className='confidentiality-section'>
                    <h2 className="section-title">{i18n.t('confidentiality.legislation_title')}</h2>
                    <h4 className="section-content">{i18n.t('confidentiality.legislation_content')}</h4>
                    <h4 className="section-content" style={{ fontWeight: 'bold' }}>- {i18n.t('confidentiality.legislation_content_2')}</h4>
                    <h4 className="section-content" style={{ fontWeight: 'bold' }}>- {i18n.t('confidentiality.legislation_content_3')}</h4>
                    <h4 className="section-content" style={{ fontWeight: 'bold' }}>- {i18n.t('confidentiality.legislation_content_4')}</h4>
                </section>

                <section className='confidentiality-section'>
                    <h2 className="section-title">{i18n.t('confidentiality.access_title')}</h2>
                    <h4 className="section-content">{i18n.t('confidentiality.access_content')}</h4>
                    <h4 className="section-content">{i18n.t('confidentiality.access_content_2')}:</h4>
                    <h4 className="section-content" style={{ padding: 0 }}>{`${i18n.t('confidentiality.access_address')} : 6607 Louis-Hémon, Montréal H2G 2L1`}</h4>
                    <h4 className="section-content" style={{ padding: 0 }}>{`${i18n.t('confidentiality.access_email')} : mtlgenie@gmail.com`}</h4>
                    <h4 className="section-content" style={{ padding: 0 }}>{`${i18n.t('confidentiality.access_website')} : https://www.messenger.com/t/GenieMtl/`}</h4>
                </section>
            </div>
       </div>
   );
}

export default CGU;