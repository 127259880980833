import React from 'react';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { setToasted } from '../../../actions/chatAction';

const Toast = ({ toast, setToasted }) => {

    const { addToast } = useToasts();

    if (toast) {
        addToast(
            toast.msg,
            {
                appearance: toast.type,
                autoDismiss: true,
            },
        );
        setToasted();
    }

    return (<></>);
};

const mapStateToProps = state => ({
    toast: state.chat.toast,
});

const mapDispatchToProps = {
    setToasted,
};

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
