export const checkFields = (fields, data) => new Promise((resolve, reject) => {

    let errors = [];
    var validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validPhone = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    for (let i in fields) {
        for (let j in fields[i]) {
            if (j === 'empty' && (data[i] === '' || !data[i])) {
                errors[i] = fields[i].empty;
                break;
            }
            if (j === 'isEmail' && !validEmail.test(data[i])) {
                errors[i] = fields[i].isEmail;
                break;
            }
            if (j === 'valid' && (!validPhone.test(data[i]))) {
                errors.push(fields[i].valid);
                break;
            }
        }
    }
    if (Object.keys(errors).length > 0) {
        return reject(errors);
    }
    resolve();
});

export const resizeImage = (url, size) => {
    if (url?.includes("amazonaws") && !url?.includes('.ico')) {
        if (url.includes("original")) {
            var slash = url.replace('original', 'resized');
            url = slash;
        } else {
            var slash = url.lastIndexOf('/');
            url = url.slice(0, slash) + '/resized/' + url.slice(slash + 1)
        }

        var point = url.lastIndexOf('.');
        url = url.slice(0, point) + size + url.slice(point + 1).toLowerCase();
        return url;
    }
    else {
        return url;
    }
}