import React from 'react';
import i18n from '../../translations/i18n';

const CGU = () => {

   return(
       <div className="paddingContainer">
            <div id="cguPage">

                <section id="cgu-title">
                    <h1 className="title-head">{i18n.t('cgu.title')}</h1>
                    <h4 className="title-content">{i18n.t('cgu.title_content')}</h4>
                </section>

                <section className='cgu-section'>
                    <h2 className="section-title">1. {i18n.t('cgu.acceptation_title')}</h2>
                    <h4 className="section-content">{i18n.t('cgu.acceptation_content')}</h4>
                    <h4 className="section-content">{i18n.t('cgu.acceptation_content_2')}</h4>
                    <h4 className="section-content">{i18n.t('cgu.acceptation_content_3')}</h4>
                </section>

                <section className='cgu-section'>
                    <h2 className="section-title">2. {i18n.t('cgu.services_title')}</h2>
                    <h4 className="section-content">
                        {i18n.t('cgu.services_content_part_1')}&nbsp;
                        <a href='www.geniemtl.com' target="_blank">www.geniemtl.com</a>&nbsp;
                        {i18n.t('cgu.services_content_part_2')}
                    </h4>
                    <h4 className="section-content">{i18n.t('cgu.services_content_2')}</h4>
                </section>

                <section className='cgu-section'>
                    <h2 className="section-title">3. {i18n.t('cgu.benefits_title')}</h2>
                    <h4 className="section-content">{i18n.t('cgu.benefits_content')}</h4>
                    <h4 className="section-content">{i18n.t('cgu.benefits_content_2')}</h4>
                </section>

                <section className='cgu-section'>
                    <h2 className="section-title">4. {i18n.t('cgu.guarantee_title')}</h2>
                    <h4 className="section-content">{i18n.t('cgu.guarantee_content')}</h4>
                    <h4 className="section-content">{i18n.t('cgu.guarantee_content_2')}</h4>
                    <h4 className="section-content">{i18n.t('cgu.guarantee_content_3')}</h4>
                    <h4 className="section-content">{i18n.t('cgu.guarantee_content_4')}</h4>
                </section>

                <section className='cgu-section'>
                    <h2 className="section-title">5. {i18n.t('cgu.bad_use_title')}</h2>
                    <h4 className="section-content">{i18n.t('cgu.bad_use_content')}</h4>
                </section>

                <section className='cgu-section'>
                    <h2 className="section-title">6. {i18n.t('cgu.payments_title')}</h2>
                    <h4 className="section-content">{i18n.t('cgu.payments_content')}</h4>
                    <h4 className="section-content">{i18n.t('cgu.payments_content_2')}</h4>
                    <h4 className="section-content">{i18n.t('cgu.payments_content_3')}</h4>
                    <h4 className="section-content">{i18n.t('cgu.payments_content_4')}</h4>
                </section>

                <section className='cgu-section'>
                    <h2 className="section-title">7. {i18n.t('cgu.permit_title')}</h2>
                    <h4 className="section-content">{i18n.t('cgu.permit_content')}</h4>
                    <h4 className="section-content" style={{ fontWeight: 'bold' }}>{i18n.t('cgu.permit_content_2')}</h4>
                    <h4 className="section-content">{i18n.t('cgu.permit_content_3')}</h4>
                </section>
            </div>
       </div>
   );
}

export default CGU;