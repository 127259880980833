import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import StoreItem from '../../containers/Store/StoreItem';
import StoreProductSingle from '../../containers/Store/StoreProductSingle';

import Loading from "./UI/Loading";
import StoreProductSingleComponent from './Store/StoreProductSingle'

// Carousel Imports
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import SVG from 'react-inlinesvg';


import i18n from '../../translations/i18n';

const SearchPage = ({ keyword, searchedProducts, searchedStores, searchedServices, isLoading}) => {

    if(isLoading) return <Loading emptyPage={true} />

    return(
        <div className="paddingContainer">
            <section id="storeSection">
                <div id="breadcrumb">
                    <p><Link to="/">{i18n.t('footer.home')}</Link> &gt; {i18n.t('search.search')} &gt; {keyword}</p>
                </div>

                { searchedStores && searchedStores.length > 0 ?
                    <>
                        <h4>{i18n.t('footer.stores')}</h4>

                        <div className="storeList">
                            <Row style={{width: '100%', display: 'block'}}>
                                <CarouselProvider
                                    totalSlides={searchedStores.length}
                                    visibleSlides={4}
                                    isIntrinsicHeight={true}
                                    className="carouselContainer"
                                >
                                    <Slider>
                                        {searchedStores != null && searchedStores.map((store, index) => (
                                            <Slide>
                                                <Col sm="12" md="12" key={index}>
                                                    <StoreItem store={store} key={index} />
                                                </Col>
                                            </Slide>
                                        ))}
                                    </Slider>
                                    { searchedStores.length > 4 && 
                                    <>
                                        <ButtonBack className="carouselNav previous"><SVG src={chevronBackOutline} alt="icon previous" className="carouselIcon"/></ButtonBack>
                                        <ButtonNext className="carouselNav next"><SVG src={chevronForwardOutline} alt="icon next" className="carouselIcon"/></ButtonNext>
                                    </>}
                                </CarouselProvider>
                            </Row>
                        </div>
                    </>
                    : undefined
                }

                { searchedServices && searchedServices.length > 0 ?
                    <>
                        <h4>{i18n.t('footer.services')}</h4>

                        <div className="storeList">
                            <Row style={{width: '100%', display: 'block'}}>
                                <CarouselProvider
                                    totalSlides={searchedServices.length}
                                    visibleSlides={4}
                                    isIntrinsicHeight={true}
                                    className="carouselContainer"
                                >
                                    <Slider>
                                        {searchedServices != null && searchedServices.map((service, index) => (
                                            <Slide>
                                                <Col sm="12" md="12" key={index}>
                                                    <StoreItem store={service} key={index} />
                                                </Col>
                                            </Slide>
                                        ))}
                                    </Slider>
                                    { searchedServices.length > 4 && 
                                    <>
                                        <ButtonBack className="carouselNav previous"><SVG src={chevronBackOutline} alt="icon previous" className="carouselIcon"/></ButtonBack>
                                        <ButtonNext className="carouselNav next"><SVG src={chevronForwardOutline} alt="icon next" className="carouselIcon"/></ButtonNext>
                                    </>}
                                </CarouselProvider>
                            </Row>
                        </div>
                    </>
                    : undefined
                }

                { searchedProducts && Object.keys(searchedProducts).length > 0 ?
                    <>
                        <h4>{i18n.t('search.products')}</h4>
                        {
                            Object.keys(searchedProducts).map((key, index) => (
                                <section key={index}>
                                    <h5>{key}</h5>

                                    <div className='storeItem'>
                                        <div id="storeProduct" style={{marginTop: 0}}>
                                            <div className="productList">
                                                <Row style={{width: '100%', display: 'block'}}>
                                                    <CarouselProvider
                                                        totalSlides={searchedProducts[key].length}
                                                        visibleSlides={4}
                                                        isIntrinsicHeight={true}
                                                        className="carouselContainer"
                                                    >
                                                        <Slider>
                                                            {searchedProducts[key].map((product, index) => (
                                                                <Slide>
                                                                    <Col sm="12" md="12" key={index}>
                                                                        <StoreItem 
                                                                        store={product.myStore ? product.myStore : product.store}
                                                                        product={product._id}
                                                                        category={product.category}
                                                                        image={product.images ? product.images.split(';')[0] : ""}
                                                                        name_EN={product.name_EN ? product.name_EN : product.name}
                                                                        name_FR={product.name_FR ? product.name_FR : product.name}
                                                                        key={index} />
                                                                    </Col>
                                                                </Slide>
                                                            ))}
                                                        </Slider>
                                                        { searchedProducts[key].length > 4 && 
                                                        <>
                                                            <ButtonBack className="carouselNav previous"><SVG src={chevronBackOutline} alt="icon previous" className="carouselIcon"/></ButtonBack>
                                                            <ButtonNext className="carouselNav next"><SVG src={chevronForwardOutline} alt="icon next" className="carouselIcon"/></ButtonNext>
                                                        </>}
                                                    </CarouselProvider>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            ))
                        }
                    </>
                    : undefined
                }

                {
                    (!searchedProducts || Object.keys(searchedProducts).length === 0) && ( !searchedStores || searchedStores.length === 0 ) && (!searchedServices || searchedServices.length === 0) ? 
                    <h4>{i18n.t('search.noResult')}</h4> : undefined
                }

            </section>
        </div>
    )
};

export default SearchPage;