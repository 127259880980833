import userReducer from './userReducer';
import applicationReducer from './applicationReducer';
import storeReducer from './storeReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import orderReducer from './orderReducer';
import pickupReducer from './pickupReducer';
import chatReducer from './chatReducer';

const rehydrateReducer = (state = false, action) => {
    switch (action.type) {
        case 'persist/REHYDRATE':
            return true;
        default:
            return state;
    }
};

export default {
    rehydrate: rehydrateReducer,
    carts: cartReducer,
    user: userReducer,
    products: productReducer,
    stores: storeReducer,
    orders: orderReducer,
    pickups: pickupReducer,
    chat: chatReducer,
    application: applicationReducer
};
