import React from 'react';
import { Row, Col, Label, Input } from 'reactstrap';

import i18n from '../../../translations/i18n';

import SVG from 'react-inlinesvg';

import { trashOutline } from "ionicons/icons";

const AddressBlock = ({ address, handleChangeInput, handleRemoveAddress }) => (
    <>
        <Row>
            <Col>
                <Label className="accountLabel" htmlFor="street">{i18n.t('address.street')}</Label>
                <Input type="text" name="street" value={address.street} disabled></Input>
            </Col>
            <Col>
                <Label className="accountLabel" htmlFor="app">{i18n.t('address.app')}</Label>
                <Input type="text" name="apartment" value={address.apartment ? address.apartment : ''} onChange={e => handleChangeInput(e)}></Input>
            </Col>
            <Col>
                <Label className="accountLabel" htmlFor="postalCode">{i18n.t('address.postalCode')}</Label>
                <Input type="text" name="postalCode" value={address.postalCode} disabled></Input>
            </Col>
            <Col>
                <Label className="accountLabel" htmlFor="city">{i18n.t('address.city')}</Label>
                <Input type="text" name="city" value={address.city} disabled></Input>
            </Col>
            <Col>
                <Label className="accountLabel" htmlFor="region">{i18n.t('address.state')}</Label>
                <Input type="text" name="region" value={address.region} disabled></Input>
            </Col>
            <Col>
                <Label className="accountLabel" htmlFor="country">{i18n.t('address.country')}</Label>
                <Input type="text" name="country" value={address.country} disabled></Input>
            </Col>
            <Col>
                <SVG src={trashOutline} className="invert actionButton" onClick={e => handleRemoveAddress()} />
            </Col>
        </Row>

        <hr />
    </>
)

export default AddressBlock;