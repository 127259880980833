import React, {useState} from 'react';
import { Button, Modal, Input } from 'reactstrap';

import i18n from "../../../translations/i18n";

const TipBlock = ({cart, order, handleToggleModal, isModalOpen, handleUpdateOrderInput}) => {

    const [tipsAmount, setTipsAmount] = useState(0)

    const handleChangeTip = (e) => {
        if (e.target.value < 0) {
            setTipsAmount(0)
        } else {
            setTipsAmount(e.target.value)
        }
    }

    return(
        <>
        <div className="tipContainer">
            <div>{i18n.t('payment.tip')}</div>

            <div className="tipTable">
                <Button className={order.tipsPercent == 10 ? "tipBlock isActive" : "tipBlock"} onClick={ e => handleUpdateOrderInput({ target: { name: "tipsPercent", value: 10 }})}>
                    <div className="tipTitle">{i18n.t('payment.tenpercent')}</div>
                    <div className="tipSubTitle">{(cart.totalPrice*0.10).toFixed(2)}$</div>
                </Button>
                <Button className={order.tipsPercent == 15 ? "tipBlock isActive" : "tipBlock"} onClick={ e => handleUpdateOrderInput({ target: { name: "tipsPercent", value: 15 }})}>
                    <div className="tipTitle">{i18n.t('payment.fifteenpercent')}</div>
                    <div className="tipSubTitle">{(cart.totalPrice*0.15).toFixed(2)}$</div>
                </Button>
                <Button className={order.tipsPercent == 20 ? "tipBlock isActive" : "tipBlock"} onClick={ e => handleUpdateOrderInput({ target: { name: "tipsPercent", value: 20 }})}>
                    <div className="tipTitle">{i18n.t('payment.twentypercent')}</div>
                    <div className="tipSubTitle">{(cart.totalPrice*0.20).toFixed(2)}$</div>
                </Button>
                <Button className={order.tipsPercent == 25 ? "tipBlock isActive" : "tipBlock"} onClick={ e => handleUpdateOrderInput({ target: { name: "tipsPercent", value: 25 }})}>
                    <div className="tipTitle">{i18n.t('payment.twentyfivepercent')}</div>
                    <div className="tipSubTitle">{(cart.totalPrice*0.25).toFixed(2)}$</div>
                </Button>
                <Button className={!order.tipsPercent && order.tips > 0 ? "tipBlock isActive" : "tipBlock"} onClick={ (e) => { handleToggleModal(); handleUpdateOrderInput({ target: { name: "tipsPercent", value: undefined }}) }}>
                    <div className="tipTitle">{i18n.t('global.other')}</div>
                </Button>
            </div>

            <Modal isOpen={isModalOpen} toggle={handleToggleModal} id="tipModal">
                <div className="modalTip">
                    <div className="modalTipTitle">{i18n.t('payment.chooseAmount')}</div>
                    <Input className="modalTipInput" min="0" type="number" value={tipsAmount} onChange={ e => handleChangeTip(e)}></Input>
                </div>
                <Button className="modalButton" onClick={ (e) => { handleToggleModal(); handleUpdateOrderInput({ target: { name: "tips", value: parseFloat(tipsAmount) }}) }}>
                    <div>{i18n.t('global.confirm')}</div>
                </Button>
            </Modal>
        </div>
        </>
    )
}

export default TipBlock;