import React, { Component } from 'react';
import { connect } from 'react-redux';


class CardBlock extends Component {

    handleRemoveCard = () => {
        this.props.handleRemoveCard(this.props.card.id);
    }

    render() {
        const { Layout } = this.props;

        return (

            <Layout 
                card={this.props.card}
                handleRemoveCard={this.handleRemoveCard.bind(this)}
            />
        );
    }
    
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
};


export default connect(mapStateToProps, mapDispatchToProps)(CardBlock);
