import React, { useState } from 'react';
import axios from 'axios';
import ChatDropzone from './chatDropzone';

import i18n from '../../../translations/i18n';
import config from '../../../config/config';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

const thumbDelete = {
    position: 'absolute',
    width: 90,
    height: 100,
    display: 'block',
    textAlign: 'right',
    paddingRight: '5px',
    zIndex: 1000,
};

const Thumb = fieldProps => {
    const {
        imgSource,
        deleteImage,
    } = fieldProps;

    return (
        <div style={thumb} key={`thumb${imgSource}`}>
{/*            <div style={thumbDelete}>
                <IconContext.Provider value={{ color: 'red', className: 'global-class-name' }}>
                    <FaTimes onClick={() => deleteImage(imgSource)} size={'1.5em'}/>
                </IconContext.Provider>
            </div>

            <div style={{ ...thumbInner }}>
                <img src={imgSource} style={img} alt="uploaded"/>
            </div>*/}
        </div>
    );
};

function ChatImageInput (props) {
    const {
        label,
    } = props;
    const [location, setLocation] = useState('');
    const [loading, setLoading] = useState(false);

    const uploadFile = file => {
        setLoading(true);

        let body = {};
        body.fileName = Math.random().toString().substr(2, 5) + '-' + Date.now() + '.' + file.name.split('.').pop();

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const token = localStorage.getItem('token');

            body.file = reader.result;

            axios.post(config.baseUrlApi + '/amazon/upload', body, {
                headers: { Authorization: `JWT ${token}` },
            }).then(response => {
                setLocation(response.data);
                setLoading(false);
                props.callback(response.data)
            }).catch(err => {
                console.log('err', err);
            });
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const onDrop = acceptedFiles => {
        return acceptedFiles.map(aFile => {
            return uploadFile(aFile);
        });
    };

    const deleteImage = () => {
        setLocation('');
    };

    return (
        <section className="container">
            {!loading &&
                <ChatDropzone accept={'image/*'} onDrop={onDrop} label={label} multiple={false}/>
            }

{/*            <aside style={thumbsContainer}>
                {location && <Thumb imgSource={location} deleteImage={deleteImage}/>}
                <CircleLoader
                    size={50}
                    color={'#123abc'}
                    loading={loading}
                />
            </aside>*/}
        </section>
    );
}

export default ChatImageInput;
