import React, { Component } from 'react';
import { connect } from 'react-redux';

import { formatCVC, formatCreditCardNumber, formatExpirationDate } from "../../utils/utils";

import { updateUser, createUserCard, removeUserCard } from "../../actions/userAction";

import i18n from '../../translations/i18n';

class Account extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            ...props.user.user,
            addresses: props.user.user.addresses,
            cards: props.user.cards,
            isOpenLogin : false,
            isOpenSignUp : false,
            currentCard: {
                cvc: '',
                number: '',
                name: '',
                expiry: ''
            }
        }

        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+props.title;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.token !== nextProps.user.user.token){
            prevState = { ...nextProps.user.user };
        }

        if(prevState.token){
            if( prevState.cards && nextProps.user.cards && prevState.cards.length !== nextProps.user.cards.length ){
                prevState = { ...prevState, cards: nextProps.user.cards };
            }
        }
        
        return prevState;
    }

    handleChangeInput = e => {
        if ( e.target.name === "phone" && e.target.value.length > 10) return;

        this.setState({ [e.target.name] : e.target.value });
    }

    handleUpdateCurrentCard = e => {
        if (e.target.name === "number") e.target.value = formatCreditCardNumber(e.target.value);
        if (e.target.name === "expiry") e.target.value = formatExpirationDate(e.target.value);
        if (e.target.name === "cvc") e.target.value = formatCVC(e.target.value);


        this.setState({ currentCard: { ...this.state.currentCard, [e.target.name] : e.target.value }});
    }

    handleUpdateCurrentCardNative = form => {
        this.setState({ currentCard: { ...this.state.currentCard, ...form.values }});
    }

    handleUpdateUser = e => {
        this.props.updateUser(this.state).then(response => {
            this.setState({ toastMessage: { type: 'success', msg: i18n.t('account.success_update')} }, () => {
                this.setState({ toastMessage: undefined });
            });
        }).catch(err => {
            this.setState({ toastMessage: { type: 'error', msg: i18n.t('account.error_update')} }, () => {
                this.setState({ toastMessage: undefined });
            });
        })
    }

    toggleSignUp = () => {
        this.setState({
            isOpenSignUp: !this.state.isOpenSignUp
        })
    }

    toggleLogin = () => {
        this.setState({
            isOpenLogin: !this.state.isOpenLogin
        })
    }

    handleAddAddress = (address, latLng) => {
        let newAddress = {};

        address.address_components.forEach(aPart => {
            if(aPart.types.includes('street_number')){
                newAddress.street = aPart.long_name;
            }
            if(aPart.types.includes('route')){
                newAddress.street = newAddress.street + ' ' + aPart.long_name;
            }
            if(aPart.types.includes('locality')){
                newAddress.city = aPart.long_name;
            }
            if(aPart.types.includes('administrative_area_level_1')){
                newAddress.region = aPart.long_name;
                newAddress.regionShort = aPart.short_name;
            }
            if(aPart.types.includes('country')){
                newAddress.country = aPart.long_name;
            }
            if(aPart.types.includes('postal_code')){
                newAddress.postalCode = aPart.long_name;
            }
        })

        newAddress.full = newAddress.street + ', '+ newAddress.city + ', '+ newAddress.regionShort + ', '+ newAddress.country;
        newAddress.latitude = latLng.lat;
        newAddress.longitude = latLng.lng;

        let addresses = this.state.addresses ? this.state.addresses : [];
        addresses.push(newAddress);

        this.setState({addresses: addresses}, () => {
            this.props.updateUser({...this.state, ...this.props.user.user, addresses: this.state.addresses}).then(response => {
                this.setState({ toastMessage: { type: 'success', msg: i18n.t('account.success_add_address')} }, () => {
                    this.setState({ toastMessage: undefined });
                });
            }).catch(err => {
                this.setState({ toastMessage: { type: 'error', msg: i18n.t('account.errror_add_address')} }, () => {
                    this.setState({ toastMessage: undefined });
                });
            })
        });

    }

    handleRemoveAddress = (addressId, index) => {
        let addresses = this.state.addresses;

        this.setState({ addresses: [] }, () => {
            if(addressId) addresses = addresses.filter( a => ( a.id !== addressId))
            else addresses.splice(index, 1);

            this.setState({ addresses: addresses }, () => {
                this.props.updateUser({...this.state, ...this.props.user.user, addresses: this.state.addresses}).then(response => {
                    this.setState({ toastMessage: { type: 'success', msg: i18n.t('account.success_remove_address')} }, () => {
                        this.setState({ toastMessage: undefined });
                    });
                }).catch(err => {
                    this.setState({ toastMessage: { type: 'error', msg: i18n.t('account.errror_remove_address')} }, () => {
                        this.setState({ toastMessage: undefined });
                    });
                })
            });
        });

    }

    handleUpdateAddress = (address, index) => {
        let addresses = this.state.addresses;

        if(address.id){
            addresses.forEach( a => {
                if(a.id === address.id){
                    a.apartment = address.apartment;
                }
            })
        }
        else{
            addresses[index].apartment = address.apartment;
        }

        this.setState({ addresses: addresses });
    }

    handleAddCard = () => {
        this.props.createUserCard(this.state.currentCard).then(response => {
            this.setState({ toastMessage: { type: 'success', msg: i18n.t('account.success_card')} }, () => {
                this.setState({ toastMessage: undefined });
            });
        }).catch(err => {
            this.setState({ toastMessage: { type: 'error', msg: i18n.t('account.error_card') }}, () => {
                this.setState({ toastMessage: undefined });
            });
            console.log('***** Account - handleAddCard', err);
        })

        this.setState({ currentCard: {}});
    }

    handleRemoveCard = cardId => {
        this.props.removeUserCard(cardId);
    }

    handleFocusField = e => {
        this.setState({ focusedField : e.target.name });
    }

    render() {
        const { Layout, isLoading, navigation } = this.props;

        return (
            <Layout
                user={this.state}
                currentCard={this.state.currentCard}
                handleChangeInput={this.handleChangeInput.bind(this)}
                handleUpdateUser={this.handleUpdateUser.bind(this)}
                handleUpdateCurrentCard={this.handleUpdateCurrentCard.bind(this)}
                handleUpdateCurrentCardNative={this.handleUpdateCurrentCardNative.bind(this)}
                navigation={navigation}
                handleAddAddress={this.handleAddAddress.bind(this)}
                handleUpdateAddress={this.handleUpdateAddress.bind(this)}
                handleRemoveAddress={this.handleRemoveAddress.bind(this)}
                handleAddCard={this.handleAddCard.bind(this)}
                handleRemoveCard={this.handleRemoveCard.bind(this)}
                isLoading={isLoading}
                isOpenSignUp={this.state.isOpenSignUp}
                isOpenLogin={this.state.isOpenLogin}
                toggleSignUp={this.toggleSignUp}
                toggleLogin={this.toggleLogin}
                toastMessage={this.state.toastMessage}
                handleFocusField={this.handleFocusField.bind(this)}
                focusedField={this.state.focusedField}
            />

        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.user.isFetching,
    user: state.user
});

const mapDispatchToProps = {
    updateUser,
    createUserCard,
    removeUserCard
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);