import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  deleteMessages,
  toggleWidget,
  renderCustomComponent,
} from "react-chat-widget";
import { io } from "socket.io-client";
import { close } from "ionicons/icons";
import * as Sentry from "@sentry/react";
import Toast from "../UI/Toast";
import config from "../../../config/config";
import "react-chat-widget/lib/styles.css";
import {
  getCurrentGenie,
  clearCurrentGenie,
  getChatUser,
  getMessagesSelected,
  receiveMessage,
  setChatOpen,
  getGlobalChatStatus,
  clearUnseenMessages,
  setToastMessage,
} from "../../../actions/chatAction";
import i18n from "../../../translations/i18n";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import ChatImageInput from "./chatImageInput";
import ReactLoading from "react-loading";
import refresh from "../../../assets/images/refresh-outline.svg";
import moment from "moment";
import "moment/locale/fr";

const notification = new Audio(process.env.PUBLIC_URL + "/sounds/tada.mp3");

const renderImage = (props) => {
  if (props.isUser) {
    return (
      <div style={{ justifyContent: "flex-end", flex: 1, display: "flex" }}>
        <img
          src={props.image}
          alt={""}
          style={{
            marginTop: 10,
            maxWidth: "90%",
            height: "auto",
            borderRadius: 5,
          }}
        />
      </div>
    );
  }

  return (
    <img
      src={props.image}
      alt={""}
      style={{
        marginTop: 10,
        maxWidth: "90%",
        height: "auto",
        borderRadius: 5,
      }}
    />
  );
};

const renderBtn = (props) => {
  return (
    <Link to={props.link} style={{ width: "100%" }}>
      <button className="primary chatButton">{props.title}</button>
    </Link>
  );
};

class ChatButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount = () => {
    this.init();
  };

  componentDidUpdate() {
    this.init();
  }

  init = () => {
    if (this.props.showChat === true && this.state.isOpen === false) {
      this.setState({ isOpen: true }, () => {
        this.connectSocket().finally(() => toggleWidget());
      });
    } else if (this.props.showChat === false && this.state.isOpen === true) {
      this.setState({ isOpen: false }, toggleWidget);
    }
  };

  openChat = () => {
    if (window.navigator.onLine) {
      this.props.setChatOpen(true);
    } else {
      this.props.setToastMessage(i18n.t("error_messages.internet_connection"));
    }
  };

  closeChat = () => {
    this.props.setChatOpen(false);
    this.leaveChatroom();
  };

  refreshChat = async () => {
    await this.closeChat();
    await this.openChat();
  };

  connectSocket = async () => {
    const { getMessagesSelected } = this.props;

    let room = this.props.chat;

    if (room && !room.error) {
      deleteMessages(1000);
      let messages = await getMessagesSelected(room.id);

      messages
        .slice(0)
        .reverse()
        .forEach((m) => {
          let messageUserId = undefined;
          if (m.user._id)
            messageUserId =
              typeof m.user._id == "string" ? m.user._id : m.user._id.id;

          if (messageUserId === this.props.user.id && !m.isImage) {
            addUserMessage(m.text, true, messageUserId);
            renderCustomComponent(CustomTimeStampFragment, {
              date: m.createdAt,
            });
          } else {
            if (m.linkType) {
              renderCustomComponent(renderBtn, {
                title: "Payer ma commmande",
                link: "/" + m.linkType + "/" + m.linkId,
              });
            } else if (m.isImage) {
              renderCustomComponent(renderImage, {
                image: m.image,
                isUser: messageUserId === this.props.user.id,
              });
              if (messageUserId === this.props.user.id)
                renderCustomComponent(CustomTimeStampFragment, {
                  date: m.createdAt,
                });
              else
                renderCustomComponent(CustomTimeStampFragment, {
                  isResponse: true,
                  date: m.createdAt,
                });
            } else {
              addResponseMessage(m.text, messageUserId);
              renderCustomComponent(CustomTimeStampFragment, {
                isResponse: true,
                date: m.createdAt,
              });
            }
          }
        });

      const handleMsg = async (message) => {
        const messageUserId =
          typeof message.user == "string" ? message.user : message.user.id;

        if (
          messageUserId !== this.props.user.id &&
          !message.linkType &&
          !message.isImage
        ) {
          addResponseMessage(message.message, messageUserId);
        } else if (message.linkType) {
          renderCustomComponent(renderBtn, {
            title: "Payez ma commmande",
            link: "/" + message.linkType + "/" + message.linkId,
          });
        } else if (message.isImage) {
          renderCustomComponent(renderImage, {
            image: message.message,
            isUser: messageUserId === this.props.user.id,
          });
          renderCustomComponent(CustomTimeStampFragment, {
            isResponse: false,
            date: message.createdAt,
          });
        }
        if (message.message && messageUserId != this.props.user.id) {
          try {
            notification.play();
          } catch (error) {
            Sentry.captureException(new Error(error.message ? error.message : 'Notification could not play'));
          }
          await this.props.clearUnseenMessages(room.id, messageUserId);
        }
      };

      /**
       * /!\
       * Socket connect -> join room & listen to events
       */

      if (!this.socket) {
        const socket = io(`${config.chatWebSocketUrl}/chat`, {
          reconnection: true,
          reconnectionAttempts: 5,
          reconnectionDelay: 1000,
          auth: { token: `JWT ${this.props.user.token}` },
        });

        socket.on("joinedRoom", (room) => {
          // Sentry Tracing Logs
          this.roomTransaction = Sentry.startTransaction({ 
            op: 'joinRoom', 
            name: 'Client Chatroom Session', 
            tags: {
              room_id: room,
              user_id: this.props.user.id
            } 
          });
          console.log(`Joined room: ${room}`); 
          this.chatroom = room;
        });

        socket.on("leftRoom", (room) => {
          this.roomTransaction.finish();
          console.log(`Left room: ${room}`);
          if (this.chatroom) {
            this.chatroom = null;
          }
          if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
          }
          clearInterval(this.checkSocketInterval);
        });

        socket.on("disconnect", this.disconnected);

        this.socket = socket;

        socket.io.on("reconnect_failed", () => {
          this.closeChat();
        });
      }

      if (!this.chatroom) {
        if (this.socket) {
          this.socket.emit("joinRoom", room.id);
          this.socket.on("chatToClients", handleMsg);
        }
      }
    }
  };

  leaveChatroom = () => {
    if (this.chatroom) {
      if (this.socket) {
        this.socket.emit("leaveRoom", this.chatroom);
        this.socket.off("chatToClients");
      }
      this.chatroom = null;
    }
  };

  disconnected = () => {
    if (this.socket) {
      this.socket.emit("disconnected");
    }
    this.props.setToastMessage(`⚠️ ${i18n.t("chat.disconnected")}`);
    this.chatroom = null;
    this.socket = null;
    this.closeChat();
  };

  handleErrorMsg = (message) => {
    // Log the error with Sentry
    if (message) {
      Sentry.captureException(new Error(message ? message : 'Client emit message error'));
    }

    addResponseMessage(i18n.t("chat.errorMessage"));
    renderCustomComponent(CustomTimeStampFragment, {
      isResponse: true,
      date: new Date().toISOString(),
    });
  };

  handleNewUserMessage = (newMessage) => {
    if (window.navigator.onLine) {
      const newMessageTransaction = Sentry.startTransaction({ 
        op: 'handleNewUserMessage',
        name: 'Handle New Client Message',
        tags: {
          user_id: this.props.user.id,
          chat_id: this.props.chat.id,
          message: newMessage
        }
      });

      const chatMessage = {
        message: newMessage,
        user: this.props.user.id,
        chat: this.props.chat.id,
      };

      const socketEmitSpan = newMessageTransaction.startChild({ 
        op: 'socket.emit.chatToServer', 
        description: 'Emit chatToServer message' 
      });

      if (this.socket) {
        this.socket.emit("chatToServer", chatMessage, (response) => {
          if (response.error) {
            Sentry.captureEvent({
              message: 'Message not successfully submitted to websocket server',
              level: 'error',
              tags: {
                user_id: this.props.user.id,
                chat_id: this.props.chat.id,
                message: newMessage
              },
            });
            this.handleErrorMsg();
          }
          socketEmitSpan.finish();
          newMessageTransaction.finish();
        });
      }

      this.props.getGlobalChatStatus().then((_) => {
        if (!this.props.isGlobalChatOpen) {
          addResponseMessage(
            `${i18n.t("chat.hello")} ${this.props.user.firstName}. ${
              i18n.currentLocale() == "fr"
                ? this.props.closedChatMessageFr
                : this.props.closedChatMessageEn
            }`
          );
          renderCustomComponent(CustomTimeStampFragment, {
            isResponse: true,
            date: new Date().toISOString(),
          });
        }
      });
    } else {
      this.props.setToastMessage(i18n.t("error_messages.internet_connection"));
      this.closeChat();
    }
  };

  onImageUpload = async (url) => {
    const chatMessage = {
      isImage: true,
      message: url,
      user: this.props.user.id,
      chat: this.props.chat.id,
    };
    if (this.socket) {
      this.socket.emit("chatToServer", chatMessage, (response) => {
        if (response.error) {
          this.handleErrorMsg(response.message);
        }
      });
    }
  };

  getCustomLauncher = () => {
    return !this.props.showChat ? (
      <div
        style={{ cursor: "pointer" }}
        onClick={this.openChat}
        id="chatButton"
      >
        <div className="roundedLogo">
          {this.props.fetchingGenie ? (
            <ReactLoading
              type={"spin"}
              color={"white"}
              height={35}
              width={35}
            />
          ) : (
            <img src="/images/logo_color.png" alt="logo color genie montreal" />
          )}
        </div>
        <span>
          {this.props.fetchingGenie
            ? i18n.t("chat.connecting")
            : i18n.t("chat.talk")}
        </span>
      </div>
    ) : (
      <>
        <div
          onClick={this.refreshChat}
          style={{
            position: "absolute",
            width: "10%",
            height: 70,
            top: 0,
            right: 30,
            cursor: "pointer",
          }}
        >
          <SVG
            src={refresh}
            alt="refresh"
            style={{
              position: "absolute",
              color: "white",
              width: 30,
              top: 10,
              right: 10,
              cursor: "pointer",
              fill: "white",
            }}
          />
        </div>
        <div
          onClick={this.closeChat}
          style={{
            position: "absolute",
            width: "10%",
            height: 70,
            top: 0,
            right: 0,
            cursor: "pointer",
          }}
        >
          <SVG
            src={close}
            alt="close"
            style={{
              position: "absolute",
              color: "white",
              width: 30,
              top: 10,
              right: 10,
              cursor: "pointer",
              fill: "white",
            }}
          />
        </div>
        <ChatImageInput label={""} callback={this.onImageUpload} />
      </>
    );
  };

  render = () => {
    return (
      <>
        <Toast />
        <Widget
          handleNewUserMessage={this.handleNewUserMessage}
          title={i18n.t("chat.talkNative")}
          subtitle=""
          launcher={(handleToggle) => this.getCustomLauncher(handleToggle)}
          //titleAvatar={'/images/logo_color.png'}
          showCloseButton
          senderPlaceHolder={i18n.t("chat.placeHolder")}
          showTimeStamp={false}
          profileAvatar={"/images/logo_color_min.png"}
          emojis={false}
        />
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  messages: state.chat.messages,
  user: state.user.user,
  chat: state.chat.chat,
  showChat: state.chat.isOpen,
  isGlobalChatOpen: state.chat.isGlobalOpen,
  closedChatMessageFr: state.chat.chatMessageFr,
  closedChatMessageEn: state.chat.chatMessageEn,
  fetchingGenie: state.chat.isFetching,
});

const mapDispatchToProps = {
  getMessagesSelected,
  getCurrentGenie,
  clearCurrentGenie,
  getChatUser,
  receiveMessage,
  setChatOpen,
  getGlobalChatStatus,
  clearUnseenMessages,
  setToastMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatButtonComponent);

const CustomTimeStampFragment = ({ isResponse, date }) => {
  moment.locale(i18n.currentLocale());

  const currentDate = moment(date);
  const isCurrentDay = currentDate.isSame(new Date(), "day");
  const isCurrentYear = currentDate.isSame(new Date(), "year");

  return (
    <div
      style={{
        fontSize: 10,
        textAlign: isResponse ? "left" : "right",
        width: "100%",
      }}
    >
      {i18n.currentLocale() === "fr"
        ? isCurrentDay
          ? currentDate.format("HH:mm")
          : isCurrentYear
          ? currentDate.format("DD MMMM, HH:mm")
          : currentDate.format("DD MMMM YYYY, HH:mm")
        : isCurrentDay
        ? currentDate.format("HH:mm")
        : isCurrentYear
        ? currentDate.format("MMMM Do, HH:mm")
        : currentDate.format("MMMM Do YYYY, HH:mm")}
    </div>
  );
};
