import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Col, Row, Button, Form, FormText, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';


import i18n from '../../../translations/i18n';

const QCommerce = ({ }) => {
    
    return(
    <div className="paddingContainerMobile">
        <div id="partnerPage" className="qcommercepage">

            <Container id="title-section">

                <div className="title-text-wrapper">
                    <div className="title-text">
                        <h1 className="bigTitle">Genie révolutionne le monde de la livraison avec sa formule <strong>Quick-Commerce (Livraison Ultra-Rapide)</strong>.</h1>
                    </div>
                </div>

                <div id="articleHeader">
                    <p>Article rédigé par: </p>
                    <p>Simo Filali</p>
                    <p>Directeur des Communications, Genie Mtl.</p>
                </div>

                <p><strong>Un concept innovateur...</strong></p>

                <p>Nous définissons le Quick-Commerce (Q-commerce) comme étant la prochaine génération du commerce en ligne pratiquée par Genie depuis 2017. Cette méthode vient révolutionner le commerce électronique traditionnel, en permettant notamment des délais de livraison plus rapides.</p>

                <p>Pour vous mettre en contexte, lorsque le e-commerce est apparu, les délais de livraison standards (DLS) avoisinnaient 3 à 5 jours ouvrables. Ainsi, lorsqu’une personne passait commande, et ce, même à partir du commerce du coin, elle devait souvent attendre ces délais afin de recevoir ses colis.</p>

                <p>Genie a su tirer profit de cette situation et a créé en 2017 sa plateforme de gestion de commande lui permettant de servir sa clientèle la journée-même. Ainsi, le concept du Q-commerce est né et a permis à la clientèle de Genie de bénéficier de DLS plus rapides et plus personnalisés.</p>

                <p>Genie vous livre des articles ménagers, des commissions, des plats des restaurants préférés, et tout ce que vous voulez la journée-même. Ainsi, cette méthode de livraison révolutionnaire permet aux consommateurs de bénéficier d’un sentiment de proximité avec leurs commerçants locaux. Quant à eux, les commerçants peuvent s’appuyer sur un partenaire de livraison qui livre les produits commandés instantannément de manière pratique et rapide.</p>

                <p>Notre PDG et cofondateur, Olivier Hudon, a mentionné:</p>

                <p className="citation">“Le Q-Commerce est l’avenir du commerce de proximité. La COVID-19 nous a démontré, malheureusement, les failles des systèmes de livraison traditionnels. Chez Genie nous avons mis en place un outil permettant à tous de  recevoir instantannément ce qu’ils désirent. Nous croyons que ce système révolutionnera notre manière de consommer, puisqu’il s’agit d’une optimisation des procédures logistiques existantes. Nous sommes heureux de partager cette nouvelle façon de faire avec nos partenaires et nos clients”.  </p>
                <p className="author">Olivier Hudon, PDG et co-fondateur.</p>

                <p><strong>Le Q-Commerce dans notre quotidien.</strong></p>
                <p>Aujourd’hui, et après plusieurs années d’activité, Genie continue de travailler en partenariat avec des centaines d’entrerpises locales pour offrir à ses clients un large éventail de produits pouvant être livré la journée-même. Parmi ces partenaires nous retrouvons des dépanneurs, restaurants, épiceries, magasins en ligne, pharmacies, fleuristes, etc.</p>
                <p>Très tôt, Genie a su déceler le besoin pour livrer d’autres produits que de la nourriture des restaurants. Avec un large panel de choix, le client sur le Market Place de Genie peut tout se faire livrer la journée-même. Après avoir construit sa clientèle et sa légitimité sur les réseaux sociaux, Genie a décidé de lancer son site web transactionnel et ses applications en 2020. Aujourd’hui, Genie livre à des milliers d’utilisateurs par mois!</p>

                <p><strong>Comment ça marche? </strong></p>
                <p>Notre réputation s’est bâti sur la réussite de notre modèle logistique. Grâce à un ciblage optimal de nos Partenaires, un choix méticuleux de nos Assistants Personnels, et une technologie d’optimisation de routes à la fine pointe, nous parvenons aujourd’hui à garantir des délais de livraison imbattables dans la région Montréalaise.</p>
                <p>Pour le client, notre choix de partenaires lui permet de bénéficier de produits frais et locaux, aux prix des magasins. Avec une offre variée, des délais de livraison ultra-rapides, une livraison sécuritaire, et une technologie fiable, nous parvenons à répondre au besoin du client.</p>
                <p>Nos équipes travaillent conjointement avec les partenaires afin de mettre en avant des produits exclusifs et vendeurs, notamment grâce aux données et à une gestion de stock optimisée. Nos équipes commerciales offrent des conseils et des bonnes pratiques permettant à tout partenaire de maitriser les outils qui feront son succès en ligne.</p>

                <p><strong>Toujours à l’affût d’innovation.</strong></p>
                <p>Genie ne cesse de se réinventer et de révolutionner ses façons de faire. Les modes de vies changent, de nouveaux besoins sont nés, et Genie s’adapte. Aujourd’hui plus que jamais les commerces sont amenés à sa digitaliser et à faire face à un nouveau type de demande. Le process du e-commerce comme nous le connaissions avant est remis en question. Les mots d’ordre depuis le début de la pandémie sont: Rapidité et Flexibilité. Le Q-Commerce devient donc la continuité naturelle de son prédecesseur. Genie est présent pour vous y accompagner.</p>

                <p>Pour plus d’informations à ce sujet, nous vous invitons à communiquer avec info@geniemtl.com</p>
                <Link to="/devenez-partenaire"><Button className="primary uppercase">Devenez partenaire</Button></Link>
            </Container>

        </div>
    </div>
)}

export default QCommerce;