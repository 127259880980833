import axios from "axios";
import AppConfig from "../config/config";
import uuid from "uuid-random";
import i18n from "../translations/i18n";

export const DATA_SEND = "REQUEST_DATA_SEND";
export const DATA_RECEIVE = "SOCKET_DATA_RECEIVE";
export const REQUEST_DATA = "REQUEST_CHAT_DATA";
export const RECEIVE_DATA = "RECEIVE_CHAT_DATA";
export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_DATA_SELECTED = "RECEIVE_CHAT_DATA_SELECTED";
export const RECEIVE_CHAT_OPEN_STATUS = "RECEIVE_CHAT_OPEN_STATUS";
export const OPEN_CHAT = "OPEN_CHAT";
export const SET_TOAST = "SET_TOAST";

export const requestData = () => ({
  type: REQUEST_DATA,
});

export const receiveData = (data) => ({
  type: RECEIVE_DATA,
  payload: data,
});

export const receiveUser = (user) => ({
  type: RECEIVE_USER,
  payload: user,
});

export const setChat = (open) => ({
  type: OPEN_CHAT,
  payload: open,
});

export const setToast = (toastMessage) => ({
  type: SET_TOAST,
  payload: toastMessage,
});

export const receiveDataSelected = (data) => ({
  type: RECEIVE_DATA_SELECTED,
  payload: data,
});

export const receiveGlobalOpenStatus = (data) => ({
  type: RECEIVE_CHAT_OPEN_STATUS,
  payload: data,
});

export const messageSend = (messages, data) => ({
  type: DATA_SEND,
  payload: appendMsg(messages, data),
});

export const messageReceive = (messages, data) => ({
  type: DATA_RECEIVE,
  payload: appendMsg(messages, data),
});

const appendMsg = (currentMessages, messages) => {
  if (!Array.isArray(messages)) {
    messages = [messages];
  }

  return messages.concat(currentMessages);
};

const formatMessage = (o) => {
  let id = uuid();
  let user;
  let userId = undefined;

  if (o.user && o.user.id) userId = o.user.id;
  else userId = o.user;

  if (typeof o.user === "object") {
    user = {
      _id: userId,
      ...o.user,
      name: "React Native",
      avatar: require("../assets/images/icon-bg.png"),
    };
  } else {
    user = {
      _id: userId,
      name: "React Native",
      avatar: require("../assets/images/icon-bg.png"),
    };
  }

  const formattedMessage = {
    _id: o.id ? o.id : id,
    text: o.isImage ? "" : o.message,
    linkType: o.linkType,
    linkId: o.linkId,
    isImage: o.isImage,
    image: o.isImage ? o.message : null,
    sent: true,
    payed: o.payed,
    createdAt: o.createdAt ? o.createdAt : new Date(),
    user,
  };

  return formattedMessage;
};

export const getMessagesSelected =
  (id, loadMore = 0) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(requestData());
      axios
        .get(
          AppConfig.baseUrlApi +
            "/message/findByChat?chat=" +
            id +
            "&limit=" +
            (150 + loadMore)
        )
        .then((response) => {
          dispatch(
            receiveDataSelected(
              response.data.messages.map((o) => formatMessage(o))
            )
          );
          resolve(response.data.messages.map((o) => formatMessage(o)));
        })
        .catch((error) => {
          console.log(error);
          dispatch(receiveDataSelected([]));
          reject({ error: "Erreur pas d'historique des messages" });
        });
    });

export const getChatUser = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(requestData());
    axios
      .get(AppConfig.baseUrlApi + "/user/connect")
      .then((response) => {
        dispatch(receiveUser(response.data));
        resolve();
      })
      .catch((error) => {
        console.log(error);
        dispatch(receiveUser({}));
        reject({ message: "Erreur" });
      });
  });

export const getCurrentGenie = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(requestData());
    axios
      .get(AppConfig.baseUrlApi + "/chat/getCurrentGenie", {
        params: { userId: getState().user.user.id },
      })
      .then((response) => {
        dispatch(receiveData(response.data));
        resolve(response.data);
      })
      .catch((error) => {
        dispatch(receiveData({}));
        dispatch(
          setToast({
            msg: i18n.t("global.noGenieAvailable"),
            type: "error",
          })
        );
        reject({ error: "Aucun genie disponible." });
      });
  });

export const clearUnseenMessages = (roomId, userId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        AppConfig.baseUrlApi +
          `/message/clearChatUnseen?chat=${roomId}&user=${userId}`
      )
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        console.log("Could not update seen message");
        resolve();
      });
  });

/**
 * @deprecated
 * Now using NestJS chat websocket to create messages
 */
export const sendMessage = (message) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(messageSend(getState().chat.messages, formatMessage(message)));

    axios
      .post(AppConfig.baseUrlApi + "/message", message)
      .then((response) => {
        if (!response.data.error) {
          resolve();
        } else {
          reject({ message: "Erreur" });
        }
      })
      .catch((error) => {
        console.log(error);
        reject({ message: "Erreur" });
      });
  });

export const mockMessage = (message) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    _dispatch(messageSend(getState().chat.messages, formatMessage(message)));
    resolve(message);
  });

export const receiveMessage = (message) => (_dispatch, getState) =>
  new Promise((resolve) => {
    _dispatch(messageReceive(getState().chat.messages, formatMessage(message)));
    resolve();
  });

export const clearCurrentGenie = () => (_dispatch) =>
  new Promise((resolve) => {
    _dispatch(receiveData({}));
    resolve();
  });

export const setChatOpen =
  (open = true) =>
  (_dispatch, getState) =>
    new Promise(async (resolve) => {
      const user = getState().user.user;

      if (open === false) {
        return _dispatch(setChat(open));
      }

      if (user && user.token) {
        let room = await _dispatch(getCurrentGenie());

        if (room && !room.error) {
          _dispatch(setChat(open));
        }
      } else {
        _dispatch(
          setToast({
            msg: i18n.t("global.needToBeConnected"),
            type: "error",
          })
        );
      }

      resolve();
    });

export const setToasted = () => (_dispatch) =>
  new Promise((resolve) => {
    _dispatch(setToast(null));
    resolve();
  });

export const setToastMessage = (msg) => (_dispatch) =>
  new Promise((resolve) => {
    _dispatch(
      setToast({
        msg,
        type: "error",
      })
    );
    resolve();
  });

export const sendPushToken = (token) => (_dispatch, getState) =>
  new Promise((resolve) => {
    const user = getState().user;
    if (user?.user?.pushToken == token) return resolve("same token");
    if (user && user.user?.token) {
      axios
        .patch(AppConfig.baseUrlApi + "/chat/pushToken", { token })
        .then((response) => {
          return resolve();
        })
        .catch((error) => {
          console.log(error);
          return resolve();
        });
    } else {
      return resolve("not connected");
    }
  });

export const getGlobalChatStatus = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const openStatus = getState().chat.isGlobalOpen;

    dispatch(requestData());
    axios
      .get(AppConfig.baseUrlApi + "/chatconfig/openStatus")
      .then((response) => {
        dispatch(receiveGlobalOpenStatus(response.data));
        resolve();
      })
      .catch((error) => {
        console.log("getGlobalChatStatus err:", error);
        dispatch(receiveGlobalOpenStatus(openStatus));
        reject(error);
      });
  });
