import React from 'react';
import StoreItemComponent from './StoreItem';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import i18n from '../../../translations/i18n';

const StorePageComponent = ({ stores }) => {
    return (
        <div className="paddingContainer">
            <section id="storeSection">
                <div id="breadcrumb">
                    <p><Link to="/">{i18n.t('footer.home')}</Link> &gt; {i18n.t('footer.stores')}</p>
                </div>
                <h4>{i18n.t('search.allStores')}</h4>

                <div className="storeList">
                    <Row>
                    {
                        stores && stores.map((store, index) => (
                            <Col sm="4" md="3" key={index}>
                                <StoreItemComponent store={store} key={index} />
                            </Col>
                        ))
                    }
                    </Row>
                </div>

            </section>
        </div>
    )
};

export default StorePageComponent;