import React, { useEffect } from "react";
import { Row, Button } from "reactstrap";

import { useToasts } from "react-toast-notifications";
import "react-confirm-alert/src/react-confirm-alert.css";

import i18n from "../../translations/i18n";
import moment from "moment";

import Loading from "./UI/Loading";

import { Stepper } from "react-form-stepper";

const TrackMyPickup = ({
  pickup,
  toastMessage,
  refreshTracking,
  isLoading,
  isRefreshing,
  downloadInvoice,
}) => {
  moment.locale(i18n.currentLocale());
  const { addToast } = useToasts();

  const isCancelled = pickup?.status == "cancelled";

  const currentStep =
    pickup?.status === i18n.t("status.planned") ||
    pickup?.status === "planned" ||
    pickup?.status === i18n.t("status.assigned") ||
    pickup?.status === "assigned"
      ? 0
      : pickup?.status === i18n.t("status.onPickup") ||
        pickup?.status === "onPickup" ||
        pickup?.status === "pickupCompleted"
      ? 1
      : pickup?.status === i18n.t("status.onDelivery") ||
        pickup?.status === "onDelivery"
      ? 2
      : 3;

  const steps = isCancelled
    ? [
        { label: i18n.t("status.planned") },
        { label: i18n.t("status.onPickup") },
        { label: i18n.t("status.onDelivery") },
        { label: i18n.t("status.cancelled") },
      ]
    : [
        { label: i18n.t("status.planned") },
        { label: i18n.t("status.onPickup") },
        { label: i18n.t("status.onDelivery") },
        { label: i18n.t("status.completed") },
      ];

  // Set automatic refresh logic every minute
  useEffect(() => {
    const interval = setInterval(refreshTracking, 60000);
    
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <>
      {toastMessage
        ? addToast(toastMessage.msg, {
            appearance: toastMessage.type,
            autoDismiss: true,
          })
        : undefined}

      <img
        style={{ display: "block", margin: "0 auto", width: 200 }}
        src="/images/loading.gif"
        alt=""
      />

      {(isRefreshing || isLoading) && <Loading />}
      <Stepper
        steps={steps}
        activeStep={currentStep}
        connectorStateColors
        connectorStyleConfig={{
          activeColor: "#8c688f",
          completedColor: "#8c688f",
          size: 2,
        }}
        styleConfig={{
          activeBgColor: "#8c688f",
          labelFontSize: "1.5em",
          completedBgColor: "#8c688f",
          borderRadius: "100%",
          size: "3em",
        }}
      />

      {currentStep == 0 ? (
        pickup?.deliveryType == "asap" ? (
          <h3 style={{ textAlign: "center", margin: "50px" }}>
            {i18n.t("trackMyOrder.pickup")}&nbsp;
            {moment(pickup.departureDateStart).format(
              `${
                i18n.locale == "fr"
                  ? "dddd, Do MMMM YYYY [entre]"
                  : "dddd, MMMM Do YYYY [between]"
              } hh:mm a`
            )}
            {i18n.locale == "fr" ? " et " : " and "}
            {moment(pickup.departureDateStart)
              .add(30, "minutes")
              .format("hh:mm a")}
            {`.`}
          </h3>
        ) : (
          <h3 style={{ textAlign: "center", margin: "50px" }}>
            {i18n.t("trackMyOrder.pickup")}&nbsp;
            {moment(pickup.departureDateStart).format(
              `${
                i18n.locale == "fr"
                  ? "dddd, Do MMMM YYYY [entre]"
                  : "dddd, MMMM Do YYYY [between]"
              } hh:mm a`
            )}
            {i18n.locale == "fr" ? " et " : " and "}
            {moment(pickup.departureDateEnd).format("hh:mm a")}
            {`.`}
          </h3>
        )
      ) : currentStep == 3 ? (
        <>
          <h3 style={{ textAlign: "center", marginTop: 50 }}>
            {isCancelled
              ? i18n.t("trackMyOrder.pickupCancelled")
              : i18n.t("trackMyOrder.pickupDone")}
          </h3>
          {!isCancelled && (
            <Row
              className="categoryOrder"
              style={{
                flexDirection: "columns",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="success"
                className="primary"
                style={{ marginTop: 15, marginBottom: 15, marginRight: 10 }}
                onClick={(e) => downloadInvoice("pickup", pickup.id)}
              >
                {i18n.t("historic.emailInvoice")}
              </Button>
              <Button
                variant="success"
                className="primary"
                onClick={(e) => downloadInvoice("pickup", pickup.id, true)}
              >
                {i18n.t("historic.downloadInvoice")}
              </Button>
            </Row>
          )}
        </>
      ) : currentStep == 2 && pickup?.urlTrackingDropoff ? (
        <iframe
          src={pickup.urlTrackingDropoff}
          className="iframeTracking"
        ></iframe>
      ) : currentStep == 1 && pickup?.urlTrackingPickup ? (
        pickup?.status === 'pickupCompleted' ?
          <h3 style={{ textAlign: "center", marginTop: 50 }}>
            {i18n.t("status.pickupCompleted")}
          </h3>
          :
          <iframe
            src={pickup.urlTrackingPickup}
            className="iframeTracking"
          ></iframe>
      ) : (
        <h3 style={{ textAlign: "center", marginTop: 50 }}>
          {i18n.t("trackMyOrder.linkUnavailable")}
        </h3>
      )}
    </>
  );
};

export default TrackMyPickup;
