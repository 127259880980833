import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { payCustomPurchase } from '../../actions/orderAction';

class Purchase extends Component {

    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+ this.props.title;
    }

    createCheckoutSessions = (amount, description) => {
        this.props.payCustomPurchase({amount: parseInt(amount), description})
        .then(url => window.location.replace(url));
    }

    render() {
        const { Layout, navigation, location } = this.props;

        return (
            <Layout
                /**user={this.state.user}*/
                navigation={navigation}
                search={location.search}
                createCheckoutSessions={this.createCheckoutSessions.bind(this)}
            />

        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    payCustomPurchase
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Purchase));