import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from '../../config/config';
import i18n from '../../translations/i18n';
import axios from 'axios';

class TrackMyPickup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toastMessage: undefined,
            pickup: {},
            isLoading: false,
            isRefreshing: false
        }

        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+ props.title;
    }

    componentDidMount() {
        const pickupId = this.props?.match?.params?.id || this.props.route?.params?.pickup?.id;
        if(pickupId){
            this.setState({ isLoading: true });
            axios.get(config.baseUrlApi + '/pickup/'+ pickupId).then(response => {
                this.setState({ pickup: response.data })
            }).catch( error => {
                console.log('TrackMyPickup - ComponentDidMount - get pickup', error);
            }).finally( _ => {
                this.setState({ isLoading: false });
            })
        }
    }

    refreshTracking = () => {
        const pickupId = this.props?.match?.params?.id || this.props.route?.params?.pickup?.id;
        if(pickupId){
            this.setState({ isRefreshing: true });
            axios.get(config.baseUrlApi + '/pickup/'+ pickupId).then(response => {
                this.setState({ pickup: response.data })
            }).catch( error => {
                console.log('TrackMyPickup - ComponentDidMount - get pickup', error);
            }).finally( _ => {
                this.setState({ isRefreshing: false });
            })
        }
    }

    openModal = () => {
        this.setState({ isOpen: true })
    }

    downloadInvoice(type, id, download = false){
        this.setState({ isLoading: true });
        axios.post( config.baseUrlApi + '/' + type + "/sendInvoice", { id: id, download: download }, { responseType: 'blob' } ).then(response => {
            if(download){
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'invoice.pdf');
                document.body.appendChild(link);
                link.click();
            }else{
                this.setState({ toastMessage: { type: 'success', msg: i18n.t('historic.emailSent')} }, () => {
                    this.setState({ toastMessage: undefined });
                });
            }
        }).catch(err => {
            this.setState({ toastMessage: { type: 'error', msg: i18n.t('historic.emailError')} }, () => {
                this.setState({ toastMessage: undefined });
            });
            console.log("ERROR - sendInvoice", err.response);
        }).finally( _ => {
            this.setState({ isLoading: false })
        })
    }
    
    render() {
        const { Layout, isLoading, navigation } = this.props;
        return (
            <Layout
                isOpen={this.props.isOpen || this.state.isOpen}
                isLoading={isLoading || this.state.isLoading }
                refreshTracking={this.refreshTracking}
                isRefreshing={this.state.isRefreshing}
                navigation={navigation}
                pickup={ this.state.pickup }
                toastMessage={this.state.toastMessage}
                openModal={this.openModal}
                downloadInvoice={this.downloadInvoice.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.user.isFetching
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackMyPickup);
