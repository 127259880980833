import axios from 'axios';
import AppConfig from '../config/config';
import moment from "moment";
import i18n from "../translations/i18n";

export const REQUEST_ORDER_DATA = 'REQUEST_ORDER_DATA';
export const RECEIVE_ORDER_DATA = 'RECEIVE_ORDER_DATA';
export const RECEIVE_LOAD_ORDER_DATA = "RECEIVE_LOAD_ORDER_DATA";
export const RECEIVE_RESPONSE_ORDER = "RECEIVE_RESPONSE_ORDER";
export const RECEIVE_CUSTOM_ORDER_DATA = "RECEIVE_CUSTOM_ORDER_DATA";
export const RECEIVE_MY_LAST_ORDER = "RECEIVE_MY_LAST_ORDER";
export const CLEAN_MY_LAST_ORDER = "CLEAN_MY_LAST_ORDER";
export const RATING_ORDER = "RATING_ORDER";


export const requestOrderData = () => ({
    type: REQUEST_ORDER_DATA,
});

export const receiveResponse = () => ({
    type: RECEIVE_RESPONSE_ORDER,
});

export const receiveOrderData = (data, customOrderData) => ({
    type: RECEIVE_ORDER_DATA,
    payload: {orders: data, customOrders: customOrderData},
});

export const receiveCustomOrderData = (data) => ({
    type: RECEIVE_CUSTOM_ORDER_DATA,
    payload: data,
});

export const receiveLoadOrderData = (data) => ({
    type: RECEIVE_LOAD_ORDER_DATA,
    payload: data,
});

export const receiveMyLastOrder = (data) => ({
    type: RECEIVE_MY_LAST_ORDER,
    payload: data,
});

export const ratingOrder = (data) => ({
    type: RATING_ORDER,
    payload: data,
});

export const cleanMyLastOrder = (data) => ({
    type: CLEAN_MY_LAST_ORDER
})

export const getMyOrders = (skip = 0, limit = 10) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        const lang = getState().user.lang;

        _dispatch(requestOrderData());

        axios.get(`${AppConfig.baseUrlApi}/order?sort=date%20DESC&timestamp=${Date.now()}&skip=${skip}&limit=${limit}`)
        .then(response => {
            const pages = Math.ceil(response.data.count / limit)
            Promise.all(response.data.data.map(async o => {
               o = await getOneOrder(o.id, lang)
               o.status = i18n.t('status.'+o.status)
                return o;
            })).then( response => {
                _dispatch(receiveOrderData(response, undefined))
                return resolve(pages);
            }).catch( error => {
                _dispatch(receiveOrderData([], []));
                reject(error); 
            })
        })
        .catch(error => {
            _dispatch(receiveOrderData([], []));
            reject(error);
        });

    })
);

export const getMyCustomOrders = (skip = 0, limit = 10) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestOrderData());
        axios.get(`${AppConfig.baseUrlApi}/customorder?sort=date%20DESC&skip=${skip}&limit=${limit}`)
        .then(response => {
            const pages = Math.ceil(response.data.count / limit)
            response.data.data.map(o => o.status = i18n.t('status.'+o.status));
            _dispatch(receiveOrderData(undefined, response.data.data))
            return resolve(pages);
        })
        .catch(error => {
            _dispatch(receiveOrderData([], []));
            reject(error);
        });

    })
);

export const getOneOrder = (id, lang) => (
    new Promise((resolve, reject) => {
        axios.get(AppConfig.baseUrlApi + '/order/'+id)
        .then(response => {
            if( response.data.items.length > 0) {
                response.data.items.map(i => {
                    i.product = sanitizeProductLang(i.product, lang)
                    if(i.subProducts != null) {
                        i.subProducts = i.subProducts.map(sp => sanitizeProductLang(sp, lang));
                    }
                    return i;
                })
            }
            
            return resolve(response.data);
        })
        .catch(error => {
            return reject(error);
        });

    })
);

export const calculatePrice = order => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestOrderData());
        axios.post(AppConfig.baseUrlApi + '/order/calculatePrice', order)
            .then(response => {
                _dispatch(receiveResponse());
                return resolve(response.data);
            })
            .catch(error => {
                console.log('***** error', error.response)

                _dispatch(receiveResponse());
                reject(error.response.data);
            });
    })
);

export const sendOrder = order => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestOrderData());
        axios.post(AppConfig.baseUrlApi + '/order', order)
        .then(response => {
            _dispatch(receiveMyLastOrder(response.data));
            _dispatch(receiveResponse());
            return resolve(response);
        })
        .catch(error => {
            _dispatch(receiveResponse());
            return reject(error.response);
        });

    })
);

export const payCustomOrder = order => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestOrderData());
        axios.post(AppConfig.baseUrlApi + '/customorder', order)
        .then(response => {
            _dispatch(receiveMyLastOrder(response.data));
            _dispatch(receiveResponse());
            return resolve();
        })
        .catch(error => {
            console.log('***** error.response', error.response);
            _dispatch(receiveResponse());
            return reject(error.response);
        });

    })
);

export const payCustomPurchase = purchase => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        axios.post(AppConfig.baseUrlApi + '/stripe/createPurchaseCheckoutSession', purchase)
        .then(response => {
            return resolve(response.data.url);
        })
        .catch(error => {
            console.log('***** error.payCustomPurchase', error.response);
            return reject(error.response);
        });
    })
);

export const checkVoucherCode = (voucher, type) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        axios.get(AppConfig.baseUrlApi + '/vouchercode/getMyVoucher?name=' + voucher + '&type=' + type)
        .then(response => {
            if(response.data.length > 0) return resolve(response.data[0]);
            else return reject();
        })
        .catch(error => {
            return reject(error);
        });

    })
);

export const cancelOrder = (orderId) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestOrderData());

        axios.patch(AppConfig.baseUrlApi + '/order/' + orderId, { id: orderId, status: 'cancelled'})
        .then(response => {
            let orders = getState().orders.orders.map(x => {
                if(x.id === orderId) x.status = i18n.t('status.cancelled');
                return x;
            });
            _dispatch(receiveOrderData(orders));
            return resolve();
        })
        .catch(error => {
            _dispatch(receiveResponse());
            return reject(error);
        });

    })
);

export const loadMyOrder = orderId => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestOrderData());

        axios.get(AppConfig.baseUrlApi + '/order?where={"id": "'+orderId+'"}')
        .then(response => {            
            if(response.data.data.length > 0){
                const items = response.data.data[0].items.map(i => i.product);
                axios.get(AppConfig.baseUrlApi + '/product?where={"id": '+JSON.stringify(items)+'}').then(products => {
                    response.data.data[0].items = response.data.data[0].items.map( i => {
                        let product = products.data.data.find( p => i.product === p.id);

                        return {
                            ...i,
                            ...product,
                            selectedQuantity: i.quantity
                        }
                    })

                    _dispatch(receiveLoadOrderData(response.data.data[0]));
                    return resolve(response.data.data[0]);

                }).catch(error => {
                    return reject(error);
                });

            }
            else return reject("no result");
        })
        .catch(error => {
            return reject(error);
        });

    })   
);

export const updateCommentStatus = (orderId, status) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {

        axios.patch(AppConfig.baseUrlApi + '/order/' + orderId, { id: orderId, commentStatus: status })
        .then(response => {  
            return resolve()
        })
        .catch(error => {
            console.log('***** error', error);
            return reject(error);
        });

    })   
);

export const loadMyCustomOrder = orderId => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestOrderData());

        axios.get(AppConfig.baseUrlApi + '/customorder?where={"id": "'+orderId+'"}')
        .then(response => {  
            if(response.data.data.length > 0){
                _dispatch(receiveCustomOrderData(response.data.data[0]));
                return resolve(response.data.data[0]);
            }
            else{
                _dispatch(receiveCustomOrderData());
                return reject("no result");
            }
        })
        .catch(error => {
            console.log('***** error', error);
            return reject(error);
        });

    })   
);

export const ratingMyOrder = (store, order, comment, rating) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestOrderData());

        axios.post( AppConfig.baseUrlApi + '/ordercomment', 
            {
                store: store,
                order: order,
                comment: comment,
                rating: rating
            })
        .then( response => { 
            _dispatch(ratingOrder(order));
            return resolve();
        })
        .catch(error => {
            console.log('***** error', error);
            return reject(error);
        });

    })   
)

export const sanitizeProductLang = (product, lang) => {
    if(lang === 'fr'){
        product.description = product.description_FR;
        product.name = product.name_FR;
        product.keywords = product.keywords_FR;

        if(product.subProducts){
            product.subProducts = product.subProducts.map( sp => ({ ...sp, name: sp.name_FR }))
        }
    }
    else{
        product.description = product.description_EN;
        product.name = product.name_EN;
        product.keywords = product.keywords_EN;

        if(product.subProducts){
            product.subProducts = product.subProducts.map( sp => ({ ...sp, name: sp.name_EN }))
        }
    }

    return product;
}

export const uploadImage = data => (
    (_dispatch) => new Promise((resolve, reject) => {    
        axios.post( AppConfig.baseUrlApi + '/order/upload-image', data)
            .then((response) => {
                return resolve(response.data);
            })
            .catch((error) => {
                console.log('upload error', error);
                return reject(error.response.data);
            });
    })
)