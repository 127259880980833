import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';

import { useToasts } from 'react-toast-notifications'
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import i18n from '../../translations/i18n';
import moment from 'moment';

import Loading from './UI/Loading';

import { Stepper } from 'react-form-stepper';

const TrackMyOrder = ({ isOpen, order, toastMessage, refreshTracking, isLoading, isRefreshing, downloadInvoice}) => {

  moment.locale(i18n.currentLocale());
  const { addToast } = useToasts();

  const isServiceOrder = order?.items?.some(anItem => anItem.product.isService);
  const isCancelled = order?.status == 'cancelled' || order?.status == 'rejected';

  const currentStep = isServiceOrder ?
  ((order?.status === 'waitingValidation') ? 0 :
  (order?.status === 'planned' || order?.status === 'assigned' || order?.status === 'onDelivery') ? 1 :
  2)
  :
  ((order?.status === i18n.t("status.waitingValidation") || order?.status === 'waitingValidation') ? 0 :
  (order?.status === i18n.t("status.planned") || order?.status === 'planned') ? 1 :
  (order?.status === i18n.t("status.assigned") || order?.status === 'assigned') ? 2 :
  (order?.status === i18n.t("status.onDelivery") || order?.status === 'onDelivery') ? 3 :
  4);

  const steps = isServiceOrder ? (
    isCancelled ? 
    [{ label: i18n.t("status.waitingValidation") }, { label: i18n.t("status.confirmed") }, { label: i18n.t("status.cancelled") }]
    :
    [{ label: i18n.t("status.waitingValidation") }, { label: i18n.t("status.confirmed") }, { label: i18n.t("status.completed") }]
  )
  :
  [{ label: i18n.t("status.waitingValidation") }, { label: i18n.t("status.planned") }, { label: i18n.t("status.assigned") }, { label: i18n.t("status.onDelivery") }, { label: i18n.t("status.completed") }];
  
  // Set automatic refresh logic every minute
  useEffect(() => {
    const interval = setInterval(refreshTracking, 60000);
    
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  
  return (
    <>
    { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }

    <img style={{ display: "block", margin: "0 auto", width: 200}} src="/images/loading.gif" alt="" />

    { (isRefreshing || isLoading ) && <Loading /> }
    <Stepper
      steps={steps}
      connectorStateColors
      connectorStyleConfig={{
        activeColor: "#8c688f",
        completedColor: "#8c688f",
        size: 2
      }}

      styleConfig={{
        activeBgColor:"#8c688f",
        labelFontSize: "1.5em",
        completedBgColor: "#8c688f",
        borderRadius:"100%",
        size: "3em"

      }}
      activeStep={currentStep}
    />

    { currentStep == 3 ?
      <iframe src={order.urlTrackingDropoff} className="iframeTracking" ></iframe>
      :
      currentStep == 4 || (isServiceOrder && currentStep == 2) ?
      <> 
        <h3 style={{ textAlign: "center", marginTop: 50 }}>
          { isServiceOrder ? 
          ( isCancelled ? i18n.t('trackMyOrder.serviceCancelled') : i18n.t('trackMyOrder.serviceDone')) 
          : i18n.t('trackMyOrder.done') }
        </h3>
        { !isCancelled && 
        <Row className="categoryOrder" style={{ flexDirection: 'columns', alignItems: 'center', justifyContent: 'center'}}>
          <Button variant="success" className="primary" style={{ marginTop: 15, marginBottom: 15, marginRight: 10}} onClick={e => downloadInvoice('order', order.id)}>{i18n.t('historic.emailInvoice')}</Button>
          <Button variant="success" className="primary" onClick={e => downloadInvoice('order', order.id, true)}>{i18n.t('historic.downloadInvoice')}</Button>
        </Row>
        }
      </>
      :
      isServiceOrder ? 
      <h3 style={{ textAlign: "center", marginTop: 50 }}>
        {i18n.t('trackMyOrder.service')}&nbsp;
        {moment(order.deliveryDate).format(`${i18n.locale == 'fr' ? 'dddd, Do MMMM YYYY [entre]' : 'dddd, MMMM Do YYYY [between]'} hh:mm a`)}
        {i18n.locale == 'fr' ? ' et ' : ' and '}{moment(order.deliveryDate).add(order?.store?.deliveryRange || 60, 'minutes').format('hh:mm a')}{`.`}
      </h3> 
      : 
      <h3 style={{ textAlign: "center", marginTop: 50 }}>{i18n.t('trackMyOrder.linkUnavailable')}</h3>
    } 
  </>)
};

export default TrackMyOrder;