import React, { Component } from 'react';
import { connect } from 'react-redux';

import { loginFacebook, emailValidation, setToggleLang, getLastOrderToComment } from "../../actions/userAction";
import { getStoreHomepage, getCarouselImages } from "../../actions/storeAction";
import { updateCommentStatus, loadMyOrder } from "../../actions/orderAction";
import { sendPushToken } from "../../actions/chatAction";

import i18n from "../../translations/i18n";

class Home extends Component {

    constructor(props){
        super(props);

        this.state = {
            redirectToHome: false,
            redirectToCart: false,
            orderToReview: undefined,
            showToastReview: false,
            carousel: undefined,
        }
    }

    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+this.props.title;

        if(this.props.location && this.props.location.search.includes('code')){
            let code = this.props.location.search.split('code=')[1];
            this.props.loginFacebook(code).then(response => {
                this.setState({redirectToHome: this.props.cart?.products.length > 0 ? false : true, redirectToCart: this.props.cart?.products.length > 0 ? true : false}, () => {
                    this.setState({ redirectToHome: false, toastMessage: { type: 'success', msg: i18n.t('global.connected')} });
                })
            }).catch( err => {
                this.setState({redirectToHome: true}, () => {
                    this.setState({ redirectToHome: false});
                })
                console.log('***** Home - componentWillMount - loginFacebook', err);
            })
        }

        if(this.props.location && this.props.location.search.includes('validemail')){
            let code = this.props.location.search.split('validemail=')[1];
            this.props.emailValidation(code).then(response => {
                this.setState({ redirectToHome: true, toastMessage: { type: 'success', msg: i18n.t('login.account_activated')} }, () => {
                    this.setState({ toastMessage: undefined, redirectToHome: false });
                });
            }).catch( err => {
                this.setState({ redirectToHome: true, toastMessage: { type: 'error', msg: i18n.t('error_messages.invalid_account_activation')} }, () => {
                    this.setState({ toastMessage: undefined, isLoadingLogin: false });
                });
                console.log('***** Home - componentWillMount - emailValidation', err);
            })
        }

        if(this.props.user?.user?.token){
            this.props.getLastOrderToComment().then(order => {
                if(order){
                    this.setState({ orderToReview: order, showToastReview: true }, () => this.setState({ showToastReview: false }))
                }
            });
        }

        this.props.getCarouselImages().then(response => {
            this.setState({ carousel: response.data.filter(image => image.location?.includes("home")).sort(function(a, b){return a.order-b.order}) })
        }).catch( e => {
            console.log("e",e);
        })
        
        this.props.getStoreHomepage().then(response => {
        }).catch( e => {
            console.log("e",e);
        })
        
    }

    sendPushToken = token => {
        this.props.sendPushToken(token);
    }

    render() {
        const { Layout, stores, cart, user, navigation, setToggleLang, isFetching } = this.props;

        return (

            <Layout
                isFetching={isFetching}
                cart={cart}
                user={user}
                stores={stores}
                carousel={this.state.carousel}
                redirectToHome={this.state.redirectToHome}
                redirectToCart={this.state.redirectToCart}
                navigation={navigation}
                setToggleLang={setToggleLang}
                toastMessage={this.state.toastMessage}
                orderToReview={this.state.orderToReview}
                updateCommentStatus={this.props.updateCommentStatus}
                showToastReview={this.state.showToastReview}
                loadMyOrder={this.props.loadMyOrder}
                sendPushToken={this.sendPushToken}
                route={this.props.route}
            />

        );
    }
}

const mapStateToProps = (state) => ({
    isFetching: state.stores.isFetching,
    cart: state.carts.cart,
    stores: state.stores.stores,
    user: state.user
});

const mapDispatchToProps = {
    sendPushToken,
    loadMyOrder,
    updateCommentStatus,
    getLastOrderToComment,
    getStoreHomepage,
    getCarouselImages,
    loginFacebook,
    setToggleLang,
    emailValidation
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
