import {
    DATA_SEND,
    REQUEST_DATA,
    RECEIVE_DATA,
    RECEIVE_USER,
    RECEIVE_DATA_SELECTED,
    RECEIVE_CHAT_OPEN_STATUS,
    DATA_RECEIVE,
    OPEN_CHAT,
    SET_TOAST,
} from '../actions/chatAction';
import { LOG_OUT } from '../actions/userAction';
import Store from '../store/chatStore';

export const initialState = Store;

export default (state = initialState, action) => {
    switch (action.type) {
        case DATA_SEND:
            return {
                ...state,
                messages: action.payload,
            };
        case DATA_RECEIVE:
            return {
                ...state,
                messages: action.payload,
            };
        case REQUEST_DATA:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_DATA:
            return {
                ...state,
                isFetching: false,
                chat: action.payload,
            };
        case RECEIVE_DATA_SELECTED:
            return {
                ...state,
                isFetching: false,
                messages: action.payload,
            };
        case RECEIVE_USER:
            return {
                ...state,
                user: action.payload,
            };
        case RECEIVE_CHAT_OPEN_STATUS:
            return {
                ...state,
                isFetching: false,
                isGlobalOpen: action.payload.isOpen,
                chatMessageFr: action.payload.messageFr,
                chatMessageEn: action.payload.messageEn,
            };
        case OPEN_CHAT:
            return {
                ...state,
                isOpen: action.payload,
            };
        case SET_TOAST:
            return {
                ...state,
                toast: action.payload,
            };
        case LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
