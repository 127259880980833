export default {
    isFetching: false,
    isRequesting: false,
    store: {},
    stores: [],
    searchedStores: [],
    searchedServices: [],
    carouselImages: [],

};
