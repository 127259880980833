import React from 'react';
import {RemoveScroll} from 'react-remove-scroll';
 
const Loading = ({emptyPage}) => (
    <RemoveScroll>
        <div className={emptyPage ? 'heightLoader' : ''}>
                <div className="lds-ripple"><div></div><div></div></div>
                <div className="backLoader"></div>
        </div>
    </RemoveScroll>
)

export default Loading;