import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

class Header extends Component {
    render() {
        const { Layout, LayoutTopBar, handleToggleLang } = this.props;

        return (

            <Layout 
                handleToggleLang={handleToggleLang}
                isHomePage={this.props.location.pathname === '/'}
                Layout={LayoutTopBar}
            />
        );
    }
}

export default withRouter(props => <Header {...props} />)
