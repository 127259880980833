import axios from 'axios';
import AppConfig from '../config/config';

export const REQUEST_SEARCH_DATA = 'REQUEST_SEARCH_DATA';
export const RECEIVE_SEARCH_DATA = 'RECEIVE_SEARCH_DATA';

export const requestSearchData = () => ({
    type: REQUEST_SEARCH_DATA,
});

export const receiveSearchData = (data) => ({
    type: RECEIVE_SEARCH_DATA,
    payload: data,
});

export const searchAll = (keyword) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        const lang = getState().user.lang;

        _dispatch(requestSearchData());
        axios.get(AppConfig.baseUrlApi + '/search/search?term=' + keyword)
            .then(response => {
                // response.data.products.map(p => sanitizeProductLang(p, lang));
                for (const [key, value] of Object.entries(response.data.products)) {
                    response.data.products[key] = value.map(p => sanitizeProductLang(p, lang));
                }
                response.data.stores.map(s => sanitizeStoreLang(s, lang));
                response.data.services.map(se => sanitizeStoreLang(se, lang));
                return resolve(_dispatch(receiveSearchData(response.data)));
            })
            .catch(error => {
                _dispatch(receiveSearchData({ products: {}, stores: [], services: []}));
                reject(error);
            });
    })
);

export const sanitizeStoreLang = (store, lang) => {
    if(lang === 'fr'){
        store.categoryName = store.categoryName_FR;
        store.parentCategoryName = store.parentCategoryName_FR;
        store.keywords = store.keywords_FR;
    }
    else{
        store.categoryName = store.categoryName_EN;
        store.parentCategoryName = store.parentCategoryName_EN;
        store.keywords = store.keywords_EN;
    }

    return store;
}

export const sanitizeProductLang = (product, lang) => {
    if(lang === 'fr'){
        product.description = product.description_FR;
        product.name = product.name_FR;
        product.keywords = product.keywords_FR;

        if(product.category){
            product.category = product.category.name_FR;
        }
    }
    else{
        product.description = product.description_EN;
        product.name = product.name_EN;
        product.keywords = product.keywords_EN;

        if(product.category){
            product.category = product.category.name_EN;
        }
    }

    return product;
}