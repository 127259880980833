import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Redirect, Link } from "react-router-dom";
import i18n from '../../translations/i18n';
import SVG from 'react-inlinesvg';
import StoreItemComponent from './Store/StoreItem';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import { resizeImage } from '../../config/utils';

import Skeleton from './UI/Skeleton';

import { useToasts } from 'react-toast-notifications'

const Home = ({ redirectToHome, redirectToCart, stores, isFetching, toastMessage, carousel }) => {
    const { addToast } = useToasts();
    let currentCat = '';

    let refs = [];

    const executeScroll = (cat) => {
        refs[cat].scrollIntoView();
    }

    if(toastMessage)  addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true });

    if(redirectToHome) return(<Redirect to="/" />)
    if(redirectToCart) return(<Redirect to="/store/reservation" />)

    return(
        <>
            <section id="storeSection" className="paddingContainer">
                { carousel?.length > 0 && <Row>
                    <Col sm="12" md="12">
                        <CarouselProvider
                            totalSlides={carousel.length}
                            visibleSlides={2}
                            interval={10000}
                            infinite
                            isIntrinsicHeight={true}
                            isPlaying={true}
                            className="carouselContainer"
                            // hasMasterSpinner
                        >
                            <Slider className="carouselSlider">
                                {carousel?.map((slide, key) =>
                                <Slide className="carouselSlide" style={{ backgroundImage: `url(${resizeImage(slide.background, '_1000x.')})`}} index={key}>
                                    <Link to={slide.url} className="carouselContent">
                                        <h5 className="carouselText" style={{ color: slide.textColor }}>{slide.text}</h5>
                                        <button className="carouselButton">{slide.buttonText}</button>
                                    </Link>
                                </Slide>)}
                            </Slider>
                            {carousel.length > 2 ?
                            <>
                                <ButtonBack className="carouselNav previous"><SVG src={chevronBackOutline} alt="icon previous" className="carouselIcon"/></ButtonBack>
                                <ButtonNext className="carouselNav next"><SVG src={chevronForwardOutline} alt="icon next" className="carouselIcon"/></ButtonNext>
                            </> : null}                        
                        </CarouselProvider>
                    </Col>
                </Row>}

                <Row>

                    <Col sm="4" md="4">
                        <Link to={"/stores/restaurant"}>
                            <div className="storeItem">
                                <div className="storeImage" style={{
                                    backgroundImage: "linear-gradient(#342535, #342535), url('/images/min_food.jpg')",
                                    backgroundBlendMode: "hard-light",
                                    height: 120
                                }}>
                                    <p style={{ color: "white", textAlign: "center", width: "100%", lineHeight: "120px", fontSize: "1.8em" }}>{ i18n.t("rounded_menu.food")}</p>
                                </div>
                            </div>
                        </Link>
                    </Col> 

                    <Col sm="4" md="4">
                        <Link to={"/stores/commerce"}>
                            <div className="storeItem">
                                <div className="storeImage" style={{
                                    backgroundImage: "linear-gradient(#342535, #342535), url('/images/min_store.jpg')",
                                    backgroundBlendMode: "hard-light",
                                    height: 120
                                }}>
                                    <p style={{ color: "white", textAlign: "center", width: "100%", lineHeight: "120px", fontSize: "1.8em" }}>{ i18n.t("rounded_menu.shop")}</p>
                                </div>
                            </div>
                        </Link>
                    </Col> 
                    <Col sm="4" md="4">
                        <Link to={"/stores/service"}>
                            <div className="storeItem">
                                <div className="storeImage" style={{
                                    backgroundImage: "linear-gradient(#342535, #342535), url('/images/min_service.jpg')",
                                    backgroundBlendMode: "hard-light",
                                    height: 120
                                }}>
                                    <p style={{ color: "white", textAlign: "center", width: "100%", lineHeight: "120px", fontSize: "1.8em" }}>{ i18n.t("rounded_menu.services")}</p>
                                </div>
                            </div>
                        </Link>
                    </Col> 
                </Row>

                {/* <div class="scroll-horizontal">
                    { isFetching ? undefined : 
                        
                        <>

                        { stores && stores.sort((a,b) => (a['parentCategoryName_' + i18n.locale.toUpperCase()] > b['parentCategoryName_' + i18n.locale.toUpperCase()]) ? 1 : ((b['parentCategoryName_' + i18n.locale.toUpperCase()] > a['parentCategoryName_' + i18n.locale.toUpperCase()]) ? -1 : 0)).map((store, index) => {

                            let newCat = false;
                            if(store['parentCategoryName_' + i18n.locale.toUpperCase()] && currentCat != store['parentCategoryName_' + i18n.locale.toUpperCase()]){
                                currentCat = store['parentCategoryName_' + i18n.locale.toUpperCase()];
                                newCat = true;
                            }
                            else if(!store['parentCategoryName_' + i18n.locale.toUpperCase()]){
                                currentCat = '';
                                newCat = true;
                            }

                            return(
                            <>
                                { newCat ?
                                        <div
                                            className="scroll-horizontal-category"
                                            onClick={e => executeScroll(store['parentCategoryName_' + i18n.locale.toUpperCase()] ? store['parentCategoryName_' + i18n.locale.toUpperCase()] : i18n.t('global.other'))}>
                                            {store['parentCategoryName_' + i18n.locale.toUpperCase()] ? store['parentCategoryName_' + i18n.locale.toUpperCase()] : i18n.t('global.other')}
                                        </div>
                                    : undefined
                                }
                            </>                                 
                        )})}
                        </>
                    }

                </div> */}

                <div className="storeList">
                    { isFetching ? <Skeleton /> :
                    <Row>
                        <div className="serviceDropdown" style={{ display: 'block', width: '100%', marginLeft: 25, marginTop: 50}}>
                            <span className="dropdownTitle">
                                    <h4>{i18n.t('homepage.featured')}</h4>
                            </span>
                        </div>

                    {
                        stores && stores.sort((a,b) => (a['parentCategoryName'] > b['parentCategoryName']) ? 1 : ((b['parentCategoryName'] > a['parentCategoryName']) ? -1 : 0)).map((store, index) => {

                            let newCat = false;
                            if(store['parentCategoryName'] && currentCat != store['parentCategoryName']){
                                currentCat = store['parentCategoryName'];
                                newCat = true;
                            }
                            else if(!store['parentCategoryName']){
                                currentCat = '';
                                newCat = true;
                            }

                            return(
                            <>

                            { newCat ?
                            <div ref={el => (refs[store['parentCategoryName'] ? store['parentCategoryName'] : i18n.t('global.other')] = el)} className="serviceDropdown" style={{ display: 'block', width: '100%', marginLeft: 25, marginTop: 0}} key={'dp-'+index}>
                                <span className="dropdownTitle">
                                        <hr/>
                                </span>
                            </div> : undefined
                            }

                            <Col sm="4" md="3" key={'col'+index}>
                                <StoreItemComponent store={store} key={'si'+index} />
                            </Col> 

                            </>
                        )})
                    }
                    </Row> }
                </div>
            </section>

            <section id="mobileApplicationSection">
                <div className="imgMobileBlock">
                    <img src={"/images/mobile/phone_half_accueil_fr.png"} alt="mobile app"/>
                    {/* <img src={"/images/app_phone_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".png"} alt="mobile app"/> */}
                </div>
                <div className="textBlock">
                    <h3 className="title">{i18n.t('homepage.applicationTitle')}</h3>
                    <p className="text">
                        {i18n.t('homepage.applicationDescription')}
                    </p>
                    <div className="badgeDownload">
                        <a href="https://apps.apple.com/us/app/genie-mtl/id1545682977"><img src={"/images/mobile/download_apple_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".svg"} alt="available on apple store" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.geniemtl.geniemtl"><img src={"/images/mobile/download_google_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".png"} alt="available on play store" /></a>
                    </div>
                </div>
            </section>

            <section id="serviceSection">
                <h1 className="title">{i18n.t('homepage.serviceTitle')}</h1>
                <p className="legend">{i18n.t('homepage.serviceDescription')}</p>
            
                <div className="serviceList">
                    <Row>
                        <Col>
                            <div className="serviceSingle">
                                <div className="serviceImage" style={{ backgroundImage: "url('/images/website/img_become.JPG')" }}></div>
                                <p className="serviceTitle">{i18n.t('homepage.becomeGenie')}</p>
                                <p className="serviceDescription">
                                    {i18n.t('homepage.becomeGenieDescription')}
                                </p>
                                <Link to="/recrutement"><button className="primary">{i18n.t('homepage.joinCrew')}</button></Link>
                            </div>
                        </Col>
                        <Col>
                            <div className="serviceSingle">
                                <div className="serviceImage" style={{ backgroundImage: "url('/images/website/img_partner.jpg')" }}></div>
                                <p className="serviceTitle">{i18n.t('homepage.becomePartner')}</p>
                                <p className="serviceDescription">
                                    {i18n.t('homepage.becomePartnerDescription')}
                                </p>
                                <Link to="/devenez-partenaire"><button className="primary">{i18n.t('homepage.joinPartner')}</button></Link>
                            </div>
                        </Col>
                        <Col>
                            <div className="serviceSingle">
                                <div className="serviceImage" style={{ backgroundImage: "url('/images/website/img_job.jpg')" }}></div>
                                <p className="serviceTitle">{i18n.t('homepage.job')}</p>
                                <p className="serviceDescription">
                                    {i18n.t('homepage.jobDescription')}
                                </p>
                                <Link to="/geniebusiness"><button className="primary">{i18n.t('homepage.joinJob')}</button></Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
};

export default Home;