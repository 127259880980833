import axios from 'axios';
import AppConfig from '../config/config';

import moment from 'moment';

import i18n from "../translations/i18n";

import { receiveMyLastOrder } from "./orderAction";

export const REQUEST_PICKUP_DATA = 'REQUEST_PICKUP_DATA';
export const RECEIVE_PICKUP_DATA = 'RECEIVE_PICKUP_DATA';
export const RECEIVE_MULTIPLE_PICKUP_DATA = 'RECEIVE_MULTIPLE_PICKUP_DATA';
export const RECEIVE_DATA = 'RECEIVE_DATA';
export const SET_FETCHING = 'SET_FETCHING';

export const requestPickupData = () => ({
    type: REQUEST_PICKUP_DATA,
});

export const setFetching = (data) => ({
    type: SET_FETCHING,
    payload: data,
});

export const receivePickupData = (data) => ({
    type: RECEIVE_PICKUP_DATA,
    payload: data,
});

export const receiveData = () => ({
    type: RECEIVE_DATA
});


export const receiveMultiplePickupData = (data) => ({
    type: RECEIVE_MULTIPLE_PICKUP_DATA,
    payload: data,
});

export const createPickup = pickup => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestPickupData());
        axios.post(AppConfig.baseUrlApi + '/pickup', pickup)
            .then(response => {
                _dispatch(receiveMyLastOrder(response.data));
                return resolve(_dispatch(receivePickupData(response.data.data)));
            })
            .catch(error => {
                _dispatch(receivePickupData({}));
                reject(error.response);
            });
    })
);

export const calculatePrice = pickup => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestPickupData());
        axios.post(AppConfig.baseUrlApi + '/pickup/calculatePrice', pickup)
            .then(response => {
                _dispatch(receiveData());
                return resolve(response.data);
            })
            .catch(error => {
                _dispatch(receiveData());
                console.log('***** error.response', error.response);
                reject(error.response);
            });
    })
);

export const getMyPickups = (skip = 0, limit = 10) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestPickupData());

        axios.get(`${AppConfig.baseUrlApi}/pickup?sort=date%20DESC&skip=${skip}&limit=${limit}`)
        .then(response => {
            const pages = Math.ceil(response.data.count / limit)
            response.data.data.map(p => p.status = i18n.t('status.'+p.status));
            _dispatch(receiveMultiplePickupData(response.data.data))
            return resolve(pages);
        })
        .catch(error => {
            _dispatch(receiveMultiplePickupData([]));
            reject(error);
        });

    })
);

export const loadMyPickup = pickupId => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        _dispatch(requestPickupData());

        axios.get(AppConfig.baseUrlApi + '/pickup?where={"id": "'+pickupId+'"}')
        .then(response => {            
            if(response.data.data.length > 0){
                _dispatch(receivePickupData(response.data.data[0]));
                return resolve(response.data.data[0]);
            }
            else return reject("no result");
        })
        .catch(error => {
            return reject(error);
        });

    })   
);

export const getAvailableHours = (date) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {        

        _dispatch(setFetching(true));
        axios.get(AppConfig.baseUrlApi + '/pickupConfig/businesshours?date='+date)
            .then(response => {
                resolve(response.data)
                _dispatch(setFetching(false));
            })
            .catch(error => {
                console.log('***** storeAction - getAvailableHours - axios', error);
                reject(error);
                _dispatch(setFetching(false));
            });
    })
);

export const getMonthClosedDays = (date) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {

        _dispatch(setFetching(true));
        axios.get(AppConfig.baseUrlApi + '/pickupConfig/monthClosedDays?date=' + date)
            .then(response => {
                resolve(response.data);
                _dispatch(setFetching(false));
            })
            .catch(error => {
                console.log('***** storeAction - getMonthClosedDays - axios', error);
                reject(error);
                _dispatch(setFetching(false));
            });
    })
);