export const REQUEST_CART_DATA = 'REQUEST_CART_DATA';
export const RECEIVE_CART_DATA = 'RECEIVE_CART_DATA';
export const ADD_CART_DATA = 'ADD_CART_DATA';
export const ADD_TIPS_DATA = 'ADD_TIPS_DATA';

export const requestCartData = () => ({
    type: REQUEST_CART_DATA,
});

export const addTipsData = (data) => ({
    type: ADD_TIPS_DATA,
    payload: data,
});

export const receiveCartData = (data) => ({
    type: RECEIVE_CART_DATA,
    payload: data,
});

export const addCartData = (data) => ({
    type: ADD_CART_DATA,
    payload: data,
});

export const reinitCart = (storeId) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        return resolve(_dispatch(receiveCartData({
            storeId: storeId,
            products: [],
            totalPrice: 0,
        })));
    })
);

export const addProductCart = (items) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        let cart = getState().carts.cart;
        let product = {...items};

        let totalAdditionnalPrice = 0;

        const getAdditionnalPrice = (sp, parent) => {
            totalAdditionnalPrice += (sp.additionalPrice || 0) * sp.quantity;
            if(sp.subProducts) sp.subProducts.forEach(s => { getAdditionnalPrice(s, sp)})
        }

        getAdditionnalPrice(product);

        if (!cart.totalPrice) cart.totalPrice = 0;

        product.unitPriceOptions = product.unitPrice + totalAdditionnalPrice;
        product.selectedQuantity = 1;
    
        if (cart.products?.length > 0) {
            if (cart.storeId !== product.store) {
                cart.storeId = product.store;
                cart.products = [];
            }

            cart.products.push(product);

        }else{
            cart.storeId = product.store;
            cart.products = [product];
        }

        let totalPrice = 0;

        cart.products.forEach(p => { totalPrice = totalPrice + p.unitPriceOptions * p.selectedQuantity })

        cart.totalPrice = totalPrice;

        return resolve(_dispatch(receiveCartData(cart)));
    })
);


export const chooseService = (service_temp) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        let optionsPrice = 0;

        let service = {...service_temp};

        service.subProducts && service.subProducts.map( sp => {
            if(sp.quantity > 0) optionsPrice += parseFloat(sp.additionalPrice) * sp.quantity;
        })

        service.unitPriceOptions = service.unitPrice + parseFloat(optionsPrice);

        return resolve(_dispatch(receiveCartData({
            storeId: service.store,
            products: [ {...service, selectedQuantity: 1} ],
            totalPrice: service.unitPriceOptions
        })));
    })
);

export const updateQuantityProduct = (index, qty) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        let cart = getState().carts.cart;

        let myProduct = cart.products[index];
        myProduct.selectedQuantity += qty;

        cart.products = cart.products.filter(p => (p.selectedQuantity > 0));

        let totalPrice = 0;

        cart.products.forEach(p => {
            totalPrice = parseFloat(totalPrice) + ( parseFloat(p.unitPriceOptions || p.unitPrice) * parseFloat(p.selectedQuantity) );
        })
        cart.totalPrice = totalPrice;

        return resolve(_dispatch(receiveCartData(cart)));

    })
);

export const addTips = (amount) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        return resolve(_dispatch(addTipsData(amount.toFixed(2))));
    })
);

export const createNewCart = (newCart) => (
    (_dispatch, getState) => new Promise((resolve, reject) => {
        return resolve(_dispatch(receiveCartData(newCart)));
    })
);
