import React, { Component } from "react";
import { connect } from "react-redux";
import { checkFields } from "../../config/utils";
import { errorMessagesLogin, errorMessagesForgot } from "../../config/messages";
import {
  login,
  loginFacebookToken,
  loginFacebookLimited,
  loginUserApple,
  checkAppleId,
  forgot,
  receiveUserData,
} from "../../actions/userAction";
import i18n from "../../translations/i18n";
import { sendPushToken } from "../../actions/chatAction";

class Login extends Component {
  state = {
    formErrors: {},
    isLoadingLogin: false,
    email: "",
    password: "",
    refreshToken: false,
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleLogin = (e) => {
    if (e) e.preventDefault();
    this.setState({ isLoadingLogin: true, formErrors: {} });

    checkFields(errorMessagesLogin, this.state)
      .then(() => {
        this.props
          .login(this.state.email, this.state.password)
          .then((response) => {
            if (this.props.navigation) {
              this.setState({ refreshToken: true }, () => {
                this.setState({ refreshToken: false });
              });
            }

            if (this.props.toggle) {
              this.props.toggle(
                this.props.navigation ? this.props.navigation : undefined
              );
            }
            this.setState({ isLoadingLogin: false, formErrors: {} });
          })
          .catch((error) => {
            if (error.message && error.message.notActivate) {
              this.setState(
                {
                  toastMessage: {
                    type: "error",
                    msg: i18n.t(error.message.message),
                  },
                },
                () => {
                  this.setState({
                    toastMessage: undefined,
                    isLoadingLogin: false,
                  });
                  this.props.receiveUserData({
                    ...this.state.user,
                    email: this.state.email,
                  });

                  if (
                    error.message?.message ===
                    "error_messages.user_not_activated"
                  ) {
                    if (this.props.navigation) {
                      this.props.navigation.navigate("AccountActivation");
                    } else if (this.props.toggleAccountActivation) {
                      this.props.toggle && this.props.toggle();
                      this.props.toggleAccountActivation();
                    }
                  }
                }
              );
            }

            let errors = {
              user_not_activated: error.message.notActivate
                ? i18n.t("error_messages.user_not_activated")
                : undefined,
              standard_user_only:
                error.message.message &&
                error.message.message.startsWith(
                  "ONLY STANDARD USER CAN CONNECT"
                )
                  ? i18n.t("error_messages.invalid_credentials")
                  : undefined,
              forbidden:
                error.message.message &&
                error.message.message.startsWith("Unknown user")
                  ? i18n.t("error_messages.invalid_credentials")
                  : undefined,
              wrong_credentials:
                error.message.message &&
                error.message.message === "Invalid Password"
                  ? i18n.t("error_messages.invalid_credentials")
                  : undefined,
              email_not_validated:
                error.code === "EMAIL_NOT_VALIDATED"
                  ? i18n.t("error_messages.email_not_validated")
                  : undefined,
            };

            if (Object.values(errors).every((el) => el === undefined)) {
              errors.unknown = i18n.t("error_messages.unknown_error");
            }

            this.setState((state) => ({
              formErrors: {
                ...state.formErrors,
                login: errors,
              },
              isLoadingLogin: false,
            }));
            console.log("***** Login - handleLogin", error);
          });
      })
      .catch((errors) => {
        this.setState((state) => ({
          formErrors: {
            ...state.formErrors,
            login: errors,
          },
          isLoadingLogin: false,
        }));
      });
  };

  handleForgot = (e, mobile) => {
    if (e) e.preventDefault();
    this.setState({
      isLoadingLogin: true,
      formErrors: {},
      forgotSuccess: false,
    });

    checkFields(errorMessagesForgot, this.state)
      .then(() => {
        this.props
          .forgot(this.state.email.toLowerCase())
          .then((response) => {
            this.setState(
              {
                isLoadingLogin: false,
                forgotSuccess: true,
                isForgot: false,
                formErrors: {},
                toastMessage: {
                  msg: i18n.t("login.forgot_success"),
                  type: "success",
                },
              },
              () => {
                this.setState({ toastMessage: undefined });
              }
            );

            if (mobile) this.props.navigation.navigate("Home");
          })
          .catch((errors) => {
            if (errors.message === "User.invalid.email")
              errors.message = i18n.t("error_messages.invalid_email");
            this.setState((state) => ({
              formErrors: {
                ...state.formErrors,
                forgot: errors,
              },
              isLoadingLogin: false,
              forgotSuccess: false,
            }));
          });
      })
      .catch((errors) => {
        this.setState((state) => ({
          formErrors: {
            ...state.formErrors,
            forgot: errors,
          },
          isLoadingLogin: false,
          forgotSuccess: false,
        }));
      });
  };

  handleLoginFacebook = (token, platform, profile) => {
    return new Promise((resolve, reject) => {
      if (platform === "ios") {
        this.props
          .loginFacebookLimited(profile)
          .then((response) => {
            if (this.props.navigation) {
              this.setState({ refreshToken: true }, () => {
                this.setState({ refreshToken: false });
              });
            }
            resolve(response);
          })
          .catch((error) => {
            console.log("***** Login - handleLoginFacebook ios", error);
            reject(error);
          });
      } else {
        this.props
          .loginFacebookToken(token)
          .then((response) => {
            if (this.props.navigation) {
              this.setState({ refreshToken: true }, () => {
                this.setState({ refreshToken: false });
              });
            }
            resolve(response);
          })
          .catch((error) => {
            console.log("***** Login - handleLoginFacebook android", error);
            reject(error);
          });
      }
    });
  };

  handleCheckAppleId = (credentials) => {
    this.props
      .checkAppleId(credentials)
      .then((response) => {
        console.log("response+++:", response);
        if (!response.err) {
          if (response.status === "ALREADY_CREATED") {
            this.displayConnectedToast();
            if (this.props.navigation) {
              this.setState({ refreshToken: true }, () => {
                this.setState({ refreshToken: false });
              });
              this.props.navigation.goBack();
            }
          } else if (response.status === "NOT_ACTIVATE") {
            this.setState(
              {
                toastMessage: {
                  type: "error",
                  msg: i18n.t("error_messages.user_not_activated"),
                },
              },
              () => {
                this.setState({ toastMessage: undefined });
              }
            );
            if (this.props.navigation) {
              this.setState({ refreshToken: true }, () => {
                this.setState({ refreshToken: false });
              });
              this.props.navigation.goBack();
            }
          } else if (response.status === "ALREADY_USED") {
            this.setState(
              {
                toastMessage: {
                  type: "error",
                  msg: i18n.t("error_messages.appleId_already_linked"),
                },
              },
              () => {
                this.setState({ toastMessage: undefined });
              }
            );
          }
        }
      })
      .catch((err) => {
        console.log("***** Login - handleCheckAppleId - checkAppleId", err);
      });
  };

  toggleHandler = () => {
    this.setState({ formErrors: {} });
    this.props.toggle();
  };

  resetState = () => {
    this.setState({
      forgotSuccess: false,
    });
  };

  displayConnectedToast = () => {
    this.setState(
      { toastMessage: { type: "success", msg: i18n.t("global.connected") } },
      () => {
        this.setState({ toastMessage: undefined });
      }
    );
  };

  displayNotConnectedToast = () => {
    if (this.props.navigation) {
      this.setState(
        {
          refreshToken: true,
        },
        () => {
          this.setState(
            {
              refreshToken: false,
            },
            () => {
              this.setState(
                {
                  toastMessage: {
                    type: "error",
                    msg: i18n.t("global.notConnected"),
                  },
                },
                () => {
                  this.setState({ toastMessage: undefined });
                }
              );
            }
          );
        }
      );
    }
  };

  sendPushToken = (token) => {
    this.props.sendPushToken(token);
  };

  render() {
    const { Layout, isOpen, isLoading, navigation, toggleSignUp, cart } =
      this.props;
    const { formErrors, isLoadingLogin } = this.state;

    return (
      <Layout
        isLoading={isLoading || isLoadingLogin}
        isOpen={isOpen}
        toggleSignUp={toggleSignUp}
        navigation={navigation}
        formErrors={formErrors}
        email={this.state.email}
        password={this.state.password}
        toggle={this.toggleHandler.bind(this)}
        handleChangeInput={this.handleChangeInput.bind(this)}
        handleLoginFacebook={this.handleLoginFacebook.bind(this)}
        handleLogin={this.handleLogin.bind(this)}
        handleCheckAppleId={this.handleCheckAppleId.bind(this)}
        handleForgot={this.handleForgot.bind(this)}
        forgotSuccess={this.state.forgotSuccess}
        resetState={this.resetState.bind(this)}
        toastMessage={this.state.toastMessage}
        displayConnectedToast={this.displayConnectedToast.bind(this)}
        displayNotConnectedToast={this.displayNotConnectedToast.bind(this)}
        cart={cart}
        refreshToken={this.state.refreshToken}
        sendPushToken={this.sendPushToken}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.user.isFetching,
  cart: state.carts.cart,
  user: state.user.user,
});

const mapDispatchToProps = {
  sendPushToken,
  login,
  forgot,
  loginFacebookToken,
  loginFacebookLimited,
  loginUserApple,
  receiveUserData,
  checkAppleId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
