export default {
    isFetching: false,
    isRequesting: false,
    lang: 'fr',
    user: {
        token: null
    },
    cards: [],
    interceptorId: undefined,
    onboarding: true
};
