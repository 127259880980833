import {
    REQUEST_ORDER_DATA,
    RECEIVE_ORDER_DATA,
    RECEIVE_RESPONSE_ORDER,
    RECEIVE_CUSTOM_ORDER_DATA,
    RECEIVE_MY_LAST_ORDER,
    CLEAN_MY_LAST_ORDER,
    RATING_ORDER
} from '../actions/orderAction';

import Order from '../store/orderStore';

export const initialState = Order;

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ORDER_DATA:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_RESPONSE_ORDER:
            return {
                ...state,
                isFetching: false,
            };
        case RECEIVE_ORDER_DATA:
            let orders = {};
            if(action.payload.orders) orders.orders = action.payload.orders;
            if(action.payload.customOrders) orders.customOrders = action.payload.customOrders;

            return {
                ...state,
                isFetching: false,
                ...orders
            };
        case RATING_ORDER:
            let index = state.orders.findIndex( o => o.id == action.payload);

            if(index > -1) state.orders[index].commentStatus = 'done';

            return {
                ...state,
                isFetching: false
            }; 
        case RECEIVE_CUSTOM_ORDER_DATA:
            return {
                ...state,
                isFetching: false,
                order: action.payload,
            };
        case RECEIVE_MY_LAST_ORDER:
            return {
                ...state,
                lastOrder: action.payload,
        };
        case CLEAN_MY_LAST_ORDER:
            return {
                ...state,
                lastOrder: undefined,
        };
        default:
            return state;
    }
}
