import React, { Component } from 'react';

import RoundedMenuComponent from '../../web/components/Layout/RoundedMenu';

class RoundedMenu extends Component {

    render() {
        return (
            <RoundedMenuComponent withChat={this.props.withChat} isHomePage={this.props.isHomePage}/>
        );
    }
}

export default (RoundedMenu);
