import React, { useState, useRef } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Form } from 'reactstrap';

import LocationSearchInput from "../../containers/Utils/LocationSearchInput";
import AddressBlock from '../../containers/UI/AddressBlock';
import CardBlock from '../../containers/UI/CardBlock';

import i18n from '../../translations/i18n';

import AddressBlockComponent from './UI/AddressBlock';
import CardBlockComponent from './UI/CardBlock';
import Loading from "./UI/Loading";
import PhoneInput from "./UI/PhoneInput";

import SignUpModal from '../../containers/Page/SignUpModal';
import SignUpModalComponent from './SignUpModal';

import Login from '../../containers/Page/Login';
import LoginComponent from './Login';

import { useToasts } from 'react-toast-notifications'

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

const Account = ({ user, currentCard, handleChangeInput, handleUpdateUser, handleUpdateCurrentCard, handleAddAddress, handleUpdateAddress, handleRemoveAddress, handleAddCard, isOpenSignUp, isOpenLogin, toggleSignUp, toggleLogin, handleRemoveCard, isLoading, toastMessage, handleFocusField, focusedField }) => { 
    
    const { addToast } = useToasts();
    const [tempAddress, setTempAddress] = useState(undefined);
    let refSearch = useRef();

    return(
    <>
        { isLoading ? <Loading /> : undefined }
        { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }
        { !user.token ? 
            <div id="accountPage" className="paddingContainer">
                <h1 style={{ textAlign: "center"}}>{i18n.t('global.notLoggedTitle')}</h1>
                <div class="not_logged" style={{ textAlign: "center"}}>
                    {i18n.t('global.notLoggedSubtitleShort')}<div class="login_link" onClick={toggleLogin}>{i18n.t('global.notLoggedSubtitleEnd')}</div>
                </div>
            </div>
            
            :

            <div id="accountPage" className="paddingContainer">
                <h2 className="accountTitle">{i18n.t('global.myAccount')}</h2>

                <Form className="accountForm">
                    <FormGroup>
                        <Row>
                            <Col>
                                <Label className="accountLabel" htmlFor="lastName">{i18n.t('global.lastName')}</Label>
                                <Input type="text" name="lastName" value={user.lastName} onChange={ e => handleChangeInput(e) }></Input>
                            </Col>
                            <Col>
                                <Label className="accountLabel" htmlFor="firstName">{i18n.t('global.firstName')}</Label>
                                <Input type="text" name="firstName" value={user.firstName} onChange={ e => handleChangeInput(e) }></Input>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col>
                                <Label className="accountLabel" htmlFor="phone">{i18n.t('global.phoneNumber')}</Label>
                                <PhoneInput phone={user.phone} onChange={ phone => handleChangeInput({ target: { name: "phone", value: phone }})}/>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col>
                                <Label className="accountLabel" htmlFor="phone">{i18n.t('global.address')}</Label>
                                <LocationSearchInput myRef={refSearch} onSelect={(address, latLng) => setTempAddress({address, latLng})} handleCheckedAddress={setTempAddress} />
                            </Col>
                            <Col md="3" style={{ display: "flex"}}>
                                <Button className="submitButton" disabled={!tempAddress} style={{ backgroundColor: "#342535", width: "100%", marginBottom: "1rem", alignSelf: "flex-end"}} onClick={ e => { handleAddAddress(tempAddress.address, tempAddress.latLng); setTempAddress(undefined); refSearch.current.clearValue();} }>
                                    {i18n.t('account.add_address')}
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup className="adressLine">
                        { user?.addresses?.map((a, index) => (
                            <AddressBlock Layout={AddressBlockComponent} address={a} handleRemoveAddress={handleRemoveAddress} handleUpdateAddress={handleUpdateAddress} key={index} index={index} />
                        ))}
                    </FormGroup>

                    <FormGroup>
                        <Label className="accountLabel">{i18n.t('payment.info')}</Label>
                        <Row className="accountCreditCard">
                            <Col xs="4">
                                <Cards
                                    cvc={currentCard.cvc ? currentCard.cvc : ''}
                                    expiry={currentCard.expiry ? currentCard.expiry : ''}
                                    name={currentCard.name ? currentCard.name : ''}
                                    number={currentCard.number ? currentCard.number : ''}
                                    focused={focusedField}
                                    locale={{valid: ""}}
                                    placeholders={{name: "Martin Jean"}}
                                />
                            </Col>
                            <Col xs="8" className="cardInformation">
                                <Row className="accountRow">
                                    <Col>
                                        <Input type="text" placeholder={i18n.t('payment.name')} onFocus={e => {handleFocusField(e)}} name="name" value={currentCard.name ? currentCard.name : ''} onChange={e => handleUpdateCurrentCard(e)}></Input>
                                    </Col>
                                </Row>

                                <Row className="accountRow">
                                    <Col>
                                        <Input type="text" placeholder={i18n.t('payment.number')} onFocus={e => {handleFocusField(e)}} name="number" value={currentCard.number ? currentCard.number : ''} onChange={e => handleUpdateCurrentCard(e)} maxLength="19"></Input>
                                    </Col>
                                </Row>

                                <Row className="accountRow">
                                    <Col>
                                        <Input type="text" placeholder={i18n.t('payment.mmyy')} onFocus={e => {handleFocusField(e)}} name="expiry" value={currentCard.expiry ? currentCard.expiry : ''} onChange={e => handleUpdateCurrentCard(e)}></Input>
                                    </Col>

                                    <Col>
                                        <Input type="text" placeholder={i18n.t('payment.cvc')} onFocus={e => {handleFocusField(e)}} name="cvc" value={currentCard.cvc ? currentCard.cvc : ''} onChange={e => handleUpdateCurrentCard(e)}></Input>
                                    </Col>
                                </Row>

                                <Row>
                                <Button className="primary" style={{ position: "absolute", right:"0"}}
                                    onClick={e => handleAddCard()} 
                                    disabled={  (!currentCard.cvc || !currentCard.cvc.match( /([0-9]{3})/ )) ||
                                                (!currentCard.expiry || !currentCard.expiry.match( /(0[1-9]|10|11|12)\/[0-9]{2}/ )) ||
                                                (!currentCard.number || !currentCard.number.replaceAll(' ','').match( /([0-9]{16})/ )) ||
                                                (!currentCard.name || !currentCard.name.length > 3)
                                            }
                                >{i18n.t('payment.addCard')}</Button>
                                </Row>
                            </Col>
                    
                        </Row>

   

                        <hr />

                        <FormGroup style={{paddingLeft: "100px"}}>
                            { user && user.cards && user.cards.map((c, index) => (
                                <CardBlock Layout={CardBlockComponent} card={c} handleRemoveCard={handleRemoveCard} key={index} />
                            ))}
                        </FormGroup>


                    </FormGroup>

                    <FormGroup className="accountButton">
                        <Button className="submitButton" onClick={ e => handleUpdateUser(e) }>
                            {i18n.t('global.save')}
                        </Button>
                    </FormGroup>
                    
                </Form>

            </div>
        }
        <SignUpModal isOpen={isOpenSignUp} toggle={toggleSignUp} toggleLogin={toggleLogin} Layout={SignUpModalComponent} />
        <Login isOpen={isOpenLogin} toggle={toggleLogin} toggleSignUp={toggleSignUp} Layout={LoginComponent} />
    </>
    )
}

export default Account;