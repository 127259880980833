import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import SVG from 'react-inlinesvg';
import { time } from "ionicons/icons";
import LocationSearchInput from '../../../../containers/Utils/LocationSearchInput';
import PhoneInput from "../../UI/PhoneInput";

import moment from "moment";

import i18n from "../../../../translations/i18n";

import enCA from 'date-fns/locale/en-CA';
import frCA from 'date-fns/locale/fr-CA';
registerLocale('fr-CA', frCA);
registerLocale('en-CA', enCA);


const ReservationStep1Component = ({store, order, currentStep, cart, openingHours, unavailableDays, nextStep, handleUpdateOrderInput, handleCheckedAddress, isService, isServiceRequiringDelivery, isServiceWithoutBooking, isServiceWithDestination, requiredFields, fileSelectedHandler, instructionImages}) => {
    const language = useSelector(state => state.user.lang);
    const hiddenFileInput = useRef(null);
    const currentDate = moment().format("YYYY-MM-DD");
    const maxDate = moment().add( store.maximumTimeBeforeReservation , "days").format("YYYY-MM-DD");

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    return (
        <>
        <FormGroup>
            <Label>{ isService ?
                <div className="categoryTitle">
                    <SVG className="iconCategory" src="/images/menu/icon-location.svg" alt="icon location"/> {i18n.t('payment.paymentInfoClient')}
                </div>
                : 
                <div className="categoryTitle">
                    <SVG className="iconCategory" src="/images/menu/icon-location.svg" alt="icon location"/> {i18n.t('pickup.deliveryAddress')}
                </div> }
            </Label>
            <Row className="clientName reservationRow">
                <Col><Input type="text" value={order.fullName} placeholder={i18n.t('global.contactName')} onChange={ e => handleUpdateOrderInput(e) } name="fullName"></Input>{requiredFields && requiredFields.fullName ? <span className="errorMsg">{requiredFields.fullName}</span> : undefined }<span className="red_ast">*</span></Col>
            </Row>
            <Row className="clientPhone reservationRow">
                <Col><PhoneInput phone={order.phone} onChange={ phone => handleUpdateOrderInput({ target: { name: "phone", value: phone }})}/>{requiredFields && requiredFields.phone ? <span className="errorMsg">{requiredFields.phone}</span> : undefined }<span className="red_ast">*</span></Col>
            </Row>

            { isServiceRequiringDelivery || !isService ? 
                <div className="clientAddress">
                    <Row className="street reservationRow">
                        <FormGroup className="form-check">
                        <div className="categoryTitle">
                            {isServiceWithDestination ? i18n.t('address.pickupAddress') : i18n.t('address.deliveryAddress')}
                        </div>
                        <span className="red_ast">*</span>
                        { order && order.addresses && order.addresses.map( (a, index) => (
                            <Label className="form-check" key={index}>
                                <Input checked={ order.address && order.address.full === a.full } type="radio" name="address" onChange={ e => handleUpdateOrderInput({ target: { name:'address', value: {...a , apartment: a.apartment}}}) }/>{a.full}
                            </Label>
                        ))}

                            <Label className="form-check">
                                <Input value type="radio" name="address" checked={order.displayOtherAddress} onChange={ e => handleUpdateOrderInput({ target: { name:'displayOtherAddress', value: true} }) }/>{i18n.t('global.other')}
                            </Label>
                        </FormGroup>

                        { order && order.displayOtherAddress ?
                            <>
                                <Col xs="8"><LocationSearchInput value={order.otherAddress && order.otherAddress?.formatted_address} handleCheckedAddress={ isChecked => handleCheckedAddress(isChecked)} onSelect={ (a, latLng) => handleUpdateOrderInput({ target: { name: "otherAddress", value: {...a, ...latLng} }})}/></Col>
                                <Col xs="4"><Input type="text" placeholder={i18n.t('address.app')} value={order.otherAddress && order.otherAddress.apartment} onChange={ e => handleUpdateOrderInput(e) } name="apartment"></Input></Col>
                            </>
                            : undefined
                        }

                        {requiredFields && requiredFields.address ? <span className="errorMsg">{requiredFields.address}</span> : undefined }
                    </Row>
                </div> : undefined
            }

            <Row className="clientName reservationRow">
                <Col><Input type="textarea" value={order.instruction} placeholder={store.instructionsPlaceholder ? store.instructionsPlaceholder : i18n.t('global.instruction')} onChange={ e => handleUpdateOrderInput(e) } name="instruction"></Input>{requiredFields && requiredFields.instruction ? <span className="errorMsg">{requiredFields.instruction}</span> : undefined }{store.requiresInstructions ? <span className="red_ast">*</span> : undefined }</Col>
            </Row>
            <div className="categoryTitle">
                {i18n.t('pickup.chooseImages')}
            </div>
            <input type="file" ref={hiddenFileInput} style={{display:'none'}} accept="images/*" multiple onChange={ e => fileSelectedHandler(e) } name="instructionImages"></input>
            <Row className="reservationRow">
                <Col>
                    <Button
                        className="imageButton"
                        onClick={handleClick}
                    >{i18n.t('global.choose')}</Button>
                </Col>
            </Row>
            <Row className="previewRow">
                <Col>
                { instructionImages?.map((image, key) => {
                    return(
                        <img className="previewImage" src={image.file} />
                    )
                })}
                </Col>
            </Row>
        </FormGroup>

        { isServiceWithDestination &&
        
        <Row className="reservationRow">
            <FormGroup className="form-check">
                <div className="categoryTitle">
                    {i18n.t('global.destinationAddress')}
                </div>
            </FormGroup>

            <Col xs="8"><LocationSearchInput value={order.destinationAddress && order.destinationAddress.formatted_address} handleCheckedAddress={isChecked => handleCheckedAddress(isChecked)} onSelect={(a, latLng) => handleUpdateOrderInput({ target: { name: "destinationAddress", value: { ...a, ...latLng } } })} /><span className="red_ast">*</span></Col>
            <Col xs="4"><Input type="text" placeholder={i18n.t('address.app')} value={order.destinationAddress && order.destinationApartment} onChange={e => handleUpdateOrderInput(e)} name="destinationApartment"></Input></Col>
            {requiredFields && requiredFields.destinationAddress ? <span className="errorMsg">{requiredFields.destinationAddress}</span> : undefined }
        </Row> }

        { !isServiceWithoutBooking ? 
        <FormGroup>
            <Label>
                <div className="categoryTitle" style={{ marginBottom: 0}}>
                    <SVG className="iconCategory" src={time} alt="button edit"></SVG> {i18n.t('pickup.datePreference')}
                </div>
            </Label>
            <p className="red_information">{ i18n.t('payment.date_information')}</p>

            <Row className="reservationRow">
                <Col>
                    <DatePicker 
                        className='form-control' 
                        locale={language === 'fr' ? 'fr-CA' : 'en-CA'} 
                        type="date" 
                        placeholderText="Aujourd'hui" 
                        selected={moment(order.sendingDate).toDate()} 
                        onChange={ e => handleUpdateOrderInput({ target: { name:'sendingDate', value: e} }) } 
                        minDate={moment(currentDate).toDate()} 
                        maxDate={moment(maxDate).toDate()} 
                        excludeDates={unavailableDays || []}
                    />
                </Col>
            </Row>
            <Row className="reservationRow">
                <Col>
                    <Input type="select" disabled={!order.sendingDate} id="selectTime" value={order.sendingHours} defaultValue="" onChange={ e => handleUpdateOrderInput(e) } name="sendingHours">
                        <option value="" disabled>{openingHours && openingHours.length === 0 ? i18n.t('pickup.noHours') : '-------'}</option>
                        { openingHours && openingHours.filter((object,index) => index === openingHours.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))).map( (h, i) => (
                            <option value={h.value} key={'dh-'+i}>{h.label}</option>
                        ))}
                    </Input>
                </Col>
            </Row>
            {requiredFields && requiredFields.sendingHours ? <span className="errorMsg">{requiredFields.sendingHours}</span> : undefined }

        </FormGroup> : undefined }

        <FormGroup className="buttonForm">
            <div className="confirmation">
                <Button
                    //disabled={ ( (!isService || isServiceRequiringDelivery) && (order.displayOtherAddress ? !order.otherAddress || !order.isCheckedAddress : !order.address)) || !order.fullName || !order.sendingDate || !order.sendingHours || (!order.phone || order.phone.length !== 10) }
                    className="confirmationButton"
                    onClick={ e => nextStep(!isService || isServiceRequiringDelivery, isServiceWithoutBooking, isServiceWithDestination)}
                >{i18n.t('global.continue')}</Button>
            </div>
        </FormGroup>

        </>
    )

};

export default ReservationStep1Component;
