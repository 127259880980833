import React from 'react';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const inputPhone = ({ phone, onChange}) => (
  
  <PhoneInput
    country={'ca'}
    onlyCountries={['ca']}
    disableCountryCode={true}
    disableDropdown={true}
    placeholder="(555) 555 5555"
    value={phone}
    onChange={phone => onChange(phone)}
    containerStyle={{
      width: "100%"
    }}
    inputStyle={{
      fontFamily: 'Oswald',
      height: '38px',
      width: "100%"

    }}
  />
)

export default inputPhone