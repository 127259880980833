import React from 'react';
import { Redirect } from "react-router-dom";

import ReactLoading from 'react-loading';

import i18n from '../../translations/i18n';

import { useToasts } from 'react-toast-notifications';

const Forgot = ({ state, handleChangeInput, handleChangePassword, isLoading }) => {

    const { addToast } = useToasts();

    return (
        <div id='forgotPage'>
        { state.toastMessage ? addToast(state.toastMessage.msg, { appearance: state.toastMessage.type, autoDismiss: true }) : undefined }
        { state.redirect ? <Redirect to="/" /> : undefined }
        <div className="forgotPage" id="signUpModal">
            <h3 className="text-uppercase">{i18n.t('forgot.title')}</h3>

            <form className="pt-3">
                <div className="inputContainer ml-0 pl-0 w-50">
                    <img src="/images/menu/icon-password.svg" alt="icon password" />
                    <input type="password" name="password" placeholder={i18n.t('forgot.new_password')} autoComplete="username" onChange={e => handleChangeInput(e)} />
                </div>

                <div className="inputContainer ml-0 pl-0 w-50">
                    <img src="/images/menu/icon-password.svg" alt="icon password" />
                    <input type="password" name="confirmPassword" placeholder={i18n.t('forgot.retype_new_password')} autoComplete="current-password" onChange={e => handleChangeInput(e)} />
                </div>

                {
                    isLoading ?
                        <div className="loading">
                            <ReactLoading type={'bars'} color={'#342535'} height={30} width={30} />
                        </div>
                        :
                        <div className="">
                            <button type="submit" disabled={!state.password || !state.confirmPassword || state.password !== state.confirmPassword} className="primary ml-0 mt-5" onClick={e => handleChangePassword(e)}>{i18n.t('forgot.reset')}</button>
                        </div>
                }
            </form>
        </div>
        </div>
    )
};

export default Forgot;