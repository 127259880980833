import React from 'react';

import i18n from '../../translations/i18n';

const Purchase = ({ search, createCheckoutSessions }) => {

    function getQueryVariable(variable) {
        var query = search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
        return null;
    }

    const amount = getQueryVariable('amount');
    const description = getQueryVariable('description');
    const redirect = getQueryVariable('redirect');

    return(
       <div className="paddingContainerMobile">
            <div id="purchasePage">
                <section id="purchase-content">
                    { redirect && redirect == 'cancel' ?  <h1 className="title-content">{i18n.t('purchase.error')}</h1> : null}
                    <h1 className="title-content">{description?.replace('+', ' ')}</h1>
                    { amount && amount > 0 ? <h1 className="title-content">{`${amount / 100}$`}</h1> : null }
                </section>

                { amount && amount > 0 ? 
                <button id='purchase-button' onClick={() => createCheckoutSessions(amount, description)}>
                    <h2 className="purchase-btn-text">{i18n.t('purchase.pay')}</h2>
                </button> : null }
            </div>
       </div>
    );
}

export default Purchase;