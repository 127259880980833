import React from 'react';
import LocationSearchInput from "../../containers/Utils/LocationSearchInput";
import Loading from "./UI/Loading";
import { useToasts } from 'react-toast-notifications'

import { Container, Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';

import i18n from '../../translations/i18n';

const Recruit = ({recruitForm, handleSendEmail, handleUpdateRecruitInput, isLoading, toastMessage}) => {
    
    const { addToast } = useToasts();

    return(
    <div className="paddingContainerMobile">
        { isLoading ? <Loading /> : undefined }
        { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }

        <div id="recruitPage">
            <Container fluid id="recruitTitle">
                <Row lg= {2} sm = {1} xs = {1}>
                    <Col>
                        <img className="responsive-img" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1577735252171-YBGFRHHP6TSV669SFU96/ke17ZwdGBToddI8pDm48kBbgV28uc97m1jNh03OWDNsUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcjD9ITV3Ba5QWq4ueKVGPzZaOMIi-YGrvmC64B5pW7RO3GM1ZD5y1XpGbqy7_FlcE/Assistant+genie.jpg?format=750w"
                        alt="Assistant genie"/>
                    </Col>

                    <Col className="fig-caption">
                        <h1 className="title">{i18n.t('recruit.title')}</h1>
                        <h4 className="legend">{i18n.t('recruit.legend')}</h4>
                    </Col>
                </Row>
            </Container>

            <Container fluid id="recruitInfo">
                <Row lg={3} sm = {1} xs = {1}>
                    <Col>
                        <img className="responsive-img2" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1598370939182-BNNMMJAJ2TTG3ORR5CCP/ke17ZwdGBToddI8pDm48kM2sjr7HLyKE9_ljgMK9YY8UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcb3EU_9sZj7riWmx_pOuwy-T-EXZtoctbKh-JM1vFSzODtfhKcMKwt66NqTBncZiP/undraw_pay_online_b1hk.png?format=1000w"
                        alt="Payment"/>
                        <h2>{i18n.t('recruit.info_payment_title')}</h2>
                        <p>{i18n.t('recruit.info_payment_legend')}</p>
                    </Col>

                    <Col>
                        <img className="responsive-img2" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1598371174884-3ZSEV4ENKP5FO9F0B7V2/ke17ZwdGBToddI8pDm48kMJ--srSKIa_MqeFc1hndnMUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcK8U3t3cjZvim5QCiBovXFTsntRNnbD0NO6KiY4u9af4oaQNTKti48Ng6Gb3dLcAd/undraw_Mobile_life_re_jtih.png?format=1000w"
                        alt="Tools"/>
                        <h2>{i18n.t('recruit.info_tool_title')}</h2>
                        <p>{i18n.t('recruit.info_tool_legend')}</p>
                    </Col>

                    <Col>
                        <img className="responsive-img2" src="https://images.squarespace-cdn.com/content/v1/592cd8b05016e1bf41abdfe3/1598371081057-SKOLIFDNQR0XO8SIE1EP/ke17ZwdGBToddI8pDm48kOgxM3oTKX9nAKUk9g9xi18UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcC0H_086r5asR2_Noycss-29Pau53ozAzbUETDiOvsL_9qXcYq7AIuVKsOst4b6NH/undraw_online_calendar_kvu2.png?format=1000w"
                        alt="Schedule"/>
                        <h2>{i18n.t('recruit.info_schedule_title')}</h2>
                        <p>{i18n.t('recruit.info_schedule_legend')}</p>
                    </Col>
                </Row>
            </Container>

            <Form className="accountForm" style={{padding: "0 10%"}}>
                <h3 style={{textAlign: "center", padding: "5% 0"}}>{i18n.t('recruit.recruit-form')}</h3>
                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="lastName">{i18n.t('global.lastName')}</Label>
                            <Input autofocus={true} type="text" name="lastName" value={recruitForm.lastName || ""} onChange={e => handleUpdateRecruitInput(e)}></Input>
                        </Col>
                        <Col>
                            <Label className="accountLabel" htmlFor="firstName">{i18n.t('global.firstName')}</Label>
                            <Input type="text" name="firstName" value={recruitForm.firstName || ""} onChange={e => handleUpdateRecruitInput(e)}></Input>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="email">{i18n.t('global.email')}</Label>
                            <Input type="email" name="email" value={recruitForm.email || ""} onChange={e => handleUpdateRecruitInput(e)}></Input>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="phone">{i18n.t('global.phoneNumber')}</Label>
                            <Input type="text" name="phone" value={recruitForm.phone || ""} onChange={e => handleUpdateRecruitInput(e)}></Input>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="address">{i18n.t('global.address')}</Label>
                            <LocationSearchInput name="address" value={recruitForm.address || ""} onSelect={e => handleUpdateRecruitInput({ target: { name: "address", value: e.formatted_address }})}/>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="select-work">{i18n.t('recruit.form-legal-work')}</Label>
                            <Input type="select" name="select-work" style={{width: "70px"}} onChange={e => handleUpdateRecruitInput(e)}>
                                <option disabled selected>-----</option>
                                <option>{i18n.t('global.yes')}</option>
                                <option>{i18n.t('global.no')}</option>
                            </Input>
                        </Col>
                    </Row>

                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label className="accountLabel" htmlFor="select-age">{i18n.t('recruit.form-legal-age')}</Label>
                            <Input type="select" name="select-age" style={{width: "70px"}} onChange={e => handleUpdateRecruitInput(e)}>
                                <option disabled selected>-----</option>
                                <option>{i18n.t('global.yes')}</option>
                                <option>{i18n.t('global.no')}</option>
                            </Input>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="accountLabel" htmlFor="disponibilities">{i18n.t('recruit.form-disponibilities')}</Label>
                                <Input type="textarea" name="disponibilities" value={recruitForm.disponibilities || ""} onChange={e => handleUpdateRecruitInput(e)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="accountLabel" htmlFor="languages">{i18n.t('recruit.form-languages')}</Label>
                                <Input type="textarea" name="languages" value={recruitForm.languages || ""} onChange={e => handleUpdateRecruitInput(e)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="accountLabel" htmlFor="transport">{i18n.t('recruit.form-transport')}</Label>
                                <Input type="textarea" name="transport" value={recruitForm.transport || ""} onChange={e => handleUpdateRecruitInput(e)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="accountLabel" htmlFor="payment">{i18n.t('recruit.form-personal-payment')}</Label>
                                <Input type="textarea" name="payment" value={recruitForm.payment || ""} onChange={e => handleUpdateRecruitInput(e)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="accountLabel" htmlFor="smartphone">{i18n.t('recruit.form-smartphone')}</Label>
                                <Input type="textarea" name="smartphone" value={recruitForm.smartphone || ""} onChange={e => handleUpdateRecruitInput(e)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="accountLabel" htmlFor="talents">{i18n.t('recruit.form-talents')}</Label>
                                <Input type="textarea" name="talents" value={recruitForm.talents || ""} onChange={e => handleUpdateRecruitInput(e)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup className="accountButton">
                    <Button onClick={ e => handleSendEmail(e) } disabled={!recruitForm || !recruitForm['select-work'] || !recruitForm['select-age']} className="submitButton primary" /**onClick={ e => handleUpdateUser(e) }*/>
                        {i18n.t('recruit.submit')}
                    </Button>
                </FormGroup>
            </Form>
        </div>
    </div>
    )}

export default Recruit;