import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col} from 'reactstrap';
import Sticky from 'react-sticky-el';

import i18n from "../../translations/i18n";

const CartComponent = ({ cart, voucher, isSticky, deliveryFee, tips, showAll, handleUpdateQuantity, ordering, store, isServiceWithRate }) => {
    const totalPrice = cart && cart.totalPrice ? cart.totalPrice : 0;
    const totalTaxablePrice = cart.products?.filter(p => p.isTaxable).reduce((prev, cur) => prev + ((cur.unitPriceOptions) * cur.selectedQuantity), 0) || 0;
    const totalNonTaxablePrice = cart.products?.filter(p => !p.isTaxable).reduce((prev, cur) => prev + ((cur.unitPriceOptions) * cur.selectedQuantity), 0) || 0;
    const discountedTotalPrice = voucher && voucher.isOnProduct ? totalPrice * ((100 - voucher.percentage)/100) : totalPrice;
    const discountedTotalTaxablePrice = voucher && voucher.isOnProduct ? totalTaxablePrice * ((100 - voucher.percentage)/100) : totalTaxablePrice;
    const discountedTotalNonTaxablePrice = voucher && voucher.isOnProduct ? totalNonTaxablePrice * ((100 - voucher.percentage)/100) : totalNonTaxablePrice;
    const isService = cart?.products?.find(p => p.isService);
    const serviceFee = isService && store && store.serviceFee ? parseFloat(store.serviceFee) : 0;
    const serviceFeeDiscounted = voucher && voucher.isOnGenieFee ? serviceFee * ((100 - voucher.percentage)/100) : serviceFee;

    const calculateTaxes = (amount) => {
        const tps = parseFloat((amount * 0.05).toFixed(2));
        const tvq = parseFloat((amount * 0.09975).toFixed(2));

        return parseFloat((amount + tps + tvq).toFixed(2));
    }

    const getTPS = (amount) => {
        return parseFloat((amount * 0.05).toFixed(2));
    }

    const getTVQ = (amount) => {
        return parseFloat((amount * 0.09975).toFixed(2));
    }
    
    const ref = useRef(null);
    const [height, setHeight] = useState(0)
    const [isLarger, setisLarger] = useState(true);

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [ref?.current?.clientHeight])

    if (height + 50 > window.innerHeight) {
        if (isLarger)
            setisLarger(false)
    } else {
        if (!isLarger)
            setisLarger(true)
    }

    return (
        <Sticky disabled={!isLarger || !isSticky} topOffset={15} stickyStyle={{ backgroundColor: 'white', top: 5, zIndex: 999}}>
            <div id="yourOrder" ref={ref}>
                <p className="title">{i18n.t('payment.yourOrder')}</p>
                <div className="order">
                    {   !isServiceWithRate ?
                        cart && cart.products && cart.products.map((p, index) => (
                                <Row key={index}>
                                    <Col xs="3" className="itemSelector">
                                        <div className="addItem">
                                        { ordering ? <span className="selector" onClick={() => handleUpdateQuantity(index, -1)} >-</span> : undefined}{p.selectedQuantity}{ !ordering || p.isService ? undefined : p.quantity > p.selectedQuantity ? <span className="selector" onClick={() => handleUpdateQuantity(index, 1)}>+</span> : <span className="selector disabled">+</span>}
                                        </div>
                                        </Col>
                                        <Col xs="6" className="itemTitle">{p['name_'+ i18n.locale.toLocaleUpperCase()]}
                                        { 
                                        p.subProducts?.map((sp, i) => 
                                            (
                                                <CartSubproductLineItem subproduct={sp} />
                                            )
                                                //if(sp.name !== 'standard') return(<div key={'sp-'+i} className="legendItem">{sp.name}</div>)
                                        ) } </Col>
                                    <Col xs="3" className="itemPrice">{ (p.unitPriceOptions ? p.unitPriceOptions.toFixed(2) : (p.unitPrice * p.selectedQuantity).toFixed(2)) }$</Col>
                                </Row>
                        )) : 
                        <>
                        <Row>
                            <Col xs="9" className="itemTitle">{cart.products[0]['name_'+ i18n.locale.toLocaleUpperCase()]} </Col>
                            <Col xs="3" className="itemPrice" style={ voucher && voucher.isOnProduct ? {textDecoration: 'line-through'} : undefined } >{ (cart.products[0].unitPriceOptions ? cart.products[0].unitPriceOptions.toFixed(2) : cart.products[0].unitPrice.toFixed(2)) * cart.products[0].selectedQuantity }$ / h</Col>
                        </Row>
                        { voucher && voucher.isOnProduct ?
                        <>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                                <div className="price">{voucher.name} ({voucher.percentage}%)</div>
                            </div>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                                <div className="price">{ discountedTotalPrice.toFixed(2) }$</div>
                            </div>
                        </> : undefined }

                        { serviceFee > 0 ?
                        <Row>
                            <Col xs="9" className="itemTitle">{i18n.t('payment.serviceFee')}</Col>
                            <Col xs="3" className="itemPrice" style={ voucher && voucher.isOnGenieFee ? {textDecoration: 'line-through'} : undefined }>{serviceFee.toFixed(2)}$</Col>
                        </Row> : undefined }
                        { serviceFee > 0  && voucher && voucher.isOnGenieFee ?
                        <>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                                <div className="price">{voucher.name} ({voucher.percentage}%)</div>
                            </div>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                                <div className="price" >{serviceFeeDiscounted.toFixed(2)}$</div>
                            </div>
                        </>: undefined }

                        { deliveryFee ?
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('pickup.delivery')}</div>
                            <div className="price" style={ voucher && voucher.isOnDeliveryOnly ? {textDecoration: 'line-through'} : undefined }>{deliveryFee.deliveryBasePrice}$</div>
                        </div> : undefined }

                        { deliveryFee && deliveryFee.deliveryExtraPrice > 0 ?
                        <div className="commandInfo">
                            <div className="priceTitle">+ {i18n.t('payment.extraDelivery')}</div>
                            <div className="price" style={ voucher && voucher.isOnDeliveryOnly ? {textDecoration: 'line-through'} : undefined }>{deliveryFee.deliveryExtraPrice.toFixed(2)}$</div>
                        </div> : undefined }

                        { deliveryFee && voucher && voucher.isOnDeliveryOnly ?
                        <>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                                <div className="price">{voucher.name} ({voucher.percentage}%)</div>
                            </div>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                                <div className="price">{ deliveryFee.priceAfterDiscount.toFixed(2) }$</div>
                            </div>
                        </> : undefined }


                        {/* <Row>
                            <Col xs="9" className="itemTitle">{i18n.t('payment.preauthorization')}</Col>
                            <Col xs="3" className="itemPrice">{ cart.products[0].preAuthorizationHourlyRate }$</Col>
                        </Row> */}
                        </>
                    }
                </div>

                { showAll && !isServiceWithRate ? 
                    <>
                        <hr/>

                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.subtotal')}</div>
                            <div className="price" style={ voucher && voucher.isOnProduct ? {textDecoration: 'line-through'} : undefined }>{ totalPrice ? totalPrice.toFixed(2) : 0}$</div>
                        </div>
                        { voucher && voucher.isOnProduct ?
                        <>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                                <div className="price">{voucher.name} ({voucher.percentage}%)</div>
                            </div>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                                <div className="price">{ discountedTotalPrice.toFixed(2) }$</div>
                            </div>
                        </> : undefined }
                        { deliveryFee ?
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('pickup.delivery')}</div>
                            <div className="price" style={ voucher && voucher.isOnDeliveryOnly ? {textDecoration: 'line-through'} : undefined }>{deliveryFee.deliveryBasePrice}$</div>
                        </div> : undefined }

                        { deliveryFee && deliveryFee.deliveryExtraPrice > 0 ?
                        <div className="commandInfo">
                            <div className="priceTitle">+ {i18n.t('payment.extraDelivery')}</div>
                            <div className="price" style={ voucher && voucher.isOnDeliveryOnly ? {textDecoration: 'line-through'} : undefined }>{deliveryFee.deliveryExtraPrice.toFixed(2)}$</div>
                        </div> : undefined }

                        { deliveryFee && voucher && voucher.isOnDeliveryOnly ?
                        <>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                                <div className="price">{voucher.name} ({voucher.percentage}%)</div>
                            </div>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                                <div className="price">{ deliveryFee.priceAfterDiscount.toFixed(2) }$</div>
                            </div>
                        </> : undefined }

                        { serviceFee > 0 ?
                        <div className="commandInfo">
                            <div className="priceTitle" >{i18n.t('payment.serviceFee')}</div>
                            <div className="price" style={ voucher && voucher.isOnGenieFee ? {textDecoration: 'line-through'} : undefined }>{serviceFee.toFixed(2)}$</div>
                        </div> : undefined }

                        { serviceFee > 0  && voucher && voucher.isOnGenieFee ?
                        <>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.promoCode')}</div>
                                <div className="price">{voucher.name} ({voucher.percentage}%)</div>
                            </div>
                            <div className="commandInfo">
                                <div className="priceTitle">{i18n.t('payment.priceAfterPromo')}</div>
                                <div className="price" >{serviceFeeDiscounted.toFixed(2)}$</div>
                            </div>
                        </>: undefined }

                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.tps')}</div>
                            <div className="price">{ voucher ? ( getTPS(serviceFeeDiscounted) + getTPS(discountedTotalTaxablePrice) + (deliveryFee ? getTPS(deliveryFee.priceAfterDiscount) : 0) ).toFixed(2) : ( getTPS(serviceFee) + getTPS(totalTaxablePrice) + (deliveryFee ? getTPS(deliveryFee.priceAfterDiscount) : 0)).toFixed(2)}$</div>
                        </div>
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.tvq')}</div>
                            <div className="price">{ voucher ? ( getTVQ(serviceFeeDiscounted) + getTVQ(discountedTotalTaxablePrice) + (deliveryFee ? getTVQ(deliveryFee.priceAfterDiscount) : 0) ).toFixed(2) : ( getTVQ(serviceFee) + getTVQ(totalTaxablePrice) + (deliveryFee ? getTVQ(deliveryFee.priceAfterDiscount) : 0)).toFixed(2)}$</div>
                        </div> 
                        { tips && tips > 0 ?
                        <div className="commandInfo">
                            <div className="priceTitle">{i18n.t('payment.tips')}</div>
                            <div className="price">{ tips.toFixed(2) }$</div>
                        </div> : undefined }
                    </> : undefined
                }

                <hr/>

                { !isServiceWithRate ? <p className="itemsTotalPrice">{i18n.t('payment.totalOrder')} <span>{ showAll ? voucher ? parseFloat(( calculateTaxes(serviceFeeDiscounted) + calculateTaxes(discountedTotalTaxablePrice) + (deliveryFee ? calculateTaxes(deliveryFee.priceAfterDiscount) : 0)) + discountedTotalNonTaxablePrice + (tips ? tips : 0)).toFixed(2) : parseFloat(( calculateTaxes(serviceFee) + calculateTaxes(totalTaxablePrice) + (deliveryFee ? calculateTaxes(deliveryFee.priceAfterDiscount) : 0)) + totalNonTaxablePrice + (tips ? tips : 0)).toFixed(2) : parseFloat(totalPrice).toFixed(2)}$</span></p> :
                <p className="itemsTotalPrice">{i18n.t('payment.preauthorization_detail')}</p>
                //<p className="itemsTotalPrice">{i18n.t('payment.preauthorization')} <span>{ cart.products[0].preAuthorizationHourlyRate}$</span></p>
                } 

                { ordering ? 
                    <Link to={"/store/reservation"} disabled style={{ pointerEvents: cart.totalPrice.toFixed(2) < store.minimumOrder ? 'none' : ''}}>
                        <button className="primary" style={{ marginBottom: 10}}
                            disabled={ cart.products.length === 0 || cart.totalPrice.toFixed(2) < store.minimumOrder }
                        >
                            {i18n.t('payment.ordering')}
                        </button>
                        { cart.totalPrice.toFixed(2) < store.minimumOrder ? <p style={{ textAlign: 'center', marginTop: 0, fontSize: 14, color: "#c0392b"}}>{i18n.t('error_messages.minimum_order')} {store.minimumOrder}$</p> : undefined }
                    </Link> : undefined
                }
            </div>
        </Sticky>
    )
};

const CartSubproductLineItem = ({ subproduct }) => {

    return(
        <>
        <p className="cart_subcat">{subproduct.quantity}x {subproduct['name_'+i18n.locale.toUpperCase()]} {subproduct.additionalPrice > 0 && "(" + subproduct.additionalPrice + "$)"}</p>
        <ul>
        { subproduct?.subProducts?.map((item, i) => (
            <li><p className="cart_subcat">{item.quantity}x {item['name_'+i18n.locale.toUpperCase()]} {item.additionalPrice > 0 && "(" + item.additionalPrice + "$)"}</p></li>
        ))}
        </ul>
        </>
    )
}

export default CartComponent;