import React, { useEffect } from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";
import i18n from '../../translations/i18n';
import MainContainer from '../../containers/Layout/Main';
import config from "../../config/config";

//CONTROLLER
import HomeContainer from '../../containers/Page/Home';
import AccountContainer from '../../containers/Page/Account';
import StorePageContainer from '../../containers/Store/StorePage';
import StoreCategoryPageContainer from '../../containers/Store/StoreCategoryPage';
import StoreCategoriesContainer from '../../containers/Store/StoreCategories';
import StoreSingleContainer from '../../containers/Store/StoreSingle';
import StoreReservationContainer from '../../containers/Store/StoreReservation';
import SearchPageContainer from '../../containers/Page/SearchPage';
import PickupPageContainer from '../../containers/Pickup/PickupPage';
import HistoricPageContainer from '../../containers/Page/HistoricPage';
import RecruitPageContainer from '../../containers/Page/RecruitPage';
import CustomOrderContainer from '../../containers/Page/CustomOrder';
import PartnerPageContainer from '../../containers/Page/PartnerPage';
import BusinessPageContainer from '../../containers/Page/BusinessPage';
import ForgotContainer from '../../containers/Page/Forgot';
import ConfirmOrderContainer from '../../containers/Page/ConfirmOrder';
import QCommercePageContainer from '../../containers/Page/QCommercePage';
import DeliveryTerritoryPageContainer from '../../containers/Page/DeliveryTerritoryPage';
import CreateYourStorePageContainer from '../../containers/Page/CreateYourStorePage';
import ChooseYourPlanPageContainer from '../../containers/Page/ChooseYourPlanPage';
import TrackMyOrderPageContainer from '../../containers/Page/TrackMyOrder';
import TrackMyPickupPageContainer from '../../containers/Page/TrackMyPickup';
import CGUPageContainer from '../../containers/Page/CGUPage';
import ConfidentialityPageContainer from '../../containers/Page/ConfidentialityPage';
import PurchaseContainer from '../../containers/Page/Purchase';
import ThankYouContainer from '../../containers/Page/ThankYou';

//COMPONENT
import HeaderComponent from '../components/Layout/Header';
import FooterComponent from '../components/Layout/Footer';
import TopBarComponent from '../components/Layout/TopBar';

import HomeComponent from '../components/Home';
import AccountComponent from '../components/Account';
import StorePageComponent from '../components/Store/StorePage';
import StoreCategoryPageComponent from '../components/Store/StoreCategoryPage';
import StoreSingleComponent from '../components/Store/StoreSingle';
import StoreCategoriesComponent from '../components/Store/StoreCategories';
import StoreReservationComponent from '../components/Store/StoreReservation';
import SearchPageComponent from '../components/SearchPage';
import PickupPageComponent from '../components/Pickup/PickupPage';
import HistoricPageComponent from '../components/HistoricPage';
import RecruitPageComponent from '../components/RecruitPage';
import CustomOrderComponent from '../components/CustomOrder';
import PartnerPageComponent from '../components/PartnerPage';
import BusinessPageComponent from '../components/BusinessPage';
import DeliveryTerritoryPageComponent from '../components/Pages/DeliveryTerritory';
import CreateYourStorePageComponent from '../components/Pages/CreateYourStore';
import ChooseYourPlanPageComponent from '../components/Pages/ChooseYourPlan';
import TrackMyOrderPageComponent from '../components/TrackMyOrder';
import TrackMyPickupPageComponent from '../components/TrackMyPickup';
import CGUPAgeComponent from '../components/CGUPage';
import ConfidentialityPageComponent from '../components/ConfidentialityPage';
import PurchaseComponent from '../components/Purchase';
import ThankYouComponent from '../components/ThankYou';

import ForgotComponent from '../components/Forgot';
import ConfirmOrderComponent from '../components/ConfirmOrder';

const Index = (props) => {
  const location = useLocation();
  useEffect(() => {
    if (
      process.env.NODE_ENV !== "development" &&
      process.env.NODE_ENV !== "staging"
    ) {
      ReactGA.initialize(config.googleAnalyticsMeasurementId);
    }
  }, []);

  useEffect(() => {
    if (
      process.env.NODE_ENV !== "development" &&
      process.env.NODE_ENV !== "staging"
    ) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [location]);

  return (
    <MainContainer header={HeaderComponent} {...props} footer={FooterComponent} topbar={TopBarComponent} >
      <Switch>
        <Route exact path="/" component={(props) => <HomeContainer  {...props} Layout={HomeComponent} title={i18n.t('global.home')} />} />
        <Route exact path="/auth/facebook" component={(props) => <HomeContainer  {...props} Layout={HomeComponent} title={i18n.t('global.home')} />} />
        <Route exact path="/account" component={(props) => <AccountContainer  {...props} Layout={AccountComponent} title={i18n.t('global.myAccount')} />} />
        <Route exact path="/stores" component={(props) => <StorePageContainer  {...props} Layout={StorePageComponent} title={i18n.t('footer.stores')} />} />
        <Route exact path="/stores/:category" component={(props) => <StoreCategoryPageContainer  {...props} Layout={StoreCategoryPageComponent} title={i18n.t('footer.stores')} />} />
        <Route exact path="/category/:categoryId" component={(props) => <StoreCategoryPageContainer  {...props} Layout={StoreCategoryPageComponent} title={i18n.t('footer.stores')} />} />
        <Route exact path="/categories/:categoryId" component={(props) => <StoreCategoriesContainer  {...props} Layout={StoreCategoriesComponent} title={i18n.t('footer.stores')} />} />
        <Route exact path="/store/reservation" component={(props) => <StoreReservationContainer  {...props} Layout={StoreReservationComponent} title={i18n.t('progressBar.payment')} />} />
        <Route exact path="/store/reservation/:id" component={(props) => <StoreReservationContainer  {...props} Layout={StoreReservationComponent} title={i18n.t('progressBar.payment')} />} />
        <Route exact path="/store/:id" component={(props) => <StoreSingleContainer  {...props} Layout={StoreSingleComponent} title={i18n.t('footer.stores')} />} />
        <Route exact path="/search/:keyword" component={(props) => <SearchPageContainer  {...props} Layout={SearchPageComponent} title={i18n.t('search.search')} />} />
        <Route exact path="/pickup" component={(props) => <PickupPageContainer  {...props} Layout={PickupPageComponent} title={i18n.t('footer.pickup')} />} />
        <Route exact path="/business" component={(props) => <PickupPageContainer  {...props} Layout={PickupPageComponent} title={i18n.t('rounded_menu.business')} />} />
        <Route exact path="/pickup/:id" component={(props) => <PickupPageContainer  {...props} Layout={PickupPageComponent} title={i18n.t('footer.pickup')} />} />
        <Route exact path="/historic" component={(props) => <HistoricPageContainer  {...props} Layout={HistoricPageComponent} title={i18n.t('global.historic')} />} />
        <Route exact path="/order/:id" component={(props) => <CustomOrderContainer  {...props} Layout={CustomOrderComponent} title={i18n.t('historic.customOrder')} />} />
        <Route exact path="/recrutement" component={(props) => <RecruitPageContainer {...props} Layout={RecruitPageComponent} title={i18n.t('recruit.title')} />} />
        <Route exact path="/devenez-partenaire" component={(props) => <PartnerPageContainer {...props} Layout={PartnerPageComponent} title={i18n.t('partner.partner_title')} />} />
        <Route exact path="/geniebusiness" component={(props) => <BusinessPageContainer {...props} Layout={BusinessPageComponent} title={i18n.t('business.business_title')} />} />
        <Route exact path="/forgot/:token" component={(props) => <ForgotContainer {...props} Layout={ForgotComponent} title={i18n.t('forgot.title')} />} />
        <Route exact path="/confirmation" component={(props) => <ConfirmOrderContainer {...props} Layout={ConfirmOrderComponent} title={i18n.t('confirmOrder.title')} />} />
        <Route exact path="/track/:id" component={(props) => <TrackMyOrderPageContainer {...props} Layout={TrackMyOrderPageComponent} title={i18n.t('trackMyOrder.title')} />} />
        <Route exact path="/track-pickup/:id" component={(props) => <TrackMyPickupPageContainer {...props} Layout={TrackMyPickupPageComponent} title={i18n.t('trackMyOrder.pickupTitle')} />} />
        <Route exact path="/conditions-dutilisation" component={(props) => <CGUPageContainer {...props} Layout={CGUPAgeComponent} title={i18n.t('cgu.title')} />} />
        <Route exact path="/confidentialite" component={(props) => <ConfidentialityPageContainer {...props} Layout={ConfidentialityPageComponent} title={i18n.t('confidentiality.title')} />} />
        <Route exact path="/purchase" component={(props) => <PurchaseContainer {...props} Layout={PurchaseComponent} title={i18n.t('purchase.title')} />} />
        <Route exact path="/merci" component={(props) => <ThankYouContainer {...props} Layout={ThankYouComponent} title={i18n.t('thankYou.title')} />} />
        <Route exact path="/qcommerce" component={(props) => <QCommercePageContainer {...props} title="QCommerce" />} />
        <Route exact path="/delivery-zone" component={(props) => <DeliveryTerritoryPageContainer {...props} Layout={DeliveryTerritoryPageComponent} title={i18n.t('partner.montreal_territory')} />} />
        <Route exact path="/creez-votre-store-en-ligne" component={(props) => <CreateYourStorePageContainer {...props} Layout={CreateYourStorePageComponent} title={i18n.t('market.title')} />} />
        <Route exact path="/choisissez-votre-plan" component={(props) => <ChooseYourPlanPageContainer {...props} Layout={ChooseYourPlanPageComponent} title={i18n.t('plan.title')} />} />
        
        <Redirect to='/' />
      </Switch>
    </MainContainer>
  )
};

export default withRouter(props => <Index {...props} />);
