/* global document */
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/es/integration/react';
import storageLocal from 'redux-persist/es/storage/'
import { ToastProvider } from 'react-toast-notifications'
import configureStore from '../store/indexStore';
import * as serviceWorker from './register-service-worker';
import Routes from './routes/index';
// Load css
import './styles/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from '../containers/Utils/ScrollToTop';
import MobileHome from './components/UI/MobileHome';
import i18n from '../translations/i18n';

const history = createBrowserHistory();

Sentry.init({
  dsn: "https://51ed72c8d7876efc4e4c75357143d639@o490156.ingest.sentry.io/4505749834629120",
  environment: process.env.NODE_ENV ? process.env.NODE_ENV : "production",
  integrations: [
    new Sentry.BrowserTracing({
      // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      // ... other options
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^localhost:1337\/api/, 
    /^https:\/\/geniemtl-(dev|staging)\.herokuapp\.com\/api/,
    /^https:\/\/geniemtl\.com\/api/
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // debug: true,
});

const { persistor, store, dispatch } = configureStore(storageLocal);
// persistor.purge(); // Debug to clear persist
// <PersistGate loading={<Loading />} persistor={persistor}>

const Root = () => {
  i18n.locale = store.getState().user.lang;
  return(
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router dispatch={dispatch} store={store} history={history}>
            <MobileHome />
            <ScrollToTop />
            <ToastProvider>
              <Routes />
            </ToastProvider>
          </Router>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
)};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
