import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getStore, getAvailableHours } from "../../actions/storeAction";
import { getAvailableHoursService } from "../../actions/productAction";
import { getTokenCard, createUserCard } from "../../actions/userAction";
import { addTips, reinitCart } from "../../actions/cartAction";
import { checkVoucherCode, sendOrder, loadMyOrder, calculatePrice, uploadImage } from "../../actions/orderAction";

import { formatCVC, formatCreditCardNumber, formatExpirationDate, checkRequiredFields } from "../../utils/utils";

import i18n from "../../translations/i18n";

import moment from "moment";

class StoreReservation extends Component {
    constructor(props){
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+ props.title;

        super(props);

        this.state = {
            isLoading: false,
            currentStep: 1,
            requiredFields: undefined,
            isOpenLogin : false,
            isOpenSignUp : false,
            isModalOpen: false,
            openingHours: [],
            openingHoursPerDay: [],
            unavailableDays: [],
            order: {
                ...props.user.user,
                sendingDate: moment().format('YYYY-MM-DD'),
                address: this.props.user.user.addresses && this.props.user.user.addresses.length > 0 ? this.props.user?.user?.addresses[0]?.full ? this.props.user?.user?.addresses[0] : 'other'  : 'other',
                displayOtherAddress: this.props.user.user.addresses && this.props.user.user.addresses.length > 0 ? false : true,
                paymentCard: this.props.user.cards && this.props.user.cards.length > 0 ? this.props.user?.cards[0]?.id : 'other',
                displayOtherCard: this.props.user.cards && this.props.user.cards.length > 0 ? false : true,
            },
            card_errors: {
                number: false,
                expiration: false,
                cvc: false
            },
            instructionImages: []
        }
    }

    handleUpdateOrderInput = (e) => {
        let checkField = {};

        if(e.target.name.startsWith('address') && this.state.order.displayOtherAddress) checkField = { displayOtherAddress: false, otherAddress: undefined, apartment: null };
        if(e.target.name === 'displayOtherAddress') checkField = { address: null, apartment: null };

        if(e.target.name.startsWith('facturation') && this.state.order.displayOtherFacturationAddress) checkField = { displayOtherFacturationAddress: false, otherFacturationAddress: undefined, facturationApartment: null  };
        if(e.target.name === 'displayOtherFacturationAddress') checkField = { facturationAddress: null, facturationApartment: null };

        if(e.target.name.startsWith('paymentCard') && this.state.order.displayOtherCard) checkField = { displayOtherCard: false, otherPaymentCardName: null, otherPaymentCardNumber: null, otherPaymentCardExpiry: null, otherPaymentCardCVC: null };
        if(e.target.name === 'displayOtherCard') checkField = { paymentCard: null };

        if (e.target.name === "otherPaymentCardNumber") e.target.value = formatCreditCardNumber(e.target.value);
        if (e.target.name === "otherPaymentCardExpiry") e.target.value = formatExpirationDate(e.target.value);
        if (e.target.name === "otherPaymentCardCVC") e.target.value = formatCVC(e.target.value);

        if (e.target.name === "tipsPercent" && e.target.value) checkField = { tips: this.props.cart.totalPrice * (e.target.value / 100) }

        if (e.target.name === "phone" && e.target.value.length > 10) return;

        if(e.target.name === 'sendingDate'){
            let selectedDate = e.target.value;
            selectedDate = moment(selectedDate).format('YYYY-MM-DD');
            const openingHours = this.state.openingHoursPerDay?.find(openingHours => openingHours.day === selectedDate)?.openingHours || [];
            const newOrder = this.state.order;
            newOrder.sendingDate = selectedDate;
            newOrder.sendingHours = '';
            this.setState({ openingHours: openingHours, order: newOrder, ...checkField });
        } else {
            this.setState({ order: { ...this.state.order, [e.target.name] : e.target.value, ...checkField}});
        }

    }

    requiredFields = (mobile, currentStep, isServiceWithDelivery, isServiceWithoutBooking, isServiceWithDestination) => {
        return new Promise( (resolve, reject) => {

            let checkedRequired = undefined;
            let currentCheck;

            switch (currentStep) {
                case 1:
                    currentCheck = checkRequiredFields(this.state.order.fullName, 'name');
                    if(currentCheck) checkedRequired = { ...checkedRequired, fullName: currentCheck}

                    currentCheck = checkRequiredFields(this.state.order.phone, 'phone');
                    if(currentCheck) checkedRequired = { ...checkedRequired, phone: currentCheck}

                    if(this.props.store.requiresInstructions){
                        currentCheck = checkRequiredFields(this.state.order.instruction, 'instruction');
                        if(currentCheck) checkedRequired = { ...checkedRequired, instruction: currentCheck}
                    }

                    if(isServiceWithDelivery){
                        if(this.state.order.displayOtherAddress || this.state.order.address === 'other'){
                            if( !this.state.order.isCheckedAddress) checkedRequired = { ...checkedRequired, address: i18n.t('error_messages.invalid_address')}
                            else{
                                currentCheck = checkRequiredFields(this.state.order.otherAddress, 'address');
                                if(currentCheck) checkedRequired = { ...checkedRequired, address: currentCheck}
                            }
                        }else{
                            currentCheck = checkRequiredFields(this.state.order.address, 'address');
                            if(currentCheck) checkedRequired = { ...checkedRequired, address: currentCheck}
                        }
                    }

                    if(isServiceWithDestination){
                        currentCheck = checkRequiredFields(this.state.order.destinationAddress, 'address');
                        if(currentCheck) checkedRequired = { ...checkedRequired, destinationAddress: currentCheck}
                    }

                    if(!isServiceWithoutBooking && !this.state.order.sendingHours) checkedRequired = { ...checkedRequired, sendingHours: i18n.t('error_messages.required_field') }

                    break;
                default:

            }

            this.setState({ requiredFields: checkedRequired });

            if(checkedRequired)return resolve(false)
            else return resolve(true)
        });
    }

    nextStep = async (isServiceWithDelivery = true, isServiceWithoutBooking = false, isServiceWithDestination = false) => {
        if(window && window.document) window.scrollTo({top: 0, behavior: 'smooth'});

        let currentStep = this.state.currentStep

        let allFieldsRequired = await this.requiredFields(false, currentStep, isServiceWithDelivery, isServiceWithoutBooking, isServiceWithDestination);

        if(!allFieldsRequired) return;

        if (isServiceWithDelivery || isServiceWithDestination) {
            try {
                const price = await this.calculatePrice()
                this.setState({
                    order: {
                        ...this.state.order,
                        price: price,
                        facturationAddress: this.state.order.address && this.state.order.address !== 'other' ? this.state.order.address : this.state.order.otherAddress
                    },
                    
                });
            } catch (err) {
                return console.log('***** StoreReservation - nextStep - calculatePrice', err);
            }
        }

        let tips = 0;

        if(this.state.order.tipsPercent) tips = this.props.cart.totalPrice * (this.state.order.tipsPercent / 100);
        else tips = this.state.order.tips > 0 ? this.state.order.tips : 0;

        this.setState({
            currentStep: currentStep + 1,
            order: {
                ...this.state.order,
                tips: tips
            },
            card_errors: {
                number: false,
                expiration: false,
                cvc: false
            }
        })
    }

    prevStep = () => {
        if(window && window.document) window.scrollTo({top: 0, behavior: 'smooth'});

        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1? 1: currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    changeStep = step => {
        if(window && window.document) window.scrollTo({top: 0, behavior: 'smooth'});

        this.setState({
            currentStep: step
        })
    }

    initOrder = _ => {
        let isService = this.props.cart && this.props.cart.products && this.props.cart.products.length === 1 && this.props.cart.products[0].isService;
        let preparationTime = Math.max.apply(Math, this.props.cart.products.map(function(o) { return o.preparationTime; }))
        const unavailableDays = [];
        const openingHoursPerDay = [];

        for (let day = 0; day <= this.props.store.maximumTimeBeforeReservation; day++) {
            const currentDate = moment().add(day, 'days').format('YYYY-MM-DD');
            let openingHours = [];

            const fetchHoursCallback = (hours, isService) => {
                if (hours.length === 0 || hours.length === 48) {
                    unavailableDays.push(moment(currentDate).toDate());
                } else {
                    let currentHours = moment().add(preparationTime, 'hours');
                    openingHours = hours;
                    
                    if(moment(currentDate).isBefore(currentHours, 'days')){
                        openingHours = [];
                        unavailableDays.push(moment(currentDate).toDate());
                    }
                    if(moment(currentDate).isSame(currentHours, 'days')){
                        openingHours = hours.filter( h => h.value >= currentHours.format("HH:mm"))
                    }
                    

                    if(openingHours.length > 0) {
                        openingHours = openingHours.map( oh => ({ value: oh.value, label: oh.label + ' - ' + moment(oh.value, 'HH:mm').add(this.props.store.deliveryRange, 'minutes').format('HH[h]mm')})).sort( (a,b) => a.value > b.value);
                        if (currentDate === moment().format('YYYY-MM-DD') && !isService) {
                            openingHours[0] = { ...openingHours[0], label: i18n.t('pickup.asap')};
                        }
                    }
                    
                    if (currentDate === moment().format('YYYY-MM-DD')) {
                        this.setState({ openingHours: openingHours, order: { ...this.state.order, sendingHours: openingHours[0]?.value} });
                    }
                    
                }
                openingHoursPerDay.push({ day: currentDate, openingHours });
            };

            if (isService) {
                this.props.getAvailableHoursService(this.props.cart.products[0].product, currentDate).then(hours => fetchHoursCallback(hours, isService));
            } else {
                this.props.getAvailableHours(this.props.cart.storeId, currentDate).then(hours => fetchHoursCallback(hours, isService));
            }
        }
        this.setState({ unavailableDays, openingHoursPerDay });
        
        const orderId = this.props.match && this.props.match.params ? this.props.match.params.id : this.props.route && this.props.route.params && this.props.route.params.id ? this.props.route.params.id : undefined;

        if(orderId){
            this.props.loadMyOrder(orderId).then(response => {

                this.setState({
                    order:{
                        ...this.state.order,
                        fullName: response.customerName,
                        phone: response.customerPhone,
                        instruction: response.specialInstructions,
                        sendingDate: moment.unix(response.deliveryDate / 1000).format('YYYY-MM-DD')
                    }
                });
            }).catch( e => {
                console.error("e",e);
            })
        }  

        this.setState({ order: { ...this.state.order, displayOtherAddress: this.state.order.addresses && this.state.order.addresses.length === 0 ? true : false, displayOtherCard: this.props.user.cards && this.props.user.cards.length === 0 ? true : false} })


        // this.props.getStore(this.props.match.params.id).then(response => {

        // }).catch( e => {
        //     console.error("e",e);
        // })
    }

    componentDidUpdate(prevProps, prevState){   
       if(!prevState.order?.token && prevProps.user?.user?.token){
            prevState.order = {...prevState.order, ...prevProps.user.user}
            this.initOrder()
       }

       return prevState;
    }

    componentDidMount() {
        if(this.state.order?.token) this.initOrder()
    }

    handleSendOrder = async (e, isServiceWithDelivery = true, isService = false, isServiceWithDestination = false) => {
        this.setState({isLoading: true});

        if(e) e.preventDefault();

        // Create clean order object
        let order = {};

        // General
        order.customer = { id: this.props.user.user.id };
        order.customerName = this.state.order.fullName;
        order.customerPhone = this.state.order.phone;
        order.serviceFee = this.props.store.serviceFee ? this.props.store.serviceFee : 0;

        if(this.state.order.voucherCode) order.voucherCode = {id: this.state.order.voucherCode};

        order.store = {id: this.props.cart.storeId};
        order.date = moment().valueOf();

        let preparationTime = Math.max.apply(Math, this.props.cart.products.map(function(o) { return o.preparationTime; })) || 0;

        if(this.state.order.sendingHours === 'NOW') order.deliveryDate = moment().add(15, 'minutes').valueOf();
        else order.deliveryDate = moment(this.state.order.sendingDate + ' ' + this.state.order.sendingHours).valueOf();

        if(order.deliveryDate < moment().add(preparationTime, "hours").valueOf()) order.deliveryDate = moment().add(preparationTime, "hours").add(5, 'minutes').valueOf();

        // TODO : Delivery Price
        order.deliveryPrice = 0;

        // Cart
        order.items = this.props.cart.products;

        order.items.map((item, i) => {
            order.items[i].quantity = order.items[i].selectedQuantity;
        });

        if(this.state.order.instruction) order.specialInstructions = this.state.order.instruction;

        // Address
        if(!isService || isServiceWithDelivery){
            if(this.state.order.address && this.state.order.address !== null && this.state.order.address !== 'other') order.deliveryAddress = {id: this.state.order.address.id};
            else order.deliveryAddress = { apartment: this.state.order.apartment, ...this.sanitizeAddress(this.state.order.otherAddress) };
        }

        // Destination address
        if(isServiceWithDestination){
            order.destinationAddress = { apartment: this.state.order.destinationApartment, ...this.sanitizeAddress(this.state.order.destinationAddress) };
        }

        // Facturation Addresses
        if(this.state.order.facturationAddress && this.state.order.facturationAddress !== null && this.state.order.facturationAddress !== 'other') order.billingAddress = this.state.order.facturationAddress.id ? {id: this.state.order.facturationAddress.id} : order.deliveryAddress;
        else order.billingAddress = { apartment: this.state.order.facturationApartment, ...this.sanitizeAddress(this.state.order.otherFacturationAddress) };

        order.isBillingAddressDifferent = this.state.order.displayOtherFacturationAddress || this.state.order.facturationAddress === 'other' || (isService && !isServiceWithDelivery);

        // Payment
        if(this.state.order.paymentCard && this.state.order.paymentCard !== 'other') order.stripeToken = this.state.order.paymentCard;
        else{
            try{
                let card = await this.props.getTokenCard({
                    name: this.state.order.otherPaymentCardName,
                    number: this.state.order.otherPaymentCardNumber,
                    expiry: this.state.order.otherPaymentCardExpiry,
                    cvc: this.state.order.otherPaymentCardCVC
                });

                order.stripeToken = card.data.id;
            }catch(err){
                console.log('getTokenCard err', err);
                if(err.response.status === 402){
                    this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.invalid_credit_card')},
                    card_errors: { number: true },
                    isLoading: false 
                }, () => {
                        this.setState({ toastMessage: undefined });
                        this.prevStep();
                    });
                }else{
                    this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.payment_error')}, isLoading: false }, () => {
                        this.setState({ toastMessage: undefined });
                    });   
                }

                return;
            }
        }

        // Tips
        order.tips = parseFloat(this.state.order.tips.toFixed(2));
        if(this.state.order.paymentCard === 'other' || this.state.order.displayOtherCard){
            try{
                let cardTips = await this.props.getTokenCard({
                    name: this.state.order.otherPaymentCardName,
                    number: this.state.order.otherPaymentCardNumber,
                    expiry: this.state.order.otherPaymentCardExpiry,
                    cvc: this.state.order.otherPaymentCardCVC
                });
                order.genieStripeToken = cardTips.data.id;
            }catch(err){
                if(err.response.status === 402){
                    this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.invalid_credit_card')}, isLoading: false }, () => {
                        this.setState({ toastMessage: undefined });
                        this.prevStep();
                    });
                }else{
                    this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.payment_error')}, isLoading: false }, () => {
                        this.setState({ toastMessage: undefined });
                    });   
                }

                return;
            }
        }

        // Images
        if (this.state.instructionImages.length > 0) {
            try {
                order.instructionImages = await this.uploadFiles();
            } catch (error) {
                console.log('***** error.uploadFiles', error.data?.message);
            }
        }

        this.props.sendOrder(order)
        .then(async (response) => {
            if (this.state.order.paymentCard === 'other') {
                try {
                    await this.props.createUserCard({
                        name: this.state.order.otherPaymentCardName,
                        number: this.state.order.otherPaymentCardNumber,
                        expiry: this.state.order.otherPaymentCardExpiry,
                        cvc: this.state.order.otherPaymentCardCVC
                    });
                } catch (error) {
                    console.log(error);
                }
            }
            this.props.reinitCart(undefined);
            this.setState({ toastMessage: { type: 'success', msg: i18n.t('payment.payment_success')}, redirect: true, isLoading: false }, () => {
                this.setState({ toastMessage: undefined, redirect: false });
            });
            if (!e) this.props.navigation.navigate('ConfirmOrder');

        })
        .catch(err => {
            console.log('***** err.message', err.data?.message);

            if(err.data?.message === "Error.Order.customerPhone.unvalid"){
                this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.wrong_phone_number')}, isLoading: false}, () => {
                    this.setState({ toastMessage: undefined });
                });
            }
            else{
                if(err.data?.message.decline_code === 'insufficient_funds' || err.data?.message.code === 'insufficient_funds') {
                    this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.insufficient_funds')}, isLoading: false }, () => {
                        this.setState({ toastMessage: undefined });
                        this.prevStep();
                    });
                } else if(err.data?.message.decline_code === 'expired_card' || err.data?.message.code === 'expired_card') {
                    this.setState({ 
                        toastMessage: { type: 'error', msg: i18n.t('error_messages.expired_card')}, 
                        card_errors: { expiration: true }, 
                        isLoading: false 
                    }, () => {
                        this.setState({ toastMessage: undefined });
                        this.prevStep();
                    });
                } else if(err.data?.message.decline_code === 'incorrect_cvc' || err.data?.message.code === 'incorrect_cvc') {
                    this.setState({ 
                        toastMessage: { type: 'error', msg: i18n.t('error_messages.incorrect_cvc')},
                        card_errors: { cvc: true },
                        isLoading: false 
                    }, () => {
                        this.setState({ toastMessage: undefined });
                        this.prevStep();
                    });
                } else if(err.data?.message.statusCode === 402){
                    this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.invalid_credit_card')}, isLoading: false }, () => {
                        this.setState({ toastMessage: undefined });
                        this.prevStep();
                    });
                } else{
                    this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.payment_error')}, isLoading: false}, () => {
                        this.setState({ toastMessage: undefined });
                    });
                }
            }

            console.log('***** ERROR : StoreReservation - handleSendOrder - sendOrder', err);
        })

    }

    handleCheckVoucher = (haveDelivery = true) => {
        if(this.state.order.promoCode){
            this.props.checkVoucherCode(this.state.order.promoCode, 'order')
            .then(response => {
                if(response.isActive){
                    if(
                        (!response.isOnProduct && !response.isOnDeliveryOnly && response.isOnGenieFee && !this.state.order.serviceFee) ||
                        (!response.isOnProduct && !response.isOnGenieFee && response.isOnDeliveryOnly && !this.state.order.price)
                        ){
                        this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.promo_not_for_this_type')} }, () => {
                            this.setState({ toastMessage: undefined });
                        });
                    }
                    else{
                        this.setState({ order: { ...this.state.order, voucherCode: response.id, voucher: response}}, _ => {
                            if(haveDelivery) this.calculatePrice()
                        })
                    }
                }
            })
            .catch( err => {
                this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.promo_not_available')} }, () => {
                    this.setState({ toastMessage: undefined });
                });
            })
        }
        else{

        }

    }

    sanitizeAddress = address => {
        let addressObj = {};

        address.address_components.forEach(aPart => {
            if(aPart.types.includes('street_number')){
                addressObj.street = aPart.long_name;
            }
            if(aPart.types.includes('route')){
                addressObj.street = addressObj.street + ' ' + aPart.long_name;
            }
            if(aPart.types.includes('locality')){
                addressObj.city = aPart.long_name;
            }
            if(aPart.types.includes('administrative_area_level_1')){
                addressObj.region = aPart.long_name;
                addressObj.regionShort = aPart.short_name;
            }
            if(aPart.types.includes('country')){
                addressObj.country = aPart.long_name;
            }
            if(aPart.types.includes('postal_code')){
                addressObj.postalCode = aPart.long_name;
            }

            addressObj.full = addressObj.street + ', '+ addressObj.city + ', '+ addressObj.regionShort + ', '+ addressObj.country;
            addressObj.latitude = address.lat;
            addressObj.longitude = address.lng;
        })

        return addressObj;
    }

    handleUpdateCurrentCardNative = form => {
        let card = {
            otherPaymentCardName: form.values.name,
            otherPaymentCardNumber: form.values.number,
            otherPaymentCardExpiry: form.values.expiry,
            otherPaymentCardCVC: form.values.cvc
        }
        
        this.setState({ order: { ...this.state.order, ...card } });
    }

    calculatePrice = () => {
        return new Promise((resolve, reject) => {
            let addresses = [];
            if (this.state.order.destinationAddress) addresses.push(this.state.order.destinationAddress.lat + ',' + this.state.order.destinationAddress.lng);
            const isService = this.props.cart?.products?.length === 0 || (this.props.cart?.products?.length === 1 && this.props.cart?.products[0]?.isService);
            const isServiceRequiringDelivery = this.props.cart?.products?.length === 0 ? false : isService && this.props.cart?.products[0]?.isRequiringDelivery;
            if (isServiceRequiringDelivery || !isService) {
                if (this.state.order.address && this.state.order.address !== 'other') addresses.push(this.state.order.address.latitude + ',' + this.state.order.address.longitude);
                else addresses.push(this.state.order.otherAddress.lat + ',' + this.state.order.otherAddress.lng);
            }

            this.props.calculatePrice({
                voucherCodeId: this.state.order.voucherCode ? this.state.order.voucherCode : undefined,
                storeId: this.props.cart.storeId,
                addresses
            }).then(price => {
                let order = this.state.order;
                order.price = price;

                this.setState({
                    order: order
                })

                return resolve(price);
            }).catch(err => {
                this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.address_too_far')}}, () => {
                    this.setState({ toastMessage: undefined });
                });
                return reject(err);
            });
        });
    };

    handleCheckedAddress = isChecked => {
        this.setState({ order: { ...this.state.order, isCheckedAddress: isChecked } });
    }

    handleToggleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen});
    }

    toggleSignUp = () => {
        this.setState({
            isOpenSignUp: !this.state.isOpenSignUp
        })
    }

    toggleLogin = () => {
        this.setState({
            isOpenLogin: !this.state.isOpenLogin
        })
    }

    fileSelectedHandler = async event => {
        let imageArray = [];

        for (const file of event.target.files) {
            const fileName = file.name;
            let base64File = await this.convertBase64(file);

            imageArray.push({file: base64File, fileName});
        }

        this.setState({ instructionImages: imageArray });
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }

    uploadFiles = async () => {
        return new Promise(async (resolve, reject) => {
            let imageString = '';

            for (const file of this.state.instructionImages) {
                try {
                    const url = await this.props.uploadImage(file);
                    imageString = imageString.concat(url, ', ');
                } catch (error) {
                    reject(error);
                }
            }
    
            imageString = imageString.slice(0, -2);
            resolve(imageString);
          });
    }

    render() {
        const { Layout, store, user, cart, isLoading, navigation } = this.props;

        return (

            <Layout 
                cart={cart}
                order={this.state.order}
                user={user}
                store={store}
                isLoading={isLoading || this.state.isLoading}
                openingHours={this.state.openingHours}
                unavailableDays={this.state.unavailableDays}
                handleUpdateOrderInput={this.handleUpdateOrderInput.bind(this)}
                handleUpdateCurrentCardNative={this.handleUpdateCurrentCardNative.bind(this)}
                currentStep={this.state.currentStep}
                nextStep = {this.nextStep}
                prevStep = {this.prevStep}
                changeStep={this.changeStep.bind(this)}
                handleSendOrder={this.handleSendOrder.bind(this)}
                handleCheckVoucher={this.handleCheckVoucher.bind(this)}
                handleCheckedAddress={this.handleCheckedAddress.bind(this)}
                handleToggleModal={this.handleToggleModal.bind(this)}
                isModalOpen={this.state.isModalOpen}
                deliveryFee={4.99}
                toastMessage={this.state.toastMessage}
                redirect={this.state.redirect}
                navigation= {navigation}
                isOpenSignUp={this.state.isOpenSignUp}
                isOpenLogin={this.state.isOpenLogin}
                toggleSignUp={this.toggleSignUp}
                toggleLogin={this.toggleLogin}
                requiredFields={this.state.requiredFields}
                cardErrors={this.state.card_errors}
                fileSelectedHandler={this.fileSelectedHandler.bind(this)}
                instructionImages={this.state.instructionImages}
            />
        );
    }
    
}

const mapStateToProps = (state) => ({
    isLoading: state.user.isFetching || state.orders.isFetching || state.products.isFetching,
    user: state.user,
    store: state.stores.store,
    cart: state.carts.cart
});

const mapDispatchToProps = {
    getStore,
    getTokenCard,
    checkVoucherCode,
    sendOrder,
    loadMyOrder,
    calculatePrice,
    getAvailableHours,
    getAvailableHoursService,
    addTips,
    reinitCart,
    uploadImage,
    createUserCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreReservation);