import {
    ONBOARDING_DONE,
    ONBOARDING_START
} from '../actions/applicationAction';

import Store from '../store/applicationStore';


export const initialState = Store;

export default (state = initialState, action) => {
    switch (action.type) {

        case ONBOARDING_DONE:
            return {
                onboarding: false
            }
        case ONBOARDING_START:
            return {
                onboarding: true
            }
        default:
            return state;
    }
}
