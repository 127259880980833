import React from 'react';
import Skeleton from 'react-loading-skeleton';
 
const SkeletonUI = () => (
    <>
        <div style={{ width: "33%", padding: 15, display: "inline-block" }}>
            <Skeleton height={200} />
            <Skeleton height={30} />
            <Skeleton height={20} />
        </div>

        <div style={{ width: "33%", padding: 15, display: "inline-block" }}>
            <Skeleton height={200} />
            <Skeleton height={30} />
            <Skeleton height={20} />
        </div>

        <div style={{ width: "33%", padding: 15, display: "inline-block" }}>
            <Skeleton height={200} />
            <Skeleton height={30} />
            <Skeleton height={20} />
        </div>
    </>
)

export const SkeletonProduct = () => (
    <>
    <div style={{ marginLeft: 15, display: 'flex' }}>
        <Skeleton width={'10rem'} height={'10rem'} />
        <div style={{ marginLeft: 25, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <Skeleton width={200} height={40} />
            <Skeleton width={200} height={20} />
            <Skeleton width={200} height={10} />
            <Skeleton width={200} height={10} />
            <Skeleton width={200} height={10} />
            <Skeleton width={200} height={10} />
        </div>
    </div>
    <div style={{ marginLeft: 15, marginTop: 15, display: 'flex' }}>
        <Skeleton width={'10rem'} height={'10rem'} />
        <div style={{ marginLeft: 25, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <Skeleton width={200} height={40} />
            <Skeleton width={200} height={20} />
            <Skeleton width={200} height={10} />
            <Skeleton width={200} height={10} />
            <Skeleton width={200} height={10} />
            <Skeleton width={200} height={10} />
        </div>
    </div>
    <div style={{ marginLeft: 15, marginTop: 15, display: 'flex' }}>
        <Skeleton width={'10rem'} height={'10rem'} />
        <div style={{ marginLeft: 25, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <Skeleton width={200} height={40} />
            <Skeleton width={200} height={20} />
            <Skeleton width={200} height={10} />
            <Skeleton width={200} height={10} />
            <Skeleton width={200} height={10} />
            <Skeleton width={200} height={10} />
        </div>
    </div>
    </>
)
export default SkeletonUI;