import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Col, Row, Button, Form, FormText, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';


import i18n from '../../../translations/i18n';

const DeliveryTerritory = ({ }) => {
    
    return(
    <div className="paddingContainerMobile">
        <div id="partnerPage" className="qcommercepage">

            <Container id="title-section">

                <div className="title-text-wrapper">
                    <div className="title-text">
                        <h1 className="bigTitle" style={{ textAlign: 'center', fontSize: "3em", marginBottom: "25px", hyphens: 'none'}}>{ i18n.t("partner.montreal_territory") }</h1>
                    </div>

                    <img style={{ width: "100%"}} src="/images/delivery_map.png" />

                    <p>{ i18n.t("partner.montreal_territory_source") }</p>
                </div>

             
            </Container>

        </div>
    </div>
)}

export default DeliveryTerritory;