import React from 'react';
import { Link } from "react-router-dom";
import i18n from '../../../translations/i18n';
import ChatButton from '../Chat/ChatButton';

const persist = 
    window.location.href.includes('/purchase') || 
    window.location.href.includes('/merci') 
    window.location.href.includes('/recrutement') || 
    window.location.href.includes('/devenez-partenaire') ||
    window.location.href.includes('/qcommerce') ||
    window.location.href.includes('/choisissez-votre-plan') ||
    window.location.href.includes('/delivery-zone') ||
    window.location.href.includes('/creez-votre-store-en-ligne') ||
    window.location.href.includes('/forgot') || 
    window.location.href.includes('/confidentialite');
    
    const badgeDownloadClass = persist ? "badgeDownloadMobile" : "badgeDownload";
    const lastLineClass = persist ? "lastLineMobile" : "lastLine";
    const logoSocialMediaClass = persist ? "logoSocialMediaMobile" : "logoSocialMedia";
    const footerMenuClass = persist ? "footerMenuMobile" : "footerMenu";
    
const FooterComponent = (props) => {

    return(
        <footer id="genieFooter">
            <div className='footerMobileLinks'>
                <Link to="/recrutement">{i18n.t('homepage.becomeGenie')}</Link>
                <Link to="/devenez-partenaire">{i18n.t('homepage.becomePartner')}</Link>
            </div>
            <img className="logo" src="/images/logo_color.png" alt="logo orange"/>
            <div className={badgeDownloadClass}>
                <a className='footerLink' href="https://apps.apple.com/us/app/genie-mtl/id1545682977"><img className='footerImg' src={"/images/mobile/download_apple_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".svg"} alt="available on apple store"/></a>
                <a className='footerLink' href="https://play.google.com/store/apps/details?id=com.geniemtl.geniemtl"><img className='footerImg' src={"/images/mobile/download_google_" + (i18n.locale === 'en' ? 'en' : 'fr') + ".png"} alt="available on play store"/></a>
            </div>
            <hr />
            <div className={lastLineClass}>
                <div className={logoSocialMediaClass}>
                    <a className='footerLink' href="https://www.facebook.com/GenieMtl" target='_BLANK' rel="noopener noreferrer"><img className='footerImg' src="/images/social/social-facebook.svg" alt="social facebook" /></a>
                    <a className='footerLink' href="https://www.instagram.com/geniemontreal/" target='_BLANK' rel="noopener noreferrer"><img className='footerImg' src="/images/social/social-instagram.svg" alt="social instagram" /></a>
                </div>
                <div className={footerMenuClass}>
                    <ul className='footerList'>
                        <li><Link className='footerLink' to="/">{i18n.t('footer.home')}</Link></li>
                        <li><Link className='footerLink' to="/stores">{i18n.t('footer.stores')}</Link></li>
                        <li><Link className='footerLink' to="/pickup">{i18n.t('footer.pickup')}</Link></li>
                        <li><Link className='footerLink' to="/historic">{i18n.t('footer.history')}</Link></li>
                        <li><Link className='footerLink' to="/conditions-dutilisation">{i18n.t('footer.cgu')}</Link></li>
                        <li><Link className='footerLink' to="/confidentialite">{i18n.t('footer.policy')}</Link></li>
                        <li className='footerListItem'>© 2020 Genie Montreal</li>
                    </ul>
                </div>
            </div>

            <ChatButton user={props.user} />
        </footer>
    )
};

export default FooterComponent;