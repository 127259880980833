import React, { Component } from "react";
import { connect } from "react-redux";

import {
  createUser,
  login,
  loginFacebookToken,
  loginFacebookLimited,
  loginUserApple,
  checkAppleId,
  forgot,
} from "../../actions/userAction";

import i18n from "../../translations/i18n";
import { checkFields } from "../../config/utils";
import { errorMessagesRegister } from "../../config/messages";

class SignUpModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      user: undefined,
      isLoadingRegister: false,
      formErrors: {},
    };
  }

  handleChangeInputUser = (e) => {
    this.setState({
      user: { ...this.state.user, [e.target.name]: e.target.value },
    });
  };

  handleOnBlur = (e) => {
    if (e.target.name === "password" && e.target.value)
      this.state.user &&
      this.state.user.confirmPassword &&
      e.target.value !== this.state.user.confirmPassword
        ? this.setState({
            formErrors: {
              ...this.state.formErrors,
              register: {
                different_password: i18n.t("signup.error_different_password"),
              },
            },
          })
        : this.setState({
            formErrors: {
              ...this.state.formErrors,
              register: { different_password: undefined },
            },
          });
    if (e.target.name === "confirmPassword" && e.target.value)
      this.state.user &&
      this.state.user.password &&
      e.target.value !== this.state.user.password
        ? this.setState({
            formErrors: {
              ...this.state.formErrors,
              register: {
                different_password: i18n.t("signup.error_different_password"),
              },
            },
          })
        : this.setState({
            formErrors: {
              ...this.state.formErrors,
              register: { different_password: undefined },
            },
          });
    if (
      e.target.name === "password" &&
      e.target.value &&
      e.target.value.length < 8
    )
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          register: { different_password: i18n.t("signup.password_too_short") },
        },
      });
  };

  handleCreateUser = (e) => {
    if (e) e.preventDefault();

    this.setState({ isLoadingRegister: true, formErrors: {} });
    checkFields(errorMessagesRegister, this.state.user)
      .then(() => {
        this.props
          .createUser(this.state.user)
          .then((_) => {
            this.setState(
              {
                isLoadingRegister: false,
                formErrors: {},
                toastMessage: {
                  type: "success",
                  msg: i18n.t("login.email_validation"),
                },
              },
              () => {
                this.setState({ toastMessage: undefined });
              }
            );

            if (this.props.navigation) {
              this.props.navigation.navigate("AccountActivation");
            } else {
              this.props.toggle();
            }
          })
          .catch((error) => {
            console.log("***** error", error);
            let errors = {
              existing_user:
                error.code === "E_UNIQUE" || error.code === "USER_ALREADY_EXIST"
                  ? i18n.t("error_messages.email_already_used")
                  : undefined,
              missing_field:
                error.code === "MISSING_FIELD"
                  ? i18n.t("error_messages.missing_field")
                  : undefined,
            };

            if (Object.values(errors).every((el) => el === undefined)) {
              errors.unknown = i18n.t("error_messages.unknown_error");
            }

            this.setState((state) => ({
              formErrors: {
                ...state.formErrors,
                register: errors,
              },
              isLoadingLogin: false,
              isLoadingRegister: false,
            }));
            console.log(
              "***** SignUpModal - handleCreateUser - createUser ",
              error
            );
          });
      })
      .catch((errors) => {
        this.setState((state) => ({
          formErrors: {
            ...state.formErrors,
            register: errors,
          },
          isLoadingRegister: false,
        }));
      });
  };

  handleLoginFacebook = (token) => {
    this.props
      .loginFacebookToken(token)
      .then((response) => {})
      .catch((error) => {
        console.log("***** Login - handleLoginFacebook", error);
      });
  };

  handleLoginFacebook = (token, platform, profile) => {
    return new Promise((resolve, reject) => {
      if (platform === "ios") {
        this.props
          .loginFacebookLimited(profile)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("***** SignUpModal - handleLoginFacebook ios", error);
            reject(error);
          });
      } else {
        this.props
          .loginFacebookToken(token)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(
              "***** SignUpModal - handleLoginFacebook android",
              error
            );
            reject(error);
          });
      }
    });
  };

  handleCheckAppleId = (credentials) => {
    this.props
      .checkAppleId(credentials)
      .then((response) => {
        if (!response.err) {
          if (response.status === "ALREADY_CREATED") {
            if (this.props.navigation) this.props.navigation.navigate("Home");
          } else if (response.status === "NOT_ACTIVATE") {
            this.setState(
              {
                toastMessage: {
                  type: "error",
                  msg: i18n.t("error_messages.user_not_activated"),
                },
              },
              () => {
                this.setState({ toastMessage: undefined });
              }
            );
            if (this.props.navigation) this.props.navigation.navigate("Home");
          } else if (response.status === "ALREADY_USED") {
            this.setState(
              {
                toastMessage: {
                  type: "error",
                  msg: i18n.t("error_messages.appleId_already_linked"),
                },
              },
              () => {
                this.setState({ toastMessage: undefined });
              }
            );
          }
        }
      })
      .catch((err) => {
        console.log(
          "***** SignUpModal - handleCheckAppleId - checkAppleId",
          err
        );
      });
  };

  displayConnectedToast = () => {
    this.setState(
      { toastMessage: { type: "success", msg: i18n.t("global.connected") } },
      () => {
        this.setState({ toastMessage: undefined });
      }
    );
  };

  displayNotConnectedToast = () => {
    if (this.props.navigation) {
      this.setState(
        {
          refreshToken: true,
        },
        () => {
          this.setState(
            {
              refreshToken: false,
            },
            () => {
              this.setState(
                {
                  toastMessage: {
                    type: "error",
                    msg: i18n.t("global.notConnected"),
                  },
                },
                () => {
                  this.setState({ toastMessage: undefined });
                }
              );
            }
          );
        }
      );
    }
  };

  render() {
    const { Layout, isOpen, toggle, navigation, toggleLogin, isLoading } =
      this.props;
    const { isLoadingRegister } = this.state;

    return (
      <Layout
        isLoading={isLoading || isLoadingRegister}
        isOpen={isOpen}
        toggle={toggle}
        toggleLogin={toggleLogin}
        user={this.state.user}
        navigation={navigation}
        handleChangeInputUser={this.handleChangeInputUser.bind(this)}
        handleCreateUser={this.handleCreateUser.bind(this)}
        handleOnBlur={this.handleOnBlur.bind(this)}
        handleLoginFacebook={this.handleLoginFacebook.bind(this)}
        handleCheckAppleId={this.handleCheckAppleId.bind(this)}
        formErrors={this.state.formErrors}
        toastMessage={this.state.toastMessage}
        displayConnectedToast={this.displayConnectedToast.bind(this)}
        displayNotConnectedToast={this.displayNotConnectedToast.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.user.isFetching,
  user: state.user,
});

const mapDispatchToProps = {
  createUser,
  login,
  forgot,
  loginFacebookToken,
  loginFacebookLimited,
  loginUserApple,
  checkAppleId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModal);
