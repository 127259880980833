export const ONBOARDING_DONE = 'ONBOARDING_DONE';
export const ONBOARDING_START = 'ONBOARDING_START';

export const onboardingDone = () => ({
    type: ONBOARDING_DONE,
});

export const onboardingStart = () => ({
    type: ONBOARDING_START,
});
