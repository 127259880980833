import {
    RECEIVE_PRODUCT_DATA,
    REQUEST_PRODUCT_DATA,
    RECEIVE_SEARCHED_PRODUCTS_DATA,
    SET_FETCHING
} from '../actions/productAction';

import {
    RECEIVE_SEARCH_DATA,
    REQUEST_SEARCH_DATA
} from '../actions/searchAction';

import Product from '../store/productStore';

export const initialState = Product;

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_PRODUCT_DATA:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_PRODUCT_DATA:
            return {
                ...state,
                isFetching: false,
                products: action.payload,
            };
        case RECEIVE_SEARCHED_PRODUCTS_DATA:
            return {
                ...state,
                isFetching: false,
                searchedProducts: action.payload,
            };
        case REQUEST_SEARCH_DATA:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_SEARCH_DATA:
            return {
                ...state,
                isFetching: false,
                searchedProducts: action.payload.products,
            };
        case SET_FETCHING:
            return {
                ...state,
                isFetching: action.payload,
            };
        default:
            return state;
    }
}
