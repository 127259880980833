import React, { Component } from 'react';
import { connect } from 'react-redux';

import { resetPassword } from "../../actions/userAction";

import i18n from "../../translations/i18n";

class Forgot extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }

        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+ props.title;
    }

    UNSAFE_componentWillMount() {
        const token = this.props.match && this.props.match.params ? this.props.match.params.token : this.props.route && this.props.route.params && this.props.route.params.token ? this.props.route.params.token : undefined;
        this.setState({ token: token });
    } 

    handleChangeInput = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangePassword = e => {
        if(e) e.preventDefault();

        this.props.resetPassword(this.state.password, this.state.token).then( response => {
            if(e){
                this.setState({ toastMessage: {msg: i18n.t("forgot.successResetPassword"), type: "success"}, redirect: true }, () => {
                    this.setState({ toastMessage: undefined, redirect: false });
                })
            }
        }).catch(err => {
            if(e){
                this.setState({ toastMessage: {msg: i18n.t("forgot.errorResetPassword"), type: "error"}, redirect: true }, () => {
                    this.setState({ toastMessage: undefined, redirect: false });
                })
            }
        })
    }

    render() {
        const { Layout } = this.props;

        return (
            <Layout
                state={this.state}
                handleChangeInput={this.handleChangeInput.bind(this)}
                handleChangePassword={this.handleChangePassword.bind(this)}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.user.isFetching
});

const mapDispatchToProps = {
    resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
