import React from 'react';
import { Row, Col, Form } from 'reactstrap';
import Step1 from '../Store/Reservation/ReservationStep1';
import Step2 from '../Store/Reservation/ReservationStep2';
import { resizeImage } from '../../../config/utils';
import i18n from "../../../translations/i18n";

import Cart from '../../../containers/Page/Cart';
import CartComponent from '../Cart';

import SignUpModal from '../../../containers/Page/SignUpModal';
import SignUpModalComponent from '../SignUpModal';

import Login from '../../../containers/Page/Login';
import LoginComponent from '../Login';

import { Redirect } from 'react-router-dom';
import Loading from "../UI/Loading";
import ProgressBar from "../UI/ProgressBar";

import { useToasts } from 'react-toast-notifications'


const StoreReservationComponent = ({ order, cart, user, store, currentStep, isOpenLogin, isOpenSignUp, toggleSignUp, toggleLogin, openingHours, unavailableDays, nextStep, prevStep, handleUpdateOrderInput, changeStep, handleSendOrder, handleCheckedAddress, handleCheckVoucher, isLoading, redirect, toastMessage, handleToggleModal, isModalOpen, requiredFields, cardErrors, fileSelectedHandler, instructionImages }) => {
    
    const { addToast } = useToasts();

    const isService = cart.products.length === 0 || (cart.products.length === 1 && cart.products[0].isService);
    const isServiceRequiringDelivery = cart.products.length === 0 ? false : isService && cart.products[0].isRequiringDelivery;
    const isServiceWithoutBooking = cart.products.length === 0 ? false : isService && cart.products[0].isWithoutBooking;
    const isServiceWithDestination = cart.products.length === 0 ? false : isService && cart.products[0].isRequiringDestination;
    const isServiceWithRate = cart.products.length === 0 ? false : isService && cart.products[0].isProductWithHourlyRate;

    return(
    <>
        { isLoading ? <Loading /> : undefined }
        { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }
        { redirect ? <Redirect to='/confirmation' /> : undefined }

        { !user || !user.user || !user.user.token ? 
        <div id="historicPage" className="paddingContainer">
            <h1 style={{ textAlign: "center"}}>{i18n.t('global.notLoggedTitle')}</h1>
            <div class="not_logged" style={{ textAlign: "center"}}>
                {i18n.t('global.notLoggedSubtitleShort')}<div class="login_link" onClick={toggleLogin}>{i18n.t('global.notLoggedSubtitleEnd')}</div>
            </div>
        </div>
        
        :
        <>
            <div id="storeBanner" style={{backgroundImage: "url('" + resizeImage(store.mainPhoto, '_2000x.') +"')"}}></div>
            <div id="reservationSection" className="paddingContainer">
            <Row className="reservationSectionRow">
                <Col xs="8" className="singleStore">
                    <div id="breadcrumb">
                        {/* <p>&lt; Continuer de magasiner</p> */}
                    </div>

                    <Form className="reservationForm">
                        <ProgressBar type="reservation" currentStep={currentStep} changeStep={changeStep} />

                        
                        { currentStep === 1 ?
                        <Step1
                            store={store}
                            cart={cart}
                            order={order}
                            currentStep={currentStep}
                            nextStep={nextStep}
                            handleCheckedAddress={handleCheckedAddress}
                            handleUpdateOrderInput={handleUpdateOrderInput}
                            openingHours={openingHours}
                            unavailableDays={unavailableDays}
                            isService={isService}
                            isServiceRequiringDelivery={isServiceRequiringDelivery}
                            isServiceWithoutBooking={isServiceWithoutBooking}
                            isServiceWithDestination={isServiceWithDestination}
                            requiredFields={requiredFields}
                            isServiceWithRate={isServiceWithRate}
                            fileSelectedHandler={fileSelectedHandler}
                            instructionImages={instructionImages}
                        /> : undefined }
                        
                        { currentStep === 2 ?
                        <Step2
                            store={store}
                            cards={user.cards}
                            order={order}
                            cart={cart}
                            user={user.user}
                            handleToggleModal={handleToggleModal}
                            isModalOpen={isModalOpen}
                            currentStep={currentStep}
                            prevStep={prevStep}
                            handleUpdateOrderInput={handleUpdateOrderInput}
                            handleCheckVoucher={handleCheckVoucher}
                            isService={isService}
                            isServiceRequiringDelivery={isServiceRequiringDelivery}
                            isServiceWithDestination={isServiceWithDestination}
                            isServiceWithRate={isServiceWithRate}
                            cardErrors={cardErrors}
                            handleSendOrder={handleSendOrder}
                        /> : undefined }
                    </Form>
                    
                </Col>

                <Col xs="4">
                    <Cart Layout={CartComponent} store={store} tips={order.tips} deliveryFee={order.price && typeof order.price?.priceAfterDiscount == 'number' && (isServiceWithDestination || isServiceRequiringDelivery || !isService) ? order.price : undefined} voucher={order.voucherCode ? order.voucher : null} showAll={true} ordering={false} isServiceWithRate={isServiceWithRate} />
                </Col>
            </Row>
        </div>
        </>
        } 
        <Login isOpen={isOpenLogin} toggle={toggleLogin} toggleSignUp={toggleSignUp} Layout={LoginComponent} />
        <SignUpModal isOpen={isOpenSignUp} toggle={toggleSignUp} toggleLogin={toggleLogin} Layout={SignUpModalComponent} />  
    </> 
)}

export default StoreReservationComponent;
