import React, { Component } from 'react';

import QCommerceFR from '../../web/components/Pages/QCommerce_fr';
import QCommerceEN from '../../web/components/Pages/QCommerce_en';

import i18n from '../../translations/i18n';
class QCommerce extends Component {

    constructor(props){
        super(props);
    }

    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+this.props.title;
    }

    render() {
        const { Layout, navigation } = this.props;

        if(i18n.locale == 'en') return ( <QCommerceEN /> );
        else return ( <QCommerceFR /> );
    }
}

export default QCommerce;