import React, { Component } from 'react';

class ThankYou extends Component {
    render() {
        const { Layout, navigation } = this.props;

        return (
            <Layout
                /**user={this.state.user}*/
                navigation={navigation}
            />

        );
    }

    UNSAFE_componentWillMount() {
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+ this.props.title;
    }
}

export default ThankYou;