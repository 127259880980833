import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getMyOrders, getMyCustomOrders, cancelOrder } from "../../actions/orderAction";
import { getMyPickups } from "../../actions/pickupAction";
import { createNewCart } from "../../actions/cartAction";

import i18n from "../../translations/i18n";
import axios from "axios";
import config from "../../config/config";

class Historic extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            user: props.user.user,
            view: 'order',
            orders: undefined,
            customOrders: undefined,
            isOpenLogin : false,
            isOpenSignUp : false,
            pickups: undefined,
            isLoading: false,
            displayMobileInvoice: false,
            downloadMobileInvoice: false,
            fileName: '',
            pages: 0,
            activePage: 1,
        }
        if(window && window.document && window.document.title) window.document.title = 'Genie Montréal | '+props.title;

    }

    componentDidMount() {
        if(this.state.user.token != null){
            this.fetchMyHistoric();
        }
    }

    componentDidUpdate(){
        if(this.state.user.token != null && !this.state.orders){
            this.fetchMyHistoric();
        }
    }

    toggleSignUp = () => {
        this.setState({
            isOpenSignUp: !this.state.isOpenSignUp
        })
    }

    toggleLogin = () => {
        console.log('toggleLogin');
        this.setState({
            isOpenLogin: !this.state.isOpenLogin
        })
        console.log('isOpenLogin',this.state.isOpenLogin );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.user.token !== nextProps.user.user.token){
            prevState = { ...prevState, user: nextProps.user.user };
        }

        if(prevState.orders !== nextProps.orders){
            prevState = { ...prevState, orders: nextProps.orders };
        }

        if(prevState.customOrders !== nextProps.customOrders){
            prevState = { ...prevState, customOrders: nextProps.customOrders };
        }
        
        if(prevState.pickups !== nextProps.pickups){
            prevState = { ...prevState, pickups: nextProps.pickups };
        }

        return prevState;
    }

    setView = (view) => {
        this.setState({ view, activePage: 1 }, async () => {
            await this.fetchMyHistoric();
        })
    }

    setActivePage = (activePage) => {
        this.setState({ activePage }, async () => {
            if(window && window.document) window.scrollTo({top: 0, behavior: 'smooth'});
            await this.fetchMyHistoric();
        })
    } 

    fetchMyHistoric = async () => {
        this.setState({ isLoading: true });
        let pages = 0;
        const skip = (this.state.activePage - 1) * 10
        try {
            switch (this.state.view) {
                case 'order':
                    pages = await this.props.getMyOrders(skip);
                    await this.props.getMyCustomOrders(skip);
                    await this.props.getMyPickups(skip);
                    break;
                case 'customOrder':
                    await this.props.getMyOrders(skip);
                    pages = await this.props.getMyCustomOrders(skip);
                    await this.props.getMyPickups(skip);
                    break;
                case 'pickup':
                    await this.props.getMyOrders(skip);
                    await this.props.getMyCustomOrders(skip);
                    pages = await this.props.getMyPickups(skip);
                    break;
                default:
                    break;
            }

            this.setState({ pages })
        } catch (error) {
            console.log('***** HistoricPage - fetchMyHistoric', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    passSameOrder(order) {
        return new Promise( (resolve, reject) => {
            let newCart = {};
            let newTotalCart = 0;
    
            newCart.storeId = order.store.id;
            newCart.products = [];
    
            order.items.map( i => {
                let newProduct = i.product;
                newProduct.selectedQuantity = i.quantity;
                newProduct.selectedSubproducts = i.subProducts;
    
                let unitPriceOptions = i.subProducts.reduce(function(prev, cur) {
                    return prev + cur.additionalPrice;
                  }, 0);
    
                unitPriceOptions += i.product.unitPrice;
    
                newTotalCart += (unitPriceOptions * i.quantity)
            
                newProduct.unitPriceOptions = unitPriceOptions;
    
                newCart.products.push(newProduct);
            })
    
            newCart.totalPrice = newTotalCart;
    
            this.props.createNewCart(newCart).then(res => resolve()).catch(err => reject());
        })
    }

    mobileDownloadDone(){
        this.setState({ 
            downloadMobileInvoice: false
        });
    }

    downloadInvoice(type, id, download = false, platform = 'web', mobileType, item){
        this.setState({ isLoading: true });
        axios.post( config.baseUrlApi + '/' + type + "/sendInvoice", { id: id, download: download }, { responseType: 'blob' } ).then(response => {
            if(download){
                if (platform === 'mobile') {
                    const fileReaderInstance = new FileReader();
                    const fileName = `genie-${type}${item?.store?.name ? '-' + item.store.name + '-' : '-'}${id}.pdf`;
                    fileReaderInstance.onload = async () => {
                        if (mobileType && mobileType === 'download') {
                            this.setState({ 
                                fileName: fileName.replace(/ /g, "_"),
                                mobileInvoiceBase64: fileReaderInstance.result.split(',')[1], 
                                downloadMobileInvoice: true 
                            });
                        } else {
                            this.setState({ 
                                mobileInvoiceBase64: fileReaderInstance.result, 
                                displayMobileInvoice: true 
                            });
                        }
                    };
                    fileReaderInstance.onerror = (e) => {
                        console.log(fileReaderInstance.error);
                        alert(i18n.t('historic.mobileDownloadError'));
                    };
                    fileReaderInstance.readAsDataURL(new Blob([response.data], {type: 'application/pdf'})); 
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'invoice.pdf');
                    document.body.appendChild(link);
                    link.click();
                }
            }else{
                this.setState({ toastMessage: { type: 'success', msg: i18n.t('historic.emailSent')} }, () => {
                    this.setState({ toastMessage: undefined });
                });
            }
        }).catch(err => {
            this.setState({ toastMessage: { type: 'error', msg: i18n.t('historic.emailError')} }, () => {
                this.setState({ toastMessage: undefined });
            });
            console.log("ERROR - sendInvoice", err);
        }).finally( _ => {
            this.setState({ isLoading: false });
        })
    }

    downloadInvoiceGenie(type, id, download = false, platform = 'web', mobileType, item){
        this.setState({ isLoading: true });
        axios.post( config.baseUrlApi + '/' + type + "/sendInvoiceGenie", { id: id, download: download }, { responseType: 'blob' } ).then(response => {
            if(download){
                if (platform === 'mobile') {
                    const fileReaderInstance = new FileReader();
                    const fileName = `genie-${type}${item?.store?.name ? '-' + item.store.name + '-' : '-'}${id}.pdf`;
                    fileReaderInstance.onload = async () => {
                        if (mobileType && mobileType === 'download') {
                            this.setState({ 
                                fileName: fileName.replace(/ /g, "_"),
                                mobileInvoiceBase64: fileReaderInstance.result.split(',')[1], 
                                downloadMobileInvoice: true 
                            });
                        } else {
                            this.setState({ 
                                mobileInvoiceBase64: fileReaderInstance.result, 
                                displayMobileInvoice: true 
                            });
                        }
                    };
                    fileReaderInstance.onerror = (e) => {
                        console.log(fileReaderInstance.error);
                        alert(i18n.t('historic.mobileDownloadError'));
                    };
                    fileReaderInstance.readAsDataURL(new Blob([response.data], {type: 'application/pdf'})); 
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'invoice-genie.pdf');
                    document.body.appendChild(link);
                    link.click();
                }
            }else{
                this.setState({ toastMessage: { type: 'success', msg: i18n.t('historic.emailSent')} }, () => {
                    this.setState({ toastMessage: undefined });
                });
            }
        }).catch(err => {
            this.setState({ toastMessage: { type: 'error', msg: i18n.t('historic.emailError')} }, () => {
                this.setState({ toastMessage: undefined });
            });
            console.log("ERROR - sendInvoice", err);
        }).finally( _ => {
            this.setState({ isLoading: false });
        })
    }

    setInvoiceModalVisibility = () => {
        this.setState({ displayMobileInvoice: false });
    }

    cancelOrder(id) {
        this.props.cancelOrder(id).then(res => {
            this.setState({ toastMessage: { type: 'success', msg: i18n.t('historic.cancelledOrder')} }, () => {
                this.setState({ toastMessage: undefined });
            });
        }).catch(err => {
            this.setState({ toastMessage: { type: 'error', msg: i18n.t('error_messages.error_order_cancellation') }}, () => {
                this.setState({ toastMessage: undefined });
            });
            console.log('***** HistoricPage - cancelOrder', err);
        })
    }

    render() {
        const { Layout, isLoading, navigation } = this.props;

        return (
            <Layout
                user={this.state.user}
                orders={this.state.orders}
                customOrders={this.state.customOrders}
                pickups={this.state.pickups}
                view={this.state.view}
                setView={this.setView.bind(this)}
                pages={this.state.pages}
                activePage={this.state.activePage}
                setActivePage={this.setActivePage.bind(this)}
                passSameOrder={this.passSameOrder.bind(this)}
                cancelOrder={this.cancelOrder.bind(this)}
                navigation={navigation}
                isOpenLogin={this.state.isOpenLogin}
                isOpenSignUp={this.state.isOpenSignUp}
                toggleSignUp={this.toggleSignUp}
                toggleLogin={this.toggleLogin}
                isLoading={isLoading || this.state.isLoading}
                toastMessage={this.state.toastMessage}
                downloadInvoice={this.downloadInvoice.bind(this)}
                downloadInvoiceGenie={this.downloadInvoiceGenie.bind(this)}
                mobileDownloadDone={this.mobileDownloadDone.bind(this)}
                mobileInvoiceBase64={this.state.mobileInvoiceBase64}
                displayMobileInvoice={this.state.displayMobileInvoice}
                downloadMobileInvoice={this.state.downloadMobileInvoice}
                fileName={this.state.fileName}
                setInvoiceModalVisibility={this.setInvoiceModalVisibility.bind(this)}
            />

        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    pickups: state.pickups.pickups,
    orders: state.orders.orders,
    customOrders: state.orders.customOrders,
    isLoading: state.orders.isFetching || false
});

const mapDispatchToProps = {
    getMyOrders,
    getMyCustomOrders,
    getMyPickups,
    createNewCart,
    cancelOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(Historic);
