import React, {useState, useRef, useEffect } from 'react';
import { Col, Modal, FormGroup, Label, Input } from 'reactstrap';
import { Redirect } from "react-router-dom";
import { useToasts } from 'react-toast-notifications'
import SVG from 'react-inlinesvg';
import { resizeImage } from '../../../config/utils';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/zoom/zoom.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
// import Swiper core and required modules
import SwiperCore, {
    Keyboard,Zoom,Navigation,Pagination
  } from 'swiper/core';
  
  // install Swiper modules
  SwiperCore.use([Keyboard,Zoom,Navigation,Pagination]);

  import { addCircleOutline, removeCircleOutline } from 'ionicons/icons'

import i18n from '../../../translations/i18n';

const StoreProductSingleComponent = ({ product, selectedProduct, prodRefs, readOnly, handleAddProductToCart, handleRemoveProductToCart, alreadyInCart, chooseService, chooseProduct, handleToggleModal, isModalOpen, handleChooseSubProduct, clearMyProductSubProducts, redirect, toastMessage, handleUpdateSubproduct, items, checkSelectedQteFromSubcategory, checkValidationButton, fillSubproduct}) => {
    const { addToast } = useToasts();
    const refSwiper = useRef(null);
    const [discPercentage, setDiscPercentage] = useState(0);

    useEffect(() => {
        if (product.isCurrentlyInDiscount && product.defaultPrice != null) {
            setDiscPercentage(Math.ceil(((product.defaultPrice - product.unitPrice)/product.defaultPrice)*100))
        }
    }, [product]);

    const zoomImage = () => {
        refSwiper.current?.swiper?.zoom?.toggle();
    }

    useEffect(() => {
        if (selectedProduct === product.id) {
            handleToggleModal("targetLabel product-column");
        }
    }, [selectedProduct]);

    if(redirect) return <Redirect to="/store/reservation" />
    return(
        <>
        { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }
            <Col xs="6" lg="6" xl="4" style={{overflow: "hidden", cursor: "pointer"}} className='product hover_bold' aria-label={"product-column"} data-label={"product-column"} onClick={ (e) => handleToggleModal(e.target.dataset.label)}>
                <div className="productDescription" aria-label={"product"} data-label={"product"} ref={el => (prodRefs['product_#' + product.id] = el)} id={'product_' + product.id}>
                    <p className="productTitle" aria-label={"product-title"} data-label={"product-title"}>{product.name}</p>
                    <p className="legend" aria-label={"product-legend"} data-label={"product-legend"}>{product.description ? product.description.length > 125 ? product.description.substring(0, 125) + "..." : product.description : undefined }</p>
                    <div className="addProduct" aria-label={"product-add"} data-label={"product-add"}>
                        { product.isCurrentlyInDiscount && product.defaultPrice != null ?
                        <div>
                            <span className="productPriceOriginal" aria-label={"product-price-original"} data-label={"product-price-original"}>{product.defaultPrice.toFixed(2)}$</span>
                            <span className="productPriceDiscount" aria-label={"product-price-discount"} data-label={"product-price-discount"}>{product.unitPrice.toFixed(2)}$</span>
                        </div> :
                        <div className="productPrice" aria-label={"product-price"} data-label={"product-price"}>{product.unitPrice.toFixed(2)}${product.isProductWithHourlyRate && '/h'}</div>}
                    </div>
                    {/* { !readOnly && !product.currentlyUnavailable ? <button className="button primary" style={{ width: "75px", marginTop: 25}} onClick={ (e) => handleToggleModal()}>{i18n.t( 'global.choose' )}</button>: undefined} */}
                </div>
                <div className="productImg" aria-label={"product-img"} data-label={"product-img"} style={
                (product.currentlyUnavailable || product.quantity <= 0) && !product.isService ? {
                    backgroundImage: "linear-gradient(black, black), url('" + resizeImage(product.images?.split(';')[0], '_250x.') +"')",
                    backgroundBlendMode: 'saturation',
                    alignItems: 'center',
                    display: "flex",
                    height: product.images?.length > 0 ? '10rem' : 0
                
                } : {
                    backgroundImage: "url('" + resizeImage(product.images?.split(';')[0], '_250x.') + "')",
                    alignItems: 'center',
                    display: "flex",
                    height: product.images?.length > 0 ? '10rem' : 0
                }}>
                    { product.isCurrentlyInDiscount && product.defaultPrice != null &&
                        <div className='productDiscountContainer'>
                            <p className='productDiscountPercentage'>-{discPercentage}%</p>
                        </div>
                    }
                    {( product.quantity <= 0 || !product.quantity || product.currentlyUnavailable ) && <p className="currentlyClose">{i18n.t("payment.unavailable")}</p>}
                </div>

                <Modal isOpen={isModalOpen} toggle={handleToggleModal} id="productModal">
                    <button onClick={handleToggleModal} className="btnClose">
                        <img src="/images/menu/icon-close.svg" alt="icon close" />
                    </button>
                    
                    { product.images ?
                    <Swiper
                        ref={refSwiper}
                        style={{'--swiper-navigation-color': '#342535','--swiper-pagination-color': '#342535'}}
                        keyboard={{"enabled": true}}
                        slidesPerView={1}
                        spaceBetween={30}
                        zoom={true}
                        navigation={true}
                        loop={true}
                        pagination={{"clickable": true}}
                        className="mySwiper"
                        onClick={zoomImage}
                    >
                        {product.images?.split(";").map((i, key) => (
                            <SwiperSlide key={key}><div className="swiper-zoom-container"><img className="imgProduct" src={resizeImage(i, '_500x.')} /></div></SwiperSlide>
                        ))}
                    </Swiper>
                    :
                        <div className="imgProduct" style={{ backgroundImage: "url('" + "/images/no_pic.png" + "')"}}></div>
                    }

                    <h4>{product.name}</h4>
                    <p className="legend" color>{product.description }</p>

                    { product && product.subProducts && product.subProducts.length >= 1 ? 
                        <ProductChoice product={product} items={items} handleUpdateSubproduct={handleUpdateSubproduct} checkSelectedQteFromSubcategory={checkSelectedQteFromSubcategory} chooseService={chooseService} checkValidationButton={checkValidationButton} chooseProduct={chooseProduct} clearMyProductSubProducts={clearMyProductSubProducts} handleToggleModal={handleToggleModal} root={true} fillSubproduct={fillSubproduct} />   
                        : 
                        <>
                            {product.isService ? 
                                <button className="button primary" onClick={ (e) => handleChooseSubProduct(e, product.subProducts[0])}>{i18n.t( product.quantity > 0 ? 'global.choose' : 'payment.outOfStock')}</button>
                            :
                                <button disabled={product.currentlyUnavailable || product.quantity <= 0} className="button primary" aria-label={"product-select"} data-label={"product-select"} onClick={ (e) => {handleChooseSubProduct(e, product.subProducts[0]); handleToggleModal(e.target.dataset.label) }}>{i18n.t( product.quantity > 0 ? 'global.choose' : 'payment.outOfStock')}</button>
                            }
                        </>
                    }      
                </Modal>
            </Col>
        </>
)}




const ProductChoice = ({ product, items, handleUpdateSubproduct, checkSelectedQteFromSubcategory, chooseService, checkValidationButton, chooseProduct, clearMyProductSubProducts, handleToggleModal, setMyParentModal, root, fillSubproduct }) => {
    const [isModalOptionOpen, setIsModalOptionOpen] = useState(false);
    const [mySlectedOption, setMySlectedOption] = useState(false);

    const optionsCategories = product?.optionProductObj?.optionCategories || product?.optionCategories;
    const subProducts = product?.optionProductObj?.subProducts || product?.subProducts;

    const checkProductQty = (sp) => {
        const subProductsWithQty = product.optionProductObj?.subProducts?.filter(o => o.qty)

        if (subProductsWithQty?.length >= product.optionProductObj?.maxPerOption) {
           const subProductWithQty = subProductsWithQty?.map(sp => sp.id)
           return !subProductWithQty.includes(sp.id)
        }
    }

    return(
        <>
            { ( !root && (!optionsCategories || optionsCategories?.length == 0)) && <>
                <p className="productTitle" style={{ marginBottom: 0}} aria-label={"product-title"} data-label={"product-title"}>{product?.optionProductObj.name}</p>
                <p className="legend" style={{ marginTop: 0}} aria-label={"product-legend"} data-label={"product-legend"}>{i18n.t("payment.noOption")}</p>
                </>
            }

            { !isModalOptionOpen && <Accordion preExpanded={["1"]}>
            { optionsCategories?.map(op => {
                return (
                <AccordionItem uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            { op['name_' + i18n.locale.toLocaleUpperCase()] }
                            { op.subProductOptionMinimum > 0 && <span className='orderSubCatInfo'>{i18n.t('payment.required')}{ op.subProductOptionMinimum == op.subProductOptionMaximum ? (' (' + op.subProductOptionMaximum + ')') : (' (' + op.subProductOptionMinimum + ')') } </span> } 
                            { op.subProductOptionMinimum != op.subProductOptionMaximum && <span className='orderSubCatInfo'>{i18n.t('payment.chooseUpTo')} {op.subProductOptionMaximum}</span> } 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel aria-label={"options-accordion"} data-label={"options-accordion"}>
                        { subProducts?.filter( sp => sp.category == op.id).map( (sp, index, arr) => {
                            if(op.subProductOptionMaximum == 1 && op.subProductOptionMinimum == 1){ return(
                                <FormGroup aria-label={"options-formGroup"} data-label={"options-formGroup"}>
                                    <Label>
                                        <Input type="radio" name={"options-" + op.id} aria-label={"options-" + op.id} data-label={"options-" + op.id} value={sp.id} onChange={e => handleUpdateSubproduct(sp, e.target.checked, true, op, arr) } data-name={sp.name} data-price={sp.additionalPrice} checked={sp.qty > 0} />
                                        {sp.optionProduct ? sp.optionProductObj.name : sp.name} <span className="orderAdditionnalPrice">{sp.additionalPrice !== 0 ? "(+ "+sp.additionalPrice.toFixed(2) +"$)" : undefined }</span>
                                    </Label>
                                </FormGroup>
                            )}
                            else{
                             return(
                                <FormGroup aria-label={"options-formGroup"} data-label={"options-formGroup"}>
                                    <>
                                        { sp.maxPerOption > 1 ? 
                                            <>
                                            { sp.qty > 0 && <SVG src={removeCircleOutline} className="invert" style={{ width: 25, verticalAlign: 'sub', cursor: 'pointer'}} onClick={e => handleUpdateSubproduct(sp, false, false, op)}/> }
                                            <span>{ sp.qty ? sp.qty : ''}</span>
                                            { ((sp.qty || 0) < sp.maxPerOption && (op.selectedQty || 0) < op.subProductOptionMaximum) && <SVG src={addCircleOutline} className="invert" style={{ width: 25, verticalAlign: 'sub', cursor: 'pointer'}} onClick={e => handleUpdateSubproduct(sp, true, false, op)}/>}

                                            {sp.optionProduct ? sp.optionProductObj.name : sp.name} <span className="orderAdditionnalPrice">{sp.additionalPrice !== 0 ? "(+ "+sp.additionalPrice.toFixed(2) +"$)" : undefined }</span>
                                            { (sp.isMultiOptions || sp.optionProduct) && (sp.optionProductObj?.subProducts?.filter( sp => sp.name !== 'standard' ).length > 0) && sp.qty > 0 && <div className='subProduct_block_options'><span onClick={ _ => { fillSubproduct(sp).then( _ => { setMySlectedOption(sp); setIsModalOptionOpen(true) }) }}>
                                                { 
                                                    sp.optionProductObj?.subProducts?.filter( sp => sp.qty && sp.qty > 0 ).length > 0 ?
                                                    sp.optionProductObj?.subProducts?.filter( sp => sp.qty && sp.qty > 0 ).map((sp, i, arr) => (
                                                        <>
                                                        <span>{sp.qty}x {sp.name} {i < arr.length - 1 ? ' | ' : undefined}</span>
                                                        { i == arr.length - 1 && <span className="orderAdditionnalPrice" style={{ position: "relative", right: 0}}>(+{arr.reduce((p, c) => p+c.additionalPrice, 0)}$)</span> }
                                                        </>
                                                    )) :
                                                    i18n.t("payment.customizeItem")
                                                }
                                            </span></div> }
                                            </>
                                            :
                                            <>
                                            <Label className="options_checkbox_label">
                                            <Input type="checkbox" disabled={!checkSelectedQteFromSubcategory(sp, op) && (!sp.qty || sp.qty == 0) || checkProductQty(sp, op)} name="options" value={sp.id} onClick={e => {handleUpdateSubproduct(sp, e.target.checked, false, op)}} checked={sp.qty > 0} data-name={sp.optionProduct ? sp.optionProductObj.name : sp.name} data-price={sp.additionalPrice} />
                                            </Label>
                                            { sp.optionProduct ? sp.optionProductObj.name : sp.name } <span className="orderAdditionnalPrice">{sp.additionalPrice !== 0 ? "(+ "+sp.additionalPrice.toFixed(2) +"$)" : undefined }</span>
                                            { (sp.isMultiOptions || sp.optionProduct) && (sp.optionProductObj?.subProducts?.filter( sp => sp.name !== 'standard' ).length > 0) && sp.qty > 0 && <div className='subProduct_block_options'><span onClick={ _ => { fillSubproduct(sp).then( _ => { setMySlectedOption(sp); setIsModalOptionOpen(true) }) }}>
                                                { 
                                                    sp.optionProductObj?.subProducts?.filter( sp => sp.qty && sp.qty > 0 ).length > 0 ?
                                                    sp.optionProductObj?.subProducts?.filter( sp => sp.qty && sp.qty > 0 ).map((sp, i, arr) => (
                                                        <>
                                                        <span>{sp.qty}x {sp.name} {i < arr.length - 1 ? ' | ' : undefined}</span>
                                                        { i == arr.length - 1 && <span className="orderAdditionnalPrice" style={{ position: "relative", right: 0}}>(+{arr.reduce((p, c) => p+c.additionalPrice, 0)}$)</span> }
                                                        </>
                                                    )) :
                                                    i18n.t("payment.customizeItem")

                                                }
                                            </span></div> }
                                            </>
                                        }
                                    </>
                                </FormGroup>   
                            )}
                        })}
                    </AccordionItemPanel>
                </AccordionItem>
            )}) }

        </Accordion> }

        { isModalOptionOpen && <ProductChoice product={mySlectedOption} checkValidationButton={checkValidationButton} handleUpdateSubproduct={handleUpdateSubproduct} checkSelectedQteFromSubcategory={checkSelectedQteFromSubcategory} root={false} setMyParentModal={setIsModalOptionOpen} fillSubproduct={fillSubproduct} /> }

        { root && !isModalOptionOpen ? 
            <>
                {product.isService ? 
                    // product.subproducts > 1 because the "Standard" subproduct does not count
                    <button className="button primary" aria-label={"service-select"} data-label={"service-select"} disabled={product.currentlyUnavailable || product.subproducts > 1 && (!product.selectedSubproducts || product.selectedSubproducts.length < 1)} onClick={ (e) => chooseService(product)}>{i18n.t( product.quantity > 0 ? 'global.choose' : 'payment.outOfStock')}</button>
                :
                    <button className="button primary" aria-label={"product-select"} data-label={"product-select"} disabled={product.currentlyUnavailable || !checkValidationButton()} onClick={ (e) => { chooseProduct(); clearMyProductSubProducts(); handleToggleModal(e.target.dataset.label) }}>{i18n.t( product.quantity > 0 ? 'global.choose' : 'payment.outOfStock')}</button>
                } 
            </>
            :
            <> { !isModalOptionOpen && <button className="button primary" disabled={!checkValidationButton(product)} onClick={ (e) => setMyParentModal(false)}>{i18n.t('global.choose')}</button> } </>
        }
        </>
    )
}

export default StoreProductSingleComponent ;