import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import ReactLoading from 'react-loading';
import AppleLogin from 'react-apple-login';

import { useToasts } from 'react-toast-notifications'

import AppConfig from "../../config/config";
import i18n from '../../translations/i18n';

const LoginComponent = ({ isOpen, toggle, toggleSignUp, handleChangeInput, handleLogin, formErrors, isLoading, handleForgot, forgotSuccess, resetState, toastMessage }) => {
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  const [isForgot, setIsForgot] = useState(false);

  const getForgotPage = () => {
    resetState();
    setIsForgot(!isForgot);
  }

  const { addToast } = useToasts();

  return (
    <>
    { toastMessage ? addToast(toastMessage.msg, { appearance: toastMessage.type, autoDismiss: true }) : undefined }

    <Modal isOpen={isOpen} toggle={toggle} id="signUpModal">
      <button onClick={toggle} className="btnClose">
        <img src="/images/menu/icon-close.svg" alt="icon close" />
      </button>
      { !isForgot ?
        <div>
          <h4>{i18n.t('login.login')}</h4>

          <a href={AppConfig.baseUrlApi + '/auth/facebook'}>
            <button id="signupFacebook" className="primary">
              {i18n.t('login.login_with')}
              <img src="/images/social/social-facebook-filled.svg" alt="icon facebook" />
            </button>
          </a>
          {false ?
            <AppleLogin
              clientId={"com.react.apple.login"}
              redirectURI={"https://redirectUrl.com"}
              responseType={"id_token"}
              responseMode={"query"}
              designProp={
                {
                  height: 35,
                  width: 200,
                  color: "black",
                  border: true,
                  type: "continue",
                  border_radius: 15,
                  scale: 1,
                  locale: "fr_CA",
                }
              }
            />
            : undefined}
          <p>{i18n.t('login.or')} </p>

          <form>

            <div className="inputContainer">
              <img src="/images/menu/icon-mail.svg" alt="icon mail" />
              <input type="text" name="email" placeholder={i18n.t('login.field.email')} autoComplete="username" onChange={e => handleChangeInput(e)} />
            </div>

            <div className="inputContainer">
              <img src="/images/menu/icon-password.svg" alt="icon password" />
              <input type="password" name="password" placeholder={i18n.t('login.field.password')} autoComplete="current-password" onChange={e => handleChangeInput(e)} />
            </div>

            <div className="forgotContainer">
              <a className="forgotText" onClick={getForgotPage}>
                {i18n.t('login.forgot_password')}
              </a>
            </div>

            {formErrors && formErrors.login ? Object.keys(formErrors.login).map(t => <p className="errorMessage" key={`error${t}`}>{formErrors.login[t]}</p>) : undefined}

            {
              isLoading ? (
                <div className="alignCenterContent loading">
                  <ReactLoading type={'bars'} color={'#342535'} height={30} width={30} />
                </div>
              ) : (
                  <div>
                    <button type="submit" className="primary" onClick={(e) => handleLogin(e)}>{i18n.t('login.login_with_email')}</button>
                    <p className="already" onClick={_ => { toggle(); toggleSignUp(); }} >
                      {i18n.t('login.not_already_registered')}
                    </p>
                  </div>
                )

            }
          </form>
        </div>
        :
        <div>
          <h4>{i18n.t('login.forgot_title')}</h4>
          <form className="forgotForm">
            {
              forgotSuccess ?
                <h6>{i18n.t('login.forgot_success')}</h6>
                :
                <h6>{i18n.t('login.forgot_indication')}</h6>
            }
            <div className="inputContainer">
              <img src="/images/menu/icon-mail.svg" alt="icon mail" />
              <input type="text" name="email" placeholder={i18n.t('login.field.email')} autoComplete="username" onChange={e => handleChangeInput(e)} />
            </div>

            {formErrors && formErrors.forgot ? Object.keys(formErrors.forgot).map(t => <p className="errorMessage" key={`error${t}`}>{formErrors.forgot[t]}</p>) : undefined}

            {
              isLoading ? (
                <div className="alignCenterContent loading">
                  <ReactLoading type={'bars'} color={'#342535'} height={30} width={30} />
                </div>
              ) : (
                  <div className="mt-3">
                    {
                      !forgotSuccess ?
                        <button type="submit" className="primary" onClick={(e) => handleForgot(e)}>{i18n.t('login.send_forgot_email')}</button>
                        :
                        undefined
                    }
                    <p className="already" onClick={getForgotPage} >
                      {i18n.t('login.back_to_login')}
                    </p>
                  </div>
                )

            }
          </form>
        </div>
      }
    </Modal>
  </>)
};

export default LoginComponent;