import React, { Component } from 'react';

import StoreItemComponent from '../../web/components/Store/StoreItem';

class StoreItem extends Component {

    render() {
        return (
            <StoreItemComponent
                store={this.props.store}
                product={this.props.product}
                category={this.props.category}
                image={this.props.image}
                name_EN={this.props.name_EN}
                name_FR={this.props.name_FR}
            />
        );
    }
}

export default (StoreItem);
