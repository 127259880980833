import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Col, Row, Button, Form, FormText, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';


import i18n from '../../../translations/i18n';

const CreateYourStore = ({ }) => {
    
    return(
    <div className="paddingContainerMobile">
        <div id="partnerPage" className="qcommercepage">

            <Container id="title-section">
                <img src={ "/images/market/" + ( i18n.locale === 'en' ? 'en' : 'fr' ) + "/market_header.png"} style={{ width: "100%" }} />

                < hr/>

                <div className="title-text-wrapper">
                    <div className="title-text">
                        <h1 className="bigTitle" style={{ textAlign: 'center', fontSize: "2.7em", marginBottom: "25px"}}>{ i18n.t("market.market_title") }</h1>
                        <p style={{ textAlign: 'center'}}>{ i18n.t("market.market_subtitle") }</p>
                    </div>
                </div>

                <Row>
                    <Col xs={6} sm={3}><img style={{ width: "100%" }} src={ "/images/market/" + ( i18n.locale === 'en' ? 'en' : 'fr' ) + "/market_1.png"} /><p>{ i18n.t("market.market_caption_1") }</p></Col>
                    <Col xs={6} sm={3}><img style={{ width: "100%" }} src={ "/images/market/" + ( i18n.locale === 'en' ? 'en' : 'fr' ) + "/market_2.png"} /><p>{ i18n.t("market.market_caption_2") }</p></Col>
                    <Col xs={6} sm={3}><img style={{ width: "100%" }} src={ "/images/market/" + ( i18n.locale === 'en' ? 'en' : 'fr' ) + "/market_3.png"} /><p>{ i18n.t("market.market_caption_3") }</p></Col>
                    <Col xs={6} sm={3}><img style={{ width: "100%" }} src={ "/images/market/" + ( i18n.locale === 'en' ? 'en' : 'fr' ) + "/market_4.png"} /><p>{ i18n.t("market.market_caption_4") }</p></Col>
                </Row>

                <p style={{ textAlign: 'center', marginTop: 25}}>{ i18n.t("market.market_speech") }</p>
             
                < hr/>

                <Row>
                    <Col sm={6}>
                        <h2 className="bigTitle">{i18n.t("market.increase_visibility")}</h2>
                        <p>{i18n.t("market.increase_visibility_desc")}</p>

                        <h2 className="bigTitle">{i18n.t("market.free_platform")}</h2>
                        <p>{i18n.t("market.free_platform_desc")}</p>

                        <h2 className="bigTitle">{i18n.t("market.quality_delivery")}</h2>
                        <p>{i18n.t("market.quality_delivery_desc")}</p>
                    </Col>
                    <Col sm={6}>
                        <img src={ "/images/market/" + ( i18n.locale === 'en' ? 'en' : 'fr' ) + "/market_anim.gif"} style={{ width: "100%" }} />
                    </Col>
                </Row>

                < hr/>

                <h1 className="bigTitle" style={{ textAlign: 'center', fontSize: "2.7em", marginBottom: "25px"}}>{ i18n.t("market.selling_genie") }</h1>
                <p style={{ textAlign: 'center', marginTop: 25}}>{ i18n.t("market.selling_genie_sub") }</p>

                <img src={ "/images/market/" + ( i18n.locale === 'en' ? 'en' : 'fr' ) + "/market_example.png"} style={{ width: "100%" }} />

                <p style={{ textAlign: 'center', marginTop: 25}}>{ i18n.t("market.selling_genie_text") }</p>

            </Container>

        </div>
    </div>
)}

export default CreateYourStore;