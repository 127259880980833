import React from 'react';
import StoreItemComponent from './StoreItem';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { resizeImage } from '../../../config/utils';

import i18n from '../../../translations/i18n';

const StorePageComponent = ({ stores, categories }) => (
        <div className="paddingContainer">
            <section id="storeSection">
                <div id="breadcrumb">
                    <p><Link to="/">{i18n.t('footer.home')}</Link></p>
                </div>
                <h4> </h4>

                <div className="storeList">
                    <Row style={{ width: '100%', marginBottom: 50 }}>
                    { categories.length > 0 && categories.map( (cat, index) => (
                        <><Col sm="4" md="3" key={'catIndex-' + index}>
                            <Link to={"/category/" + cat.id} style={{}}>
                                <div className="storeImage" style={{
                                    backgroundImage: "linear-gradient(#342535, #342535), url('" + resizeImage(cat.image, '_250x.') + "')",
                                    backgroundBlendMode: "hard-light",
                                    height: 150,
                                    borderRadius: 5,
                                    backgroundSize: "cover",
                                    marginBottom: 25,
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <p style={{ color: "white", textAlign: "center", width: "100%", fontSize: "1.3em", marginBottom: 0 }}>{cat.name}</p>
                                </div>
                            </Link>    
                        </Col>
                    </>
                    ))}
                    </Row>
                </div>

            </section>
        </div>
    )

export default StorePageComponent;