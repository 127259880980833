import React from 'react';
import { Row } from 'reactstrap';

import SVG from 'react-inlinesvg';

import { trashOutline } from "ionicons/icons";

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

const Card = ({ card, handleRemoveCard }) => (
    <>
        <Row>

            <Cards
                preview='true'
                cvc=''
                expiry={card.exp_month + '/' + card.exp_year}
                name={card.name}
                issuer={card.brand}
                number={'************' + card.last4}
                locale={{valid: ""}}

            />


                <SVG src={trashOutline} className="invert actionButton" onClick={e => handleRemoveCard()} />

        </Row>

        <hr />
    </>
)

export default Card;